<template>
    <div>
        <PageHeader title="角色管理" hidden-breadcrumb>
            <Button type="primary" icon="md-add" class="ivu-mb" slot="action" @click="onAdd">新增角色</Button>
        </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" @on-submit="onSubmit" ref="table-form" />
            <table-page @onPageChange="onPageChange" :list="list" />
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { renderButton, clearEmpty, jumpPage } from '@/libs/util';
import { SettingRoleList, SettingRoleDel } from '@/api/setting';

export default {
    name: 'setting-role-list',
    components: {
        tableForm,
        tablePage,
    },
    data() {
        return {
            isChangePage: false,
            formList: [
                {
                    title: '角色名称',
                    type: 'input',
                    key: 'name',
                    placeholder: '请输入角色名称',
                },
            ],
            list: {
                current: 1,
                total: 0,
                size: window.$pageSize,
                columns: [
                    {
                        title: '角色名称',
                        key: 'name',
                        align: 'center',
                    },
                    {
                        title: '创建时间',
                        key: 'createDate',
                        align: 'center',
                    },
                    {
                        title: '操作',
                        key: 'oprate',
                        minWidth: 100,
                        align: 'center',
                        render: (h, p) => {
                            return [
                                renderButton(h, { text: '详情', type: 'primary' }, () => {
                                    this.detail(p.row);
                                }),
                                renderButton(h, { text: '修改', type: 'success' }, () => {
                                    this.edit(p.row);
                                }),
                                renderButton(h, { text: '删除', type: 'warning', confirm: true }, () => {
                                    this.delete(p.row);
                                }),
                            ];
                        },
                    },
                ],
                data: [],
                loading: false,
            },
        };
    },

    mounted() {
        this.getData();
        this.$bus.$on('refresh', this.getData);
    },

    beforeDestroy() {
        this.$bus.$off();
    },

    methods: {
        getData(p) {
            this.list.loading = true;
            p = clearEmpty(p);
            let params = {
                current: this.list.current,
                size: this.list.size,
                ...p,
            };

            // console.log('角色列表 请求数据 ：', params)
            SettingRoleList(params).then((data) => {
                this.list.data = (data.records || []).map((item) => {
                    return {
                        id: item.id,
                        name: item.name,
                        createDate: item.createDate,
                    };
                });
                this.isChangePage = false;
                this.list.loading = false;
                this.list.total = data.total;
            });
        },
        onSubmit(params) {
            this.list.current = 1;
            this.getData(params);
        },
        onPageChange(val) {
            if (this.isChangePage) return;
            this.list.current = val;
            let form = this.$refs['table-form'].getFormData();
            this.getData(form);
        },
        onPageSizeChange(val) {
            this.list.current = 1;
            this.isChangePage = true;
            this.list.size = val;
            let form = this.$refs['table-form'].getFormData();
            this.getData(form);
        },
        onAdd() {
            jumpPage({ path: '/sub-vue2/setting/role/edit', params: { mode: 2 } });
        },
        detail(row) {
            jumpPage({ path: '/sub-vue2/setting/role/edit', params: { id: row.id, mode: 1 } });
        },
        edit(row) {
            jumpPage({ path: '/sub-vue2/setting/role/edit', params: { id: row.id, mode: 2 } });
        },
        delete(row) {
            SettingRoleDel(row.id).then(() => {
                this.$Notice.success({
                    title: '删除成功',
                });
            });
            this.list.data.splice(row._index, 1);
        },
    },
};
</script>
