<!--
 * @Author: chengleilei
 * @Date: 2021-11-03 10:22:34
 * @Description: 提现
-->
<template>
    <div class="withdrawal-page" v-loading="pageLoading">
        <PageHeader title="提现" hidden-breadcrumb> </PageHeader>
        <Card class="ivu-mt" :bordered="false" dis-hover>
            <p>账户总余额</p>
            <h2>{{ pageData.balance }}<span class="unit">元</span></h2>
            <Row>
                <Col span="4">含到账金额：{{ pageData.receivedBalance }}元</Col>
                <Col span="20">在途金额：{{ pageData.receivableBalance }}元</Col>
            </Row>
            <br />
            <Alert show-icon>
                提示
                <template slot="desc">
                    <p>
                        1.账户余额=
                        到账金额+在途金额；到账金额：截止至昨日收到的资金，已到账；在途金额：今日收到的资金，明日到账
                    </p>
                    <p>2.到账金额提现当日到银行卡；在途金额提现下一工作日到银行卡</p>
                </template>
            </Alert>
        </Card>
        <Card class="ivu-mt" :bordered="false" dis-hover title="我的账户">
            <div class="empty-wrapper" v-if="pageData.accounts.length < 1">
                <img src="@/assets/images/empty.png" />
                <h3>您还没创建账户</h3>
            </div>
            <div class="card-wrapper" v-else>
                <Card
                    v-for="item in pageData.accounts"
                    :key="item.id"
                    style="width: 450px; margin-right: 40px; margin-bottom: 20px"
                >
                    <img
                        :src="
                            require(`@/assets/images/${item.accountType == '12' ? 'font-business' : 'font-person'}.png`)
                        "
                    />
                    <div class="card-content">
                        <h4>{{ item.accountName || '-' }}</h4>
                        <div class="card-content__amount">
                            <h4>
                                账户余额：<span>{{ item.balance }}元</span>
                            </h4>
                            <p class="text-btn" @click="withdrawalRecords(item)">提现记录</p>
                        </div>
                        <Divider />
                        <ul>
                            <li class="mb">
                                <p><span>到账金额：</span>{{ item.receivedBalance }}元</p>
                                <Button type="primary" size="small" @click="goWithdrawal(item, 'receivedBalance')">
                                    提现
                                </Button>
                            </li>
                            <li>
                                <p><span>在途金额：</span>{{ item.receivableBalance }}元</p>
                                <Button type="primary" size="small" @click="goWithdrawal(item, 'receivableBalance')">
                                    提现
                                </Button>
                            </li>
                        </ul>
                    </div>
                </Card>
            </div>
        </Card>
    </div>
</template>

<script>
import { getAccountList } from '@/api/withdrawal';
import { jumpPage } from '@/libs/util';

export default {
    data() {
        return {
            pageLoading: false,
            pageData: {
                accounts: [],
                balance: null, // 余额
                id: null,
                receivableBalance: null, // 在途余额
                receivedBalance: null, // 到账余额
                userId: null,
            },
        };
    },
    created() {
        this.getData();
    },
    methods: {
        /**
         * @description 获取中金账户信息
         */
        getData() {
            this.pageLoading = true;
            getAccountList()
                .then((res) => {
                    this.pageData = res || {};
                })
                .finally(() => {
                    this.pageLoading = false;
                });
        },
        /**
         * @description 提现按钮单击事件
         * @param {Object} item 单个账户数据
         * @param {type} String receivedBalance到账提现 receivableBalance在途提现
         */
        goWithdrawal(item, type) {
            if (item[type] == 0) {
                this.$Message.error('账户余额为0，不可提现');
                return;
            }
            jumpPage({ path: '/sub-vue2/assets/withdrawal/form', params: { userId: item.userId, type } });
        },
        // 提现记录
        withdrawalRecords(item) {
            jumpPage({ path: '/sub-vue2/assets/withdrawal/list', params: { userId: item.userId } });
        },
    },
};
</script>

<style lang="less" scoped>
.withdrawal-page {
    h2 {
        margin-bottom: 16px;
        .unit {
            margin-left: 4px;
            font-weight: 400;
            font-size: 14px;
        }
    }
    .empty-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        img {
            width: 200px;
            height: 220px;
        }
    }
    .card-wrapper {
        display: flex;
        position: relative;
        flex-flow: row wrap;
        img {
            position: absolute;
            left: 16px;
            top: 16px;
            width: 48px;
            height: 48px;
        }
        .card-content {
            padding-left: 64px;
            h4 {
                font-size: 15px;
            }
            &__amount {
                display: flex;
                justify-content: space-between;
                margin-top: 16px;
                font-size: 15px;
                h4 {
                    color: #999;
                    span {
                        color: #ff9d5b;
                    }
                }
                .text-btn {
                    cursor: pointer;
                    color: #2d8cf0;
                }
            }
            ul {
                li {
                    display: flex;
                    justify-content: space-between;
                    p {
                        span {
                            color: #999;
                        }
                    }
                }
                .mb {
                    margin-bottom: 16px;
                }
            }
        }
    }
}
</style>
