<template>
    <div class="role-edit" v-loading="loading">
        <PageHeader :title="title" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage">
                <Icon type="md-arrow-back" />
            </div>
        </PageHeader>
        <Card title="基本信息" :bordered="false" dis-hover class="ivu-mt">
            <Form ref="form" :model="form" :rules="rules" :label-width="100">
                <Row :gutter="24">
                    <Col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
                        <FormItem label="角色名称：" prop="name">
                            <Input v-if="mode == 2" v-model="form.name" :maxlength="15"></Input>
                            <span v-else>{{ form.name }}</span>
                        </FormItem>
                    </Col>
                </Row>
                <Row :gutter="24">
                    <Col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
                        <FormItem label="描述：">
                            <Input
                                v-model="form.description"
                                :disabled="mode == '1' ? true : false"
                                type="textarea"
                                :autosize="{ minRows: 2, maxRows: 3 }"
                                :maxlength="30"
                                placeholder="请输入描述信息..."
                            ></Input>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        </Card>
        <Card title="角色菜单" :bordered="false" dis-hover class="ivu-mt ivu-pb-16">
            <Tree ref="roleTree" :data="treeList" show-checkbox check-directly></Tree>
        </Card>
        <FooterToolbar v-if="mode == '2'">
            <Button type="primary" :disabled="disabled" @click="onSave">保存</Button>
        </FooterToolbar>
    </div>
</template>

<script>
import { SettingMenuList, SettingRoleSave, SettingRoleInfo } from '@/api/setting';
import { transPathToName, jumpPage } from '@/libs/util';

export default {
    name: 'setting-role-edit',
    data() {
        return {
            id: this.$route.query.id || null,
            title: this.$route.query.id ? (this.$route.query.mode === '1' ? '角色详情' : '编辑角色') : '新增角色',
            mode: this.$route.query.mode || null,
            menuList: [],
            disabled: false,
            loading: false,
            form: {
                name: '',
                description: '',
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入角色名称',
                        trigger: 'blur',
                    },
                ],
            },
            treeList: [],
        };
    },
    mounted() {
        if (this.id) {
            this.getData();
        } else {
            this.getMenu();
        }
    },
    methods: {
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/setting/role/list' });
        },
        getData() {
            if (!this.id || this.loading) return;
            this.loading = true;
            this.disabled = true;
            SettingRoleInfo(this.id)
                .then((data) => {
                    this.treeList = this.resolveTree(data.menuVos || []);
                    this.form.name = data.name;
                    this.form.description = data.description;
                    this.loading = false;
                    this.disabled = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.disabled = false;
                });
        },
        getMenu() {
            SettingMenuList().then((data) => {
                this.treeList = this.resolveTree(data || []);
            });
        },
        resolveTree(list) {
            const newList = list.map((parent) => {
                const newParent = {
                    title: parent.title,
                    authId: parent.id,
                    checked: parent.checked,
                    disabled: this.mode == 1 ? true : !parent.canUpdate,
                    expand: true,
                    children:
                        parent.children &&
                        parent.children.map((child) => {
                            const newChild = {
                                title: child.title,
                                authId: child.id,
                                checked: child.checked,
                                disabled: this.mode == 1 ? true : !child.canUpdate,
                            };
                            return newChild;
                        }),
                };
                return newParent;
            });
            return newList;
        },
        onSave() {
            if (this.disabled) return;
            this.disabled = true;
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let param = {
                        name: this.form.name,
                        description: this.form.description,
                        menusIds: this.$refs['roleTree'].getCheckedNodes().map((item) => item.authId),
                    };
                    if (this.id) {
                        param.id = this.id;
                    }
                    SettingRoleSave(param)
                        .then(() => {
                            this.$Notice.success({
                                title: `${this.id ? '修改' : '新增'}成功`,
                            });
                            setTimeout(() => {
                                this.jumpPrevPage();
                                this.$bus.$emit('refresh');
                            }, 100);
                        })
                        .catch(() => {
                            this.disabled = false;
                        });
                } else {
                    this.disabled = false;
                }
            });
        },
    },
};
</script>
