<!--
 * @Author: chengleilei
 * @Date: 2021-09-30 15:54:12
 * @Description: 开户结果页
-->
<template>
    <div class="open-account-result" v-loading="pageLoading">
        <PageHeader title="开户" hidden-breadcrumb> </PageHeader>
        <Card class="ivu-mt" :bordered="false" dis-hover>
            <!-- 开户处理中 -->
            <template v-if="accountStatus == 2">
                <div class="img-wrapper">
                    <img src="@/assets/images/result-loading.png" />
                </div>
                <Result title="开户处理中">
                    <div slot="desc">您已成功提交信息，请耐心等待开户处理，预计1个工作日内完成</div>
                    <div slot="actions">
                        <Button
                            type="primary"
                            @click="
                                goPage('/sub-vue2/assets/dealerAccount/detail', { cpcnAccountId, userType, userId })
                            "
                            >查看账户详情</Button
                        >
                        <Button @click="goPage(`/sub-vue2/assets/dealerAccount/list`)">返回账户管理</Button>
                    </div>
                </Result>
            </template>
            <!-- 开户失败 -->
            <template v-else-if="accountStatus == 6">
                <div class="img-wrapper">
                    <img src="@/assets/images/result-error.png" />
                </div>
                <Result title="开户失败">
                    <div slot="desc">开户失败，请重新开户</div>
                    <div slot="actions">
                        <Button
                            type="primary"
                            @click="
                                goPage('/sub-vue2/assets/dealerAccount/openAccount', {
                                    cpcnAccountId,
                                    userType,
                                    type: 'reOpen',
                                })
                            "
                            >重新开户</Button
                        >
                    </div>
                </Result>
            </template>
            <!-- 开户成功 -->
            <template v-else>
                <div class="img-wrapper">
                    <img src="@/assets/images/result-success.png" />
                </div>
                <Result title="开户成功">
                    <div slot="desc">您已完成开户，请继续办理签约</div>
                    <div slot="actions">
                        <Button
                            type="primary"
                            @click="
                                goPage('/sub-vue2/assets/dealerAccount/signContract', {
                                    userType,
                                    userId,
                                    cpcnAccountId,
                                })
                            "
                            >立即签约</Button
                        >
                    </div>
                </Result>
                <div class="step-wrapper">
                    <Steps>
                        <Step status="finish" title="填写基本信息"></Step>
                        <Step status="finish" title="绑卡认证"></Step>
                        <Step status="finish" title="开户"></Step>
                        <Step title="完成签约"></Step>
                    </Steps>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { accountDetail } from '@/api/dealerAccount';
import { jumpPage, transPathToName } from '@/libs/util';

export default {
    data() {
        return {
            pageLoading: false,
            userType: this.$route.query.userType || '12', // 账户类型【 12=企业用户 13=个体工商用户】
            cpcnAccountId: this.$route.query.cpcnAccountId || '', // 中金账户id
            userId: '', // 用户id
            accountStatus: null, // 账户状态: ：0=待绑卡认证、1=待开户、2=开户处理中、3=待签约、4=签约待完成、5=正常使用中 6=开户失败
        };
    },

    created() {
        this.pageLoading = true;
        this.getData();
    },

    methods: {
        ...mapActions('admin/page', ['close']),

        /**
         * @description 查看账户详情
         */
        getData() {
            const params = { cpcnAccountId: this.cpcnAccountId };
            accountDetail(params)
                .then((res) => {
                    this.userId = res.userId || '';
                    this.accountStatus = res.accountStatus;
                })
                .finally(() => {
                    this.pageLoading = false;
                });
        },

        /**
         * @description 跳转页面
         * @param {String} path 页面路由
         */
        goPage(path, params) {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path, params });
        },
    },
};
</script>

<style lang="less" scoped>
.open-account-result {
    .ivu-mt {
        height: calc(~'100vh - 216px');
    }
    .img-wrapper {
        margin-top: 4%;
        text-align: center;
        img {
            width: 120px;
            height: 120px;
        }
    }
    .step-wrapper {
        display: flex;
        justify-content: center;
        .ivu-steps {
            margin-top: 40px;
            width: 600px;
        }
    }
}
</style>
