<template>
    <div>
        <PageHeader title="导出列表" hidden-breadcrumb class="ivu-mb-16">
            <Button type="info" @click="refresh" slot="action">刷新</Button>
            <Button type="error" @click="massiveDelete" slot="action">批量删除</Button>
        </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-page
                @onSelectionChange="handleSelection"
                @onPageChange="onPageChange"
                @onPageSizeChange="onPageSizeChange"
                :list="list"
            />
        </Card>
    </div>
</template>

<script>
// 导出列表页
import tablePage from '@/components/tablePage';
import { renderButton, downloadFile } from '@/libs/util';
import { GetExportList, Delete } from '@/api/export';

export default {
    name: 'export-list',
    components: {
        tablePage,
    },
    data() {
        return {
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    {
                        title: '全选',
                        type: 'selection',
                        width: 60,
                        align: 'center',
                    },
                    {
                        title: '操作时间',
                        key: 'createDate',
                        align: 'center',
                        minWidth: 180,
                    },
                    {
                        title: '下载类型',
                        key: 'downloadTypeName',
                        align: 'center',
                        minWidth: 180,
                    },
                    {
                        title: '操作',
                        key: 'operate',
                        align: 'center',
                        width: 190,
                        fixed: 'right',
                        render: (h, p) => {
                            return [
                                renderButton(
                                    h,
                                    {
                                        text: p.row.status === 1 || p.row.fileUrl ? '导出Excel' : '导出中...',
                                        type: 'primary',
                                        disabled: p.row.status === 0 && !p.row.fileUrl,
                                    },
                                    () => {
                                        this.onExport(p.row);
                                    },
                                ),
                                renderButton(h, { text: '删除', type: 'warning' }, () => {
                                    this.onDelete(p.row);
                                }),
                            ];
                        },
                    },
                ],
                data: [],
            },
            tableSelection: [],
            selectedData: [],
        };
    },

    mounted() {
        this.getData();
        // 监听刷新 动作
        this.$bus.$on('refreshExportPage', this.getData);
    },
    beforeDestroy() {
        // 取消监听
        this.$bus.$off('refreshExportPage');
    },

    methods: {
        getData() {
            this.list.loading = true;
            let params = {
                current: this.list.current,
                size: this.list.size,
            };
            GetExportList(params)
                .then((res) => {
                    this.list.total = Number(res.total);
                    this.list.data = res.records || [];
                    this.isChangePage = false;
                    this.list.loading = false;
                })
                .catch(() => {
                    this.isChangePage = false;
                    this.list.loading = false;
                });
        },
        onPageChange(val) {
            if (this.isChangePage) return;
            this.list.current = val;
            this.getData();
        },
        onPageSizeChange(val) {
            this.list.current = 1;
            this.isChangePage = true;
            this.list.size = val;
            this.getData();
        },
        // table 选择改变
        handleSelection(val) {
            this.tableSelection = val;
        },
        massiveDelete() {
            if (this.tableSelection.length <= 0) {
                this.$Notice.info({
                    title: '您还未选择数据',
                });
                return;
            }
            // 批量删除
            let ids = [];
            this.tableSelection.map((item) => {
                ids.push(item.id);
            });
            let data = {
                ids: ids,
            };
            this.$Modal.confirm({
                title: '删除',
                content: `您确定删除吗？`,
                onOk: () => {
                    Delete(data).then(() => {
                        this.$Notice.success({
                            title: '批量删除成功',
                        });
                        this.$bus.$emit('refreshExportPage');
                    });
                },
            });
        },

        onDelete(row) {
            let data = {
                ids: [Number(row.id)],
            };
            this.$Modal.confirm({
                title: '删除',
                content: `您确定删除吗？`,
                onOk: () => {
                    Delete(data).then(() => {
                        this.$Notice.success({
                            title: '删除成功',
                        });
                        // this.list.data.splice(item.index, 1)
                        this.$bus.$emit('refreshExportPage');
                    });
                },
            });
        },

        onExport(row) {
            downloadFile(row.fileUrl);
        },

        refresh() {
            this.list.current = 1;
            this.isChangePage = true;
            this.$bus.$emit('refreshExportPage');
        },
    },
};
</script>

<style scoped></style>
