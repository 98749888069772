<!--
  文件描述：可伸缩table
  创建时间：2023/4/26 10:04
  创建人：Administrator
-->
<template>
    <div class="flex-table-cpm">
        <div class="table-wrapper">
            <div class="table-header">
                <div
                    class="table-header-item"
                    v-for="(item, index) in list.columns"
                    :key="Math.random() * index"
                    :style="{ width: `${item.percentage}` }"
                >
                    <span>{{ item.title }}</span>
                </div>
            </div>
            <div class="table-body">
                <div class="table-list" v-if="list.data && list.data.length > 0">
                    <div class="table-row" v-for="(item, index) in list.data" :key="index">
                        <div class="table-row-top">
                            <div class="code-wrapper">
                                <p class="subject-item NO-item">
                                    订单编号：{{ item.orderNo }}
                                    <span class="copy-btn" v-copy="item.orderNo">复制</span>
                                </p>
                            </div>
                            <div class="code-wrapper" v-if="item.paySn">
                                <p class="subject-item NO-item">
                                    支付交易号：{{ item.paySn }}<span class="copy-btn" v-copy="item.paySn">复制</span>
                                </p>
                            </div>
                            <div class="subject-item">订单类型：{{ ['线下支付', '线上支付'][item.payWay] }}</div>
                            <div class="" style="display: flex">
                                <div class="subject-item">
                                    <p v-if="item.sendGoodsStatus != null" class="sendGoodsStatus">
                                        {{ sendGoodsStatusObj[item.sendGoodsStatus].text }}
                                    </p>
                                </div>
                                <div class="subject-item">
                                    <p v-if="item.afterSaleStatus != 1" class="redStatus">
                                        {{ afterSaleStatusObj[item.afterSaleStatus].text }}
                                    </p>
                                </div>
                                <div class="subject-item">
                                    <p
                                        :style="{
                                            color: orderStatusObj[item.orderStatus]['color'],
                                            paddingRight: '12px',
                                        }"
                                    >
                                        {{ orderStatusObj[item.orderStatus].text }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="table-row-flex-main">
                            <div class="three-section-wrapper">
                                <div
                                    class="three-section-item"
                                    v-for="(orderItem, orderIndex) in item.itemsvo"
                                    :key="orderIndex"
                                >
                                    <div class="name-section row-section">
                                        <p>{{ orderItem.productName }}</p>
                                        <div>
                                            <Tag
                                                color="warning"
                                                v-for="(labelItem, labelIdx) in orderItem.labels"
                                                :key="`${labelItem}-${labelIdx}`"
                                            >
                                                {{ labelItem }}
                                            </Tag>
                                        </div>
                                    </div>
                                    <div class="money-section row-section">
                                        <p v-if="!orderItem.payDeposit">活动价￥{{ orderItem.amount || 0 }}</p>
                                        <p v-else-if="orderItem.amount">活动价￥{{ orderItem.amount }}</p>
                                        <p v-if="orderItem.payDeposit">定金￥ {{ orderItem.payDeposit || '-' }}</p>
                                        <p>×{{ `${orderItem.quantity || 0}${orderItem.unitStr}` }}</p>
                                    </div>
                                    <div class="stock-section row-section">
                                        <p v-if="orderItem.sendGoodsQuantity">
                                            发货：{{ orderItem.sendGoodsQuantity || '0' }}
                                        </p>
                                        <p>入库：{{ orderItem.inTimes || '0' }}</p>
                                        <p v-if="orderItem.cashBack">
                                            特价返利：{{ orderItem.cashBack }}元*{{ orderItem.inTimes || 0 }}
                                        </p>
                                        <p>活动编号：{{ orderItem.activitySn }}</p>
                                        <p>活动名称：{{ orderItem.activityName }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="four-section row-section">
                                <template v-for="(orderItem, orderIndex) in item.itemsvo">
                                    <div class="" :key="orderIndex" v-if="orderIndex == 0">
                                        <p>商品总数：{{ item.totalQuantity }}</p>
                                        <p>订单总额：{{ orderItem.orderAmount }}元</p>
                                        <p v-if="orderItem.deductionAmount">
                                            返利抵用：{{ orderItem.deductionAmount }}元
                                        </p>
                                        <p v-if="orderItem.thirdPartyAmount">
                                            三方支付：{{ orderItem.thirdPartyAmount }}元
                                        </p>
                                        <p v-if="orderItem.creditAmount">授信支付：{{ orderItem.creditAmount }}</p>
                                        <p v-if="item.payFee">支付手续费：{{ item.payFee }}元</p>
                                    </div>
                                </template>
                            </div>

                            <div class="five-section row-section">
                                <p>下单时间：{{ item.createDate }}</p>
                                <p>门店名称：{{ item.storeName }}</p>
                                <p>联系人：{{ item.ownerName }}</p>
                                <p>联系方式：{{ item.ownerPhone }}</p>
                                <p>门店维护人：{{ item.salesmanName }}</p>
                            </div>

                            <div class="action-section row-section">
                                <p>
                                    <Button type="primary" size="small" @click="onDetailBtnClick(item)">详情 </Button>
                                </p>
                                <p>
                                    <Button
                                        v-if="item.canRefund"
                                        class="ivu-ml-8"
                                        type="error"
                                        size="small"
                                        @click="onRefundBtnClick(item)"
                                        >退款
                                    </Button>
                                </p>
                                <p>
                                    <Button
                                        v-if="item.canSendGoods == 1"
                                        class="ivu-ml-8"
                                        type="warning"
                                        size="small"
                                        @click="onReTurnBtnClick(item)"
                                        >标记发货
                                    </Button>
                                </p>
                                <p>
                                    <Button
                                        v-if="item.payWay == 0 && item.afterSaleStatus != 5"
                                        class="ivu-ml-8"
                                        type="error"
                                        size="small"
                                        @click="onOffOrderBtnClick(item)"
                                        >取消订单
                                    </Button>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="no-data" v-else>亲，没有找到相关记录哦！~</p>
            </div>
        </div>
        <Spin v-if="list.loading" size="large" fix></Spin>
        <Divider style="margin: 0 0 24px" />
        <div class="ivu-text-right" style="width: 100%; display: flex; justify-content: flex-end">
            <Page
                :total="list.total"
                :current.sync="list.current"
                :page-size-opts="[10, 20, 30, 40]"
                :page-size="list.size || 10"
                show-sizer
                show-elevator
                show-total
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange"
                :simple="isMobile"
                :placement="'top'"
            />
        </div>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

import { mapState } from 'vuex';
import { jumpPage } from '@/libs/util';

export default {
    name: 'flex-custom-table',
    props: {
        list: {
            type: Object,
            default: () => {
                return {
                    loading: true,
                    columns: [],
                    data: [],
                };
            },
        },
    },
    components: {},
    data() {
        return {
            // 发货状态【0：待发货，1：已部分发货，2：已全部发货】；null 不要展示发货状态
            sendGoodsStatusObj: {
                0: {
                    text: '待发货',
                },
                1: {
                    text: '部分发货',
                },
                2: {
                    text: '全部发货',
                },
            },
            orderStatusObj: {
                1: {
                    text: '待付款',
                    color: '#f59a23',
                },
                2: {
                    text: '已付款\n(待入库)',
                    color: '#18ba27',
                },
                3: {
                    text: '已完成',
                    color: '#1890ff',
                },
                4: {
                    text: '已关闭',
                    color: 'inherit',
                },
                5: {
                    text: '已下单',
                    color: '#18ba27',
                },
                6: {
                    text: '已取消',
                    color: 'inherit',
                },
            },
            //售后状态【1=无退款；2=已部分退款；3=已全额退款；4=已部分取消；5=已全部取消】
            afterSaleStatusObj: {
                1: {
                    text: '无退款',
                },
                2: {
                    text: '部分退款',
                },
                3: {
                    text: '全额退款',
                },
                4: {
                    text: '部分取消',
                },
                5: {
                    text: '全部取消',
                },
            },
        };
    },
    computed: {
        ...mapState('admin/layout', ['isMobile']),
    },
    methods: {
        /**
         * @description 标记发货
         * @param {Object} item 列表行数据
         */
        onReTurnBtnClick(item) {
            this.$emit('onReturnBtnClick', item);
        },
        /**
         * @description 退款
         * @param {Object} item 列表行数据
         */
        onRefundBtnClick(item) {
            this.$emit('onRefundBtnClick', item);
        },
        /**
         * @description 退款
         * @param {Object} item 列表行数据
         */
        onOffOrderBtnClick(item) {
            this.$emit('onOffOrderBtnClick', item);
        },
        /**
         * @description 查看详情
         * @param {Object} item 列表行数据
         */
        onDetailBtnClick(item) {
            jumpPage({
                path: '/sub-vue2/sales/order/orderDetail',
                params: { id: item.id, payType: item.payWay == 0 ? 'offLine' : 'onLine' },
            });
        },
        onSelectionChange(val) {
            this.$emit('onSelectionChange', val);
        },
        onPageChange(val) {
            this.$emit('onPageChange', val);
        },
        onPageSizeChange(val) {
            this.$emit('onPageSizeChange', val);
        },
        getData() {
            this.$emit('getData');
        },
        onCurrentChange(currentRow) {
            this.$emit('highlightRowChange', currentRow);
        },
    },
    // 监控data中的数据变化
    watch: {},
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {},

    beforeCreate() {}, // 生命周期 - 创建之前
    beforeMount() {}, // 生命周期 - 挂载之前
    beforeUpdate() {}, // 生命周期 - 更新之前
    updated() {}, // 生命周期 - 更新之后
    beforeDestroy() {}, // 生命周期 - 销毁之前
    destroyed() {}, // 生命周期 - 销毁完成
    activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped lang="less">
.flex-table-cpm {
    .no-data {
        text-align: center;
        padding: 12px;
    }

    .table-wrapper {
        .table-header {
            width: 100%;

            .table-header-item {
                background-color: #f8f8f9;
                border: 1px solid #eee;
                border-right: none;
                display: inline-block;
                word-wrap: normal;
                vertical-align: middle;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
                word-break: break-all;
                box-sizing: border-box;
                padding: 12px 20px;
                //text-align: center;
            }
        }

        .table-body {
            .table-list {
                .table-row {
                    transition: background-color 0.2s ease-in-out;
                    margin: 16px 0 16px;
                    border-top: 1px solid #e7e7e7;
                    border-bottom: 1px solid #e7e7e7;
                    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
                    border-radius: 4px;
                    background: #0b85f8;

                    &:nth-child(odd) {
                        background-color: #f8f8f9;

                        .ivu-card {
                            background-color: #f8f8f9;
                        }
                    }

                    &:nth-child(even) {
                        background-color: #fff;

                        .ivu-card {
                            background-color: #fff;
                        }
                    }

                    &:hover {
                        background-color: #ebf7ff !important;

                        .ivu-card {
                            background-color: #ebf7ff !important;
                        }
                    }

                    &:active {
                        background-color: #ebf7ff !important;

                        .ivu-card {
                            background-color: #ebf7ff !important;
                        }
                    }

                    .table-row-top {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        min-height: 50px;
                        border-bottom: 1px solid #e7e7e7;

                        .sendGoodsStatus {
                            text-align: center;
                            border-width: 0px;
                            width: 64px;
                            height: 20px;
                            background: inherit;
                            background-color: rgba(249, 240, 255, 1);
                            box-sizing: border-box;
                            border-width: 1px;
                            border-style: solid;
                            border-color: rgba(211, 173, 247, 1);
                            border-radius: 4px;
                            -moz-box-shadow: none;
                            -webkit-box-shadow: none;
                            box-shadow: none;
                            font-family: 'Microsoft YaHei ', 'Microsoft YaHei', sans-serif;
                            font-weight: 400;
                            font-style: normal;
                            font-size: 12px;
                            color: #722ed1;
                            line-height: 20px;
                            margin-right: 8px;
                        }
                        .redStatus {
                            text-align: center;
                            border-width: 0px;
                            width: 64px;
                            height: 20px;
                            background: inherit;
                            background-color: rgba(254, 240, 239, 1);
                            box-sizing: border-box;
                            border-width: 1px;
                            border-style: solid;
                            border-color: rgba(255, 163, 158, 1);
                            border-radius: 4px;
                            -moz-box-shadow: none;
                            -webkit-box-shadow: none;
                            box-shadow: none;
                            font-family: 'Microsoft YaHei ', 'Microsoft YaHei', sans-serif;
                            font-weight: 400;
                            font-style: normal;
                            font-size: 12px;
                            color: #f5222d;
                            line-height: 20px;
                            margin-right: 8px;
                        }

                        .code-wrapper {
                            display: flex;
                            align-items: center;

                            & > p:first-child {
                                margin-right: 8px;
                            }
                        }

                        .subject-item {
                            display: flex;
                            align-items: center;
                            margin: 0 8px;
                            .copy-btn {
                                color: #1890ff;
                                padding-left: 10px;
                                font-weight: normal;
                                cursor: pointer;
                            }
                        }

                        .NO-item {
                            font-weight: 700;
                            white-space: nowrap;
                            word-break: keep-all;
                        }
                    }

                    .table-row-flex-main {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .row-section {
                            padding: 12px 20px;
                        }

                        .three-section-wrapper {
                            flex: 0 0 55%;
                            align-self: stretch;
                            border-right: 1px solid #e7e7e7;
                            .three-section-item {
                                display: flex;
                                border-top: 1px solid #e7e7e7;
                                .name-section {
                                    flex: 25;
                                }
                                .money-section,
                                .stock-section {
                                    flex: 15;
                                }
                            }
                        }

                        .four-section {
                            flex: 0 0 15%;
                            align-self: stretch;
                            position: relative;
                            border-right: 1px solid #e7e7e7;
                        }

                        .five-section {
                            flex: 0 0 15%;
                            align-self: stretch;
                            border-right: 1px solid #e7e7e7;
                        }

                        .action-section {
                            flex: 0 0 15%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            button {
                                margin-left: 0 !important;
                            }

                            & > p {
                                margin-bottom: 8px;
                            }
                        }
                    }

                    .table-row-main {
                        .row-section {
                            padding: 12px 20px;
                        }

                        .three-section-wrapper {
                            border-right: 1px solid #e7e7e7;
                            border-bottom: 1px solid #e7e7e7;
                            min-height: 129px;
                        }

                        .four-section {
                            position: relative;
                            border-right: 1px solid #e7e7e7;
                            border-bottom: 1px solid #e7e7e7;
                        }

                        //.four-section:after {
                        //    content: "";
                        //    display: block;
                        //    position: absolute;
                        //    top: 0;
                        //    right: 0;
                        //    width: 1px;
                        //    height: 100%;
                        //    color: red;
                        //}

                        .five-section {
                            border-right: 1px solid #e7e7e7;
                            border-bottom: 1px solid #e7e7e7;
                        }

                        .action-section {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            button {
                                margin-left: 0 !important;
                            }

                            & > p {
                                margin-bottom: 8px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
