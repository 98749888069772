<template>
    <div class="audit-process">
        <Card :bordered="true" dis-hover class="ivu-mt">
            <Spin fix v-if="loading"> 加载中... </Spin>
            <Form :label-width="180">
                <Row class="ivu-row-flex ivu-row-flex-center ivu-mt-8 ivu-mb-16">
                    <Alert :type="auditBusinessStatus == 3 ? 'info' : 'warning'">{{ auditBusinessStatusStr }}</Alert>
                </Row>
                <!--                <Steps-->
                <!--                    direction="vertical"-->
                <!--                    :status="{-->
                <!--                    '7':'wait',-->
                <!--                    '1': 'finish',-->
                <!--                    '2': 'process',-->
                <!--                    '3': 'finish',-->
                <!--                    '4': 'error',-->
                <!--                    }[auditBusinessStatus]"-->
                <!--                    :current="current">-->
                <!--                    <Step-->
                <!--                        :title="item.nodeName"-->
                <!--                        v-for="(item, index) in auditRecordList"-->
                <!--                        :key="item.nodeId + index"-->
                <!--                        :content="`操作人：${item.auditName || '-'}\xa0\xa0\xa0\xa0\xa0\xa0时间：${item.auditDate ||'-'}`"-->
                <!--                    ></Step>-->
                <!--                </Steps>-->
                <div class="step-wrapper">
                    <div class="ivu-steps ivu-steps-vertical">
                        <template v-for="(item, index) in auditRecordList">
                            <!--         1         3          -->
                            <div
                                class="ivu-steps-item ivu-steps-status-finish"
                                v-if="[1, 3].includes(item.auditStatus)"
                                :key="index"
                            >
                                <div class="ivu-steps-tail"><i></i></div>
                                <div class="ivu-steps-head">
                                    <div class="ivu-steps-head-inner">
                                        <span class="ivu-steps-icon ivu-icon ivu-icon-ios-checkmark"></span>
                                    </div>
                                </div>
                                <div class="ivu-steps-main">
                                    <div class="ivu-steps-title">{{ item.nodeName }}</div>
                                    <div class="ivu-steps-content">
                                        {{
                                            `操作人：${item.auditName || '-'}\xa0\xa0\xa0\xa0\xa0\xa0时间：${
                                                item.auditDate || '-'
                                            }`
                                        }}
                                    </div>
                                </div>
                            </div>
                            <!--2-->
                            <div
                                class="ivu-steps-item ivu-steps-status-process"
                                v-if="item.auditStatus == 2"
                                :key="index"
                            >
                                <div class="ivu-steps-tail"><i></i></div>
                                <div class="ivu-steps-head">
                                    <div class="ivu-steps-head-inner"><span>7</span></div>
                                </div>
                                <div class="ivu-steps-main">
                                    <div class="ivu-steps-title">{{ item.nodeName }}</div>
                                    <div class="ivu-steps-content">
                                        {{
                                            `操作人：${item.auditName || '-'}\xa0\xa0\xa0\xa0\xa0\xa0时间：${
                                                item.auditDate || '-'
                                            }`
                                        }}
                                    </div>
                                </div>
                            </div>

                            <!--4-->

                            <div
                                class="ivu-steps-item ivu-steps-status-error"
                                v-if="item.auditStatus == 4"
                                :key="index"
                            >
                                <div class="ivu-steps-tail"><i></i></div>
                                <div class="ivu-steps-head">
                                    <div class="ivu-steps-head-inner">
                                        <span class="ivu-steps-icon ivu-icon ivu-icon-ios-close"></span>
                                    </div>
                                </div>
                                <div class="ivu-steps-main">
                                    <div class="ivu-steps-title">{{ item.nodeName }}</div>
                                    <div class="ivu-steps-content">
                                        {{
                                            `操作人：${item.auditName || '-'}\xa0\xa0\xa0\xa0\xa0\xa0时间：${
                                                item.auditDate || '-'
                                            }`
                                        }}
                                    </div>
                                </div>
                            </div>

                            <!--                      7  -->
                            <div
                                class="ivu-steps-item ivu-steps-status-wait"
                                v-if="item.auditStatus == 7 || item.auditStatus == null"
                                :key="index"
                            >
                                <div class="ivu-steps-tail"><i></i></div>
                                <div class="ivu-steps-head">
                                    <div class="ivu-steps-head-inner"><span>7</span></div>
                                </div>
                                <div class="ivu-steps-main">
                                    <div class="ivu-steps-title">{{ item.nodeName }}</div>
                                    <div class="ivu-steps-content">
                                        {{
                                            `操作人：${item.auditName || '-'}\xa0\xa0\xa0\xa0\xa0\xa0时间：${
                                                item.auditDate || '-'
                                            }`
                                        }}
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </Form>
        </Card>
        <div class="close-wrapper" style="margin-top: 16px; display: none" v-if="detailInfo.isClosed">
            <Row>
                <Col span="12">
                    <Space direction="vertical" type="flex" size="large">
                        <p>关闭操作人：{{ detailInfo.closeName }}</p>
                        <p>关闭操作时间：{{ detailInfo.closeTime }}</p>
                        <p>关闭原因：{{ detailInfo.closeRemark }}</p>
                    </Space>
                </Col>
            </Row>
        </div>
    </div>
</template>

<script>
import { decorationApplyProcess } from '@/api/decorate';

export default {
    components: {},
    data() {
        return {
            currentStatus: '',
            current: 0,
            loading: true,
            auditRecordList: [],
            auditBusinessStatusStr: '',
            auditBusinessStatus: '',
            detailInfo: {},
        };
    },
    mounted() {
        this.getDetail();
    },
    methods: {
        getDetail() {
            decorationApplyProcess({
                applyId: this.$route.query.id,
            }).then(
                (res) => {
                    this.loading = false;
                    this.detailInfo = res;
                    this.auditRecordList = res.auditProcessList.length
                        ? res.auditProcessList.reverse()
                        : res.auditRecordList.reverse();
                    this.auditBusinessStatusStr = res.auditBusinessStatusStr;
                    this.auditBusinessStatus = res.auditBusinessStatus;
                    //审核状态【1报价完成[广告商节点] 2审核中 3审核通过 4已驳回 7待审核】
                    res.auditProcessList.map((item) => {
                        if (item.auditStatus) {
                            this.current++;
                        }
                    });
                },
                () => {
                    this.loading = false;
                },
            );
        },
    },
};
</script>

<style lang="less" scoped>
.audit-process {
    .ellipsis {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .source-img {
        display: inline-block;
        width: 70px;
        height: 70px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 15px;
        cursor: pointer;
        position: relative;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            opacity: 0;
        }
    }

    .ivu-steps-content {
        font-weight: bold;
        color: #666 !important;
        padding-left: 0;
        margin-top: 12px;
    }

    .ivu-alert {
        padding: 8px 24px 8px 24px;
        border-radius: 40px;
    }

    /deep/ .ivu-steps-vertical .ivu-steps-head {
        position: relative;
        top: 7px;
    }

    /deep/ .ivu-steps-vertical {
        display: flex;
        flex-direction: column-reverse;
    }

    /deep/ .ivu-steps-vertical .ivu-steps-tail {
        padding-top: 0;
        top: -30px;
    }

    /deep/ .ivu-steps-vertical .ivu-steps-main {
        //display: flex;
        //align-items: flex-end;
    }

    /deep/ .ivu-steps-vertical .ivu-steps-main .ivu-steps-content {
        padding-bottom: 6px;
    }
}
</style>
