<!-- eslint-disable vue/no-mutating-props -->
<!--
 * @Author: chengleilei
 * @Date: 2021-11-18 10:58:55
 * @Description: 退款弹窗组件
-->
<template>
    <div>
        <Modal v-model="modal" title="订单退款" :mask-closable="false" width="980" :closable="false">
            <Alert show-icon class="com-alert">
                可退数量不含门店已扫码入库的轮胎，如需退款请联系门店将轮胎退回（胎号见页面），经销商扫码退货该轮胎后方可发起退款
            </Alert>
            <div v-show="list.length">
                <h4 class="ivu-mb-8">订单编号：{{ specialOrderNo }}</h4>
                <Table
                    :columns="columns"
                    :data="list"
                    max-height="200"
                    :no-data-text="'暂无商品信息'"
                    :no-filtered-data-text="'暂无商品信息'"
                ></Table>
                <div class="ivu-mt-8">
                    <p class="info">
                        退款数量：<span>{{ refundNum }}</span>
                    </p>
                    <p class="info">
                        退款金额：<span>{{ refundAmount | filterNum }}</span>
                    </p>
                </div>
            </div>
            <div slot="footer">
                <Button type="text" @click="refundCancel">取消</Button>
                <Button type="primary" @click="refundOk">确定</Button>
            </div>
        </Modal>
        <Modal v-model="confirmModal" title="确认退款" :mask-closable="false" :closable="false">
            <Alert type="warning" show-icon>当订单使用了返利抵扣时，优先退返利金额</Alert>
            <div class="ivu-mt-8" v-show="amountInfo.allRefundAmount">
                <p class="info">
                    退款总金额：<span>{{ amountInfo.allRefundAmount }}元</span>
                </p>
                <p class="info">返利退款：{{ amountInfo.rebateRefundAmount }}元</p>
                <p class="info">
                    授信退款：{{ amountInfo.creditRefundAmount == null ? '-' : amountInfo.creditRefundAmount }}元
                </p>
                <p class="info">三方支付退款：{{ amountInfo.thirdRefundAmount }}元</p>
            </div>
            <div slot="footer">
                <Button type="text" @click="confirmCancel">取消</Button>
                <Button type="primary" @click="confirmOk">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import { refundStep1, refundStep2, refundStep3 } from '@/api/sales';

export default {
    props: {
        // 退款弹窗显隐
        modal: {
            type: Boolean,
            default: false,
        },
        // 订单数据
        item: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            confirmModal: false, // 确认退款弹窗显隐
            isRefunding: false, // 是否退款中
            // 商品信息表头
            columns: [
                {
                    title: '商品名称',
                    key: 'productName',
                    align: 'center',
                    minWidth: 130,
                    fixed: 'left',
                    ellipsis: true,
                    tooltip: true,
                },
                {
                    title: '单价付款',
                    align: 'center',
                    minWidth: 100,
                    render: (h, p) => {
                        return h('div', p.row.amount == null ? '-' : p.row.amount);
                    },
                },
                {
                    title: '定金付款',
                    align: 'center',
                    minWidth: 100,
                    render: (h, p) => {
                        return h('div', p.row.payDeposit == null ? '-' : p.row.payDeposit);
                    },
                },
                {
                    title: '下单数量',
                    key: 'quantity',
                    align: 'center',
                    minWidth: 100,
                },
                {
                    title: '扫码入库数量',
                    key: 'storageQuantity',
                    align: 'center',
                    minWidth: 120,
                },
                {
                    title: '入库胎号',
                    key: 'storageTyreCode',
                    align: 'center',
                    minWidth: 150,
                    render: (h, p) => {
                        return [p.row.storageTyreCode || '-'];
                    },
                },
                {
                    title: '已退数量',
                    align: 'center',
                    minWidth: 100,
                    render: (h, p) => {
                        return [p.row.refundQuantity || '-'];
                    },
                },
                {
                    title: '可退数量',
                    key: 'canRefundQuantity',
                    align: 'center',
                    minWidth: 100,
                },
                {
                    title: '本次退款数量',
                    align: 'center',
                    minWidth: 120,
                    fixed: 'right',
                    render: (h, p) => {
                        const maxNum = this.list[p.index].canRefundQuantity;
                        return h('InputNumber', {
                            props: {
                                max: maxNum,
                                min: 1,
                                value: p.row.num || '',
                                disabled: maxNum < 1,
                                formatter: (value) => value && `${parseInt(value)}`,
                            },
                            on: {
                                input: (v) => {
                                    this.$set(this.list[p.index], 'num', v);
                                },
                            },
                        });
                    },
                },
            ],
            list: [], // 商品信息列表
            specialOrderNo: '', // 订单编号
            // 退款金额明细
            amountInfo: {
                allRefundAmount: 0, // 退款总金额
                rebateRefundAmount: 0, // 返利退款
                thirdRefundAmount: 0, // 三方支付退款
            },
        };
    },
    filters: {
        filterNum(value) {
            const arr = String(value).split('.');
            if (arr[1]) {
                return value.toFixed(2);
            }
            return value;
        },
    },
    computed: {
        // 退款数量
        refundNum() {
            const num = this.list.reduce((total, currentValue) => {
                return Number(total || 0) + Number(currentValue.num || 0);
            }, 0);
            return num;
        },
        // 退款金额
        refundAmount() {
            const num = this.list.reduce((total, currentValue) => {
                return (
                    Number(total || 0) +
                    Number((currentValue.num || 0) * (currentValue.amount || currentValue.payDeposit))
                );
            }, 0);
            return num;
        },
    },
    watch: {
        modal(newValue) {
            if (newValue) {
                this.list = [];
                this.getOrderDetail();
            }
        },
    },
    methods: {
        /**
         * @description 查询订单详情和退货情况
         */
        getOrderDetail() {
            const params = { specialOrderId: this.item.id };
            refundStep1(params).then((res) => {
                this.specialOrderNo = res.specialOrderNo || '-';
                this.list = res.orderItemRefundList || [];
            });
        },

        /**
         * @description 计算退款金额明细
         */
        getRefundAmount() {
            const params = {
                refundDetailList: this.relsoveRefundList(),
                specialOrderId: this.item.id,
            };
            refundStep2(params).then((res) => {
                this.amountInfo = res;
            });
        },

        /**
         * @description 处理退款列表数据
         */
        relsoveRefundList() {
            const refundDetailList = [];
            this.list.map((k) => {
                if (k.num) {
                    refundDetailList.push({
                        num: k.num,
                        orderItemId: k.orderItemId,
                    });
                }
            });
            return refundDetailList;
        },

        /**
         * @description 退款弹窗确定
         */
        refundOk() {
            const sum = this.list.reduce((prev, current) => {
                return prev + current.canRefundQuantity;
            }, 0);
            if (sum <= 0) {
                this.$emit('update:modal', false);
                return;
            }
            if (!this.refundAmount) {
                this.$Message.error('请输入退款数量');
                return;
            }
            this.amountInfo = {
                allRefundAmount: 0, // 退款总金额
                rebateRefundAmount: 0, // 返利退款
                thirdRefundAmount: 0, // 三方支付退款
            };
            this.getRefundAmount();
            this.confirmModal = true;
        },

        /**
         * @description 退款弹窗取消
         */
        refundCancel() {
            this.$emit('update:modal', false);
        },

        /**
         * @description 确认弹窗确定
         */
        confirmOk() {
            if (this.isRefunding) return;
            this.isRefunding = true;
            const params = {
                refundDetailList: this.relsoveRefundList(),
                specialOrderId: this.item.id,
            };
            refundStep3(params)
                .then(() => {
                    this.confirmModal = false;
                    this.$emit('update:modal', false);
                    this.$Message.success('操作成功');
                    this.$emit('confirmOk');
                    this.isRefunding = false;
                })
                .catch(() => {
                    this.isRefunding = false;
                });
        },

        /**
         * @description 确认弹窗取消
         */
        confirmCancel() {
            this.confirmModal = false;
        },
    },
};
</script>

<style lang="less" scoped>
.info {
    span {
        color: #e70707;
    }
}
</style>
