<!--
 * @Author: chengleilei
 * @Date: 2021-10-19 16:18:34
 * @Description: 销售订单详情
-->
<template>
    <div class="special-order-detail" v-loading="pageLoading">
        <PageHeader title="销售订单详情" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage">
                <Icon type="md-arrow-back" />
            </div>
        </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <Tabs :value="currentTab" :animated="true" @on-click="handleTabClick">
                <TabPane label="订单信息" name="0">
                    <template>
                        <Button v-if="detailInfo.canRefund" type="primary" @click="onRefundBtnClick">退款</Button>
                        <Button
                            v-if="detailInfo.payType == 0 && detailInfo.afterSaleStatus != 5"
                            type="primary"
                            @click="offOrderModal = true"
                            >取消订单</Button
                        >
                        <Card :bordered="false" title="" dis-hover class="ivu-mt-16 i-table-no-border">
                            <DescriptionList title="">
                                <Description term="订单状态：">
                                    <b
                                        >{{
                                            {
                                                '1': '待付款',
                                                '2': '已付款(待入库)',
                                                '3': '已完成',
                                                '4': '已关闭',
                                                '5': '已下单',
                                                '6': '已取消',
                                            }[detailInfo.orderStatus] || '-'
                                        }}
                                    </b>
                                </Description>
                                <!--                                <Description term="退款状态：">-->
                                <!--                                    <b>{{-->
                                <!--                                            {-->
                                <!--                                                '0': '-',-->
                                <!--                                                '1': '已部分退款',-->
                                <!--                                                '2': '已全额退款',-->
                                <!--                                            }[detailInfo.refundStatus] || '-'-->
                                <!--                                        }}-->
                                <!--                                    </b>-->
                                <!--                                </Description>-->
                                <Description term="售后状态：">
                                    <b>{{ detailInfo.afterSaleStatus === 1 ? '-' : detailInfo.afterSaleStatusStr }} </b>
                                </Description>
                                <Description term="订单编号：">{{ detailInfo.orderNo || '-' }}</Description>
                                <Description term="下单时间：">{{ detailInfo.placeDate || '-' }}</Description>
                                <Description term="取消时间：" v-if="detailInfo.cancelTime">{{
                                    detailInfo.cancelTime || '-'
                                }}</Description>
                                <Description term="关闭时间：" v-if="detailInfo.status == 3">{{
                                    detailInfo.closeTime || '-'
                                }}</Description>
                                <Description term="订单类型：">{{
                                    ['线下支付', '线上支付'][detailInfo.payType] || '-'
                                }}</Description>
                            </DescriptionList>
                        </Card>
                        <Card :bordered="false" title="支付信息" dis-hover class="i-table-no-border">
                            <DescriptionList title="">
                                <Description term="总金额：">{{
                                    detailInfo.orderAmount == null ? '-' : `${detailInfo.orderAmount}元`
                                }}</Description>
                                <template v-if="detailInfo.status != 9">
                                    <Description term="返利抵用：">{{
                                        detailInfo.deductionAmount == null ? '-' : `${detailInfo.deductionAmount}元`
                                    }}</Description>
                                    <Description term="实收金额：">{{
                                        detailInfo.netReceiptsAmount == null ? '-' : `${detailInfo.netReceiptsAmount}元`
                                    }}</Description>
                                    <Description term="支付手续费：">{{ detailInfo.payFee || '-' }}</Description>
                                    <Description term="支付时间：">{{ detailInfo.payTime || '-' }}</Description>
                                    <Description term="支付交易号：">{{ detailInfo.paySn || '-' }}</Description>
                                    <Description term="支付方式：">{{ detailInfo.payTypeStr || '-' }}</Description>
                                    <Description term="已退总金额：">{{
                                        detailInfo.hasRefundAmount == null ? '-' : `${detailInfo.hasRefundAmount}元`
                                    }}</Description>
                                </template>
                            </DescriptionList>
                        </Card>
                        <Card :bordered="false" title="门店信息" dis-hover class="i-table-no-border">
                            <DescriptionList title="">
                                <Description term="门店名称：">{{ detailInfo.storeName || '-' }}</Description>
                                <Description term="门店备注：">{{ detailInfo.remark || '-' }}</Description>
                                <Description term="联系人：">{{ detailInfo.ownerName || '-' }}</Description>
                                <Description term="联系电话：">{{ detailInfo.ownerPhone || '-' }}</Description>
                                <Description term="经销商：">{{ detailInfo.distributorName || '-' }}</Description>
                                <Description term="门店维护人：">{{ detailInfo.salesmanName || '-' }}</Description>
                            </DescriptionList>
                        </Card>
                        <Card :bordered="false" title="商品信息" dis-hover class="i-table-no-border">
                            <Table
                                :columns="productColumns"
                                :data="productList"
                                :no-data-text="'暂无商品信息'"
                                :no-filtered-data-text="'暂无商品信息'"
                            ></Table>
                        </Card>
                    </template>
                </TabPane>
                <TabPane label="退款单" name="1" v-if="$route.query.payType == 'onLine'">
                    <table-page :hasPage="false" :list="list" />
                </TabPane>
            </Tabs>
        </Card>
        <refundModal :modal.sync="modal" :item="{ ...detailInfo, id }" @confirmOk="confirmOk"></refundModal>
        <offOrderModal
            :modal.sync="offOrderModal"
            :item="{ ...detailInfo, id }"
            @confirmOk="offOrderConfirmOk"
        ></offOrderModal>
        <RefundRecordModal ref="refundRecordModalRef" />
        <FooterToolbar>
            <Button class="ml" @click="jumpPrevPage">返回</Button>
        </FooterToolbar>
    </div>
</template>

<script>
import { renderButton } from '@/libs/util';
import { specialOrderDetail, listRefundOrder } from '@/api/sales';
import refundModal from './components/refundModal';
import offOrderModal from './components/offOrderModal';
import RefundRecordModal from './components/RefundRecordModal';
import tablePage from '@/components/tablePage';
import { transPathToName } from '@/libs/util';

export default {
    components: { tablePage, refundModal, offOrderModal, RefundRecordModal },
    data() {
        return {
            offOrderModal: false,
            modal: false, // 退款弹窗显隐
            currentTab: '0', // 当前tab 0订单信息 1退款单
            id: this.$route.query.id || '',
            pageLoading: false,
            detailInfo: {}, // 订单详情数据
            // 商品信息表头
            offLineProductColumns: [
                {
                    title: '商品名称',
                    key: 'productName',
                    align: 'center',
                    minWidth: 120,
                },

                {
                    title: '商品组商品',
                    key: 'groupProductNameList',
                    align: 'center',
                    minWidth: 120,
                    render: (h, { row }) => {
                        return h(
                            'div',
                            {},
                            row.groupProductNameList && row.groupProductNameList.length
                                ? row.groupProductNameList.map((item) => h('div', {}, item))
                                : '-',
                        );
                    },
                },
                {
                    title: '单位',
                    key: 'unit',
                    align: 'center',
                    minWidth: 80,
                    render: (h, p) => {
                        const unitMap = {
                            0: '/',
                            1: '条',
                            2: '套',
                        };
                        return [unitMap[p.row.unit]];
                    },
                },
                {
                    title: '单价',
                    key: 'amount',
                    align: 'center',
                    minWidth: 100,
                },
                {
                    title: '定金',
                    key: 'payDeposit',
                    align: 'center',
                    minWidth: 100,
                    render: (h, p) => {
                        return h('div', p.row.payDeposit == null ? '-' : p.row.payDeposit);
                    },
                },
                {
                    title: '数量',
                    key: 'quantity',
                    align: 'center',
                    minWidth: 100,
                },
                {
                    title: '金额',
                    key: 'total',
                    align: 'center',
                    minWidth: 100,
                },
                {
                    title: '已取消数量',
                    align: 'center',
                    minWidth: 100,
                    render: (h, p) => {
                        return [this.detailInfo.afterSaleStatus === 1 ? 0 : p.row.hasCancelNum || '-'];
                    },
                },
                {
                    title: '返利',
                    align: 'center',
                    minWidth: 140,
                    render: (h, p) => {
                        return h('div', { style: 'min-width:120px;' }, [
                            h('p', p.row.sendGoodsQuantity ? `发货：${p.row.sendGoodsQuantity}` : ''),
                            h('p', `入库：${p.row.inTimes || 0}`),
                            h('p', p.row.cashBack ? `特价返利：${p.row.cashBack || 0}元*${p.row.quantity || 0}` : ''),
                        ]);
                    },
                },
                {
                    title: '标签',
                    align: 'center',
                    minWidth: 150,
                    render: (h, p) => {
                        return [p.row.label || '-'];
                    },
                },
                {
                    title: '赠品名称',
                    align: 'center',
                    minWidth: 150,
                    render: (h, p) => {
                        const str = (JSON.parse(p.row.gift) || []).map((k) => k.name).join(';');
                        return [str || '-'];
                    },
                },
                {
                    title: '活动',
                    align: 'left',
                    minWidth: 160,
                    render: (h, p) => {
                        return h('div', { style: 'min-width:120px;' }, [
                            h('p', `活动编号：${p.row.activitySn || '-'}`),
                            h('p', `活动名称：${p.row.activityName || '-'}`),
                        ]);
                    },
                },
            ],
            productColumns: [],
            onLineProductColumns: [
                {
                    title: '商品名称',
                    key: 'productName',
                    align: 'center',
                    minWidth: 120,
                },

                {
                    title: '商品组商品',
                    key: 'groupProductNameList',
                    align: 'center',
                    minWidth: 120,
                    render: (h, { row }) => {
                        return h(
                            'div',
                            {},
                            row.groupProductNameList && row.groupProductNameList.length
                                ? row.groupProductNameList.map((item) => h('div', {}, item))
                                : '-',
                        );
                    },
                },
                {
                    title: '单位',
                    key: 'unit',
                    align: 'center',
                    minWidth: 80,
                    render: (h, p) => {
                        const unitMap = {
                            0: '/',
                            1: '条',
                            2: '套',
                        };
                        return [unitMap[p.row.unit]];
                    },
                },
                {
                    title: '单价',
                    key: 'amount',
                    align: 'center',
                    minWidth: 100,
                    render: (h, p) => {
                        return [p.row.unitPrice || '-'];
                    },
                },
                {
                    title: '定金',
                    key: 'payDeposit',
                    align: 'center',
                    minWidth: 100,
                    render: (h, p) => {
                        return h('div', p.row.payDeposit == null ? '-' : p.row.payDeposit);
                    },
                },
                {
                    title: '数量',
                    key: 'quantity',
                    align: 'center',
                    minWidth: 100,
                },
                {
                    title: '金额',
                    key: 'total',
                    align: 'center',
                    minWidth: 100,
                },
                {
                    title: '已退数量',
                    align: 'center',
                    minWidth: 100,
                    render: (h, p) => {
                        return [p.row.hasRefundNum || '-'];
                    },
                },
                {
                    title: '已退金额',
                    align: 'center',
                    minWidth: 100,
                    render: (h, p) => {
                        return [p.row.hasRefundAmount || '-'];
                    },
                },
                {
                    title: '返利',
                    align: 'center',
                    minWidth: 160,
                    render: (h, p) => {
                        return h('div', { style: 'min-width:120px;' }, [
                            h('p', p.row.sendGoodsQuantity ? `发货：${p.row.sendGoodsQuantity}` : ''),
                            h('p', `入库：${p.row.inTimes || 0}`),
                            h('p', p.row.cashBack ? `特价返利：${p.row.cashBack || 0}元*${p.row.quantity || 0}` : ''),
                        ]);
                    },
                },
                {
                    title: '标签',
                    align: 'center',
                    minWidth: 150,
                    render: (h, p) => {
                        return [p.row.label || '-'];
                    },
                },
                {
                    title: '赠品名称',
                    align: 'center',
                    minWidth: 150,
                    render: (h, p) => {
                        const str = (JSON.parse(p.row.gift) || []).map((k) => k.name).join(';');
                        return [str || '-'];
                    },
                },
                {
                    title: '活动',
                    align: 'left',
                    minWidth: 160,
                    render: (h, p) => {
                        return h('div', { style: 'min-width:120px;' }, [
                            h('p', `活动编号：${p.row.activitySn || '-'}`),
                            h('p', `活动名称：${p.row.activityName || '-'}`),
                        ]);
                    },
                },
            ],
            productList: [], // 商品信息表格数据
            // 退款单列表
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                columns: [
                    {
                        title: '退款单号',
                        key: 'refundOrderNo',
                        minWidth: 205,
                        align: 'center',
                    },
                    {
                        title: '退款发起时间',
                        key: 'startTime',
                        minWidth: 170,
                        align: 'center',
                    },
                    {
                        title: '退款完成时间',
                        minWidth: 170,
                        align: 'center',
                        render: (h, p) => {
                            return [p.row.successTime || '-'];
                        },
                    },
                    {
                        title: '支付方式',
                        key: 'payTypeStr',
                        minWidth: 100,
                        align: 'center',
                    },
                    {
                        title: '退款总金额',
                        key: 'totalAmount',
                        minWidth: 120,
                        align: 'center',
                    },
                    {
                        title: '返利退款',
                        minWidth: 120,
                        align: 'center',
                        render: (h, p) => {
                            return [p.row.rebateRefundAmount === null ? '-' : p.row.rebateRefundAmount];
                        },
                    },
                    {
                        title: '授信退款',
                        minWidth: 120,
                        align: 'center',
                        render: (h, p) => {
                            return [p.row.creditRefundAmount === null ? '-' : p.row.creditRefundAmount];
                        },
                    },
                    {
                        title: '三方支付退款',
                        minWidth: 120,
                        align: 'center',
                        render: (h, p) => {
                            return [p.row.cpcnRefundAmount === null ? '-' : p.row.cpcnRefundAmount];
                        },
                    },
                    {
                        title: '退款状态',
                        key: 'refundStatusStr',
                        minWidth: 120,
                        align: 'center',
                    },
                    {
                        title: '交易流水号',
                        minWidth: 200,
                        align: 'center',
                        render: (h, p) => {
                            return [p.row.txSn || '-'];
                        },
                    },
                    {
                        title: '备注',
                        minWidth: 120,
                        align: 'center',
                        render: (h, p) => {
                            const str = p.row.returnType === 1 || p.row.refundStatus == '30' ? p.row.remark : '-';
                            return [str];
                        },
                    },
                    {
                        title: '详情',
                        key: 'oprate',
                        align: 'center',
                        width: 90,
                        fixed: 'right',
                        render: (h, p) => {
                            return [
                                p.row.returnType === 1
                                    ? renderButton(h, { text: '查看', type: 'primary' }, () => {
                                          this.checkRecord(p.row);
                                      })
                                    : '-',
                            ];
                        },
                    },
                ],
                data: [],
            },
        };
    },
    created() {
        this.getData();
        this.getRefundList();
    },
    watch: {
        // ['线下支付', '线上支付']
        'detailInfo.payType': {
            handler(payType) {
                console.log('payType', payType);
                if (payType == 0) {
                    this.productColumns = this.offLineProductColumns;
                }
                if (payType == 1) {
                    this.productColumns = this.onLineProductColumns;
                }
            },
            immediate: true,
        },
    },
    methods: {
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
        },

        /**
         * @description 退款单列表查看详情
         */
        checkRecord(row) {
            this.$refs['refundRecordModalRef'].open(row);
        },

        /**
         * @description 确认取消订单刷新页面
         */
        offOrderConfirmOk() {
            this.jumpPrevPage();
        },

        /**
         * @description 查询退款单列表
         */
        getRefundList() {
            listRefundOrder(this.id).then((res) => {
                this.list.data = res || [];
            });
        },

        /**
         * @description 切换tab
         * @param {Number} e tab标识 0订单信息 1退款单
         */
        handleTabClick(e) {
            if (e === this.currentTab) {
                return false;
            }
            this.currentTab = e;
            // e ? this.getRefundList() : this.getData()
        },

        /**
         * @description 确认退款刷新页面
         */
        confirmOk() {
            this.getData();
            this.getRefundList();
        },

        /**
         * @description 退款按钮单击事件
         */
        onRefundBtnClick() {
            this.modal = true;
        },

        /**
         * @description 查特价订单详情
         */
        getData() {
            const params = {
                orderId: this.id,
            };
            this.pageLoading = true;
            specialOrderDetail(params).then((res) => {
                this.pageLoading = false;
                if (res) {
                    this.detailInfo = res || {};
                    this.productList = res.itemsvo || [];
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.special-order-detail {
    padding-bottom: 50px;
    /deep/ .i-table-no-border .ivu-table th {
        background-color: #f8f8f9;
    }
}
</style>
