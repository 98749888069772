<template>
    <div class="fill-order">
        <Modal v-model="orderVisible" title="入库单补录" :mask-closable="false">
            <Spin fix v-if="spinShow"> </Spin>
            <div>
                <Row type="flex" align="middle">
                    <Col span="4" style="text-align: center" class="requireStarLabel">erp单号:</Col>
                    <Col span="18">
                        <Input v-model="erpOrder" placeholder="请准确输入erp单号(THDBH开头)"></Input>
                    </Col>
                </Row>
            </div>
            <div style="text-align: end" slot="footer">
                <Button
                    type="error"
                    @click="
                        () => {
                            this.orderVisible = false;
                        }
                    "
                    >取消</Button
                >
                <Button type="primary" @click="onFillOrder">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import { erpOrderFill } from '../../../api/warehouse';
export default {
    name: 'FillOrder',
    data() {
        return {
            orderVisible: false,
            erpOrder: '',
            spinShow: false,
        };
    },
    methods: {
        open() {
            this.erpOrder = '';
            this.orderVisible = true;
        },
        cancelFill() {
            this.orderVisible = false;
        },
        onFillOrder() {
            if (this.erpOrder) {
                this.saveFillOrder();
            } else {
                this.$Message.warning('请准确输入erp单号');
            }
        },
        saveFillOrder() {
            this.spinShow = true;
            erpOrderFill({
                stockOutSn: this.erpOrder,
            }).then(
                () => {
                    this.spinShow = false;
                    this.erpOrder = '';
                    this.$Message.success('补录成功~');
                    this.$emit('cancelFill');
                },
                () => {
                    this.spinShow = false;
                },
            );
        },
    },
};
</script>

<style scoped></style>
