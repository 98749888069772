<!--
 * @Author: chengleilei
 * @Date: 2021-09-16 14:06:10
 * @Description: 集团客户招标列表
-->
<template>
    <div>
        <PageHeader title="集团客户招标" hidden-breadcrumb> </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" :customLabelWidth="130" ref="table-form" @on-submit="onSubmit" />
            <table-page
                :list="list"
                ref="selection-table"
                @onPageChange="onPageChange"
                @onPageSizeChange="onPageSizeChange"
            />
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { renderButton, clearEmpty, jumpPage } from '@/libs/util';
import { listCustomerBidding } from '@/api/group';

export default {
    components: { tableForm, tablePage },
    data() {
        return {
            list: {
                page: 1,
                size: window.$pageSize,
                total: 0,
                columns: [
                    {
                        title: '项目名称',
                        key: 'projectName',
                        align: 'center',
                        minWidth: 160,
                    },
                    {
                        title: '地区',
                        key: 'cityName',
                        align: 'center',
                        minWidth: 80,
                    },
                    {
                        title: '投标截止时间',
                        key: 'tenderEndDate',
                        align: 'center',
                        minWidth: 160,
                    },
                    {
                        title: '购买标书截止时间',
                        key: 'buyTenderEndDate',
                        align: 'center',
                        minWidth: 160,
                        render: (h, p) => {
                            return [p.row.buyTenderEndDate || '-'];
                        },
                    },
                    {
                        title: '招标单位名称',
                        key: 'biddingName',
                        align: 'center',
                        minWidth: 160,
                    },
                    {
                        title: '状态',
                        key: 'statusStr',
                        align: 'center',
                        minWidth: 160,
                    },
                    {
                        title: '确认竞标倒计时',
                        key: 'distributorEndDate',
                        align: 'center',
                        minWidth: 160,
                        render: (h, p) => {
                            const secondTime = parseInt(
                                (Number(p.row.distributorEndDate) - new Date().getTime()) / 1000,
                            );
                            const flag = secondTime > 0 && secondTime < 60;
                            const time = h('CountDown', {
                                props: {
                                    target: flag
                                        ? Number(p.row.distributorEndDate) + 60000
                                        : Number(p.row.distributorEndDate),
                                    // interval: 60000,
                                    // format: date => {
                                    //     const s = parseInt(date / 1000)
                                    //     let h = 0
                                    //     let m = 0
                                    //     if (s > 60) {
                                    //         m = parseInt(s / 60)
                                    //         if (m >= 60) {
                                    //             h = parseInt(m / 60)
                                    //             m = parseInt(m % 60)
                                    //         }
                                    //     }
                                    //     return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`
                                    // },
                                },
                                on: {
                                    'on-end': () => {
                                        this.handleEnd();
                                    },
                                },
                            });
                            return h('div', [
                                new Date().getTime() < Number(p.row.distributorEndDate) ? time : h('span', '-'),
                            ]);
                        },
                    },
                    {
                        title: '是否中标',
                        key: 'win',
                        align: 'center',
                        minWidth: 100,
                        render: (h, p) => {
                            return [p.row.win || '-'];
                        },
                    },
                    {
                        title: '操作',
                        align: 'left',
                        width: 120,
                        fixed: 'right',
                        render: (h, p) => {
                            return [
                                renderButton(h, { text: '查看', type: 'info' }, () => {
                                    this.goDetails(p.row);
                                    //this.onBtnClick(`./detail?biddingId=${p.row.biddingId}`);
                                }),
                            ];
                        },
                    },
                ],
                data: [],
                loading: false,
            },
            formList: [
                {
                    title: '项目名称',
                    type: 'input',
                    key: 'projectName',
                    placeholder: '请输入项目名称',
                },
                {
                    title: '招标单位名称',
                    type: 'input',
                    key: 'biddingName',
                    placeholder: '请输入招标单位名称',
                },
                {
                    title: '状态',
                    key: 'biddingStatus',
                    type: 'select',
                    options: [
                        {
                            id: 1,
                            name: '待分配',
                        },
                        {
                            id: 2,
                            name: '待经销商确认',
                        },
                        {
                            id: 6,
                            name: '不参加',
                        },
                        {
                            id: 3,
                            name: '待上传标书',
                        },
                        {
                            id: 4,
                            name: '待上传招标结果',
                        },
                        {
                            id: 5,
                            name: '已完结',
                        },
                        {
                            id: 7,
                            name: '待分配已截止',
                        },
                        {
                            id: 8,
                            name: '未分配不参加',
                        },
                    ],
                    placeholder: '请选择状态',
                },
                {
                    title: '是否中标',
                    key: 'win',
                    type: 'select',
                    options: [
                        {
                            id: 1,
                            name: '是',
                        },
                        {
                            id: 0,
                            name: '否',
                        },
                    ],
                    placeholder: '请选择是否中标',
                },
                {
                    title: '投标截止时间',
                    type: 'daterange',
                    key: ['tenderEndStartDate', 'tenderEndEndDate'],
                    placeholder: '请选择',
                },
                {
                    title: '购买标书截止时间',
                    type: 'daterange',
                    key: ['buyTenderEndStartDate', 'buyTenderEndEndDate'],
                    placeholder: '请选择',
                },
            ],
        };
    },

    activated() {
        this.getData();
    },

    methods: {
        /**
         * @description 时间到了
         */
        handleEnd() {
            if (this.list.loading) return;
            this.$Message.warning('页面数据即将刷新...');
            this.onSubmit();
        },

        /**
         * @description 获取表格数据
         */
        getData() {
            const params = this.getParams();
            this.list.loading = true;
            listCustomerBidding(params)
                .then((res) => {
                    this.list.data = [];
                    this.list.total = Number(res.total);
                    this.list.data = res.records || [];
                })
                .catch(() => {})
                .finally(() => {
                    this.list.loading = false;
                });
        },

        /**
         * @description 获取查询参数
         */
        getParams() {
            let p = this.$refs['table-form'].getFormData();
            p = clearEmpty(p, true);
            if ('win' in p) {
                p.win = Boolean(p.win);
            }

            const copyP = { ...p };
            const params = {
                ...copyP,
                current: this.list.page,
                size: this.list.size,
            };
            return params;
        },

        /**
         * @description 页码改变
         * @param {Number} val 页码
         */
        onPageChange(val) {
            this.list.page = val;
            this.getData();
        },

        /**
         * @description x条/页改变
         * @param {Number} val 条数
         */
        onPageSizeChange(val) {
            this.list.page = 1;
            this.list.size = val;
            this.getData();
        },

        /**
         * @description 查询
         */
        onSubmit() {
            this.list.page = 1;
            this.getData();
        },

        /**
         * @description 按钮单击跳转
         * @param {String} url 跳转页面
         */
        goDetails(row) {
            jumpPage({
                path: '/sub-vue2/group/groupBidding/detail',
                params: {
                    biddingId: row.biddingId,
                },
            });
        },
    },
};
</script>

<style lang="less" scoped></style>
