<!--
 * @Author: chengleilei
 * @Date: 2021-10-06 16:55:04
 * @Description: 添加银行卡-填写信息
-->
<template>
    <div class="add-bank-card" v-loading="pageLoading">
        <PageHeader :title="userType == '12' ? '添加对公银行卡' : ' 添加个人银行卡'" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage">
                <Icon type="md-arrow-back" />
            </div>
        </PageHeader>
        <Card class="ivu-mt" dis-hover shadow>
            <Row class="ivu-mb-16">
                <Col push="6" span="12">
                    <Steps :current="currentStep">
                        <Step title="填写信息" content=""></Step>
                        <Step title="提交认证" content=""></Step>
                    </Steps>
                </Col>
            </Row>
            <Card
                :title="userType == '12' ? '请填写您的银行对公账户信息' : '请您填写法人银行账户信息'"
                :bordered="false"
                dis-hover
                class="ivu-mt i-table-no-border"
            >
                <Form hide-required-mark :model="inputForm" ref="inputForm" :rules="rules" :label-width="150">
                    <ElForm
                        hide-required-asterisk
                        :model="inputForm"
                        ref="formDataEl"
                        label-width="150px"
                        :rules="rules"
                    >
                        <FormItem :label="userType == '12' ? '账户名称：' : '持卡人姓名：'">
                            <Input v-model="inputForm.bankAccountName" placeholder="请输入" disabled></Input>
                        </FormItem>
                        <FormItem label="银行账号：" prop="bankAccountNumber">
                            <Input
                                v-model="inputForm.bankAccountNumber"
                                maxlength="20"
                                placeholder="请输入银行账号"
                            ></Input>
                        </FormItem>
                        <ElFormItem label="开户银行：" prop="bankId">
                            <ElSelect v-model="inputForm.bankId" filterable clearable style="width: 100%">
                                <ElOption
                                    :value="item.value"
                                    :label="item.label"
                                    v-for="(item, index) in bankList"
                                    :key="index"
                                >
                                    {{ item.label }}
                                </ElOption>
                            </ElSelect>
                        </ElFormItem>
                        <FormItem label="开户支行：" prop="branchName">
                            <Input v-model="inputForm.branchName" maxlength="20" placeholder="请输入开户支行"></Input>
                        </FormItem>
                        <ElFormItem label="开户行省份：" prop="provinceCode" v-if="userType == '12'">
                            <ElSelect
                                v-model="inputForm.provinceCode"
                                @change="getProvinceName"
                                :loading="provinceLoading"
                                filterable
                                clearable
                                style="width: 100%"
                            >
                                <ElOption
                                    :value="item.value"
                                    :label="item.label"
                                    v-for="item in provinceList"
                                    :key="item.value"
                                >
                                    {{ item.label }}
                                </ElOption>
                            </ElSelect>
                        </ElFormItem>
                        <ElFormItem label="开户行城市：" prop="cityCode" v-if="userType == '12'">
                            <ElSelect
                                v-model="inputForm.cityCode"
                                @change="getCityName"
                                :loading="cityLoading"
                                filterable
                                clearable
                                style="width: 100%"
                            >
                                <ElOption
                                    :value="item.value"
                                    :label="item.label"
                                    v-for="item in cityList"
                                    :key="item.value"
                                >
                                    {{ item.label }}
                                </ElOption>
                            </ElSelect>
                        </ElFormItem>
                        <!-- <FormItem label="开户行省份：" prop="provinceCode">
                            <Select v-model="inputForm.provinceCode" filterable label-in-value @on-change="onChange">
                                <Option v-for="item in provinceList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                            </Select>
                        </FormItem>
                        <FormItem label="开户行城市：" prop="provinceCity">
                            <Cascader filterable label-in-value filter-by-label :data="cityList" v-model="inputForm.provinceCity" @on-change="getCityName"></Cascader>
                        </FormItem> -->
                        <FormItem label="银行预留手机号：" prop="bankPhoneNumber" v-if="userType == '13'">
                            <Input
                                v-model="inputForm.bankPhoneNumber"
                                maxlength="11"
                                placeholder="请输入银行预留手机号"
                            ></Input>
                        </FormItem>
                    </ElForm>
                </Form>
            </Card>
        </Card>
        <FooterToolbar>
            <Button @click="jumpPrevPage">返回</Button>
            <Button type="primary" :loading="pageLoading" @click="submitData">提交</Button>
        </FooterToolbar>
    </div>
</template>

<script>
import { Form, FormItem, Select, Option } from 'element-ui';
import { clearEmpty, MOBILE_REG, transPathToName, jumpPage } from '@/libs/util';
import {
    getBankRecordById,
    getFirstCardRecord,
    addBankCard,
    provinceList,
    cityList,
    bankList,
} from '@/api/dealerAccount';

export default {
    data() {
        const validatePhone = (rule, value, callback) => {
            if (!value) {
                callback(new Error(`请输入银行预留手机号`));
            } else if (!MOBILE_REG.test(value.trim())) {
                callback(new Error(`手机号格式不正确`));
            } else {
                callback();
            }
        };
        return {
            pageLoading: false,
            provinceLoading: false,
            cityLoading: false,
            currentStep: 0,
            cpcnAccountId: this.$route.query.cpcnAccountId || '', // 中金账户id
            userType: this.$route.query.userType || '', // 账户类型【 12=企业用户 13=个体工商用户】
            userId: this.$route.query.userId || '', // 用户id
            cardId: this.$route.query.cardId || '', // 银行卡id 修改信息用
            provinceList: [], // 省份列表
            cityList: [], // 省市列表
            bankList: [], // 银行列表
            // 添加银行卡信息
            inputForm: {
                bankAccountName: '', // 持卡人姓名/企业账户名称
                bankAccountNumber: '', // 银行账号
                bankId: '', // 开户银行
                branchName: '', // 开户支行
                province: '', // 开户行省份
                city: '', // 开户行城市
                cityProvince: '', // 开户行城市级联省份
                provinceCode: '', // 开户行省份编码
                cityCode: '', // 开户行城市编码
                cityProvinceCode: '', // 开户行城市级联省份编码
                bankPhoneNumber: '', // 银行预留手机号
                // provinceCity: [], // 省市对应级联数据
            },
            // 校验规则
            rules: {
                bankAccountNumber: [
                    {
                        required: true,
                        message: '请输入银行账号',
                        trigger: 'blur',
                    },
                ],
                bankId: [
                    {
                        required: true,
                        message: '请选开户银行',
                        trigger: 'change',
                    },
                ],
                branchName: [
                    {
                        required: true,
                        message: '请输入开户支行',
                        trigger: 'blur',
                    },
                ],
                provinceCode: [
                    {
                        required: true,
                        message: '请选择开户行省份',
                        trigger: 'change',
                    },
                ],
                cityCode: [
                    {
                        required: true,
                        message: '请选择开户行城市',
                        trigger: 'change',
                    },
                ],
                // provinceCity: [
                //     {
                //         required: true,
                //         type: 'array',
                //         message: '请选择开户行城市',
                //         trigger: 'change',
                //     },
                // ],
                bankPhoneNumber: [{ validator: validatePhone, trigger: 'blur' }],
            },
        };
    },

    components: {
        ElForm: Form,
        ElFormItem: FormItem,
        ElSelect: Select,
        ElOption: Option,
    },

    created() {
        let arr = [];
        if (this.userType == '12') {
            arr = [this.getBanks(), this.getProvinces()];
        } else {
            arr = [this.getBanks()];
        }
        this.pageLoading = true;
        Promise.all(arr).then(() => {
            this.getData();
        });
    },

    methods: {
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
        },

        /**
         * @description 省份改变时
         */
        getProvinceName(code) {
            if (code) {
                const name = this.provinceList.find((item) => item.value == code).label;
                this.inputForm.province = name;
                this.getCityListByName(name, true);
            }
        },

        /**
         * @description 通过省份查找市的列表
         */
        getCityListByName(name, clear) {
            // 需要情况市选择的数据
            if (clear) {
                this.inputForm.city = '';
                this.inputForm.cityCode = '';
            }
            this.cityLoading = true;
            this.getCitys(name).then((res) => {
                this.cityList = this.reduceCityList(res[0].childCategoryList);
                this.inputForm.cityProvince = res[0].gaodeAreaDTO.name;
                this.inputForm.cityProvinceCode = res[0].gaodeAreaDTO.id;
                this.cityLoading = false;
            });
        },

        /**
         * @description 城市改变时
         */
        getCityName(code) {
            if (code) {
                const name = this.cityList.find((item) => item.value == code).label;
                this.inputForm.city = name;
            }
        },

        /**
         * @description 查省份列表
         */
        getProvinces() {
            return new Promise((resolve, reject) => {
                this.provinceLoading = true;
                provinceList()
                    .then((res) => {
                        const list = [];
                        res.map((k) => {
                            list.push({
                                value: k.code,
                                label: k.province,
                            });
                        });
                        this.provinceList = list;
                        this.provinceLoading = false;
                        resolve();
                    })
                    .catch(() => {
                        this.provinceLoading = false;
                        reject();
                    });
            });
        },

        // /**
        //  * @description 开户行省份选项改变
        //  */
        // onChange(obj) {
        //     this.inputForm.province = obj.label
        // },

        /**
         * @description 查城市列表
         */
        getCitys(name) {
            return new Promise((resolve, reject) => {
                cityList({ provinceName: name })
                    .then((res) => {
                        resolve(res);
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },

        /**
         * 重组省市数据，用于级联选择
         * @param arr
         * @return {*}
         */
        reduceCityList(arr) {
            for (let i = 0; i < arr.length; i++) {
                const item = arr[i];
                item['label'] = item.gaodeAreaDTO['name'];
                item['value'] = item.gaodeAreaDTO['id'];
                if (item.childCategoryList && item.gaodeAreaDTO.level !== 'city') {
                    item.children = this.reduceCityList(item.childCategoryList);
                    delete item.childCategoryList;
                } else {
                    delete item.childCategoryList;
                }
            }
            return arr;
        },

        /**
         * @description 查银行列表
         */
        getBanks() {
            return new Promise((resolve, reject) => {
                bankList()
                    .then((res) => {
                        const list = [];
                        res.map((k) => {
                            list.push({
                                value: k.bankCode,
                                label: k.bankName,
                            });
                        });
                        this.bankList = list;
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },

        /**
         * @description 获取第一张绑卡信息
         */
        getData() {
            if (this.cardId) {
                // 修改信息
                getBankRecordById(this.cardId)
                    .then((res) => {
                        this.userType = {
                            11: '13',
                            12: '12',
                        }[res.bankAccountType];
                        this.userId = res.userId || '';
                        this.userType == '12' && this.getCityListByName(res.province, false);
                        this.inputForm = {
                            bankAccountName: res.bankAccountName || '', // 持卡人姓名/企业账户名称
                            bankAccountNumber: res.bankAccountNumber || '', // 银行账号
                            bankId: res.bankId || '', // 开户银行
                            branchName: res.branchName || '', // 开户支行
                            province: res.province || '', // 开户行省份
                            city: res.city || '', // 开户行城市
                            cityProvince: res.cityProvince || '', // 开户行城市级联省份
                            provinceCode: res.provinceCode || '', // 开户行省份编码
                            cityCode: res.cityCode || '', // 开户行城市编码
                            cityProvinceCode: res.cityProvinceCode || '', // 开户行城市级联省份编码
                            bankPhoneNumber: res.bankPhoneNumber || '', // 银行预留手机号
                            // provinceCity: [res.cityProvinceCode, res.cityCode], // 省市对应级联数据
                        };
                    })
                    .finally(() => {
                        this.pageLoading = false;
                    });
            } else {
                getFirstCardRecord(this.userId)
                    .then((res) => {
                        this.inputForm.bankAccountName = res.bankAccountName || '';
                    })
                    .finally(() => {
                        this.pageLoading = false;
                    });
            }
        },

        /**
         * @description 提交表单数据
         */
        submitData() {
            this.$refs['inputForm'].validate((valid) => {
                this.$refs['formDataEl'].validate((validEl) => {
                    if (!valid || !validEl) {
                        this.$Modal.warning({
                            title: '提示',
                            content: '请完善表单内容',
                        });
                        return false;
                    }
                    let p = Object.assign({}, { userId: this.userId, cardId: this.cardId }, this.inputForm);
                    p = clearEmpty(p);
                    this.pageLoading = true;
                    this.$Message.loading({
                        content: '提交中...',
                        duration: 0,
                    });

                    addBankCard(p)
                        .then((res) => {
                            const bankAccountType = {
                                12: '12',
                                13: '11',
                            }[this.userType];
                            this.$Message.success({
                                duration: 3,
                                content: this.userType == 12 ? '提交成功!' : '验证码已发送',
                            });
                            window.$basePageStore.closeTab(transPathToName(location.pathname));
                            jumpPage({
                                path: '/sub-vue2/assets/dealerAccount/tiedCardApprove',
                                params: {
                                    cpcnAccountId: this.cpcnAccountId,
                                    cardId: res,
                                    firstCardStatus: false,
                                    userType: this.userType,
                                    bankAccountType,
                                    type: 'bank',
                                },
                            });
                        })
                        .catch((err) => {
                            console.error('添加银行卡失败：', err);
                        })
                        .finally(() => {
                            this.$Message.destroy();
                            this.pageLoading = false;
                        });
                });
            });
        },
    },
};
</script>

<style lang="less" scoped>
.add-bank-card {
}
</style>
