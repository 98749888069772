/*
 * @Author: chengleilei
 * @Date: 2021-12-02 17:22:11
 * @Description: 授信
 */

export default {
    namespaced: true,
    state: {
        creditPassword: '', // 授信授权密码
    },
    mutations: {
        /**
         * @description 保存密码
         */
        savePwd (state, pwd) {
            state.creditPassword = pwd;
        },

        /**
         * @description 清除保存的密码
         * */
        clearPwd (state) {
            state.creditPassword = '';
        },
    },
};
