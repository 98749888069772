<template>
    <div v-loading="loading">
        <PageHeader title="企业认证" hidden-breadcrumb />
        <Card :bordered="false" dis-hover class="ivu-mt">
            <Form ref="form" :model="data" :rules="rules" label-position="right" :label-width="155" class="">
                <Card :bordered="false" dis-hover title="经销商用章人">
                    <Row :gutter="24">
                        <Col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
                            <FormItem label="姓名：" prop="name" label-for="name">
                                <Input v-model="data.name" placeholder="请填写姓名" maxlength="30" element-id="name" />
                            </FormItem>
                        </Col>
                        <Col :xl="12" :lg="12" :md="12" :sm="24" :xs="24" style="height: 34px">
                            <p class="ivu-row-flex ivu-row-flex-middle" style="height: 34px">
                                请输入用章人身份证上登记的姓名！
                            </p>
                        </Col>
                    </Row>
                    <Row :gutter="24">
                        <Col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
                            <FormItem label="身份证号：" prop="idNumber" label-for="idNumber">
                                <Input
                                    v-model="data.idNumber"
                                    placeholder="请填写身份证号"
                                    maxlength="18"
                                    element-id="idNumber"
                                />
                            </FormItem>
                        </Col>
                        <Col :xl="12" :lg="12" :md="12" :sm="24" :xs="24" style="height: 34px">
                            <p class="ivu-row-flex ivu-row-flex-middle" style="height: 34px">
                                请输入身份证上姓名对应的身份证号码！
                            </p>
                        </Col>
                    </Row>
                    <Row :gutter="24">
                        <Col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
                            <FormItem label="手机号码：" prop="mobile" label-for="mobile">
                                <Input
                                    v-model="data.mobile"
                                    placeholder="请填写手机号码"
                                    maxlength="11"
                                    element-id="mobile"
                                />
                            </FormItem>
                        </Col>
                        <Col :xl="12" :lg="12" :md="12" :sm="24" :xs="24" style="height: 34px">
                            <p class="ivu-row-flex ivu-row-flex-middle" style="height: 34px">
                                请确保输入的手机号码是以上身份证信息注册的手机号码！
                            </p>
                        </Col>
                    </Row>
                </Card>
                <Card :bordered="false" dis-hover title="机构信息填写" class="ivu-mt">
                    <Row :gutter="24">
                        <Col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
                            <FormItem label="法人代表：" prop="legalPersonName" label-for="legalPersonName">
                                <Input
                                    v-model="data.legalPersonName"
                                    placeholder="请填写法人代表"
                                    maxlength="30"
                                    element-id="legalPersonName"
                                />
                            </FormItem>
                        </Col>
                        <Col :xl="12" :lg="12" :md="12" :sm="24" :xs="24" style="height: 34px">
                            <p class="ivu-row-flex ivu-row-flex-middle" style="height: 34px"></p>
                        </Col>
                    </Row>
                    <Row :gutter="24">
                        <Col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
                            <FormItem label="机构名称：" prop="disName" label-for="disName">
                                <Input
                                    v-model="data.disName"
                                    placeholder="请填写机构名称"
                                    maxlength="30"
                                    element-id="disName"
                                />
                            </FormItem>
                        </Col>
                        <Col :xl="12" :lg="12" :md="12" :sm="24" :xs="24" style="height: 34px">
                            <p class="ivu-row-flex ivu-row-flex-middle" style="height: 34px"></p>
                        </Col>
                    </Row>
                    <Row :gutter="24">
                        <Col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
                            <FormItem label="统一社会信用代码：" prop="disIdNumber" label-for="disIdNumber">
                                <Input
                                    v-model="data.disIdNumber"
                                    placeholder="请填写统一社会信用代码"
                                    maxlength="18"
                                    element-id="disIdNumber"
                                />
                            </FormItem>
                        </Col>
                        <Col :xl="12" :lg="12" :md="12" :sm="24" :xs="24" style="height: 34px">
                            <p class="ivu-row-flex ivu-row-flex-middle" style="height: 34px">
                                请仔细核对统一社会信用代码！
                            </p>
                        </Col>
                    </Row>
                </Card>
            </Form>

            <Row :gutter="24" class="ivu-mt-16" style="margin-bottom: 55px">
                <Col :xl="8" :lg="8" :md="8" :sm="8" :xs="8" push="8" class="">
                    <Alert type="warning" show-icon closable class="ivu-mb-16 com-alert">
                        请核对所填信息准确无误再做提交！
                    </Alert>
                    <Button
                        type="primary"
                        class="ivu-mt-16"
                        shape="circle"
                        long
                        :loading="loading"
                        @click="handleSubmit"
                        size="large"
                        >{{ loading ? '提交中...' : '提交' }}</Button
                    >
                </Col>
            </Row>
        </Card>
    </div>
</template>

<script>
import util, { CARD_REG, NAME_REG, MOBILE_REG, DIS_NUMBER_REG, jumpPage } from '@/libs/util';
import { createCompany } from '@/api/setting';

export default {
    name: 'add',
    data() {
        const validateName = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请填写姓名！'));
            } else if (!NAME_REG.test(value)) {
                // 对第二个密码框单独验证
                callback(new Error('姓名中不能含有阿拉伯数字、标点符号！'));
            } else {
                callback();
            }
        };
        const validateIdNumber = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请填写身份证号！'));
            } else if (value.length < 18) {
                // 对第二个密码框单独验证
                callback(new Error('身份证号码不得少于18位，请核对信息！'));
            } else if (!CARD_REG.test(value)) {
                // 对第二个密码框单独验证
                callback(new Error('请输入合法的身份证号码！'));
            } else {
                callback();
            }
        };

        const validateMobile = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请填写手机号码！'));
            } else if (value.length < 11) {
                callback(new Error('手机号不得少于11位，请核对信息！'));
            }
            if (!MOBILE_REG.test(value)) {
                callback(new Error('请填写正确的手机号码！'));
            } else {
                callback();
            }
        };
        const validateLegalPersonName = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请填写法人代表！'));
            } else if (!NAME_REG.test(value)) {
                // 对第二个密码框单独验证
                callback(new Error('法人代表中不能含有阿拉伯数字、标点符号、字母！'));
            } else {
                callback();
            }
        };
        const validateDisIdNumber = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请填写统一社会信用代码！'));
            } else if (!DIS_NUMBER_REG.test(value)) {
                callback(new Error('请填写合法的统一社会信用代码！'));
            } else {
                callback();
            }
        };
        return {
            data: {
                name: '',
                idNumber: '',
                mobile: '',
                legalPersonName: '',
                disName: '',
                disIdNumber: '',
                invitationUrl: '',
            },
            rules: {
                name: [{ required: true, validator: validateName, trigger: 'change' }],
                idNumber: [{ required: true, validator: validateIdNumber, trigger: 'change' }],
                mobile: [{ required: true, validator: validateMobile, trigger: 'change' }],
                legalPersonName: [{ required: true, validator: validateLegalPersonName, trigger: 'change' }],
                disName: [{ required: true, message: '请填写机构名称！', trigger: 'change' }],
                disIdNumber: [{ required: true, validator: validateDisIdNumber, trigger: 'change' }],
            },
            loading: false,
        };
    },
    methods: {
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    createCompany(this.data).then(
                        () => {
                            this.loading = false;
                            this.$Message.success('提交成功!');
                            this.loading = false;
                            // 更新用户状态
                            window.$baseAccountStore.getAccountInfo(util.cookies.get('Base-username'));
                            jumpPage({ path: '/sub-vue2/setting/company/result' });
                        },
                        () => {
                            this.loading = false;
                        },
                    );
                } else {
                    this.loading = false;
                }
            });
        },
    },
};
</script>
