/*
 * @Author: chengleilei
 * @Date: 2024-01-03 15:55:25
 * @Description: 
 */
const monthNow = new Date().getMonth() + 1;
const quarterNow = Math.floor(monthNow % 3 == 0 ? monthNow / 3 : monthNow / 3 + 1);
const yearNow = new Date().getFullYear();
const initParam = { year: yearNow, quarter: `${yearNow}:${quarterNow}` };

export { monthNow, quarterNow, yearNow, initParam }