<!-- eslint-disable vue/no-mutating-props -->
<!--
 * @Author: chengleilei
 * @Date: 2021-11-18 10:58:55
 * @Description: 线下订单取消弹窗组件
-->
<template>
    <div>
        <Modal v-model="modal" title="取消订单" :mask-closable="false" width="980" :closable="false">
            <Alert show-icon class="com-alert">
                可取消数量不含门店已扫码入库的轮胎，如需请联系门店将轮胎退回（胎号见页面），经销商扫码退货该轮胎后方可发起取消
            </Alert>
            <div v-show="list.length">
                <h4 class="ivu-mb-8">订单编号：{{ specialOrderNo }}</h4>
                <Table
                    :columns="columns"
                    :data="list"
                    max-height="200"
                    :no-data-text="'暂无商品信息'"
                    :no-filtered-data-text="'暂无商品信息'"
                ></Table>
                <div class="ivu-mt-8">
                    <p class="info">
                        取消数量：<span>{{ refundNum }}</span>
                    </p>
                </div>
            </div>
            <div slot="footer">
                <Button type="text" @click="refundCancel">取消</Button>
                <Button type="primary" @click="refundOk">确定</Button>
            </div>
        </Modal>
        <Modal v-model="confirmModal" title="确认退款" :mask-closable="false" :closable="false">
            <div class="ivu-mt-8">
                <p class="info">取消数量共计：{{ refundNum }}，是否确认操作？</p>
            </div>
            <div slot="footer">
                <Button type="text" @click="confirmCancel">取消</Button>
                <Button type="primary" @click="confirmOk">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import { getOfflineCancelOrderRecord, offlineCancelOrder } from '@/api/sales';

export default {
    props: {
        // 退款弹窗显隐
        modal: {
            type: Boolean,
            default: false,
        },
        // 订单数据
        item: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            confirmModal: false, // 确认退款弹窗显隐
            isRefunding: false, // 是否退款中
            // 商品信息表头
            columns: [
                {
                    title: '商品名称',
                    key: 'productName',
                    align: 'center',
                    minWidth: 130,
                    fixed: 'left',
                    ellipsis: true,
                    tooltip: true,
                },
                {
                    title: '单价',
                    align: 'center',
                    minWidth: 100,
                    render: (h, p) => {
                        return h('div', p.row.amount == null ? '-' : p.row.amount);
                    },
                },
                {
                    title: '下单数量',
                    key: 'quantity',
                    align: 'center',
                    minWidth: 100,
                },
                {
                    title: '扫码入库数量',
                    key: 'scanInNumber',
                    align: 'center',
                    minWidth: 120,
                },
                {
                    title: '入库胎号',
                    key: 'tyreCodes',
                    align: 'center',
                    minWidth: 150,
                    render: (h, p) => {
                        return [p.row.tyreCodes || '-'];
                    },
                },
                {
                    title: '已取消数量',
                    align: 'center',
                    minWidth: 100,
                    render: (h, p) => {
                        return [p.row.offlineCancelNumber || '-'];
                    },
                },
                {
                    title: '可取消数量',
                    key: 'canCancelNumber',
                    align: 'center',
                    minWidth: 100,
                },
                {
                    title: '本次取消数量',
                    align: 'center',
                    minWidth: 120,
                    fixed: 'right',
                    render: (h, p) => {
                        const maxNum = this.list[p.index].canCancelNumber;
                        return h('InputNumber', {
                            props: {
                                max: maxNum,
                                min: 1,
                                value: p.row.num || '',
                                disabled: maxNum < 1,
                                formatter: (value) => value && `${parseInt(value)}`,
                            },
                            on: {
                                input: (v) => {
                                    this.$set(this.list[p.index], 'num', v);
                                },
                            },
                        });
                    },
                },
            ],
            list: [], // 商品信息列表
            specialOrderNo: '', // 订单编号
            // 退款金额明细
            amountInfo: {
                allRefundAmount: 0, // 退款总金额
                rebateRefundAmount: 0, // 返利退款
                thirdRefundAmount: 0, // 三方支付退款
            },
        };
    },
    filters: {
        filterNum(value) {
            const arr = String(value).split('.');
            if (arr[1]) {
                return value.toFixed(2);
            }
            return value;
        },
    },
    computed: {
        // 退款数量
        refundNum() {
            const num = this.list.reduce((total, currentValue) => {
                return Number(total || 0) + Number(currentValue.num || 0);
            }, 0);
            return num;
        },
    },
    watch: {
        modal(newValue) {
            if (newValue) {
                this.list = [];
                this.getOrderDetail();
            }
        },
    },
    methods: {
        /**
         * @description 查询订单详情和退货情况
         */
        getOrderDetail() {
            const params = { orderId: this.item.id };
            getOfflineCancelOrderRecord(params).then((res) => {
                this.specialOrderNo = res.orderNo || '-';
                this.list = res.orderItems || [];
            });
        },

        /**
         * @description 处理退款列表数据
         */
        relsoveRefundList() {
            const refundDetailList = [];
            this.list.map((k) => {
                if (k.num) {
                    refundDetailList.push({
                        offlineCancelNumber: k.num,
                        tradeOrderItemId: k.tradeOrderItemId,
                    });
                }
            });
            return refundDetailList;
        },

        /**
         * @description 退款弹窗确定
         */
        refundOk() {
            const sum = this.list.reduce((prev, current) => {
                return prev + current.canCancelNumber;
            }, 0);
            if (sum <= 0) {
                this.$emit('update:modal', false);
                return;
            }
            if (!this.refundNum) {
                this.$Message.error('请输入取消数量');
                return;
            }
            this.amountInfo = {
                allRefundAmount: 0, // 退款总金额
                rebateRefundAmount: 0, // 返利退款
                thirdRefundAmount: 0, // 三方支付退款
            };
            this.confirmModal = true;
        },

        /**
         * @description 退款弹窗取消
         */
        refundCancel() {
            this.$emit('update:modal', false);
        },

        /**
         * @description 确认弹窗确定
         */
        confirmOk() {
            if (this.isRefunding) return;
            this.isRefunding = true;
            const params = {
                offlineCancelItemList: this.relsoveRefundList(),
                orderId: this.item.id,
            };
            //取消线下订单post
            offlineCancelOrder(params)
                .then(() => {
                    this.confirmModal = false;
                    this.$emit('update:modal', false);
                    this.$Message.success('操作成功');
                    this.$emit('confirmOk');
                    this.isRefunding = false;
                })
                .catch(() => {
                    this.isRefunding = false;
                });
        },

        /**
         * @description 确认弹窗取消
         */
        confirmCancel() {
            this.confirmModal = false;
        },
    },
};
</script>

<style lang="less" scoped>
.info {
    span {
        color: #e70707;
    }
}
</style>
