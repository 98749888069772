<template>
    <div class="expand-table">
        <Table
            :show-header="false"
            :stripe="true"
            :border="false"
            :columns="row.list.columns"
            :data="row.list.data"
            :no-data-text="row.list.noDataText ? row.list.noDataText : '亲，没有找到相关记录哦！~'"
        ></Table>
    </div>
</template>
<script>
export default {
    props: {
        row: Object,
    },
    components: {},
    mounted() {},
};
</script>
<style scoped lang="less">
.expand-table {
    margin: -20px -50px;
    overflow: hidden;
    /*margin-bottom: 16px;*/
    .td.ivu-table-expanded-cell {
    }
    /deep/ .ivu-table-body {
        overflow: hidden !important;
    }
}
</style>
