<script>
import { Form, FormItem, Row, Col, Select, Input } from 'view-design';
import SelectStore from '@/components/SelectStore';
import ShopSelect from '@/components/ShopSelect';
import ListSelect from '@/components/ListSelect';
import AreaSelect from '@/components/AreaSelect';
import DistributorSelect from '@/components/DistributorSelect';
import SelectUser from '@/components/SelectUser';
import SelectMonth from '@/components/SelectMonth';

export default {
    props: {
        formList: {
            type: Array,
            default: () => [],
        },
        // 自定义label宽度
        customLabelWidth: {
            type: Number,
            required: false,
            default: null,
        },
        initParam: {
            type: Object,
            default: () => {},
        },
        // data: {
        //     type: Object,
        //     default () {
        //         return {}
        //     }
        // }
    },
    components: {
        SelectStore, // 门店选择列表
        ShopSelect, // 店铺分类树
        ListSelect, // 商品品牌,品牌商
        AreaSelect, // 省市区选择
        DistributorSelect, // 扫码人选择 经销商账户列表
        SelectUser, // 账号筛选
        SelectMonth, // 月份下拉
    },
    data() {
        return {
            grid: {
                // xl: 8,
                // lg: 12,
                // md: 12,
                // sm: 12,
                // xs: 24,
                xxl: 6,
                xl: 8,
                lg: 12,
                md: 12,
                sm: 12,
                xs: 24,
            },
            collapse: true,
            data: {},

            isMobile: false,
        };
    },
    computed: {
        labelWidth() {
            return 110;
            //return this?.isMobile ? undefined : 110;
        },
        labelPosition() {
            return this.isMobile ? 'top' : 'right';
        },
        startThree() {
            return this.formList.slice(0, 3);
        },
        otherForm() {
            return this.formList.slice(3);
        },
        isThirdMore() {
            let str = '';
            let len = this.formList.length;
            if ((len % 4 === 1 && this.collapse) || len === 1) {
                str = 'one';
            }
            if ((len % 4 === 2 && this.collapse) || len === 2) {
                str = 'two';
            }
            return str;
        },
    },
    mounted() {},
    render(h) {
        let form = (
            <Form
                ref="form"
                style="margin-top:20px"
                nativeOn-submit={(e) => e.preventDefault()}
                label-width={this.customLabelWidth || 110}
                label-position={this.labelPosition}
            >
                <Row class="tableFormRow" gutter={24} type="flex" justify="end">
                    {this.startThree.map((p) => {
                        return this.renderList(h, p);
                    })}
                    {this.collapse && this.formList.length > 3
                        ? this.otherForm.map((p) => {
                              return this.renderList(h, p);
                          })
                        : ''}
                    {this.renderWrap(
                        h,
                        { isRight: true, className: this.isThirdMore },
                        <span>
                            <Button type="primary" onClick={this.handleSubmit}>
                                查询
                            </Button>
                            {this.formList.length > 0 ? (
                                <Button class="ivu-ml-8" onClick={this.handleReset}>
                                    重置
                                </Button>
                            ) : (
                                ''
                            )}
                            {this.$slots.extraBtn ? (
                                <span onClick={this.handleExport}>{this.$slots.extraBtn}</span>
                            ) : (
                                ''
                            )}
                            {this.formList.length > 3 ? (
                                <a
                                    v-font="14"
                                    class="ivu-ml-8"
                                    onClick={() => {
                                        this.collapse = !this.collapse;
                                    }}
                                >
                                    {
                                        // !this.collapse ? <span>
                                        //     展开 <Icon type="ios-arrow-down" />
                                        // </span> : <span>
                                        //     收起 <Icon type="ios-arrow-up" />
                                        // </span>
                                    }
                                </a>
                            ) : (
                                ''
                            )}
                        </span>,
                    )}
                </Row>
            </Form>
        );
        return form;
    },
    methods: {
        getFormData() {
            return { ...this.data };
        },
        setFormData(key, value) {
            this.data[key] = value;
        },
        handleSubmit() {
            this.$emit('on-submit', this.data);
        },
        handleExport() {
            this.$emit('on-export', this.data);
        },
        handleReset() {
            if (this.$refs.dateRange) {
                this.$refs.dateRange.handleClear();
            }
            if (this.$refs.dateMounth) {
                this.$refs.dateMounth.handleClear();
            }
            if (this.$refs.dateYear) {
                this.$refs.dateYear.handleClear();
            }
            for (let i in this.data) {
                this.data[i] = '';
            }
            this.data = { ...this.data, ...this.initParam };
            this.$emit('on-reset');
            this.$emit('on-submit', this.data);
        },
        renderList(h, p) {
            if (p.type === 'input') return this.renderInput(h, p);
            else if (p.type === 'cascader') return this.renderCascader(h, p);
            else if (p.type === 'cascaderData') return this.renderCascaderData(h, p);
            else if (p.type === 'select') return this.renderSelect(h, p);
            else if (p.type === 'search') return this.renderSelectSearch(h, p);
            else if (p.type === 'date') return this.renderDate(h, p);
            else if (p.type === 'year') return this.renderYear(h, p);
            else if (p.type === 'month') return this.renderMonth(h, p);
            else if (p.type === 'daterange') return this.renderDaterange(h, p);
            else if (p.type === 'distributorSelect') return this.renderDistributorSelect(h, p);
            else return this.renderComponent(h, p);
        },
        renderInput(h, p) {
            return this.renderWrap(
                h,
                p,
                <Input
                    clearable
                    onOn-enter={this.handleSubmit}
                    v-model={this.data[p.key]}
                    placeholder={p.placeholder || '请输入'}
                />,
            );
        },
        // 级联选择
        renderCascader(h, p) {
            // 列表默认选中传值，但是在展开收起操作会重绘组件，导致选中值又重置为默认值。
            // 所以如果使用，在父组件中，要同步更改value的值
            // this.data[p.key] = p.value
            const emptyFunc = () => {};
            return this.renderWrap(
                h,
                p,
                <Cascader
                    placeholder={p.placeholder || '请选择'}
                    data={p.options}
                    v-model={this.data[p.key]}
                    onOn-change={p.onChange || emptyFunc}
                    filterable={p.filterable}
                    change-on-select
                ></Cascader>,
            );
        },
        // 级联选择 动态加载
        renderCascaderData(h, p) {
            if (p.value) {
                this.data[p.key] = p.value;
            }
            const emptyFunc = () => {};
            return this.renderWrap(
                h,
                p,
                <Cascader
                    v-model={this.data[p.key]}
                    data={p.options}
                    load-data={p.loadData}
                    onOn-change={p.onChange || emptyFunc}
                    filterable={p.filterable}
                    change-on-select
                ></Cascader>,
            );
        },
        renderSelect(h, p) {
            // 列表默认选中传值，但是在展开收起操作会重绘组件，导致选中值又重置为默认值。
            // 所以如果使用，在父组件中，要同步更改value的值
            // this.data[p.key] = p.valuez
            if (p.valueActivation) {
                this.data[p.key] = p.value;
            }
            const emptyFunc = () => {};
            return this.renderWrap(
                h,
                p,
                <Select
                    onOn-create={p.handleCreate || emptyFunc}
                    onOn-query-change={p.handleQueryChange || emptyFunc}
                    onOn-open-change={p.handleOpenChange || emptyFunc}
                    onOn-change={p.onChange || emptyFunc}
                    allow-create={p.allowCreate}
                    clearable
                    remote={p.remote}
                    loading={p.loading}
                    remote-method={p.remoteMethod}
                    placeholder={p.placeholder || '请选择'}
                    v-model={this.data[p.key]}
                    filterable={p.filterable}
                    filter-by-label={true}
                >
                    {(p.options || []).map((item) => {
                        return (
                            <Option value={item.id} key={item.id} label={item.name}>
                                {p.selKey ? item[p.selKey] : item.name}
                            </Option>
                        );
                    })}
                </Select>,
            );
        },
        renderSelectSearch(h, p) {
            return this.renderWrap(
                h,
                p,
                <Select placeholder="请选择" v-model={this.data[p.key]} filterable>
                    {p.options.map((item) => {
                        return <Option value={item.id}>{p.selKey ? item[p.selKey] : item.name}</Option>;
                    })}
                </Select>,
            );
        },
        renderDate(h, p) {
            return this.renderWrap(
                h,
                p,
                <DatePicker v-width="100%" format="yyyy-MM-dd" placeholder="请选择" v-model={this.data[p.key]} />,
            );
        },
        renderYear(h, p) {
            const dealTime = (v) => {
                this.data[p.key] = v;
            };
            return this.renderWrap(
                h,
                p,
                <DatePicker
                    ref="dateYear"
                    type="year"
                    v-width="100%"
                    placeholder="请选择"
                    v-model={this.data[p.key]}
                    onOn-change={dealTime}
                />,
            );
        },
        renderDaterange(h, p) {
            const dealTime = (v) => {
                let [t1, t2] = v;
                t1 = t1 ? t1 + ' 00:00:00' : t1;
                t2 = t2 ? t2 + ' 23:59:59' : t2;
                this.data[p.key[0]] = t1;
                this.data[p.key[1]] = t2;
            };
            return this.renderWrap(
                h,
                p,
                <DatePicker
                    ref="dateRange"
                    v-model={this.data[p.key]}
                    type="daterange"
                    format="yyyy-MM-dd"
                    v-width="100%"
                    placeholder="请选择"
                    onOn-change={dealTime}
                />,
            );
            // return this.renderWrap(
            //     h, p, <DatePicker ref='dateRange' type='daterange' format='yyyy-MM-dd' v-width="100%" placeholder="请选择" onOn-change={dealTime}/>
            // )
        },
        renderMonth(h, p) {
            const dealTime = (v) => {
                if (typeof p.key === 'string') {
                    this.data[p.key] = v;
                } else {
                    let [t1, t2] = (v || '').split('-');
                    this.data[p.key[0]] = t1;
                    this.data[p.key[1]] = t2;
                }
            };
            return this.renderWrap(
                h,
                p,
                <DatePicker
                    ref="dateMounth"
                    value={p['value']}
                    type="month"
                    start-date={p['startDate']}
                    options={p['options']}
                    format="yyyy-MM"
                    v-width="100%"
                    placeholder="请选择"
                    onOn-change={dealTime}
                />,
            );
        },
        renderDistributorSelect(h, p) {
            const comp = (
                <DistributorSelect
                    isLabelInvalue={p.isLabelInvalue}
                    value={p.value}
                    disabled={p.value}
                    onOnchange={(val) => {
                        this.data[p.key] = val;
                    }}
                />
            );
            return this.renderWrap(h, p, comp);
        },
        renderComponent(h, p) {
            let comp = (
                <p.type
                    value={this.data[p.key]}
                    multiple={p.multiple}
                    listType={p.listType}
                    v-model={this.data[p.key]}
                    onOn-change={() => {
                        if (p.onChange) this.handleSubmit();
                    }}
                />
            );
            return this.renderWrap(h, p, comp);
        },
        renderWrap(h, p, slot) {
            return (
                <Col
                    class={p.isRight ? `ivu-text-right ${p.className}` : ''}
                    xl={this.formList.length < 1 ? 12 : this.grid.xl}
                    lg={this.formList.length < 1 ? 12 : this.grid.lg}
                    md={this.grid.md}
                    sm={this.grid.sm}
                    xs={this.grid.xs}
                >
                    <FormItem label={p.title ? p.title + ':' : ''}>{slot}</FormItem>
                </Col>
            );
        },
    },
};
</script>
<style lang="less">
.tableFormRow {
    > div:last-child {
        .ivu-form-item-content {
            margin-left: 0 !important;
        }
    }
}
// @media (min-width: 992px) {
//     .ivu-col-span-lg-6, .ivu-col-span-xl-6  {
//         &.one {
//             width: 75%;
//         }
//         &.two {
//             width: 50%;
//         }
//     }
// }
@media (min-width: 1200px) {
    .ivu-col-span-lg-6,
    .ivu-col-span-xl-6 {
        &.one {
            width: 75%;
        }
        &.two {
            width: 50%;
        }
    }
}
</style>
