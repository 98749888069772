/*
 * @Author: chengleilei
 * @Date: 2024-02-05 14:38:56
 * @Description: 扫码管理-路由
 */
import ScanFlowList from '@/pages/scan/flow/index';
import ScanFlowDetail from '@/pages/scan/flow/detail';
import ScanDistributorList from '@/pages/scan/distributor/index';
import ScanQueryList from '@/pages/scan/query/index';
import { applyRoute } from '../const';

const scanRoutes = [
    {
        path: `${applyRoute}/scan/flow/list`,
        meta: {
            auth: ['scancode'],
            title: '扫码流水',
            cache: true,
        },
        component: ScanFlowList,
    },
    {
        path: `${applyRoute}/scan/flow/detail`,
        meta: {
            auth: ['scancode'],
            title: '扫码详情',
            cache: false,
        },
        component: ScanFlowDetail,
    },
    {
        path: `${applyRoute}/scan/distributor/list`,
        meta: {
            auth: ['scancode'],
            title: '经销商扫码异常',
            cache: true,
        },
        component: ScanDistributorList,
    },
    {
        path: `${applyRoute}/scan/query/list`,
        meta: {
            auth: ['scancode'],
            title: '条码/胎号查询',
            cache: true,
        },
        component: ScanQueryList,
    },
];

export { scanRoutes };
