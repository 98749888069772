<!--
 * @Author: chengleilei
 * @Date: 2021-11-03 10:32:14
 * @Description: 提现记录列表
-->
<template>
    <div v-loading="list.loading" class="withdrawal-list">
        <PageHeader title="提现记录" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage">
                <Icon type="md-arrow-back" />
            </div>
        </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list" />
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import { listRecords } from '@/api/withdrawal';
import { jumpPage, transPathToName } from '@/libs/util';

export default {
    components: {
        tablePage,
    },
    data() {
        return {
            userId: this.$route.query.userId || '',
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                columns: [
                    {
                        title: '提现订单号',
                        key: 'orderNo',
                        width: 205,
                        align: 'center',
                    },
                    {
                        title: '发起提现时间',
                        key: 'createDate',
                        minWidth: 170,
                        align: 'center',
                    },
                    {
                        title: '到账时间类型',
                        minWidth: 120,
                        align: 'center',
                        render: (h, p) => {
                            const txt = {
                                10: 'T+1',
                                20: 'D0',
                            }[p.row.payOrderType];
                            return [txt || '-'];
                        },
                    },
                    {
                        title: '提现金额（元）',
                        key: 'amount',
                        minWidth: 140,
                        align: 'center',
                    },
                    {
                        title: '到账银行卡',
                        width: 255,
                        align: 'center',
                        render: (h, p) => {
                            return h('p', `${p.row.bankName}：${p.row.bankCardNumber}\n${p.row.accountName}`);
                        },
                    },
                    {
                        title: '状态',
                        width: 120,
                        align: 'center',
                        render: (h, p) => {
                            const txt = {
                                20: '处理中',
                                30: '提现成功',
                                40: '提现失败',
                            }[p.row.payStatus];
                            return [txt || '-'];
                        },
                    },
                    {
                        title: '交易流水号',
                        key: 'paySn',
                        minWidth: 200,
                        align: 'center',
                    },
                    {
                        title: '备注',
                        minWidth: 120,
                        align: 'center',
                        render: (h, p) => {
                            return [p.row.payStatus == '40' ? '提现金额已退还至您的账户' : '-'];
                        },
                    },
                ],
                data: [],
            },
        };
    },
    created() {
        this.getData();
    },
    methods: {
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/assets/withdrawal/index' });
        },

        /**
         * @description 获取表格数据
         */
        getData() {
            this.list.loading = true;
            const param = {
                current: this.list.current,
                size: this.list.size,
                userId: this.userId,
            };
            listRecords(param)
                .then((res) => {
                    this.list.data = [];
                    this.list.total = Number(res.total);
                    this.list.data = res.records || [];
                })
                .catch(() => {})
                .finally(() => {
                    this.list.loading = false;
                });
        },

        /**
         * @description 页码改变
         * @param {Number} val 页码
         */
        onPageChange(val) {
            this.list.current = val;
            this.getData();
        },

        /**
         * @description x条/页改变
         * @param {Number} val 条数
         */
        onPageSizeChange(val) {
            this.list.current = 1;
            this.list.size = val;
            this.getData();
        },
    },
};
</script>

<style lang="less" scoped>
.withdrawal-list {
    /deep/ p {
        text-align: left;
        white-space: pre-wrap;
    }
}
</style>
