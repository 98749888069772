import vue from 'vue';
import copy from 'copy-text-to-clipboard';

const instance = new vue();
const handleCopy = (binding) => {
    let text = binding.value || '';
    let flag = copy(text);
    if (flag) {
        instance.$Message.success('复制成功');
    } else {
        console.log('复制失败！');
    }
};

vue.directive('copy', {
    bind: function (el, binding) {
        el.__copyEvent__ = () => handleCopy(binding);
        el.addEventListener('click', el.__copyEvent__, false);
    },
    update: function (el, binding) {
        // 修复表格换页多条数据复制出错 暂时先这样
        el.removeEventListener('click', el.__copyEvent__);
        el.__copyEvent__ = () => handleCopy(binding);
        el.addEventListener('click', el.__copyEvent__, false);
    },
    unbind: function (el) {
        el.removeEventListener('click', el.__copyEvent__);
        delete el.__copyEvent__;
    },
});
