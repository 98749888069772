<!--
 * @Author: chengleilei
 * @Date: 2021-11-03 10:45:28
 * @Description: 余额提现
-->
<template>
    <div class="withdrawal-form" v-loading="pageLoading">
        <div class="i-layout-page-header">
            <PageHeader title="余额提现" hidden-breadcrumb>
                <div slot="back" @click="jumpPrevPage">
                    <Icon type="md-arrow-back" />
                </div>
            </PageHeader>
        </div>
        <Card class="ivu-mt" :bordered="false" dis-hover>
            <Form :model="withdrawalForm" ref="withdrawalForm" :rules="rules" :label-width="150">
                <FormItem label="账户名称：">
                    {{ accountName || '-' }}
                </FormItem>
                <FormItem label="可提现金额："> {{ maxAmount }}元 </FormItem>
                <FormItem label="提现金额：" prop="amount">
                    <Input style="width: 200px" v-model="withdrawalForm.amount" placeholder="请输入金额"></Input>
                    <span class="unit">元</span>
                    <span class="text-btn" @click="allWithdrawal">全部提现</span>
                </FormItem>
                <FormItem label="到账银行卡：" prop="bindingCardId">
                    <Select
                        v-model="withdrawalForm.bindingCardId"
                        style="width: 280px"
                        filterable
                        :loading="bankLoading"
                    >
                        <Option v-for="item in bankList" :value="item.cardId" :key="item.cardId">{{
                            `${item.bankName}（${item.bankCardNumber}）`
                        }}</Option>
                    </Select>
                    <span class="text-btn" @click="goPage">添加银行卡</span>
                </FormItem>
                <FormItem label="到账时间：">
                    <p>{{ type == 'receivableBalance' ? 'T+1（次工作日到账）' : 'D0（当日到账）' }}</p>
                    <p v-if="type == 'receivableBalance'" class="tip">
                        如果周五、周六、周天或者节假日提现，到账日为下周一或者节后的第一个工作日
                    </p>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="submitData">提现</Button>
                </FormItem>
            </Form>
        </Card>
    </div>
</template>

<script>
import { getAccountBalance, getCardList, submitWithDraw } from '@/api/withdrawal';
import { jumpPage, transPathToName } from '@/libs/util';

export default {
    data() {
        const validatePrice = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入提现金额'));
            } else if (!/(^[0-9]+(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(value)) {
                callback(new Error('仅输入数字和小数点，可精确到小数点后2位'));
            } else {
                callback();
            }
        };
        return {
            pageLoading: false,
            bankLoading: false,
            userId: '',
            type: '', // 到账时间
            accountName: '', // 账户名称
            maxAmount: '', // 可提现金额
            userType: '', // 账户类型
            cpcnAccountId: '', // 中金id
            // 表单内容
            withdrawalForm: {
                amount: '',
                bindingCardId: '',
            },
            // 校验规则
            rules: {
                amount: [
                    {
                        required: true,
                        validator: validatePrice,
                    },
                ],
                bindingCardId: [
                    {
                        required: true,
                        message: '请选择银行卡',
                    },
                ],
            },
            bankList: [], // 银行卡列表
        };
    },

    created() {
        this.userId = this.$route.query.userId || '';
        this.type = this.$route.query.type || '';
        this.getData();
    },
    mounted() {
        this.userId = this.$route.query.userId || '';
        this.type = this.$route.query.type || '';
        this.getBankList();
    },

    beforeRouteEnter(to, from, next) {
        next((vm) => {
            if (from.path === '/assets/withdrawal/index') {
                vm.getData();
            }
        });
    },

    methods: {
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/assets/withdrawal/index' });
        },

        /**
         * @description 获取账户详情
         */
        getData() {
            this.pageLoading = true;
            this.$refs['withdrawalForm'] && this.$refs['withdrawalForm'].resetFields();
            getAccountBalance(this.userId)
                .then((res) => {
                    this.accountName = res.accountName;
                    this.maxAmount = res[this.type];
                    this.userType = res.accountType;
                    this.cpcnAccountId = res.cpcnAccountId;
                })
                .finally(() => {
                    this.pageLoading = false;
                });
        },

        /**
         * @description 获取银行卡列表
         */
        getBankList() {
            this.bankLoading = true;
            getCardList(this.userId)
                .then((res) => {
                    this.bankList = res;
                })
                .finally(() => {
                    this.bankLoading = false;
                });
        },

        /**
         * @description 脱敏处理
         */
        // resolveVal(val) {
        //     if (!val) return '-'
        //     const len = val.length
        //     return val.substring(len - 4).padStart(len, '*')
        // },

        /**
         * @description 全部提现
         */
        allWithdrawal() {
            this.withdrawalForm.amount = this.maxAmount;
        },

        /**
         * @description 提交表单数据
         */
        submitData() {
            this.$refs['withdrawalForm'].validate((valid) => {
                if (!valid) {
                    this.$Modal.warning({
                        title: '提示',
                        content: '请完善表单内容',
                    });
                    return false;
                }
                const selectBank = this.bankList.find((k) => k.cardId === this.withdrawalForm.bindingCardId);
                const p = {
                    userId: this.userId,
                    amount: Number(this.withdrawalForm.amount * 100).toFixed(0),
                    bindingCardId: this.withdrawalForm.bindingCardId,
                    arrivalType: this.type == 'receivableBalance' ? '10' : '20',
                    bindingTxSN: selectBank.bindingTxSn,
                };
                const content = `到账银行卡：${selectBank.bankName}（${selectBank.bankCardNumber}）<br>提现金额：${this.withdrawalForm.amount}元`;
                this.$Modal.confirm({
                    title: '提现信息确认',
                    content,
                    onOk: () => {
                        this.pageLoading = true;
                        this.$Message.loading({
                            content: '提交中...',
                            duration: 0,
                        });

                        submitWithDraw(p)
                            .then(() => {
                                this.pageLoading = false;
                                window.$basePageStore.closeTab(transPathToName(location.pathname));
                                jumpPage({
                                    path: `/sub-vue2/assets/withdrawal/result`,
                                    params: {
                                        userId: this.userId,
                                    },
                                });
                            })
                            .catch((err) => {
                                console.error('提现接口失败：', err);
                            })
                            .finally(() => {
                                this.$Message.destroy();
                                this.pageLoading = false;
                            });
                    },
                    onCancel: () => {},
                });
            });
        },

        /**
         * @description 跳转页面
         * @param {String} path 页面路由
         */
        goPage() {
            jumpPage({
                path: '/sub-vue2/assets/dealerAccount/addBankCard',
                params: { userType: this.userType, cpcnAccountId: this.cpcnAccountId, userId: this.userId },
            });
        },
    },
};
</script>

<style lang="less" scoped>
.withdrawal-form {
    .unit {
        margin-left: 4px;
    }
    .text-btn {
        margin-left: 16px;
        cursor: pointer;
        color: #2d8cf0;
    }
    // .add-card-item {
    //     pointer-events: none;
    //     .add-card {
    //         pointer-events: auto;
    //     }
    // }
    .tip {
        color: #999;
    }
}
</style>
