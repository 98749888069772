<template>
    <div v-loading="loading" class="scan-code-detail">
        <PageHeader title="扫码详情" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage"><Icon type="md-arrow-back" /></div>
        </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <div class="ivu-description-list">
                <div class="ivu-description-list-title">扫码信息</div>
                <Row style="margin-left: -16px; margin-right: -16px">
                    <div
                        class="ivu-col ivu-col-span-xs-24 ivu-col-span-sm-12 ivu-col-span-md-24"
                        style="padding-left: 16px; padding-right: 16px"
                    >
                        <div class="ivu-description-term">流水号：</div>
                        <div class="ivu-description-detail">{{ sn }}</div>
                    </div>
                </Row>
                <Row style="margin-left: -16px; margin-right: -16px">
                    <div
                        class="ivu-col ivu-col-span-xs-12 ivu-col-span-sm-6 ivu-col-span-md-12"
                        style="padding-left: 16px; padding-right: 16px"
                    >
                        <div class="ivu-description-term">ERP单号：</div>
                        <div class="ivu-description-detail">{{ stockOutSn || '-' }}</div>
                    </div>
                    <div
                        class="ivu-col ivu-col-span-xs-12 ivu-col-span-sm-6 ivu-col-span-md-12"
                        style="padding-left: 16px; padding-right: 16px"
                    >
                        <div class="ivu-description-term">扫码人：</div>
                        <div class="ivu-description-detail">{{ scanCodeOperateName }}</div>
                    </div>
                </Row>
                <Divider />
                <Row style="margin-right: -16px">
                    <Col span="4">
                        <div class="ivu-description-list-title">门店信息</div>
                    </Col>
                    <Col
                        span="4"
                        style="margin-bottom: 25px; margin-top: -5px"
                        v-if="isPdaChooseStore && scanCodeTypeMeaning !== '经销商退货扫码' && storeId"
                    >
                        <Button type="info" @click="editStore">变更门店</Button>
                    </Col>
                </Row>
                <Row style="margin-left: -16px; margin-right: -16px">
                    <div
                        class="ivu-col ivu-col-span-xs-24 ivu-col-span-sm-12 ivu-col-span-md-24"
                        style="padding-left: 16px; padding-right: 16px"
                    >
                        <div class="ivu-description-term">门店名称：</div>
                        <div class="ivu-description-detail">{{ storeName }}</div>
                    </div>
                </Row>
                <Row style="margin-left: -16px; margin-right: -16px">
                    <div
                        class="ivu-col ivu-col-span-xs-12 ivu-col-span-sm-6 ivu-col-span-md-12"
                        style="padding-left: 16px; padding-right: 16px"
                    >
                        <div class="ivu-description-term">联系人：</div>
                        <div class="ivu-description-detail">{{ storeOwnerName }}</div>
                    </div>
                    <div
                        class="ivu-col ivu-col-span-xs-12 ivu-col-span-sm-6 ivu-col-span-md-12"
                        style="padding-left: 16px; padding-right: 16px"
                    >
                        <div class="ivu-description-term">联系方式：</div>
                        <div class="ivu-description-detail">{{ storeOwnerPhone }}</div>
                    </div>
                </Row>
            </div>
        </Card>
        <Card title="商品信息" :bordered="false" dis-hover class="ivu-mt">
            <Row type="flex" justify="end" style="margin-bottom: 18px">
                <Col>
                    <Button type="primary" @click="handleExport" :loading="exportLoading">{{
                        exportLoading ? '导出中' : '导出胎号'
                    }}</Button>
                </Col>
            </Row>
            <VxeTable
                stripe
                highlight-hover-row
                border
                align="center"
                ref="xTable"
                :data="list.data"
                @cell-click="handleExpand"
            >
                <VxeTableColumn field="productName" align="center" title="商品名称"></VxeTableColumn>
                <VxeTableColumn field="count" align="center" title="数量" width="250"></VxeTableColumn>
                <VxeTableColumn type="expand" align="center" title="胎号" width="250">
                    <template v-slot:content="{ row }">
                        <div class="tire-sn-table">
                            <div color="success" class="tire-label">
                                已入库({{ row.alreadyTyreCodeDetails.length }})
                            </div>
                            <div class="tire-sn-list">
                                <span
                                    class="tire-sn"
                                    v-for="(item, index) in row.alreadyTyreCodeDetails"
                                    :key="index"
                                    :style="{ color: item.isAbnormal ? 'red' : '' }"
                                >
                                    {{ item.tyreCode }}
                                </span>
                            </div>
                        </div>
                        <div class="tire-sn-table no-tyre">
                            <div color="default" class="tire-label">未入库({{ row.noCount }})</div>
                            <div class="tire-sn-list">
                                <span class="tire-sn" v-for="(item, index) in row.noyTyreCodes" :key="index">
                                    {{ item }}
                                </span>
                            </div>
                        </div>
                    </template>
                </VxeTableColumn>
            </VxeTable>
        </Card>
        <StoreEdit @getData="getData" ref="storeEdit" />
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { getScanDetail, getBarcodes, exportTyreCode } from '../../../api/scan';
import StoreEdit from './components/StoreEdit';
import { transPathToName, jumpPage } from '@/libs/util';

const isPdaChooseStore = Boolean(sessionStorage.getItem('isPdaChooseStore'));

export default {
    name: 'scan-flow-detail',
    components: {
        StoreEdit,
    },
    data() {
        return {
            exportLoading: false,
            isPdaChooseStore: isPdaChooseStore,
            loading: false,
            id: this.$route.query.id || null,
            stockOutSn: '',
            sn: '',
            scanCodeOperateName: '',
            scanCodeTypeMeaning: '',
            scanCodeDate: '',
            storeId: '',
            storeName: null,
            storeOwnerName: null,
            storeOwnerPhone: null,
            scanCodeType: null,
            list: {
                list: {
                    data: [],
                    loading: false,
                },
            },
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        handleExport() {
            this.exportLoading = true;
            this.$Message.loading({
                content: '导出中...',
                duration: 0,
            });
            exportTyreCode(this.id).then(
                (res) => {
                    this.$Message.success('导出成功！');
                    let aLink = document.createElement('a');
                    aLink.href = res || '';
                    // console.log('href ===', res)
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    this.exportLoading = false;
                    this.$Message.destroy();
                    this.$Message.success('导出成功~');
                },
                () => {
                    this.$Message.destroy();
                    this.exportLoading = false;
                },
            );
        },
        editStore() {
            this.$refs['storeEdit'].open('EDIT', this.id);
        },
        handleExpand(e) {
            // 确保只展开一行
            let { rowIndex, column } = e;
            let { title } = column;
            if (title !== '胎号') {
                return false;
            }
            this.$refs.xTable.clearRowExpand(); // 关闭全部展开行
            this.$refs.xTable.toggleRowExpansion(this.list.data[rowIndex]); // 设置当前行展开
        },
        getBarcodesData(row) {
            let rowData = this.list.data[row.rowIndex];
            this.$refs.xTable.clearRowExpand(); // 关闭全部展开行
            console.log(row);
            return new Promise((resolve, reject) => {
                console.log(rowData);
                getBarcodes({
                    materialId: rowData.materialId,
                    stockOutSn: this.sn,
                }).then(
                    (res) => {
                        this.$refs.xTable.clearRowExpand(); // 关闭全部展开行
                        if (!res || res.length <= 0) {
                            row.row.tireSnList = ['暂无胎号数据'];
                        } else {
                            row.row.tireSnList = res;
                        }
                        resolve();
                    },
                    (err) => {
                        row.row.tireSnList = ['暂无胎号数据'];
                        this.$refs.xTable.clearRowExpand(); // 关闭全部展开行
                        reject(err);
                    },
                );
            });
        },
        getData() {
            this.loading = true;
            getScanDetail(this.id)
                .then((res) => {
                    this.loading = false;
                    this.stockOutSn = res.stockOutSn || '';
                    this.sn = res.sn;
                    this.scanCodeOperateName = res.scanCodeOperateName;
                    this.scanCodeTypeMeaning = res.scanCodeTypeMeaning;
                    this.scanCodeDate = res.scanCodeDate;
                    this.storeName = res.storeName || '-';
                    this.storeId = res.storeId || '';
                    this.storeOwnerName = res.storeOwnerName || '-';
                    this.storeOwnerPhone = res.storeOwnerPhone || '-';
                    this.scanCodeType = res.scanCodeType;
                    let items = res.scanBarcodeOrderDetailV2Vos.map((item) => {
                        let alreadyTyreCodes = ['暂无已入库胎号数据'];
                        let noyTyreCodes = ['暂无未入库胎号数据'];
                        if (item.alreadyTyreCodes && item.alreadyTyreCodes.length > 0) {
                            alreadyTyreCodes = item.alreadyTyreCodes;
                        }
                        if (item.noyTyreCodes && item.noyTyreCodes.length > 0) {
                            noyTyreCodes = item.noyTyreCodes;
                        }
                        return {
                            ...item,
                            alreadyTyreCodes,
                            noyTyreCodes,
                        };
                    });
                    console.log(items);
                    this.list.data = items || [];
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        handleSummary({ columns, data }) {
            const sums = {};
            columns.forEach((column, index) => {
                const key = column.key;
                if (index === 0) {
                    sums[key] = {
                        key,
                        value: '总数量',
                    };
                    return;
                }
                const values = data.map((item) => Number(item[key]));
                if (!values.every((value) => isNaN(value))) {
                    const v = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        } else {
                            return prev;
                        }
                    }, 0);
                    sums[key] = {
                        key,
                        value: v,
                    };
                } else {
                    sums[key] = {
                        key,
                        value: 'N/A',
                    };
                }
            });
            return sums;
        },
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/scan/flow/list' });
        },
    },
    computed: {
        ...mapState('admin/layout', ['isMobile']),
    },
};
</script>
<style lang="less">
.scan-code-detail {
    .tire-sn-table {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        background: rgb(247, 247, 247);
        .tire-label {
            width: 80px;
            text-align: center;
        }
        .tire-sn-list {
            flex: 1;
            background: #fff;
            padding: 20px;
        }
    }
    .no-tyre {
        color: red;
        background: rgb(174, 174, 174);
    }
    .vxe-body--expanded-cell {
        padding: 0;
    }
}
</style>
