<template>
    <div class="cruise-detail">
        <PageHeader title="巡店记录" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage"><Icon type="md-arrow-back" /></div>
        </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <Card :bordered="true" dis-hover class="ivu-mt store-cruise">
                <Row>
                    <Col span="8">
                        <Alert type="success" size="small"
                            ><span class="ivu-mr-8" style="font-weight: bold">{{ detail.salesmenName }} </span
                            >{{ detail.createDate }}</Alert
                        >
                    </Col>
                </Row>
                <Row class="">
                    <Col span="11" class="ivu-mr-16">
                        <p class="label">门店门头照</p>
                        <div
                            class="image"
                            :style="{ backgroundImage: 'url(' + detail.headImageUrl + ')' }"
                            v-if="detail.headImageUrl"
                            @click="previewImg(detail.headImageUrl)"
                        ></div>
                        <span v-else class="no-data">- 无内容 -</span>
                    </Col>
                    <Col span="11" class="ivu-ml-16">
                        <p class="label">店内陈列商品图</p>
                        <div
                            class="image"
                            :style="{ backgroundImage: 'url(' + detail.productListImageUrl + ')' }"
                            v-if="detail.productListImageUrl"
                            @click="previewImg(detail.productListImageUrl)"
                        ></div>
                        <span v-else class="no-data">- 无内容 -</span>
                    </Col>
                </Row>
                <Row>
                    <Col span="23">
                        <p class="label">巡店简述</p>
                        <span class="no-data">{{ detail.remark || '- 无内容 -' }}</span>
                    </Col>
                </Row>

                <Modal title="图片预览" v-model="visible" width="700px" footer-hide>
                    <img :src="activeImg" style="width: 100%" />
                </Modal>
            </Card>
        </Card>
    </div>
</template>

<script>
import { StoreCruise } from '../../../api/cruise';
import { jumpPage, transPathToName } from '@/libs/util';

export default {
    components: {},
    data() {
        return {
            detail: [],
            activeImg: '',
            visible: false,
        };
    },

    mounted() {
        this.getData();
    },
    methods: {
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/statistics/cruise/detail' });
        },
        getData() {
            // delete params.provinceId
            StoreCruise({
                id: this.$route.query.id,
            }).then(
                (res) => {
                    this.detail = res;
                },
                () => {},
            );
        },
        previewImg(url) {
            // console.log('预览图片', url)
            this.activeImg = url;
            this.visible = true;
        },
        onPageChange(val) {
            if (this.isChangePage) return;
            this.list.current = val;
            this.getData();
        },
        onPageSizeChange(val) {
            this.list.current = 1;
            this.isChangePage = true;
            this.list.size = val;
            this.getData();
        },
    },
};
</script>

<style lang="less">
.store-cruise {
    .label {
        margin-bottom: 9px;
        margin-top: 18px;
        font-weight: bold;
    }
    .image {
        width: 66%;
        height: 180px;
        cursor: pointer;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        background-color: #eee;
        border-radius: 4px;
    }
    .no-data {
        width: 100%;
        min-height: 170px;
        line-height: 22px;
        font-weight: bold;
    }
}
</style>
