<template>
    <div>
        <PageHeader title="业务员巡店统计表" hidden-breadcrumb> </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" @on-submit="onSubmit" @on-export="exportInfo" ref="table-form">
                <Button class="ivu-ml-8" :disabled="isExporting" slot="extraBtn" type="error">导出</Button>
            </table-form>
            <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list" />
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { renderButton, jumpPage } from '@/libs/util';
import { storeTourList, storeTourListExport } from '../../../api/cruise';

export default {
    name: 'store-scan-rebate',
    components: {
        tableForm,
        tablePage,
    },
    data() {
        return {
            isExporting: false,
            verifyMonth: '',
            verifyStatus: null,
            isChangePage: false,
            formList: [
                {
                    title: '姓名',
                    key: 'salesmanName',
                    width: '230px',
                    type: 'input',
                    placeholder: '请输入',
                },
                {
                    title: '时间段',
                    type: 'daterange',
                    key: ['startDate', 'endDate'],
                    placeholder: '请选择',
                },
                {
                    title: '手机',
                    type: 'input',
                    key: 'phone',
                    placeholder: '请输入',
                },
            ],
            searchParams: {
                date: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-01 00:00:00`,
                provinceId: '',
                distributorName: '',
            },
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    { title: '姓名', key: 'salesmanName', align: 'center', minWidth: 150, fixed: 'left' },
                    {
                        title: '职能类型',
                        key: 'type',
                        align: 'center',
                        minWidth: 150,
                        render: (h, p) => {
                            return h(
                                'div',
                                {
                                    1: '经销商业务员',
                                    2: '经销商经理',
                                    3: '业务员',
                                    4: '业务科长',
                                    5: '业务经理',
                                    6: '渠道员',
                                }[p.row.type],
                            );
                        },
                    },
                    { title: '门店数', key: 'storeNum', align: 'center', minWidth: 140 },
                    { title: '巡店数', key: 'tourNum', align: 'center', minWidth: 140 },
                    { title: '巡店总次数', key: 'tourTotalNum', align: 'center', minWidth: 120 },
                    {
                        title: '操作',
                        key: 'oprate',
                        align: 'center',
                        width: 300,
                        fixed: 'right',
                        render: (h, p) => {
                            return [
                                renderButton(h, { text: '明细', type: 'primary' }, () => {
                                    this.checkDetail(p.row);
                                }),
                            ];
                        },
                    },
                ],
                data: [],
            },
        };
    },

    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.list.loading = true;
            let p = this.$refs['table-form'].getFormData();
            this.verifyStatus = p.status;
            delete p.date;
            if (p['dept']) {
                p.deptId = p.dept[0];
                p.regionId = p.dept[1];
                delete p['dept'];
            }
            if (p.provinceId) p.provinceId = p.provinceId[0];
            // let p = clearEmpty(this.searchParams)
            let params = {
                current: this.list.current,
                size: this.list.size,
                ...p,
            };
            // delete params.provinceId
            console.log('params after =', params);
            storeTourList(params).then(
                (data) => {
                    this.list.total = Number(data.total);
                    this.list.data = (data.records || []).map((item) => {
                        return {
                            ...item,
                        };
                    });
                    this.isChangePage = false;
                    this.list.loading = false;
                },
                () => {
                    this.isChangePage = false;
                    this.list.loading = false;
                },
            );
        },
        exportData() {
            if (this.isExporting) {
                return false;
            }
            let p = this.$refs['table-form'].getFormData();
            this.verifyStatus = p.status;
            delete p.date;
            if (p['dept']) {
                p.deptId = p.dept[0];
                p.regionId = p.dept[1];
                delete p['dept'];
            }
            if (p.provinceId) p.provinceId = p.provinceId[0];
            let params = {
                ...p,
            };
            this.$Message.loading({
                content: '导出中...',
                duration: 0,
            });
            this.isExporting = true;
            // delete params.provinceId
            storeTourListExport(params).then(
                (res) => {
                    this.$Message.destroy();
                    this.list.loading = false;
                    this.isExporting = false;
                    if (!res) {
                        this.$Message.warning('暂无数据');
                        return false;
                    }
                    let aLink = document.createElement('a');
                    aLink.href = res || '';
                    // console.log('href ===', res)
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    this.$Notice.success({
                        title: '导出成功',
                    });
                },
                () => {
                    this.isExporting = false;
                    this.$Message.destroy();
                },
            );
        },
        onPageChange(val) {
            if (this.isChangePage) return;
            this.list.current = val;
            this.getData();
        },
        onPageSizeChange(val) {
            this.list.current = 1;
            this.isChangePage = true;
            this.list.size = val;
            this.getData();
        },
        checkDetail(row) {
            jumpPage({
                path: '/sub-vue2/statistics/cruise/detail',
                params: {
                    id: row.id,
                },
            });
        },
        onSubmit() {
            this.list.current = 1;
            this.getData();
        },
        exportInfo() {
            this.exportData();
        },
    },
};
</script>

<style lang="less" scoped>
.ivu-form-item {
    &:nth-child(1) {
        width: 325px;
    }
}
</style>
