/**
 * 插件
 * */

// 错误捕获
//import pluginError from "@/plugins/error";
//// 日志插件
//import pluginLog from "@/plugins/log";
//// 鉴权指令
//import directiveAuth from "@/plugins/auth";

// 全局loading
import loading from "@/plugins/loading";

// 自定义图片渲染
//import ImgLink from "@/components/ImgLink";
//import Loader from "@/components/Loader";

export default {
    async install(Vue) {
        // 插件
        // Vue.use(pluginError);
        // Vue.use(pluginLog);

        // 指令
        //Vue.directive("auth", directiveAuth);
        Vue.directive("loading", loading);

        //Vue.component(Loader.name, Loader);
        //Vue.component(ImgLink.name, ImgLink);
    },
};
