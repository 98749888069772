<!-- eslint-disable vue/no-mutating-props -->
<!--
 * @Author: chengleilei
 * @Date: 2021-12-01 15:41:50
 * @Description: 收款弹窗组件
-->
<template>
    <Modal v-model="modal" title="收款" :mask-closable="false" @on-cancel="onCancel">
        <Form ref="makeCollectForm" :model="makeCollectForm" :rules="rules" :label-width="140" @submit.native.prevent>
            <FormItem label="门店名称：">
                {{ item.storeName || '-' }}
            </FormItem>
            <FormItem label="待收金额(元)："> {{ item.waitCollectionAmount }}元 </FormItem>
            <FormItem label="收款金额(元)：" prop="amount">
                <Input style="width: 200px" v-model="makeCollectForm.amount" placeholder="请输入"></Input>
                <span class="ivu-ml-8">元</span>
            </FormItem>
            <FormItem label="收款渠道：" prop="collectionChannel">
                <Select v-model="makeCollectForm.collectionChannel" filterable clearable>
                    <Option v-for="item in payTypeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
            </FormItem>
            <FormItem label="收款账户：" prop="collectionAccount">
                <Input v-model="makeCollectForm.collectionAccount" :maxlength="50" placeholder="请输入"></Input>
            </FormItem>
            <FormItem label="交易流水号：" prop="collectionNo">
                <Input v-model="makeCollectForm.collectionNo" :maxlength="50" placeholder="请输入"></Input>
            </FormItem>
            <FormItem label="备注：" prop="remark">
                <Input
                    v-model="makeCollectForm.remark"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 3 }"
                    :maxlength="200"
                    placeholder="请输入"
                />
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="text" @click="onCancel">取消</Button>
            <Button type="primary" @click="onOk" :loading="loading">确定</Button>
        </div>
    </Modal>
</template>

<script>
import { mapState } from 'vuex';
import { creditRepayment } from '@/api/credit';
import { clearEmpty } from '@/libs/util';

export default {
    props: {
        // 收款弹窗显隐
        modal: {
            type: Boolean,
            default: false,
        },
        // 行数据
        item: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        const validatePrice = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入收款金额'));
            } else if (!/(^[0-9]+(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(value)) {
                callback(new Error('仅输入数字和小数点，可精确到小数点后2位'));
            } else {
                callback();
            }
        };
        return {
            makeCollectForm: {
                amount: '', // 收款金额
                collectionChannel: '', // 收款渠道
                collectionAccount: '', // 收款账户
                collectionNo: '', // 交易流水号
                remark: '', // 备注
            },
            loading: false,
            // 校验规则
            rules: {
                amount: [
                    {
                        required: true,
                        validator: validatePrice,
                    },
                ],
            },
            // 收款渠道列表
            payTypeList: [
                {
                    value: 0,
                    label: '微信',
                },
                {
                    value: 1,
                    label: '支付宝',
                },
                {
                    value: 2,
                    label: '银行卡',
                },
                {
                    value: 3,
                    label: '现金',
                },
                {
                    value: 4,
                    label: '其他',
                },
            ],
        };
    },
    computed: {
        ...mapState({
            creditPassword: (state) => state.admin.credit.creditPassword,
        }),
    },
    watch: {
        modal(newValue) {
            !newValue && this.$store.commit('admin/credit/clearPwd');
        },
    },
    methods: {
        /**
         * @description 收款弹窗确定
         */
        onOk() {
            if (this.loading) return;
            this.$refs['makeCollectForm'].validate((valid) => {
                if (!valid) return;
                this.loading = true;
                let p = {
                    ...this.makeCollectForm,
                    creditPassword: this.creditPassword,
                    storeId: this.item.storeId,
                };
                p = clearEmpty(p);
                console.log(p);

                creditRepayment(p)
                    .then((res) => {
                        this.onCancel();
                        if (res) {
                            // 收款金额大于待收金额时
                            this.$Modal.warning({
                                title: '收款成功',
                                content: `待收金额已还清，<span class='red-font'>超出部分金额为${res}元，</span>将存入门店授信余额。`,
                                onOk: () => {
                                    this.$emit('refreshList');
                                },
                            });
                        } else {
                            this.$Message.success('操作成功');
                            this.$emit('refreshList');
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },

        /**
         * @description 收款弹窗取消
         */
        onCancel() {
            this.$refs['makeCollectForm'].resetFields();
            this.$emit('update:modal', false);
        },
    },
};
</script>

<style lang="less">
.red-font {
    color: #e70707;
}
</style>
