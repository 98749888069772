<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
    <Cascader
        :data="tigerAreaList"
        :change-on-select="isChangeOnSelect"
        :load-data="loadData"
        transfer
        v-model="currValue"
        @on-change="onSelectArea"
        placeholder="请选择地区"
    ></Cascader>
</template>

<script>
import { GetAddressByParentId } from "@/api/address";
import { mapGetters, mapActions } from "vuex";
export default {
    data() {
        return {
            cascaderList: [],
        };
    },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        isChangeOnSelect: {
            type: Boolean,
            default: false,
        },
        isGetAddressTree: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        currValue: {
            get() {
                return this.value;
            },
            set(k) {
                this.$emit("input", k || []);
            },
        },
        ...mapGetters("admin/area", ["tigerAreaList"]),
    },
    mounted() {
        // if (this.isGetAddressTree) {
        //     this.getArea({}).then(res => {
        //         this.cascaderList = res
        //     })
        // }
        this.getTigerAreaList();
    },
    methods: {
        ...mapActions("admin/area", ["getTigerAreaList"]),
        getArea(params) {
            return new Promise((resolve) => {
                GetAddressByParentId(params).then((res) => {
                    resolve(
                        res.map((item) => {
                            item.label = item.name;
                            item.value = item.id;
                            if (item.treeLevel !== 3) {
                                item.loading = false;
                            }
                            if (item.children === 3) {
                                delete item.children;
                            }
                            return item;
                        }),
                    );
                });
            });
        },
        loadData(item, callback) {
            item.loading = true;
            this.getArea({ parentId: item.value }).then((res) => {
                item.loading = false;
                if (!res.length) {
                    delete item.loading;
                } else {
                    item.children = res;
                }
                callback();
            });
        },
        onSelectArea(val, selectedData) {
            this.$emit("on-change", val, selectedData);
        },
        onSelectAreaList(val) {
            this.$emit("input", val);
        },
    },
};
</script>
