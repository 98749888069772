var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.pageLoading),expression:"pageLoading"}],staticClass:"open-account-result"},[_c('PageHeader',{attrs:{"title":"开户","hidden-breadcrumb":""}}),_c('Card',{staticClass:"ivu-mt",attrs:{"bordered":false,"dis-hover":""}},[(_vm.accountStatus == 2)?[_c('div',{staticClass:"img-wrapper"},[_c('img',{attrs:{"src":require("@/assets/images/result-loading.png")}})]),_c('Result',{attrs:{"title":"开户处理中"}},[_c('div',{attrs:{"slot":"desc"},slot:"desc"},[_vm._v("您已成功提交信息，请耐心等待开户处理，预计1个工作日内完成")]),_c('div',{attrs:{"slot":"actions"},slot:"actions"},[_c('Button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.goPage('/sub-vue2/assets/dealerAccount/detail', { cpcnAccountId: _vm.cpcnAccountId, userType: _vm.userType, userId: _vm.userId })}}},[_vm._v("查看账户详情")]),_c('Button',{on:{"click":function($event){return _vm.goPage(`/sub-vue2/assets/dealerAccount/list`)}}},[_vm._v("返回账户管理")])],1)])]:(_vm.accountStatus == 6)?[_c('div',{staticClass:"img-wrapper"},[_c('img',{attrs:{"src":require("@/assets/images/result-error.png")}})]),_c('Result',{attrs:{"title":"开户失败"}},[_c('div',{attrs:{"slot":"desc"},slot:"desc"},[_vm._v("开户失败，请重新开户")]),_c('div',{attrs:{"slot":"actions"},slot:"actions"},[_c('Button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.goPage('/sub-vue2/assets/dealerAccount/openAccount', {
                                cpcnAccountId: _vm.cpcnAccountId,
                                userType: _vm.userType,
                                type: 'reOpen',
                            })}}},[_vm._v("重新开户")])],1)])]:[_c('div',{staticClass:"img-wrapper"},[_c('img',{attrs:{"src":require("@/assets/images/result-success.png")}})]),_c('Result',{attrs:{"title":"开户成功"}},[_c('div',{attrs:{"slot":"desc"},slot:"desc"},[_vm._v("您已完成开户，请继续办理签约")]),_c('div',{attrs:{"slot":"actions"},slot:"actions"},[_c('Button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.goPage('/sub-vue2/assets/dealerAccount/signContract', {
                                userType: _vm.userType,
                                userId: _vm.userId,
                                cpcnAccountId: _vm.cpcnAccountId,
                            })}}},[_vm._v("立即签约")])],1)]),_c('div',{staticClass:"step-wrapper"},[_c('Steps',[_c('Step',{attrs:{"status":"finish","title":"填写基本信息"}}),_c('Step',{attrs:{"status":"finish","title":"绑卡认证"}}),_c('Step',{attrs:{"status":"finish","title":"开户"}}),_c('Step',{attrs:{"title":"完成签约"}})],1)],1)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }