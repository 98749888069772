<template>
    <div class="ivu-mt ivu-mb">
        <Result type="success" title="提交成功" class="ivu-mb-16">
            <div slot="desc">
                点击链接或者复制链接到浏览器网址栏中完成签署流程
            </div>
            <div slot="extra">
                <a :href="link" target="_blank" style="display: block;text-align: center;">{{link}}</a>
            </div>
            <div slot="actions">
                <Row :gutter="24" class="ivu-mt-16 ivu-mb-16">
                    <Col :xl="8" :lg="8" :md="8" :sm="8" :xs="8" push="8">
                        <Button type="primary" shape="circle" long size="large" @click="handleCopy">复制链接</Button>
                    </Col>
                </Row>
            </div>
        </Result>
        <p class="ivu-text-center" style="margin-top: 55px;color: #999;font-size: 13px;">如签署中遇到问题，无法完成签署流程，请联系您的渠道员！</p>
    </div>
</template>
<script>
    export default {
        name: 'result-fail',
        props: {
            link: {
                type: String,
                default: ''
            }
        },
        data () {
            return {
            }
        },
        methods: {
            handleCopy () {
                this.$Copy({
                    text: this.link,
                    success: () => {
                    },
                    error: () => {
                        this.$Message.error({
                            title: '链接复制失败，请手动复制！'
                        })
                    }
                });
            }
        }
    }
</script>
