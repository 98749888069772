// js 文件
import vue from "vue";
import Loader from "@/components/Loader.vue";

const Mask = vue.extend(Loader);
const wrapper = document.body;
const divEl = document.createElement("div");
const insertDom = (el) => {
    let loader = vue.pageLoaders || [];
    if (loader.length) return;
    wrapper.style.overflow = "hidden";
    wrapper.appendChild(el);
    vue.pageLoaders = [el];
};
const removeLoading = () => {
    let lod = vue.pageLoaders || [];
    lod.map((item) => {
        if (wrapper.contains(item)) {
            wrapper.removeChild(item);
        }
    });
    vue.pageLoaders = [];
};
export default vue.directive("loading", {
    bind: (el, binding) => {
        let mask = new Mask({
            el: divEl,
            data: {},
        });
        el.loadingElement = mask;
        if (binding.value) {
            insertDom(el.loadingElement.$el);
        }
    },
    update: (el, binding) => {
        if (binding.value) {
            insertDom(el.loadingElement.$el);
        } else {
            removeLoading();
            wrapper.style.overflow = "";
        }
    },
    // unbind: (el) => {
    //     wrapper.removeChild(el.loadingElement.$el)
    //     el.loadingElement = null
    // }
});
