<template>
    <div v-loading="list.loading">
        <PageHeader title="客户管理" hidden-breadcrumb>
            <Button
                slot="action"
                type="warning"
                class="ivu-ml"
                icon="md-cloud-download"
                @click="bulkModify"
                :loading="isExporting"
                >批量修改门店维护人</Button
            >
            <Button
                slot="action"
                type="info"
                class="ivu-ml"
                icon="md-cloud-download"
                @click="onExport"
                :loading="isExporting"
                >导出</Button
            >
        </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" @on-submit="onSubmit" ref="table-form" />
            <table-page
                @onPageChange="onPageChange"
                @onPageSizeChange="onPageSizeChange"
                @onSelectionChange="handleSelection"
                :list="list"
            />
        </Card>

        <ModalProtector ref="saleManModal" />
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { renderButton } from '@/libs/util';
import ModalProtector from './components/ModalProtector';
import { StoreList, ExportStore, SalesmanList, dictionaryListByType } from '@/api/store';
import { queryAreasByList } from '@/api/common';
import { jumpPage } from '@/libs/util';

export default {
    name: 'customer-manage-list',
    components: {
        tableForm,
        tablePage,
        ModalProtector,
    },
    data() {
        return {
            formData: {},
            showModal: false,
            isExporting: false,
            tableSelection: [],
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    { type: 'selection', width: 60, align: 'center' },
                    { title: '门店名称', key: 'storeName', minWidth: 220, align: 'center' },
                    { title: '联系人', key: 'ownerName', minWidth: 180, align: 'center' },
                    { title: '联系方式', key: 'ownerPhone', minWidth: 180, align: 'center' },
                    {
                        title: '可用返利(元)',
                        key: 'rebateBalance',
                        align: 'center',
                        minWidth: 130,
                    },
                    {
                        title: '可用积分(积分)',
                        key: 'rebateScore',
                        align: 'center',
                        minWidth: 130,
                    },
                    {
                        title: '新零售签约',
                        key: 'signStatusStr',
                        align: 'center',
                        minWidth: 130,
                    },
                    { title: '门店维护人', key: 'salesmanName', minWidth: 100, align: 'center' },
                    {
                        title: '标签',
                        align: 'center',
                        minWidth: 200,
                        render: (h, p) => {
                            const tags = p.row.tagList.map((item) => {
                                return h(
                                    'Tag',
                                    {
                                        props: {
                                            name: item,
                                            type: 'border',
                                            color: 'primary',
                                            closable: false,
                                        },
                                    },
                                    item,
                                );
                            });
                            return h('div', tags.length ? tags : '暂无标签');
                        },
                    },
                    {
                        title: '是否启用',
                        key: 'isEnabledStr',
                        align: 'center',
                        minWidth: 80,
                        // render: (h, p) => {
                        //     const str = p.row.logOutStatus == 1 ? '注销' : p.row.isEnabled ? '是' : '否'
                        //     return h('div', str)
                        // }
                    },
                    {
                        title: '操作',
                        key: 'oprate',
                        minWidth: 150,
                        align: 'center',
                        fixed: 'right',
                        render: (h, p) =>
                            renderButton(h, { text: '详情', type: 'primary' }, () => {
                                this.toDetail(p.row.id);
                            }),
                    },
                ],
                data: [],
            },
            formList: [
                {
                    title: '门店名称',
                    type: 'input',
                    key: 'storeName',
                },
                {
                    title: '联系人名称',
                    type: 'input',
                    key: 'ownerName',
                },
                {
                    title: '联系方式',
                    type: 'input',
                    key: 'ownerPhone',
                },
                {
                    title: '地区',
                    type: 'cascaderData',
                    key: 'provinceOrCityOrAreaId',
                    options: [],
                },
                {
                    title: '门店维护人',
                    type: 'select',
                    key: 'salesmanId',
                    placeholder: '请选择',
                    clearable: true,
                    options: [],
                },
                {
                    title: '标签',
                    type: 'select',
                    key: 'tagDictionaryId',
                    placeholder: '请选择',
                    clearable: true,
                    options: [],
                },
                {
                    title: '新零售签约',
                    type: 'select',
                    key: 'signStatus',
                    options: [
                        { name: '未签约', id: 0 },
                        { name: '签约中', id: 2 },
                        { name: '已签约', id: 1 },
                    ],
                },
            ],
            row: {},
            updateType: '',
        };
    },
    mounted() {
        queryAreasByList().then((res) => {
            function loop(areaList) {
                areaList.forEach((item) => {
                    item.label = item.gaodeAreaDTO.name;
                    item.value = item.gaodeAreaDTO.id;
                    item.children = item.childCategoryList;
                    delete item.childCategoryList;
                    delete item.gaodeAreaDTO;
                    if (item.children) {
                        loop(item.children);
                    }
                });
                return areaList;
            }

            this.formList[3].options = loop(res);
        });
    },
    activated() {
        // 用 keep-alive 被缓存的页面会 进入 activated 生命周期
        let p = this.$refs['table-form'].getFormData();
        this.getData(p);
        this.getSaleMan();
        this.getTagList();
    },
    methods: {
        toDetail(id) {
            jumpPage({ path: '/sub-vue2/customer/manage/storeDetail', params: { id: id } });
        },

        getSaleMan() {
            SalesmanList().then((res) => {
                this.formList[4].options = res || [];
                this.formList[4].options.unshift({ name: '无维护人', id: '0' });
            });
        },
        getData(p) {
            this.list.loading = true;
            const param = {
                current: this.list.current,
                size: this.list.size,
                ...p,
            };
            if (param.provinceOrCityOrAreaId && Array.isArray(param.provinceOrCityOrAreaId)) {
                param.provinceOrCityOrAreaId = param.provinceOrCityOrAreaId.at(-1);
            }
            StoreList(param)
                .then((res) => {
                    // console.log('99', res.records)
                    this.list.loading = false;
                    this.list.total = Number(res.total);
                    this.list.data = res.records || [];
                })
                .catch(() => {
                    this.list.loading = false;
                });
        },
        /**
         * 获取标签列表
         */
        getTagList() {
            dictionaryListByType({
                dataType: 5, // 5：门店标签
            }).then((res) => {
                this.formList[5].options =
                    (res || []).map((item) => {
                        return {
                            name: item.dataValue,
                            id: item.id,
                        };
                    }) || [];
            });
        },
        onSubmit(param) {
            this.list.current = 1;
            this.getData(param);
        },
        onPageChange(val) {
            // console.log('on Page Change ==', val)
            this.list.current = val;
            let form = this.$refs['table-form'].getFormData();
            this.getData(form);
        },
        onPageSizeChange(val) {
            // console.log('on PageSize Change ==', val)

            this.list.current = 1;
            this.list.size = val;
            let form = this.$refs['table-form'].getFormData();
            this.getData(form);
        },
        onExport() {
            this.list.loading = true;
            this.isExporting = true;
            let form = this.$refs['table-form'].getFormData();
            let params = {
                current: this.list.current,
                size: this.list.size,
                ...form,
            };
            ExportStore(params)
                .then((res) => {
                    let aLink = document.createElement('a');
                    aLink.href = res || '';
                    // console.log('href ===', res)
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    this.$Notice.success({
                        title: '导出成功',
                    });
                    this.list.loading = false;
                    this.isExporting = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.$Notice.error({
                        title: '导出失败 ！',
                    });
                    this.list.loading = false;
                    this.isExporting = false;
                });
        },
        // table 选择改变
        handleSelection(val) {
            this.tableSelection = val;
        },
        changeSalesMan(updateType, row) {
            this.$refs['saleManModal'].open(updateType, row);
        },
        bulkModify() {
            if (!this.tableSelection.length) {
                this.$Notice.info({
                    title: '请先勾选选择门店',
                });
                return;
            }
            let ids = '';
            this.tableSelection.map((item, index) => {
                ids += index === 0 ? item.id : ',' + item.id;
            });
            let row = { ids };
            this.changeSalesMan('BATCH', row);
        },
    },
};
</script>
<style scoped></style>
