<!--
 * @Author: chengleilei
 * @Date: 2022-05-18 16:16:24
 * @Description: 积分账户
-->
<template>
    <div class="score-record" v-loading="pageLoading">
        <Card :bordered="false" title="账户统计" dis-hover class="ivu-mt-16 i-table-no-border">
            <DescriptionList title="">
                <Description term="可用积分：">{{
                    detailInfo.usableScore != null ? detailInfo.usableScore : '-'
                }}</Description>
                <Description term="累计收入：">{{
                    detailInfo.totalIncomeScore != null ? detailInfo.totalIncomeScore : '-'
                }}</Description>
                <Description term="累计支出：">{{
                    detailInfo.expendScore != null ? detailInfo.expendScore : '-'
                }}</Description>
            </DescriptionList>
        </Card>
        <Card :bordered="false" title="账户明细" dis-hover class="i-table-no-border">
            <table-page :list="list" @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" />
        </Card>
    </div>
</template>
<script>
import { scoreAccountData, scoreAccountList } from '@/api/store.js';
import tablePage from '@/components/tablePage';

export default {
    components: { tablePage },
    data() {
        return {
            id: this.$route.query.id || '',
            pageLoading: false,
            detailInfo: {},
            // 表格数据列表
            list: {
                page: 1,
                size: window.$pageSize,
                total: 0,
                columns: [
                    {
                        title: '入账时间',
                        key: 'createDate',
                        align: 'center',
                        minWidth: 120,
                    },
                    {
                        title: '收入（积分）',
                        key: 'incomeScore',
                        align: 'center',
                        minWidth: 120,
                        render: (h, p) => {
                            return h('div', p.row.incomeScore || '-');
                        },
                    },
                    {
                        title: '支出（积分）',
                        key: 'expendScore',
                        align: 'center',
                        minWidth: 120,
                        render: (h, p) => {
                            return h('div', p.row.expendScore || '-');
                        },
                    },
                    {
                        title: '账户结余',
                        key: 'balanceScore',
                        align: 'center',
                        minWidth: 120,
                    },
                    {
                        title: '类型',
                        key: 'dataTypeStr',
                        align: 'center',
                        minWidth: 120,
                    },
                    {
                        title: '备注',
                        key: 'remark',
                        align: 'center',
                        minWidth: 120,
                        render: (h, p) => {
                            return h('div', p.row.remark || '-');
                        },
                    },
                ],
                data: [],
                loading: false,
            },
        };
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            this.getAccountData();
            this.getAccountList();
        },

        /**
         * @description 页码改变
         * @param {Number} val 页码
         */
        onPageChange(val) {
            this.list.page = val;
            this.getAccountList();
        },

        /**
         * @description x条/页改变
         * @param {Number} val 条数
         */
        onPageSizeChange(val) {
            this.list.page = 1;
            this.list.size = val;
            this.getAccountList();
        },

        /**
         * @description 查积分账户统计
         */
        getAccountData() {
            this.pageLoading = true;
            scoreAccountData(this.id).then(
                (res) => {
                    this.detailInfo = res || {};
                    this.pageLoading = false;
                },
                () => {
                    this.pageLoading = false;
                },
            );
        },

        /**
         * @description 查积分账户明细
         */
        getAccountList() {
            const params = {
                current: this.list.page,
                size: this.list.size,
                storeId: this.id,
            };
            this.list.loading = true;
            scoreAccountList(params)
                .then((res) => {
                    this.list.data = [];
                    this.list.total = Number(res.total);
                    this.list.data = res.records || [];
                })
                .finally(() => {
                    this.list.loading = false;
                });
        },
    },
};
</script>
<style lang="less" scoped>
.score-record {
    padding-bottom: 50px;
    /deep/ .i-table-no-border .ivu-table th {
        background-color: #f8f8f9;
    }
}
</style>
