<!--
 * @Author: chengleilei
 * @Date: 2021-11-30 14:10:25
 * @Description: 授信账户
-->

<template>
    <div v-loading="loading" class="credit-account-list">
        <PageHeader title="授信设置" hidden-breadcrumb></PageHeader>
        <Alert show-icon class="ivu-mt com-alert"> 此页面设置内容仅对您的账号生效 </Alert>

        <Card :bordered="false" dis-hover class="ivu-mt">
            <div class="setting-account-safety">
                <div class="ivu-mt ivu-pl ivu-pr">
                    <Row type="flex" justify="center" align="middle">
                        <Col span="22">
                            <h4>授信账户变动提醒</h4>
                            <p>开启后，所有门店调整授信额度和收款时，将会向您发送短信提醒</p>
                        </Col>
                        <Col span="2" class="ivu-text-right">
                            <el-switch v-model="status" @change="handleBeforeChange"> </el-switch>
                        </Col>
                    </Row>
                    <Divider />
                    <Row type="flex" justify="center" align="middle">
                        <Col span="22">
                            <h4>授权密码</h4>
                            <p>对门店授信账户进行操作时，需输入此密码验证您的身份</p>
                        </Col>
                        <Col span="2" class="ivu-text-right">
                            <span @click="handlePwdSettingOpen">
                                <i-link link-color>修改</i-link>
                            </span>
                        </Col>
                    </Row>
                    <Divider />
                </div>
            </div>
        </Card>

        <PwdSetting ref="pwdSetting" @success="settingDone"> </PwdSetting>
    </div>
</template>

<script>
import PwdSetting from '../components/PwdSetting';
import { creditStatusOpen, creditStatusClose, creditAccountInfo } from '@/api/credit';

export default {
    components: {
        PwdSetting,
    },
    data() {
        return {
            loading: false,
            status: false,
            statusLoading: true,
        };
    },

    mounted() {
        this.loading = true;
        creditAccountInfo().then(
            (res) => {
                this.status = res.creditAccountModifyStatus || false;
                this.loading = false;
            },
            () => {
                this.loading = false;
            },
        );
    },

    methods: {
        settingDone() {},
        handleBeforeChange() {
            this.status = !this.status;
            return new Promise((resolve) => {
                this.$Modal.confirm({
                    title: '切换确认',
                    content: `确认要「${this.status ? '关闭' : '开启'}」授信账户变动提醒？`,
                    onOk: () => {
                        this.statusLoading = true;
                        let request = !this.status ? creditStatusClose : creditStatusOpen;
                        request().then(
                            () => {
                                this.statusLoading = false;
                                this.status = !this.status;
                                this.$Message.success(`${!this.status ? '关闭' : '开启'}成功！`);
                                resolve();
                            },
                            () => {
                                this.statusLoading = false;
                            },
                        );
                    },
                });
            });
        },
        handlePwdSettingOpen() {
            this.$refs['pwdSetting'].open();
        },
    },
};
</script>

<style lang="less" scoped>
.credit-account-list {
}
.setting-account-safety {
    .ivu-col {
        p {
            margin-top: 8px;
            color: #808695;
        }
    }
}
</style>
