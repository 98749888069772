<!--
 * @Author: chengleilei
 * @Date: 2023-04-25 16:31:35
 * @Description: 授信手续费统计
-->
<template>
    <div>
        <PageHeader title="授信手续费统计" hidden-breadcrumb></PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" @on-submit="onSubmit" @on-export="showExportModal" ref="table-form">
                <Button class="ivu-ml-8" slot="extraBtn" type="error">导出</Button>
            </table-form>
            <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list" />
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { clearEmpty } from '@/libs/util';
import { feeList, feeListExport } from '@/api/statistics';

export default {
    components: {
        tableForm,
        tablePage,
    },
    data() {
        return {
            list: {
                page: 1,
                size: window.$pageSize,
                total: 0,
                columns: [
                    {
                        title: '订单编号',
                        key: 'orderNo',
                        align: 'center',
                        minWidth: 140,
                    },
                    {
                        title: '交易流水号',
                        key: 'paySn',
                        align: 'center',
                        minWidth: 140,
                    },
                    {
                        title: '交易时间',
                        key: 'payTime',
                        align: 'center',
                        minWidth: 100,
                    },
                    {
                        title: '交易类型',
                        key: 'payBusinessTypeStr',
                        align: 'center',
                        minWidth: 80,
                    },
                    {
                        title: '交易金额(元)',
                        key: 'creditAmount',
                        align: 'center',
                        minWidth: 80,
                    },
                    {
                        title: '手续费(元)',
                        key: 'serviceCharge',
                        align: 'center',
                        minWidth: 80,
                        render: (h, p) => {
                            return [p.row.serviceCharge == null ? '-' : p.row.serviceCharge];
                        },
                    },
                    {
                        title: '手续费退款(元)',
                        key: 'refundServiceCharge',
                        align: 'center',
                        minWidth: 100,
                        render: (h, p) => {
                            return [p.row.refundServiceCharge == null ? '-' : p.row.refundServiceCharge];
                        },
                    },
                    {
                        title: '门店名称',
                        key: 'storeName',
                        align: 'center',
                        minWidth: 100,
                    },
                    {
                        title: '联系人',
                        key: 'ownerName',
                        align: 'center',
                        minWidth: 100,
                    },
                    {
                        title: '联系方式',
                        key: 'ownerPhone',
                        align: 'center',
                        minWidth: 100,
                    },
                    {
                        title: '门店维护人',
                        key: 'salesmanName',
                        align: 'center',
                        minWidth: 100,
                    },
                ],
                data: [],
                loading: false,
            },
            formList: [
                {
                    title: '订单编号',
                    key: 'orderNo',
                    type: 'input',
                    placeholder: '请输入特价订单编号',
                },
                {
                    title: '门店信息',
                    key: 'storeInfo',
                    type: 'input',
                    placeholder: '请输入门店名称/联系人/联系方式',
                },
                {
                    title: '交易类型',
                    type: 'select',
                    key: 'payBusinessType',
                    options: [
                        {
                            name: '支付',
                            id: '0',
                        },
                        {
                            name: '退款',
                            id: '2',
                        },
                    ],
                },
                {
                    title: '交易时间',
                    type: 'daterange',
                    key: ['startTime', 'endTime'],
                    placeholder: '请选择时间',
                },
            ],
        };
    },

    mounted() {
        this.getData();
    },
    methods: {
        /**
         * @description 处理科室-部门相关数据放入选项中
         * @param {Array} arr 科室-部门相关数据
         */
        recursionDept(arr) {
            for (let i = 0; i < arr.length; i++) {
                arr[i]['label'] = arr[i]['name'];
                arr[i]['value'] = arr[i]['id'];
                if (arr[i].children) {
                    this.recursionDept(arr[i].children);
                } else {
                    delete arr[i].children;
                }
            }
            return arr;
        },

        /**
         * @description 添加到导出列表并打开提示框
         */
        showExportModal() {
            const params = this.getParams();
            console.log('params', params);

            this.$Message.loading({
                content: '导出中...',
                duration: 0,
            });
            feeListExport(params)
                .then((res) => {
                    if (!res) {
                        this.$Message.warning('暂无数据');
                        return false;
                    }
                    let aLink = document.createElement('a');
                    aLink.href = res || '';
                    // console.log('href ===', res)
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    this.$Notice.success({
                        title: '导出成功',
                    });
                })
                .catch(() => {})
                .finally(() => {
                    this.$Message.destroy();
                });
        },

        /**
         * @description 获取表格数据
         */
        getData() {
            const params = this.getParams();
            this.list.loading = true;
            console.log('search == ', params);
            feeList(params)
                .then((res) => {
                    this.list.data = [];
                    this.list.total = Number(res.total);
                    this.list.data = res.list || [];
                })
                .catch(() => {})
                .finally(() => {
                    this.list.loading = false;
                });
        },

        /**
         * @description 获取查询参数
         */
        getParams() {
            let p = this.$refs['table-form'].getFormData();
            if (p['dept']) {
                p.deptId = p.dept[0];
                p.regionId = p.dept[1];
                delete p['dept'];
            }
            p = clearEmpty(p, true);
            const copyP = { ...p };
            const params = {
                ...copyP,
                current: this.list.page,
                size: this.list.size,
            };
            return params;
        },

        /**
         * @description 页码改变
         * @param {Number} val 页码
         */
        onPageChange(val) {
            this.list.page = val;
            this.getData();
        },

        /**
         * @description x条/页改变
         * @param {Number} val 条数
         */
        onPageSizeChange(val) {
            this.list.page = 1;
            this.list.size = val;
            this.getData();
        },

        /**
         * @description 查询
         */
        onSubmit() {
            this.list.page = 1;
            this.getData();
        },
    },
};
</script>
