import request from '@/plugins/request';

// 品牌与资质列表
export function distributorBrandList (data) {
    return request({
        url: '/distributor/distributor_brand/pageDistributorBrandPool',
        method: 'get',
        params: data
    });
}
// 品牌与资质签约详情
export function distributorBrandDetail (data) {
    return request({
        url: '/distributor/distributor_brand/findSignBrandById',
        method: 'get',
        params: data
    });
}
// 品牌与资质授权详情
export function distributorEmpowerDetail (data) {
    return request({
        url: '/distributor/distributor_brand/findEmpowerBrandById',
        method: 'get',
        params: data
    });
}
// 切换品牌获取地区和部门
export function changeDistributorInfo (data) {
    return request({
        url: '/distributor/distributor_brand/findByDistributorIdAndBrandId',
        method: 'get',
        params: data
    });
}
// 经销商后台账号列表
export function distributorAccountList (data) {
    return request({
        url: '/user/list',
        method: 'post',
        data
    });
}
// 账号禁用启用
export function accountUpdate (data) {
    return request({
        url: '/user/update',
        method: 'post',
        data
    });
}
// 账号详情
export function accountDetail (data) {
    return request({
        url: '/user/findById',
        method: 'get',
        params: data
    });
}
// 经销商角色列表
export function distributorRoleList (data) {
    return request({
        url: '/role/list',
        method: 'post',
        data
    });
}
// pda下载地址
export function findPDAList (data) {
    return request({
        url: '/distributor/pda/findPDA',
        method: 'get',
        params: data
    });
}
// 门店列表
export function storeList (data) {
    return request({
        url: '/distributor/store_contract/listContractStore',
        method: 'post',
        data
    });
}
// 新建账号信息
export function saveAccount (data) {
    return request({
        url: `/user/${data.id ? 'update' : 'save'}`,
        method: 'post',
        data
    });
}
