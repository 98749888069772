<template>
    <div>
        <PageHeader title="门店线上支付统计" hidden-breadcrumb> </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" @on-submit="onSubmit" @on-export="exportInfo" ref="table-form">
                <Button class="ivu-ml-8" :disabled="exportLoading" :loading="exportLoading" slot="extraBtn" type="error"
                    >导出</Button
                >
            </table-form>
            <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list" />
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { renderButton, clearEmpty, jumpPage } from '@/libs/util';
import { payStatistics, payStatisticsExport } from '@/api/statistics';

let mouthNow = new Date().getMonth() + 1;
mouthNow = mouthNow < 10 ? '0' + mouthNow : mouthNow;
let nowMonth = `${new Date().getFullYear()}${mouthNow}`;

export default {
    components: {
        tableForm,
        tablePage,
    },
    data() {
        return {
            exportLoading: false,
            batchLoading: false,
            month: nowMonth,
            verifyStatus: null,
            isChangePage: false,
            formList: [
                {
                    title: '门店',
                    key: 'storeKeyword',
                    width: '230px',
                    type: 'input',
                    placeholder: '请输入门店名称/联系人/联系方式',
                },
                {
                    title: '月份',
                    type: 'month',
                    key: 'date',
                    placeholder: '请选择月份',
                },
            ],
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    { title: '门店名称', key: 'storeName', align: 'center', minWidth: 150, fixed: 'left' },
                    { title: '联系人', key: 'ownerName', align: 'center', minWidth: 100 },
                    { title: '联系方式', key: 'ownerPhone', align: 'center', minWidth: 140 },
                    { title: '门店类型', key: 'storeType', align: 'center', minWidth: 140 },
                    { title: '经销商', key: 'distributorName', align: 'center', minWidth: 180 },
                    { title: '科室', key: 'dept', align: 'center', minWidth: 140 },
                    { title: '部门', key: 'region', align: 'center', minWidth: 140 },
                    { title: '月份', key: 'month', align: 'center', minWidth: 140 },
                    { title: '应冲返利', key: 'needRefundAmount', align: 'center', minWidth: 140 },
                    { title: '已支付订单', key: 'payedOrderCount', align: 'center', minWidth: 120 },
                    { title: '订单总金额', key: 'totalOrderAmount', align: 'center', minWidth: 120 },
                    { title: '返利抵用', key: 'rebate', align: 'center', minWidth: 100 },
                    { title: '实付金额', key: 'actualPayedAmount', align: 'center', minWidth: 120 },
                    { title: '已退款订单', key: 'refundCount', align: 'center', minWidth: 120 },
                    { title: '已退款总金额', key: 'refundTotalAmount', align: 'center', minWidth: 125 },
                    { title: '返利退款', key: 'rebateRefundTotal', align: 'center', minWidth: 120 },
                    { title: '实付退款', key: 'actualRefundAmount', align: 'center', minWidth: 125 },
                    {
                        title: '操作',
                        key: 'oprate',
                        align: 'center',
                        width: 240,
                        fixed: 'right',
                        render: (h, p) => {
                            return [
                                renderButton(h, { text: '支付明细', type: 'primary' }, () => {
                                    this.checkDetail(p.row);
                                }),
                                renderButton(h, { text: '退款明细', type: 'primary' }, () => {
                                    this.checkRefundDetail(p.row);
                                }),
                            ];
                        },
                    },
                ],
                data: [],
            },
        };
    },
    activated() {
        this.getData();
    },
    methods: {
        recursion(arr) {
            let _this = this;
            for (let i = 0; i < arr.length; i++) {
                arr[i]['label'] = arr[i]['name'];
                arr[i]['value'] = arr[i]['id'];
                if (arr[i].children) {
                    _this.recursion(arr[i].children);
                } else {
                    delete arr[i].children;
                }
            }
            return arr;
        },
        getData() {
            this.list.loading = true;
            let p = this.$refs['table-form'].getFormData();
            if (p.date) {
                p.month = p.date.replace('-', '');
            } else {
                let mouth = new Date().getMonth() + 1;
                mouth = mouth < 10 ? '0' + mouth : mouth;
                p.month = `${new Date().getFullYear()}${mouth}`;
            }
            this.month = p.month;
            delete p.date;
            if (p['dept']) {
                p.deptId = p.dept[0];
                p.regionId = p.dept[1];
                delete p['dept'];
            }
            if (p.provinceId) p.provinceId = p.provinceId[0];
            let params = clearEmpty({
                current: this.list.current,
                size: this.list.size,
                ...p,
            });
            // delete params.provinceId
            console.log('params after =', params);
            payStatistics(params).then(
                (data) => {
                    this.list.total = Number(data.total);
                    this.list.data = (data.records || []).map((item) => {
                        return {
                            ...item,
                        };
                    });
                    this.isChangePage = false;
                    this.list.loading = false;
                },
                () => {
                    this.isChangePage = false;
                    this.list.loading = false;
                },
            );
        },
        exportData() {
            let p = this.$refs['table-form'].getFormData();
            if (p.date) {
                p.month = p.date.replace('-', '');
            } else {
                let mouth = new Date().getMonth() + 1;
                mouth = mouth < 10 ? '0' + mouth : mouth;
                p.month = `${new Date().getFullYear()}${mouth}`;
            }
            this.month = p.month;
            delete p.date;
            if (p['dept']) {
                p.deptId = p.dept[0];
                p.regionId = p.dept[1];
                delete p['dept'];
            }
            if (p.provinceId) p.provinceId = p.provinceId[0];
            let params = {
                ...p,
            };
            this.$Message.loading({
                content: '导出中...',
                duration: 0,
            });
            this.exportLoading = true;
            payStatisticsExport(params).then(
                (res) => {
                    this.exportLoading = false;
                    if (!res) {
                        this.$Message.warning('暂无数据');
                        return false;
                    }
                    let aLink = document.createElement('a');
                    aLink.href = res || '';
                    // console.log('href ===', res)
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    this.$Message.destroy();
                    this.$Message.success('导出成功');
                },
                () => {
                    this.$Message.destroy();
                    this.exportLoading = false;
                },
            );
        },
        onPageChange(val) {
            if (this.isChangePage) return;
            this.list.current = val;
            this.getData();
        },
        onPageSizeChange(val) {
            this.list.current = 1;
            this.isChangePage = true;
            this.list.size = val;
            this.getData();
        },
        checkDetail(row) {
            jumpPage({
                path: '/sub-vue2/sales/storePay/detail',
                params: {
                    storeId: row.storeId,
                    month: this.month,
                    distributorId: row.distributorId,
                },
            });
        },
        checkRefundDetail(row) {
            jumpPage({
                path: '/sub-vue2/sales/storePay/refundDetail',
                params: {
                    storeId: row.storeId,
                    month: this.month,
                    distributorId: row.distributorId,
                },
            });
        },
        onSubmit() {
            this.list.current = 1;
            this.getData();
        },
        exportInfo() {
            this.exportData();
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .ivu-form-item {
    &:nth-child(1) {
        width: 325px;
    }
}
</style>
