<!-- eslint-disable vue/no-mutating-props -->
<template>
    <Card :bordered="true" dis-hover class="ivu-mt">
        <Form :label-width="180">
            <Row class="ivu-pr8">
                <Col span="12">
                    <FormItem label="门店名称：">
                        {{ detailObj.storeName || '-' }}
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="门店编号：">
                        {{ detailObj.storeSn || '-' }}
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="联系手机：">
                        {{ detailObj.ownerPhone || '-' }}
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="店主姓名：">
                        {{ detailObj.ownerName || '-' }}
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="所在区域：">
                        {{ detailObj.provinceName || '-' }}
                        {{ detailObj.cityName || '-' }}
                        {{ detailObj.areaName || '-' }}
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="详细地址：">
                        {{ detailObj.storeAddress || '-' }}
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="经销商：">
                        {{ detailObj.distributorName || '-' }}
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="主营品牌：">
                        {{ (detailObj.majorBrand || []).join('，') || '-' }}
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="副营品牌：">
                        {{ (detailObj.secondaryBrand || []).join('，') || '-' }}
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="申请类型：">
                        {{ detailObj.storeTypeDetailStr || '-' }}
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="申请时间：">
                        {{ detailObj.applyTime || '-' }}
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="施工类型：">
                        {{ detail.constructionType || '-' }}
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="门头尺寸：">
                        <p>长度：{{ detailObj.applyLength || '-' }} 米</p>
                        <p>高度：{{ detailObj.applyHeight || '-' }} 米</p>
                        <p>檐口：{{ detailObj.cornice || '-' }} 米</p>
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="房屋情况：">
                        {{ detailObj.houseType || '-' }}
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="门面个数："> {{ detailObj.facadeNum || '-' }}个 </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="营业面积："> {{ detailObj.businessArea || '-' }}平方米 </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="工位数量："> {{ detailObj.stationsNum || '-' }}个 </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="原始照片：">
                        <span v-if="detailObj.headUrl && detailObj.headUrl.length > 0">
                            <span
                                class="source-img img-preview"
                                :style="{ backgroundImage: 'url(' + detailObj.headUrl + ')' }"
                                :src="detailObj.headUrl"
                            >
                                <img :src="detailObj.headUrl" preview="0" alt="" preview-text="原始照片" />
                            </span>
                        </span>
                        <span v-if="detailObj.indoorUrl && detailObj.indoorUrl.length > 0">
                            <span
                                class="source-img img-preview"
                                :style="{ backgroundImage: 'url(' + detailObj.indoorUrl + ')' }"
                                :src="detailObj.indoorUrl"
                            >
                                <img :src="detailObj.indoorUrl" preview="0" alt="" preview-text="原始照片" />
                            </span>
                        </span>
                        <span v-if="detailObj.trafficUrl && detailObj.trafficUrl.length > 0">
                            <span
                                class="source-img img-preview"
                                :style="{ backgroundImage: 'url(' + detailObj.indoorUrl + ')' }"
                                :src="detailObj.trafficUrl"
                            >
                                <img :src="detailObj.indoorUrl" preview="0" alt="" preview-text="原始照片" />
                            </span>
                        </span>
                    </FormItem>
                </Col>
            </Row>
        </Form>
    </Card>
</template>

<script>
export default {
    props: {
        detail: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            loading: true,
            pdf: {},
            detailObj: {},
        };
    },
    mounted() {
        this.detailObj = this.detail;
    },
    methods: {
        getDetail(res) {
            this.loading = false;
            this.detailObj = res || {};
            res.headUrl && this.$previewRefresh();
        },
    },
};
</script>

<style lang="less">
.ellipsis {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.source-img {
    display: inline-block;
    width: 70px;
    height: 70px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 15px;
    cursor: pointer;
    position: relative;
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
    }
}
</style>
