import WarehouseEntryList from '@/pages/warehouse/entry/EntryList';
import WarehouseEntryEdit from '@/pages/warehouse/entry/EntryEdit';
import { applyRoute } from '../const';

const warehouseRoutes = [
    {
        path: `${applyRoute}/warehouse/entry/list`,
        meta: {
            auth: ['outbound'],
            title: '入库管理',
            cache: true,
        },
        component: WarehouseEntryList,
    },
    {
        path: `${applyRoute}/warehouse/entry/edit`,
        meta: {
            auth: ['outbound'],
            title: '入库管理订单详情',
            cache: false,
        },
        component: WarehouseEntryEdit,
    },
];

export { warehouseRoutes };
