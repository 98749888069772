<template>
    <Modal :width="785" v-model="model" :title="'店检详情'" class="auditModal">
        <Spin size="large" fix v-if="loading">加载中...</Spin>

        <div class="store-detail">
            <Form label-colon v-if="detail">
                <Row>
                    <Col push="1" span="11">
                        <FormItem label="门店名称">
                            <p>{{ detail.storeName }}</p>
                        </FormItem>
                    </Col>
                    <Col push="1" span="9">
                        <FormItem label="联系人">
                            <p>{{ detail.ownerName }}</p>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col push="1" span="11">
                        <FormItem label="联系方式">
                            <p>{{ detail.ownerPhone }}</p>
                        </FormItem>
                    </Col>
                    <Col push="1" span="9">
                        <FormItem label="店检类型">
                            <p>
                                {{
                                    {
                                        '1': '月度店检',
                                        '2': '宣传路演活动',
                                        '3': '巡检扣分',
                                    }[detail.type]
                                }}
                            </p>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col push="1" span="11">
                        <FormItem label="活动时间">
                            <p>{{ detail.createDate }}</p>
                        </FormItem>
                    </Col>
                    <Col push="1" span="11">
                        <FormItem :label="typeKeyToValue[detail.type]">
                            <img class="img-box" :src="detail.imageUrl" alt="" @click="checkImg(detail.imageUrl)" />
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        </div>

        <Row>
            <Col push="2" span="20">
                <div id="storeMap" class="store-map ivu-mb-16"></div>
            </Col>
        </Row>

        <Row>
            <Col push="2" span="20" v-if="detail && detail.score > 0">
                <Alert>{{ modeType == 2 ? '业务主管打分' : '得分' }}：{{ level[detail.score || ''] }}</Alert>
            </Col>
            <Col push="2" span="20" style="margin-left: 26px" v-if="modeType == 2">
                <div class="store-info">
                    <RadioGroup v-model="pointEnum">
                        <Radio :label="item.value" v-for="(item, index) in options" :key="index">
                            <span>{{ item.label }}</span>
                        </Radio>
                    </RadioGroup>
                </div>
            </Col>
        </Row>

        <div slot="footer">
            <Button type="warning" @click="onCancel">{{ modeType == 1 ? '关闭' : '取消' }}</Button>
            <Button type="primary" :loading="loading" :disabled="mode === 'CHECK'" @click="submit" v-if="modeType == 2"
                >确认</Button
            >
        </div>

        <Modal title="图片预览" v-model="visible" width="700px" footer-hide>
            <img :src="activeImg" style="width: 100%" />
        </Modal>
    </Modal>
</template>

<script>
import { monthlyApplyAudit, propagandaAudit, storeAssessmentDetailOne } from '@/api/statistics';
let map = null;
let optionsA = [
    { label: '合格', value: 'ALL_QUALIFIED' },
    { label: '门头不合格', value: 'HEAD_DISPLAY_UNQUALIFIED' },
    { label: '堆头不合格', value: 'STACKING_UNQUALIFIED' },
    { label: '全不合格', value: 'ALL_UNQUALIFIED' },
];
let levelA = {
    1: '合格',
    2: '门头不合格',
    3: '堆头不合格',
    4: '全不合格',
};
let optionsB = [
    { label: '合格', value: 'ALL_QUALIFIED' },
    { label: '员工形象不合格', value: 'EMPLOYE_EIMAGE_UNQUALIFIED' },
    { label: '横幅悬挂不合格', value: 'BANNER_HANGING_UNQUALIFIED' },
    { label: '全不合格', value: 'ALL_UNQUALIFIED' },
];
let levelB = {
    1: '合格',
    2: '员工形象不合格',
    3: '横幅悬挂不合格',
    4: '全不合格',
};

export default {
    name: 'auditModal',
    data() {
        return {
            model: false,
            pointEnum: '',
            loading: true,
            detail: null,
            visible: false,
            activeImg: '',
            level: levelA,
            options: optionsA,
            modeType: false,
        };
    },
    computed: {
        typeKeyToValue(key) {
            const valuesObj = {
                1: '门头照片',
                2: '活动照片',
                3: '违规现场照片',
            };
            return valuesObj[key];
        },
    },
    beforeDestroy() {
        map = null;
    },
    mounted() {
        // eslint-disable-next-line no-undef
        map = new AMap.Map('storeMap', {
            zoom: 12,
            scrollWheel: true,
        });
    },
    methods: {
        checkImg(activeImg) {
            this.activeImg = activeImg;
            this.visible = true;
        },
        open(row, mode) {
            this.modeType = mode;
            let type = row.type;
            let applyId = row.applyId;
            this.id = applyId;
            this.type = type;
            let getDetail = storeAssessmentDetailOne;
            if (type === 2) {
                this.level = levelB;
                this.options = optionsB;
            }
            this.loading = true;
            getDetail({
                applyId,
            }).then(
                (res) => {
                    this.detail = res;
                    this.loading = false;
                    let { latitude: lat, longitude: lng } = res;
                    map.setCenter([lng, lat]); // 设置地图中心点

                    // eslint-disable-next-line no-undef
                    let marker = new AMap.Marker({
                        icon: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_r.png',
                        position: [lng, lat],
                        // eslint-disable-next-line no-undef
                        offset: new AMap.Pixel(-13, -30),
                    });
                    marker.setMap(map);
                    marker.setPosition([lng, lat]); //更新点标记位置
                },
                () => {
                    this.loading = false;
                },
            );
            this.model = true;
        },
        onCancel() {
            this.pointEnum = '';
            this.loading = true;
            this.detail = null;
            this.visible = false;
            this.activeImg = '';
            this.model = false;
        },
        submit() {
            let { id, pointEnum } = this;
            if (!pointEnum) {
                this.$Modal.warning({
                    title: '提示',
                    content: '请选择打分项！',
                });
                return false;
            }
            this.$Message.loading({
                content: '审核中...',
                duration: 0,
            });
            let type = this.type;
            let audit = monthlyApplyAudit;
            if (type === 2) {
                audit = propagandaAudit;
            }
            this.loading = true;
            audit({
                id,
                pointEnum,
            }).then(
                () => {
                    this.loading = false;
                    this.$bus.$emit('getData');
                    this.$Message.destroy();
                    this.$Message.success('审核完成~');
                    this.model = false;
                },
                () => {
                    this.loading = false;
                    this.$Message.destroy();
                },
            );
        },
    },
};
</script>

<style lang="less">
.auditModal {
    .ivu-form-item {
        margin-bottom: 0;
        &:nth-child(1) {
            width: auto;
        }
    }
    .vxe-edit-icon.vxe-icon--edit-outline {
    }
    .store-detail {
    }
    .img-box {
        width: 75px;
        height: 75px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 10px;
        cursor: pointer;
        border-radius: 4px;
    }
}
.store-map {
    height: 250px;
}
</style>
