import Vue from 'vue'
// eslint-disable-next-line
var speExp = /([\:\-\_]+(.))/g;
var mozExp = /^moz([A-Z])/;
var toCamelCase = function camelCase (name) {
    return name.replace(speExp, function (_, separator, letter, offset) {
        return offset ? letter.toUpperCase() : letter;
    }).replace(mozExp, 'Moz$1');
};
function getStyle (element, styleName) {
    if (!element || !styleName) return null;
    styleName = toCamelCase(styleName);
    if (styleName === 'float') {
        styleName = 'cssFloat';
    }
    try {
        var computed = document.defaultView.getComputedStyle(element, '');
        return element.style[styleName] || computed ? computed[styleName] : null;
    } catch (e) {
        return element.style[styleName];
    }
}
var toggleTip = (cellChild) => {
    var range = document.createRange();
    range.setStart(cellChild, 0);
    range.setEnd(cellChild, cellChild.childNodes.length);
    var rangeWidth = range.getBoundingClientRect().width;
    var padding = (parseInt(getStyle(cellChild, 'paddingLeft'), 10) || 0) + (parseInt(getStyle(cellChild, 'paddingRight'), 10) || 0);
    if ((rangeWidth + padding > cellChild.parentNode.offsetWidth + 1 || cellChild.parentNode.scrollWidth > cellChild.parentNode.offsetWidth)) {
        return true
    }
    if ((rangeWidth + padding > cellChild.offsetWidth + 1 || cellChild.scrollWidth > cellChild.offsetWidth)) {
        return true
    }
    return false
};

Vue.directive('toggleTip', function (el) {
    let $this = arguments[0].__vue__;
    $this.disabled = false;
    $this.handleShowPopper = function handleShowPopper () {
        let showTip = toggleTip(el)
        if (!showTip) {
            $this.visible = false;
            return
        }
        if ($this.timeout) clearTimeout($this.timeout);
        $this.timeout = setTimeout(() => {
            $this.visible = true;
        }, $this.delay);
        $this.tIndex = $this.handleGetIndex();
    }
});
