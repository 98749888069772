import Vue from 'vue';
import VueRouter from 'vue-router';
import { subVue2Routes } from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
    routes: subVue2Routes,
    mode: 'history',
});

export default router;
