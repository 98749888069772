// 省市区相关接口
import request from "@/plugins/request";

// 根据id获取子地址列表
export function GetAddressByParentId(data) {
    return request({
        url: "/common/area/list_by_parentId",
        method: "get",
        params: data,
    });
}
