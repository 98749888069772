/** * @Author: forguo * @Date: 2021/10/6 16:13 * @Description: 绑卡表单提交 */
<template>
    <div class="account-edit" v-loading="loading">
        <PageHeader :title="title" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage">
                <Icon type="md-arrow-back" />
            </div>
        </PageHeader>

        <Card title="" class="ivu-mt" :dis-hover="true" shadow>
            <Row class="ivu-mb-16">
                <Col push="6" span="12">
                    <Steps :current="currentStep">
                        <Step title="填写信息" content=""></Step>
                        <Step title="提交认证" content=""></Step>
                    </Steps>
                </Col>
            </Row>

            <Alert closable>
                <div class="upload-tips" v-if="userType == 12">
                    <p><span style="color: rgba(0, 0, 0, 0.647058823529412)">照片上传要求：</span></p>
                    <p>
                        <span style="color: rgba(0, 0, 0, 0.647058823529412)">1、要求传</span>
                        <span style="color: rgba(217, 0, 27, 0.996078431372549)"> jpg 格式</span>
                        <span style="color: rgba(0, 0, 0, 0.647058823529412)">图片，每个影印件大小</span>
                        <span style="color: rgba(217, 0, 27, 0.996078431372549)"> 500KB 以内</span>
                        <span style="color: rgba(0, 0, 0, 0.647058823529412)">。图片在线压缩工具：</span>
                        <a href="https://www.bejson.com/ui/compress_img/" target="_blank" style="color: #449afe"
                            >https://www.bejson.com/ui/compress_img/</a
                        >
                    </p>
                    <p>
                        <span style="color: rgba(0, 0, 0, 0.647058823529412)"
                            >2、企业用户影印件盖章要求：彩色扫描件或黑白复印件上</span
                        ><span style="color: rgba(217, 0, 27, 0.968627450980392)">加盖公章鲜章</span
                        ><span style="color: rgba(0, 0, 0, 0.647058823529412)">，每个文件盖章要求展示完整，</span
                        ><span style="color: rgba(217, 0, 27, 0.996078431372549)">一定要盖到文字部分</span
                        ><span style="color: rgba(0, 0, 0, 0.647058823529412)">，不允许空白处盖章</span>
                    </p>
                    <p><span style="color: rgba(0, 0, 0, 0.647058823529412)">3、有效证件应在有效期内</span></p>
                    <p>
                        <span style="color: rgba(0, 0, 0, 0.647058823529412)"
                            >4、原件及影印件不得涂改（除标注证件用途外），需确保文字、 图片、证件号码清晰可辨</span
                        >
                    </p>
                    <p>
                        <span style="color: rgba(0, 0, 0, 0.647058823529412)"
                            >5、不可在影印件标记其他使用用途，如必须标记，可标记只用于中金支付开户使用</span
                        >
                    </p>
                </div>
                <div class="upload-tips" v-else>
                    <p><span style="color: rgba(0, 0, 0, 0.647058823529412)">照片上传要求：</span></p>
                    <p>
                        <span style="color: rgba(0, 0, 0, 0.647058823529412)">1、要求传</span
                        ><span style="color: rgba(217, 0, 27, 0.996078431372549)"> jpg 格式</span
                        ><span style="color: rgba(0, 0, 0, 0.647058823529412)">图片，每个影印件大小</span
                        ><span style="color: rgba(217, 0, 27, 0.996078431372549)"> 500KB 以内</span
                        ><span style="color: rgba(0, 0, 0, 0.647058823529412)">。图片在线压缩工具：</span>
                        <a href="https://www.bejson.com/ui/compress_img/" target="_blank" style="color: #449afe"
                            >https://www.bejson.com/ui/compress_img/</a
                        >
                    </p>
                    <p>
                        <span style="color: rgba(0, 0, 0, 0.647058823529412)">2、请提供</span
                        ><span style="color: rgba(217, 0, 27, 0.996078431372549)">原件彩色照片</span>
                    </p>
                    <p><span style="color: rgba(0, 0, 0, 0.647058823529412)">3、有效证件应在有效期内</span></p>
                    <p>
                        <span style="color: rgba(0, 0, 0, 0.647058823529412)"
                            >4、原件照片不得涂改（除标注证件用途外），需确保文字、 图片、证件号码清晰可辨</span
                        >
                    </p>
                    <p>
                        <span style="color: rgba(0, 0, 0, 0.647058823529412)"
                            >5、不可在影印件标记其他使用用途，如必须标记，可标记只用于中金支付开户使用</span
                        >
                    </p>
                </div>
            </Alert>
        </Card>

        <Form ref="formData" :model="formData" :rules="ruleValidate" :label-width="140">
            <Card title="请上传企业资质照片" :bordered="false" dis-hover style="margin-top: -18px">
                <FormItem label="工商营业执照：" required>
                    <div class="ivu-row-flex">
                        <div class="ivu-mr-16">
                            <ImagUpload
                                :maxlength="1"
                                bigSize
                                :upload-list="businessLicenseUrl"
                                :accept="accept"
                                :format="format"
                                :maxSize="500"
                            >
                                <div slot="content" class="upload-btn">
                                    <p class="upload-icons">
                                        <Icon type="md-add" size="26" color="#c3c3c3"></Icon>
                                    </p>
                                    <p class="upload-type">上传</p>
                                </div>
                            </ImagUpload>
                        </div>
                        <Col>
                            <demoImg
                                multiple
                                style="width: 300px"
                                :list="['https://zc-oss.zcrubber.com/upload/files/2021/10/10/yinye.1633849181347.png']"
                            />
                        </Col>
                    </div>
                </FormItem>

                <!-- 企业账号上传信息-->
                <FormItem label="基本户开户证明：" v-if="userType == 12" required>
                    <div class="ivu-row-flex">
                        <div class="ivu-mr-16">
                            <ImagUpload
                                :maxlength="1"
                                bigSize
                                :upload-list="openingPermitUrl"
                                :accept="accept"
                                :format="format"
                                :maxSize="500"
                            >
                                <div slot="content" class="upload-btn">
                                    <p class="upload-icons">
                                        <Icon type="md-add" size="26" color="#c3c3c3"></Icon>
                                    </p>
                                    <p class="upload-type">上传</p>
                                </div>
                            </ImagUpload>
                        </div>
                        <Col>
                            <demoImg
                                multiple
                                style="width: 400px"
                                :list="[
                                    'https://zc-oss.zcrubber.com/upload/files/2021/10/10/kaihu.1633849202577.png',
                                    'https://zc-oss.zcrubber.com/upload/files/2021/10/10/zhanghu.1633849223007.png',
                                ]"
                            />
                        </Col>
                    </div>
                </FormItem>
            </Card>

            <Card title="请上传法人身份证照片" :bordered="false" dis-hover style="margin-top: -18px">
                <FormItem label="上传身份证正面：" required>
                    <div class="ivu-row-flex">
                        <div class="ivu-mr-16">
                            <ImagUpload
                                :maxlength="1"
                                :upload-list="frontCardUrl"
                                :accept="accept"
                                :format="format"
                                :maxSize="500"
                            >
                                <div slot="content" class="upload-btn">
                                    <p class="upload-icons">
                                        <Icon type="md-add" size="26" color="#c3c3c3"></Icon>
                                    </p>
                                    <p class="upload-type">上传身份证正面</p>
                                </div>
                            </ImagUpload>
                        </div>
                        <Col>
                            <demoImg
                                multiple
                                style="width: 204px; height: 299px"
                                :list="['https://zc-oss.zcrubber.com/upload/files/2021/10/10/id.1633849161844.png']"
                            />
                        </Col>
                    </div>
                </FormItem>

                <FormItem label="上传身份证反面：" required>
                    <div class="ivu-row-flex">
                        <div class="ivu-mr-16">
                            <ImagUpload
                                :maxlength="1"
                                :upload-list="reverseSideCardUrl"
                                :accept="accept"
                                :format="format"
                                :maxSize="500"
                            >
                                <div slot="content" class="upload-btn">
                                    <p class="upload-icons">
                                        <Icon type="md-add" size="26" color="#c3c3c3"></Icon>
                                    </p>
                                    <p class="upload-type">上传身份证反面</p>
                                </div>
                            </ImagUpload>
                        </div>
                        <Col>
                            <demoImg
                                multiple
                                style="width: 204px; height: 299px"
                                :list="['https://zc-oss.zcrubber.com/upload/files/2021/10/10/id.1633849161844.png']"
                            />
                        </Col>
                    </div>
                </FormItem>
            </Card>

            <Card
                :title="userType == 12 ? '请填写您企业的法人信息' : '法人信息'"
                :bordered="false"
                dis-hover
                style="margin-top: -18px"
            >
                <FormItem label="法人姓名" prop="legalPersonName">
                    <Input maxlength="10" v-model="formData.legalPersonName" placeholder="请输入法人姓名"></Input>
                </FormItem>
                <FormItem label="法人身份证号" prop="credentialNumber">
                    <Input
                        maxlength="18"
                        v-model="formData.credentialNumber"
                        placeholder="请输入法人身份证号码"
                    ></Input>
                </FormItem>
            </Card>

            <Card
                :title="this.userType == 12 ? '请填写您的银行对公账户信息' : '法人银行账户信息'"
                :bordered="false"
                dis-hover
                style="margin-top: -18px"
            >
                <ElForm ref="formDataEl" label-width="140px" :model="formData" :rules="ruleValidate">
                    <FormItem label="账户名称" prop="bankAccountName" v-if="userType == 12">
                        <Input
                            maxlength="40"
                            v-model="formData.bankAccountName"
                            placeholder="对公账户名称与工商营业执照上的企业名称保持一致"
                        ></Input>
                    </FormItem>
                    <FormItem label="持卡人姓名" required v-else>
                        <Input
                            maxlength="40"
                            disabled
                            v-model="formData.legalPersonName"
                            placeholder="持卡人姓名与法人姓名保持一致"
                        ></Input>
                    </FormItem>
                    <FormItem label="银行账号" prop="bankAccountNumber">
                        <Input
                            maxlength="36"
                            v-model="formData.bankAccountNumber"
                            :placeholder="this.userType == 12 ? '请输入企业对公账号' : '请输入银行账号'"
                        ></Input>
                    </FormItem>
                    <ElFormItem label="开户银行" prop="bankId">
                        <ElSelect
                            v-model="formData.bankId"
                            filterable
                            label-in-value
                            filter-by-label
                            clearable
                            :loading="bankLoading"
                            style="width: 100%"
                            placeholder="请下拉选择或输入名称快速搜索"
                        >
                            <ElOption
                                :value="item.bankCode"
                                :label="item.bankName"
                                v-for="item in bankList"
                                :key="item.bankCode"
                            >
                                <span style="float: left">{{ item.bankName }}</span>
                            </ElOption>
                        </ElSelect>
                    </ElFormItem>

                    <FormItem label="开户支行" prop="branchName">
                        <Input maxlength="20" v-model="formData.branchName" placeholder="请输入开户支行名称"></Input>
                    </FormItem>

                    <ElFormItem label="开户行省份" prop="provinceCode" v-if="userType == 12">
                        <ElSelect
                            v-model="formData.provinceCode"
                            @change="getProvinceName"
                            filterable
                            label-in-value
                            filter-by-label
                            clearable
                            :loading="provinceLoading"
                            style="width: 100%"
                            placeholder="请选择开户行省份"
                        >
                            <ElOption
                                :value="item.code"
                                :label="item.province"
                                v-for="item in provinceList"
                                :key="item.code"
                            >
                                {{ item.province }}
                            </ElOption>
                        </ElSelect>
                    </ElFormItem>
                    <ElFormItem label="开户行城市" prop="cityCode" v-if="userType == 12">
                        <ElSelect
                            v-model="formData.cityCode"
                            @change="getCityName"
                            filterable
                            label-in-value
                            filter-by-label
                            clearable
                            :loading="cityLoading"
                            style="width: 100%"
                            placeholder="请选择开户行城市"
                        >
                            <ElOption
                                :value="item.value"
                                :label="item.label"
                                v-for="item in cityList"
                                :key="item.value"
                            >
                                {{ item.label }}
                            </ElOption>
                        </ElSelect>
                    </ElFormItem>

                    <FormItem label="银行预留手机号" prop="bankPhoneNumber" v-if="userType == 13">
                        <Input
                            maxlength="11"
                            type="tel"
                            v-model="formData.bankPhoneNumber"
                            placeholder="请输入银行预留手机号"
                        ></Input>
                    </FormItem>
                    <FormItem style="margin-bottom: 12px">
                        <Button type="primary" :loading="loading" size="large" @click="handleSubmit('formData')"
                            >提交</Button
                        >
                        <Button size="large" @click="handleReset('formData')" style="margin-left: 8px">返回</Button>
                    </FormItem>
                </ElForm>
            </Card>
        </Form>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Form, FormItem, Select, Option } from 'element-ui';
import ImagUpload from '@/components/ImageListUpload';
import demoImg from './components/demoImg';
import { commitImageBank, bankList, provinceList, cityList, accountBindBack } from '@/api/dealerAccount';
import { CARD_REG, MOBILE_REG, BANK_CARD, clearEmpty, transPathToName, jumpPage } from '@/libs/util';

export default {
    components: {
        ImagUpload,
        demoImg,
        ElForm: Form,
        ElFormItem: FormItem,
        ElSelect: Select,
        ElOption: Option,
    },
    data() {
        const validatePersonName = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请填写姓名'));
            } else {
                callback();
            }
        };

        const validateCredentialNumber = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入法人身份证号'));
            } else if (value.length < 18) {
                // 对第二个密码框单独验证
                callback(new Error('身份证号码不得少于18位，请核对信息'));
            } else if (!CARD_REG.test(value)) {
                // 对第二个密码框单独验证
                callback(new Error('请输入合法的身份证号码'));
            } else {
                callback();
            }
        };

        const validateAccountName = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请填写账户名称'));
            } else {
                callback();
            }
        };

        const validateBankCard = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入银行账号'));
            } else if (!BANK_CARD.test(value)) {
                callback(new Error('请填写正确的银行账号'));
            } else {
                callback();
            }
        };

        const validatePhoneNumber = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入手机号'));
            } else if (!MOBILE_REG.test(value)) {
                callback(new Error('请填写正确的手机号'));
            } else {
                callback();
            }
        };
        return {
            format: ['jpg', 'jpeg'],
            accept: '.jpg,.jpeg,image/jpg,image/jpeg',
            title: {
                12: '企业绑卡认证',
                13: '个体工商户绑卡认证',
            }[this.$route.query.userType || ''],
            currentStep: 0,
            bankList: [],
            bankLoading: false,
            provinceList: [],
            provinceLoading: true,
            cityList: [],
            cityLoading: false,
            userType: this.$route.query.userType || '', // 影印件账户类型【11=个人账户 12=企业账户 13 = 个体工商户】
            bankAccountType: this.$route.query.bankAccountType || '', // 银行账户类型【11=个人账户 12=企业账户】
            cpcnAccountId: this.$route.query.cpcnAccountId || '', // 账户id
            cardId: this.$route.query.cardId || '',
            userId: this.$route.query.userId || '',
            formData: {
                legalPersonName: '', // 法人姓名
                credentialNumber: '', // 法人身份证号
                bankAccountType: this.$route.query.bankAccountType || '', // 银行账户类型【11=个人账户 12=企业账户】
                userType: this.$route.query.userType || '', // 影印件账户类型【11=个人账户 12=企业账户 13 = 个体工商户】
                bankAccountName: '', // 持卡人姓名/企业账户名称
                bankAccountNumber: '', // 银行账号
                bankId: '', // 开户银行
                branchName: '', // 开户支行
                province: '', // 开户行省份
                provinceCode: '', // 开户行省份编码
                city: '', // 开户行城市
                cityCode: '', // 开户行城市编码
                // provinceCity: [], // 省市对应级联数据,
                cityProvince: '',
                cityProvinceCode: '',
                bankPhoneNumber: '',
            },
            businessLicenseUrl: [], // 营业执照
            openingPermitUrl: [], // 开户许可证
            frontCardUrl: [], // 身份证正面
            reverseSideCardUrl: [], // 身份证反面
            ruleValidate: {
                legalPersonName: [{ required: true, validator: validatePersonName, trigger: 'change' }],
                credentialNumber: [{ required: true, validator: validateCredentialNumber, trigger: 'change' }],
                bankAccountName: [{ required: true, validator: validateAccountName, trigger: 'change' }],
                bankAccountNumber: [{ required: true, validator: validateBankCard, trigger: 'change' }],
                bankId: [{ required: true, message: '请选择开户银行', trigger: 'change' }],
                branchName: [{ required: true, message: '请输入开户支行', trigger: 'change' }],
                provinceCode: [{ required: true, message: '请选择开户行省份', trigger: 'change' }],
                cityCode: [{ required: true, message: '请选择开户行城市', trigger: 'change' }],
                bankPhoneNumber: [{ required: true, validator: validatePhoneNumber, trigger: 'change' }],
            },
            loading: false,
        };
    },
    async created() {
        await this.getData();
    },
    methods: {
        ...mapActions('admin/page', ['close']),
        /**
         * 获取省市对应code及value
         */
        async getProvinceName(code) {
            if (code) {
                const list = this.provinceList;
                const length = list.length;
                for (let i = 0; i < length; i++) {
                    const item = list[i];
                    if (item.code == code) {
                        const name = item.province;
                        this.formData.province = name;
                        await this.getCityListByName(name, true);
                    }
                }
            }
        },
        // 通过省份查找市的列表
        async getCityListByName(name, clear) {
            this.cityLoading = true;
            const _cityList = await cityList({
                provinceName: name,
            });
            this.cityList = this.reduceCityList(_cityList[0].childCategoryList);
            this.formData.cityProvince = _cityList[0].gaodeAreaDTO.name;
            this.formData.cityProvinceCode = _cityList[0].gaodeAreaDTO.id;
            // 需要情况市选择的数据
            if (clear) {
                this.formData.city = '';
                this.formData.cityCode = '';
            }
            this.cityLoading = false;
        },
        /**
         * 获取省市对应code及value
         */
        getCityName(code) {
            // if (selectData.length > 0) {
            //     this.formData.cityProvince = selectData[0].label;
            //     this.formData.cityProvinceCode = selectData[0].value;
            //     this.formData.city = selectData[1].label;
            //     this.formData.cityCode = selectData[1].value;
            // }
            if (code) {
                const list = this.cityList;
                const length = list.length;
                for (let i = 0; i < length; i++) {
                    const item = list[i];
                    if (item.value == code) {
                        const name = item.label;
                        this.formData.city = name;
                    }
                }
            }
        },
        /**
         * 获取基础数据
         */
        async getData() {
            try {
                this.loading = true;
                const _bankList = await bankList();
                this.bankList =
                    _bankList.map((item) => ({
                        bankCode: item.bankCode,
                        bankName: item.bankName,
                        bankQuota: item.bankQuota,
                    })) || [];
                this.bankLoading = false;

                const _provinceList = await provinceList();
                this.provinceList = _provinceList || [];
                this.provinceLoading = false;
                this.loading = false;

                const cpcnAccountId = this.$route.query.cpcnAccountId;
                if (cpcnAccountId) {
                    this.loading = true;
                    const formData = await accountBindBack({
                        cpcnAccountId,
                    });
                    this.loading = false;
                    this.setFormData(formData || {});
                }
            } catch (e) {
                this.bankLoading = false;
                this.cityLoading = false;
                this.loading = false;
            }
        },

        /**
         * 反填formData
         **/
        setFormData(formData) {
            const {
                legalPersonName: legalPersonName, // 法人姓名
                legalPersonNumber: credentialNumber, // 法人身份证号
                bankAccountName: bankAccountName, // 持卡人姓名/企业账户名称
                bankCardNumber: bankAccountNumber, // 银行账号
                bankId, // 开户银行
                bankDetailName: branchName, // 开户支行
                businessLicenseUrl = '',
                openingPermitUrl = '',
                frontCardUrl = '',
                reverseSideCardUrl = '',
                preUserId: userId = '',
                bankProvince: province, // 开户行省份
                bankProvinceCode: provinceCode = '', // 开户行省份编码
                bankCity: city, // 开户行城市
                bankCityCode: cityCode = '', // 开户行城市编码
                bankProvince: cityProvince = '', // 开户行城市对应省份
                bankCityProvinceCode: cityProvinceCode = '', // 开户行城市对应省份Code
                bankPhone: bankPhoneNumber = '',
            } = formData;

            // const provinceCity = [cityProvinceCode || '', cityCode || '']; // 省市对应级联数据
            const userType = this.userType; // 影印件账户类型【11=个人账户 12=企业账户 13 = 个体工商户】

            // 营业执照
            this.businessLicenseUrl = [
                {
                    url: businessLicenseUrl,
                },
            ];
            // 开户许可证
            this.openingPermitUrl = [
                {
                    url: openingPermitUrl,
                },
            ];
            // 身份证反面
            this.frontCardUrl = [
                {
                    url: frontCardUrl,
                },
            ];
            // 身份证反面
            this.reverseSideCardUrl = [
                {
                    url: reverseSideCardUrl,
                },
            ];
            this.formData = {
                legalPersonName, // 法人姓名
                credentialNumber: credentialNumber || '', // 法人身份证号
                bankAccountType: userType == 12 ? 12 : 11, // 银行账户类型【11=个人账户 12=企业账户】
                userType, // 影印件账户类型【11=个人账户 12=企业账户 13 = 个体工商户】
                bankAccountName, // 持卡人姓名/企业账户名称
                bankAccountNumber, // 银行账号
                bankId, // 开户银行
                branchName, // 开户支行
                province, // 开户行省份
                provinceCode, // 开户行省份编码
                city, // 开户行城市
                cityCode, // 开户行城市编码
                cityProvince, // 省市对应级联数据
                cityProvinceCode,
                userId,
                bankPhoneNumber,
            };
            // 企业账号获取省份对应市的列表
            if (userType == 12) {
                this.getCityListByName(province, false); // 开户行省份
            }
            // if (cityProvinceCode && cityCode) {
            //     this.formData.provinceCity = provinceCity;
            // }
            console.log('反填表单信息', this.formData);
        },
        /**
         * 重组省市数据，用于级联选择
         * @param arr
         * @return {*}
         */
        reduceCityList(arr) {
            for (let i = 0; i < arr.length; i++) {
                const item = arr[i];
                item['label'] = item.gaodeAreaDTO['name'];
                item['value'] = item.gaodeAreaDTO['id'];
                if (item.childCategoryList && item.gaodeAreaDTO.level !== 'city') {
                    item.children = this.reduceCityList(item.childCategoryList);
                    delete item.childCategoryList;
                    delete item.gaodeAreaDTO;
                } else {
                    delete item.childCategoryList;
                    delete item.gaodeAreaDTO;
                }
            }
            return arr;
        },
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/assets/dealerAccount/typeChose' });
        },
        /**
         * 提交
         * @param name
         * @return {boolean}
         */
        handleSubmit(name) {
            console.log(this.formData);
            const userType = this.userType;
            let {
                businessLicenseUrl = [], // 营业执照
                openingPermitUrl = [], // 开户许可证
                frontCardUrl = [], // 身份证正面
                reverseSideCardUrl = [], // 身份证反面
            } = this;
            if (businessLicenseUrl.length <= 0) {
                this.$Modal.warning({
                    title: '提示',
                    content: '请上传工商营业执照',
                });
                return false;
            }
            // 企业账号需要基本开户证明
            if (userType == 12 && openingPermitUrl.length <= 0) {
                this.$Modal.warning({
                    title: '提示',
                    content: '请上传开户证明',
                });
                return false;
            }
            if (frontCardUrl.length <= 0) {
                this.$Modal.warning({
                    title: '提示',
                    content: '请上传身份证正面',
                });
                return false;
            }
            if (reverseSideCardUrl.length <= 0) {
                this.$Modal.warning({
                    title: '提示',
                    content: '请上传身份证反面',
                });
                return false;
            }

            businessLicenseUrl = businessLicenseUrl.map((item) => item.url).join(','); // 营业执照
            openingPermitUrl = openingPermitUrl.map((item) => item.url).join(','); // 开户许可证
            frontCardUrl = frontCardUrl.map((item) => item.url).join(','); // 身份证正面
            reverseSideCardUrl = reverseSideCardUrl.map((item) => item.url).join(','); // 身份证反面

            this.$refs[name].validate((valid) => {
                this.$refs['formDataEl'].validate((validEl) => {
                    if (valid && validEl) {
                        const params = {
                            ...this.formData,
                            businessLicenseUrl,
                            frontCardUrl,
                            reverseSideCardUrl,
                        };
                        // 企业账号需要基本开户证明
                        if (userType == 12) {
                            params.openingPermitUrl = openingPermitUrl;
                            // 个人需要预留手机号
                            delete params.bankPhoneNumber;
                        } else {
                            // 个人账号持卡人和法人姓名一致
                            params.bankAccountName = params.legalPersonName;
                        }
                        this.$Modal.confirm({
                            title: '确认提交',
                            content: '提交后不可修改，请确认信息准确无误',
                            onOk: () => {
                                this.loading = true;
                                this.$Message.loading({
                                    content: '提交中...',
                                    duration: 0,
                                });
                                commitImageBank(
                                    clearEmpty({
                                        ...params,
                                    }),
                                ).then(
                                    (res) => {
                                        this.loading = false;
                                        this.$Message.destroy();
                                        this.$Message.success({
                                            duration: 3,
                                            content: this.userType == 12 ? '提交成功!' : '验证码已发送，请注意查收~',
                                        });
                                        window.$basePageStore.closeTab(transPathToName(location.pathname));
                                        jumpPage({
                                            path: `/sub-vue2/assets/dealerAccount/tiedCardApprove`,
                                            params: {
                                                cpcnAccountId: (res && res.cpcnAccountId) || '',
                                                cardId: (res && res.cardId) || '',
                                                userId: (res && res.userId) || '',
                                                bankAccountType: this.bankAccountType,
                                                userType: this.userType,
                                            },
                                        });
                                    },
                                    () => {
                                        this.$Message.destroy();
                                        this.loading = false;
                                    },
                                );
                            },
                        });
                    } else {
                        this.$Message.error('请完善表单内容!');
                    }
                });
            });
        },
        handleReset(name) {
            this.$Modal.confirm({
                title: '提示',
                content: '确认返回吗，数据将不会保留！',
                onOk: () => {
                    this.businessLicenseUrl = []; // 营业执照
                    this.openingPermitUrl = []; // 开户许可证
                    this.frontCardUrl = []; // 身份证正面
                    this.reverseSideCardUrl = []; // 身份证反面
                    this.$refs[name].resetFields();
                    this.jumpPrevPage();
                },
            });
        },
    },
};
</script>

<style lang="less">
@import '../../../styles/variable.less';

.account-edit {
    .upload-tips {
        font-size: 14px;
        line-height: 26px;
    }

    .upload-btn {
        width: 100%;
        height: 100%;
        .center();
        .flexCenter();
        flex-wrap: wrap;

        .upload-icons {
            width: 100%;
            margin-top: 90px;
        }

        .upload-type {
            width: 100%;
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 95px;
        }
    }

    .demo-img {
        width: 300px;
        height: 200px;
        margin-top: 10px;
    }
}
</style>
