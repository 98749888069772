<template>
    <div class="account-approve" v-loading="loading">
        <PageHeader :title="title" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage">
                <Icon type="md-arrow-back" />
            </div>
        </PageHeader>

        <Card title="" class="ivu-mt" :dis-hover="true" shadow>
            <Row class="ivu-mb-16">
                <Col push="6" span="12">
                    <Steps :current="currentStep">
                        <Step title="填写信息" content=""></Step>
                        <Step title="提交认证" content=""></Step>
                    </Steps>
                </Col>
            </Row>
            <Alert closable type="warning">
                <div class="upload-tips" v-show="userType == 13">
                    您已于「{{
                        detail.submitTime
                    }}」成功提交信息，请于10分钟内填写验证码，验证码仅可使用1次，填写错误或失效请重新获取
                </div>
                <div class="upload-tips" v-show="userType == 12">
                    您已于「{{ detail.submitTime }}」成功提交信息，请于3天内完成认证，超时请重新认证
                </div>
            </Alert>
        </Card>

        <Form ref="formData" :model="formData" :rules="ruleValidate" :label-width="140">
            <Card title="请输入短信验证码" :bordered="false" dis-hover style="margin-top: -18px">
                <div class="ivu-mb-16" v-show="userType == 13">
                    <p class="ivu-mb-8">
                        <span class="">1、已向您的银行预留手机号「{{ detail.bankPhoneNumber }}」发送短信验证码。</span>
                    </p>
                    <p class="ivu-mb-8"><span class="">2、请在下方输入并提交短信验证码完成认证。</span></p>
                    <p class="ivu-mb-8"><span class="">3、验证码10分钟内有效，超过10分钟请重新认证。</span></p>
                </div>
                <div class="ivu-mb-16" v-show="userType == 12">
                    <p class="ivu-mb-8">
                        <span class="" style="color: #f59a23f8"
                            >1、已向您的对公账户(银行账号「{{
                                detail.bankAccountNumber
                            }}」)转入随机金额（0.01元~0.99元），汇款账户名称：中金支付有限公司，预计2小时内到账。</span
                        >
                    </p>
                    <p class="ivu-mb-8">
                        <span class="">2、请通过网上银行、手机银行、银行柜台等方式查询转账金额。</span>
                    </p>
                    <p class="ivu-mb-8"><span class="">3、请在下方输入并提交转账金额完成认证。</span></p>
                    <p class="ivu-mb-8"><span class="">4、转账金额3天内有效，超过3天请重新认证。</span></p>
                    <p class="ivu-mb-8"><span class="">5、如果您已认证失败3次，请重新认证。</span></p>
                </div>
                <FormItem label="转账金额（元）：" prop="amount" v-if="userType == 12">
                    <InputNumber
                        style="width: 160px"
                        :max="0.99"
                        :step="0.01"
                        maxlength="4"
                        v-model="formData.amount"
                        placeholder="请输入您收到的金额"
                        @keydown="handleKeyDown"
                        @keypress="handleKeyDown"
                    ></InputNumber>
                </FormItem>
                <FormItem label="短信验证码：" prop="smsCode" v-if="userType != 12">
                    <Input
                        clearable
                        style="width: 200px"
                        type="tel"
                        maxlength="6"
                        v-model="formData.smsCode"
                        placeholder="请输入您收到的短信验证码"
                    ></Input>
                </FormItem>
                <FormItem label="" style="display: none">
                    <Input style="display: none" />
                </FormItem>
                <FormItem style="margin-bottom: 12px">
                    <!--个人才可以修改信息-->
                    <Button v-show="userType == 13" size="large" @click="handleReset('formData')">修改信息</Button>
                    <Button
                        type="primary"
                        :loading="loading"
                        style="margin-left: 8px"
                        size="large"
                        @click="handleSubmit('formData')"
                        v-if="userType == 12"
                        >提交认证</Button
                    >
                    <Button
                        type="primary"
                        :loading="loading"
                        style="margin-left: 8px"
                        size="large"
                        @click="handleSubmit('formData')"
                        v-if="userType != 12 && !codeTimeOut"
                        >提交认证</Button
                    >
                    <Button
                        type="primary"
                        :loading="sendIng"
                        style="margin-left: 8px"
                        size="large"
                        @click="handleSendSms"
                        v-if="userType != 12 && codeTimeOut"
                        >{{ sendBtnTxt }}</Button
                    >
                </FormItem>
            </Card>
        </Form>
    </div>
</template>

<script>
import {
    submitToBindingAuth,
    checkBindingCardStatus,
    accountBindBack,
    commitImageBank,
    getBankRecordById,
    addBankCard,
    getFirstCardRecord,
} from '@/api/dealerAccount';
import { CODE_REG, clearEmpty, transPathToName, jumpPage } from '@/libs/util';

export default {
    data() {
        const validateCode = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入验证码'));
            } else if (!CODE_REG.test(value)) {
                // 验证码校验
                callback(new Error('请输入6位数字验证码'));
            } else {
                callback();
            }
        };

        return {
            title: this.$route.query.type
                ? '添加银行卡'
                : {
                      12: '企业绑卡认证',
                      13: '个体工商户绑卡认证',
                  }[this.$route.query.userType || ''],
            currentStep: 1,
            userType: this.$route.query.userType || '', // 影印件账户类型【11=个人账户 12=企业账户 13 = 个体工商户】
            bankAccountType: this.$route.query.bankAccountType || '', // 银行账户类型【11=个人账户 12=企业账户】
            cpcnAccountId: this.$route.query.cpcnAccountId || '', // 账户id
            cardId: this.$route.query.cardId || '',
            userId: this.$route.query.userId || '',
            type: this.$route.query.type || '', // 认证类型，默认为绑卡，bank：银行卡认证
            formData: {
                amount: null,
                bindingWay: '',
                cardId: this.$route.query.cardId || '',
                firstCardStatus: !(this.$route.query.firstCardStatus === 'false'), // 第一次提交
                smsCode: '',
            },
            ruleValidate: {
                amount: [{ required: true, message: '请输入您收到的金额' }],
                smsCode: [{ required: true, validator: validateCode, trigger: 'change' }],
            },
            loading: false,
            detail: {},
            codeTimeOut: false, // 验证码是否超时，需要重新发送。
            sendCount: 60, // 60秒倒计时
            sendDown: false, // 是否开始倒计时
            sendIng: false, // 是否这个你在发送
            sendBtnTxt: '获取验证码', // 发送按钮文案
            countInterval: null, // 倒计时 定时器
            imageFormData: {}, // 影印件反填数据
            outTime: 0,
            inputForm: {}, // 银行卡反填数据
        };
    },
    watch: {
        sendDown: function (next) {
            if (next) {
                this.countDown();
            } else {
                this.sendBtnTxt = '获取验证码';
            }
        },
        sendIng: function (next) {
            if (next) {
                this.sendBtnTxt = '发送中...';
            } else {
                this.sendBtnTxt = '获取验证码';
            }
        },
    },
    mounted() {
        this.getData();
    },
    destroyed() {
        // 清空定时器
        this.countInterval && clearInterval(this.countInterval);
        this.countInterval = null;
    },
    methods: {
        /**
         * 验证码倒计时
         */
        startOutTimer() {
            this.countInterval = setInterval(() => {
                const now = Date.now();
                // 倒计时结束，10分钟到了
                if (this.outTime < now) {
                    // 重新发送
                    this.codeTimeOut = true;
                    // 清空定时器
                    this.countInterval && clearInterval(this.countInterval);
                    this.countInterval = null;
                }
            }, 1000);
        },
        /**
         * 发送验证码
         */
        handleSendSms() {
            let params = clearEmpty({
                ...this.imageFormData,
            });
            this.loading = true;
            this.sendIng = true;
            this.$Message.loading('提交中...');
            let request = commitImageBank;
            if (this.type) {
                request = addBankCard;
                params = {
                    ...this.inputForm,
                    userId: this.userId,
                    cardId: this.cardId,
                };
            }
            request(params).then(
                (res) => {
                    let { cardId = '', cpcnAccountId = '', userId = '' } = res;
                    if (this.type) {
                        // 添加银行卡
                        cardId = res;
                        this.formData.smsCode = '';
                        this.formData.cardId = cardId;
                        this.cardId = cardId;
                    } else {
                        this.formData.smsCode = '';
                        this.formData.cardId = cardId;
                        this.formData.cpcnAccountId = cpcnAccountId || this.cpcnAccountId;
                        this.formData.userId = userId;

                        this.cardId = cardId;
                        this.cpcnAccountId = cpcnAccountId || this.cpcnAccountId;
                        this.userId = userId;
                    }

                    this.codeTimeOut = false;
                    this.loading = false;
                    this.sendIng = false;
                    this.$Message.destroy();
                    this.$Message.success({
                        duration: 3,
                        content: this.userType == 12 ? '提交成功!' : '验证码已发送，请注意查收~',
                    });
                    this.loading = true;
                    checkBindingCardStatus(cardId).then(
                        (res) => {
                            this.loading = false;
                            if (res) {
                                this.detail = res || {};
                                if (res.submitTime) {
                                    const outTime =
                                        new Date(res.submitTime.replace('-', '/')).getTime() + 10 * 60 * 1000; // 十分钟后改变状态
                                    this.outTime = outTime; // 超时时间戳
                                    this.startOutTimer(); // 超时倒计时
                                }
                            }
                        },
                        () => {
                            this.loading = false;
                        },
                    );
                },
                () => {
                    this.$Message.destroy();
                    this.loading = false;
                    this.sendIng = false;
                },
            );
        },

        /**
         * 获取银行卡信息
         **/
        getCardData() {
            if (this.cardId) {
                this.loading = true;
                getBankRecordById(this.cardId).then(
                    (res) => {
                        this.userType = {
                            11: '13',
                            12: '12',
                        }[res.bankAccountType];
                        this.userId = res.userId || '';
                        this.inputForm = {
                            bankAccountName: res.bankAccountName || '', // 持卡人姓名/企业账户名称
                            bankAccountNumber: res.bankAccountNumber || '', // 银行账号
                            bankId: res.bankId || '', // 开户银行
                            branchName: res.branchName || '', // 开户支行
                            province: res.province || '', // 开户行省份
                            city: res.city || '', // 开户行城市
                            cityProvince: res.cityProvince || '', // 开户行城市级联省份
                            provinceCode: res.provinceCode || '', // 开户行省份编码
                            cityCode: res.cityCode || '', // 开户行城市编码
                            cityProvinceCode: res.cityProvinceCode || '', // 开户行城市级联省份编码
                            bankPhoneNumber: res.bankPhoneNumber || '', // 银行预留手机号
                        };
                        this.loading = false;
                    },
                    () => {
                        this.loading = false;
                    },
                );
            }
        },
        async getImageData() {
            const cpcnAccountId = this.$route.query.cpcnAccountId;
            // 个体账号，需要获取详情数据，重新提交用
            if (cpcnAccountId && this.userType != 12) {
                this.loading = true;
                const formData = await accountBindBack({
                    cpcnAccountId,
                });
                this.loading = false;
                this.setFormData(formData || {});
            }
        },
        /**
         * 查询状态信息
         */
        async getData() {
            // 查询状态信息
            if (this.cardId) {
                this.loading = true;
                checkBindingCardStatus(this.cardId).then(
                    async (res) => {
                        // 转账金额认证失败超过三次，直接跳转到结果页面
                        // 绑卡的状态
                        // 1000：正常，
                        // 1001：企业绑卡验证次数超过3次，
                        // 1002：企业绑卡发起时间超过3天，
                        // 1003：个人绑卡验证码超过10分钟，
                        // 1004：个人绑卡验证码错误，
                        // 1005：企业转账金额错误，
                        // 1006：处理中，请稍后查看，
                        // 1007：请等待支付平台打款，请稍后查看
                        if (res) {
                            const { cardStatus } = res;
                            console.log(11, cardStatus);
                            // 1001：企业绑卡验证次数超过3次，
                            // 1002：企业绑卡发起时间超过3天，
                            if ([1001, 1002].includes(cardStatus)) {
                                this.loading = false;
                                // 跳转到结果页
                                window.$basePageStore.closeTab(transPathToName(location.pathname));
                                jumpPage({
                                    path: '/sub-vue2/assets/dealerAccount/tiedCardResult',
                                    params: {
                                        cpcnAccountId: this.cpcnAccountId,
                                        bankAccountType: this.bankAccountType,
                                        userType: this.userType,
                                        userId: this.userId,
                                        type: this.type,
                                        cardStatus,
                                        cardId: this.cardId,
                                    },
                                });
                            } else {
                                if ([1003, 1004].includes(cardStatus)) {
                                    this.$Message.warning({
                                        duration: 3,
                                        content: {
                                            1003: '验证码超过10分钟，请重新获取',
                                            1004: '验证码错误，请重新获取',
                                        }[cardStatus],
                                    });
                                    // 1003：个人绑卡验证码超过10分钟，
                                    // 1004：个人绑卡验证码错误，
                                    this.codeTimeOut = true;
                                }
                                this.loading = false;
                                this.detail = res || {};
                                // 个人十分钟后，重新获取
                                if (res.submitTime && this.userType != 12) {
                                    const outTime =
                                        new Date(res.submitTime.replace('-', '/')).getTime() + 10 * 60 * 1000; // 十分钟后改变状态
                                    this.outTime = outTime; // 超时时间戳
                                    this.startOutTimer(); // 超时倒计时
                                }
                                if (this.type) {
                                    // 判断是添加银行卡还是绑卡认证
                                    this.getCardData();
                                } else {
                                    // 判断是添加银行卡还是绑卡认证
                                    this.getImageData();
                                }
                            }
                        }
                    },
                    () => {
                        this.loading = false;
                    },
                );
            }
        },
        /**
         * 反填formData
         **/
        setFormData(imageFormData) {
            const {
                legalPersonName: legalPersonName, // 法人姓名
                legalPersonNumber: credentialNumber, // 法人身份证号
                bankAccountName: bankAccountName, // 持卡人姓名/企业账户名称
                bankCardNumber: bankAccountNumber, // 银行账号
                bankId, // 开户银行
                bankDetailName: branchName, // 开户支行
                businessLicenseUrl = '',
                openingPermitUrl = '',
                frontCardUrl = '',
                reverseSideCardUrl = '',
                preUserId: userId = '',
                bankProvince: province, // 开户行省份
                bankProvinceCode: provinceCode = '', // 开户行省份编码
                bankCity: city, // 开户行城市
                bankCityCode: cityCode = '', // 开户行城市编码
                bankProvince: cityProvince = '', // 开户行城市对应省份
                bankCityProvinceCode: cityProvinceCode = '', // 开户行城市对应省份Code
                bankPhone: bankPhoneNumber = '',
            } = imageFormData;

            // const provinceCity = [cityProvinceCode || '', cityCode || '']; // 省市对应级联数据
            const userType = this.userType; // 影印件账户类型【11=个人账户 12=企业账户 13 = 个体工商户】

            this.imageFormData = {
                businessLicenseUrl,
                openingPermitUrl,
                frontCardUrl,
                reverseSideCardUrl,
                legalPersonName, // 法人姓名
                credentialNumber: credentialNumber || '', // 法人身份证号
                bankAccountType: userType == 12 ? 12 : 11, // 银行账户类型【11=个人账户 12=企业账户】
                userType, // 影印件账户类型【11=个人账户 12=企业账户 13 = 个体工商户】
                bankAccountName, // 持卡人姓名/企业账户名称
                bankAccountNumber, // 银行账号
                bankId, // 开户银行
                branchName, // 开户支行
                province, // 开户行省份
                provinceCode, // 开户行省份编码
                city, // 开户行城市
                cityCode, // 开户行城市编码
                cityProvince, // 省市对应级联数据
                cityProvinceCode,
                userId,
                bankPhoneNumber,
            };
        },
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
        },
        // 提交认证
        handleSubmit(name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    const params = {
                        ...this.formData,
                    };
                    // 企业校验转账信息
                    if (this.userType == 12 && !/^[0-9]+([.][0-9]{0,2})?$/.test(params.amount)) {
                        this.$Modal.warning({
                            title: '提示',
                            content: '请输入合法的金额，0.01元~0.99元',
                        });
                        return false;
                    }
                    if (this.userType == 12) {
                        // 企业
                        params.bindingWay = 20; // 认证方式【10：短信认证，20：小额打款】
                    } else {
                        // 个体
                        params.bindingWay = 10; // 认证方式【10：短信认证，20：小额打款】
                    }

                    this.loading = true;
                    this.$Message.loading({
                        content: '提交中...',
                        duration: 0,
                    });

                    getFirstCardRecord(this.userId)
                        .then((res) => {
                            const { cardId } = res;
                            params.firstCardStatus = cardId == this.cardId;
                            return submitToBindingAuth(
                                clearEmpty({
                                    ...params,
                                }),
                                cardId,
                            );
                        })
                        .then(
                            (res) => {
                                // 绑卡的状态
                                // 1000：正常，
                                // 1001：企业绑卡验证次数超过3次，
                                // 1002：企业绑卡发起时间超过3天，
                                // 1003：个人绑卡验证码超过10分钟，
                                // 1004：个人绑卡验证码错误，
                                // 1005：企业转账金额错误，
                                // 1006：处理中，请稍后查看，
                                // 1007：请等待支付平台打款，请稍后查看
                                if (!res) {
                                    this.$Modal.info({
                                        title: '提示',
                                        content: '认证失败，请重试',
                                    });
                                    return false;
                                }
                                const cardStatus = res.cardStatus || '';
                                this.loading = false;
                                this.$Message.destroy();
                                // 1000为认证成功...
                                cardStatus == 1000 && this.$Message.success('认证成功!');
                                if (cardStatus === 1005) {
                                    this.$Modal.info({
                                        title: '提示',
                                        content: '企业转账金额输入错误',
                                    });
                                } else if (cardStatus === 1006) {
                                    this.$Modal.info({
                                        title: '提示',
                                        content: '处理中，请稍后查看',
                                    });
                                } else if (cardStatus === 1007) {
                                    this.$Modal.info({
                                        title: '提示',
                                        content: '打款处理中，请在到账后输入金额',
                                    });
                                } else if (cardStatus === 1003) {
                                    this.codeTimeOut = true;
                                    this.$Modal.info({
                                        title: '提示',
                                        content: '验证码超过10分钟，请重新获取',
                                    });
                                } else if (cardStatus === 1004) {
                                    this.codeTimeOut = true;
                                    this.$Modal.info({
                                        title: '提示',
                                        content: '验证码错误，请重新获取',
                                    });
                                }
                                // 金额错误可以继续输入，三次之后跳转到结果页，重新认证
                                else {
                                    let pageType = 'tiedCardResult';
                                    if (this.type && cardStatus == 1000) {
                                        // 银行卡认证成功，去addBankCardResult结果页
                                        pageType = 'addBankCardResult';
                                    }
                                    // 跳转到结果页
                                    window.$basePageStore.closeTab(transPathToName(location.pathname));
                                    jumpPage({
                                        path: `/sub-vue2/assets/dealerAccount/${pageType}`,
                                        params: {
                                            cpcnAccountId: this.cpcnAccountId,
                                            bankAccountType: this.bankAccountType,
                                            userType: this.userType,
                                            userId: this.userId,
                                            type: this.type,
                                            cardStatus: res.cardStatus,
                                            cardId: this.cardId,
                                        },
                                    });
                                }
                            },
                            () => {
                                this.$Message.destroy();
                                this.loading = false;
                            },
                        );
                } else {
                    this.$Message.error('请完善表单内容!');
                }
            });
        },
        handleKeyDown(e) {
            console.log(e);
        },
        handleReset() {
            console.log('handleReset');
            // 跳转到结果页
            if (this.type) {
                // 跳转到添加银行卡
                window.$basePageStore.closeTab(transPathToName(location.pathname));
                jumpPage({
                    path: `/sub-vue2/assets/dealerAccount/addBankCard`,
                    params: {
                        cpcnAccountId: this.cpcnAccountId,
                        bankAccountType: this.bankAccountType,
                        userType: this.userType,
                        cardId: this.cardId,
                        userId: this.userId,
                    },
                });
            } else {
                // 影印件填写信息
                window.$basePageStore.closeTab(transPathToName(location.pathname));
                jumpPage({
                    path: `/sub-vue2/assets/dealerAccount/tiedCardForm`,
                    params: {
                        cpcnAccountId: this.cpcnAccountId,
                        bankAccountType: this.bankAccountType,
                        userType: this.userType,
                        userId: this.userId,
                    },
                });
            }
        },
    },
};
</script>

<style lang="less">
.account-approve {
    .upload-tips {
        margin: 10px 0;
    }
}
</style>
