<template>
    <div class="store-sign-list">
        <PageHeader title="门店签署管理" hidden-breadcrumb> </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" @on-submit="onSubmit" ref="table-form" />
            <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list" />
        </Card>
    </div>
</template>

<script>
import moment from 'moment';
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { renderButton, clearEmpty, jumpPage } from '@/libs/util';
import { listEsignStore } from '@/api/esign';

export default {
    name: 'customer-version-list',
    components: {
        tableForm,
        tablePage,
    },
    data() {
        return {
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    {
                        title: '合同名称',
                        key: 'contractName',
                        align: 'center',
                        minWidth: 120,
                        fixed: 'left',
                    },
                    {
                        title: '门店名称',
                        key: 'storeName',
                        align: 'center',
                        minWidth: 110,
                    },
                    {
                        title: '签署人',
                        key: 'storeSignerName',
                        align: 'center',
                        minWidth: 80,
                    },
                    {
                        title: '签约条数',
                        key: 'monthFinishValue',
                        align: 'center',
                        minWidth: 80,
                    },
                    {
                        title: '签署人联系方式',
                        key: 'storeSignerContact',
                        align: 'center',
                        minWidth: 135,
                    },
                    {
                        title: '签署人身份证号码',
                        key: 'storeSignerCard',
                        align: 'center',
                        minWidth: 180,
                    },
                    {
                        title: '签署状态',
                        key: 'flowStatus',
                        align: 'center',
                        minWidth: 250,
                        width: 250,
                        render: (h, p) => {
                            return h(
                                'div',
                                {
                                    class:
                                        {
                                            1: 'processing',
                                            2: 'success',
                                            3: 'warning',
                                            4: 'error',
                                            5: 'error',
                                            6: 'processing',
                                            7: 'error',
                                            8: 'processing',
                                            9: 'processing',
                                            10: 'warning',
                                        }[p.row.businessStatus] + ' sign-status',
                                },
                                {
                                    1: '签署中\n(待门店签署)',
                                    2: '签署完成',
                                    3: '合同失效',
                                    4: '签署失败',
                                    5: '合同作废',
                                    6: '升级中\n(待门店签署)',
                                    7: '升级失败',
                                    8: '签署中\n(待经销商签署)',
                                    9: '升级中\n(待经销商签署)',
                                    10: '待审核',
                                }[p.row.businessStatus],
                            );
                        },
                    },
                    {
                        title: '操作',
                        key: 'oprate',
                        align: 'center',
                        width: 250,
                        fixed: 'right',
                        render: (h, p) => {
                            return [
                                renderButton(
                                    h,
                                    {
                                        text: '详情',
                                        type: 'primary',
                                        disabled: [1, 3, 4, 6, 7, 8, 9, 10].indexOf(p.row.businessStatus) > -1,
                                    },
                                    () => {
                                        this.check(p.row);
                                    },
                                ),
                            ];
                        },
                    },
                ],
                data: [],
            },
            formList: [
                {
                    title: '合同名称',
                    type: 'input',
                    key: 'contractName',
                },
                {
                    title: '门店名称',
                    type: 'input',
                    key: 'storeName',
                },
                {
                    title: '签署人',
                    type: 'input',
                    key: 'storeSignerName',
                },
                {
                    title: '联系方式',
                    type: 'input',
                    key: 'storeSignerContact',
                },
                {
                    title: '签署状态',
                    type: 'select',
                    key: 'businessStatus',
                    options: [
                        {
                            name: '签署中',
                            id: 1,
                        },
                        {
                            name: '已签署',
                            id: 2,
                        },
                        {
                            name: '合同失效',
                            id: 3,
                        },
                        {
                            name: '签署失败',
                            id: 4,
                        },
                        {
                            name: '合同作废',
                            id: 5,
                        },
                        {
                            name: '升级中',
                            id: 6,
                        },
                        {
                            name: '升级失败',
                            id: 7,
                        },
                        {
                            name: '待审核',
                            id: 8,
                        },
                    ],
                },
                {
                    title: '年份',
                    type: 'year',
                    key: 'year',
                },
            ],
        };
    },
    activated() {
        let form = this.$refs['table-form'].getFormData();
        this.getData(form);
    },
    mounted() {
        this.$refs['table-form'].setFormData('year', new Date('2024')); // 默认2024
    },
    methods: {
        getData(p) {
            this.list.loading = true;
            p = clearEmpty(p);
            if (p['dept']) {
                p.deptId = p.dept[0];
                p.regionId = p.dept[1];
                delete p['dept'];
            }
            if (!p.year) {
                this.$refs['table-form'].setFormData('year', new Date('2024')); // 默认2024
                p.year = 2024; // 默认2024
            } else {
                p.year = moment(p.year).format('YYYY');
            }
            let params = {
                current: this.list.current,
                size: this.list.size,
                ...p,
            };
            listEsignStore(params)
                .then((res) => {
                    let updateType = {
                        0: '-',
                        1: '强制更新',
                        2: '非强制提示更新',
                        3: '非强制不提示更新',
                    };
                    this.list.total = Number(res.total);
                    this.list.data = res.records.map((item) => {
                        return {
                            ...item,
                            updateType: updateType[item.updateType],
                        };
                    });
                    this.isChangePage = false;
                    this.list.loading = false;
                })
                .catch(() => {
                    this.isChangePage = false;
                    this.list.loading = false;
                });
        },
        onSubmit(params) {
            this.list.current = 1;
            this.getData(params);
        },
        onPageChange(val) {
            if (this.isChangePage) return;
            this.list.current = val;
            let form = this.$refs['table-form'].getFormData();
            this.getData(form);
        },
        onPageSizeChange(val) {
            this.list.current = 1;
            this.isChangePage = true;
            this.list.size = val;
            let form = this.$refs['table-form'].getFormData();
            this.getData(form);
        },
        check(row) {
            const data = this.$refs['table-form'].getFormData();
            const year = moment(data.year).format('YYYY');
            jumpPage({
                path: '/sub-vue2/customer/storeSign/check',
                params: {
                    id: row.id,
                    mode: 2,
                    year,
                },
            });
        },
    },
};
</script>

<style lang="less">
.store-sign-list {
    .ivu-badge-status-text {
        word-break: break-word;
        white-space: pre-line;
        color: unset;
    }
}
</style>
