/**
 *@description: 门店装修相关
 *@author: forguo
 *@date: 2021/4/8
 */
/**
 * 门店装修相关接口
 */

import request from "@/plugins/request";

/** 装修审核 **/

// 装修详情基本信息
export function decorationApplyInfo(data) {
    return request({
        url: `/decorationApply/decorationApplyInfo`,
        method: "get",
        params: data
    });
}

// 装修详情装修信息
export function decorationInfo(data) {
    return request({
        url: `/decoration/audit/detail`,
        method: "get",
        params: data
    });
}

// 装修审核进度
export function decorationApplyProcess(data) {
    return request({
        url: `/decoration/audit/getAuditBarById`,
        method: "get",
        params: data
    });
}

// 装修详情发票信息
export function decorationInvoiceInfo(data) {
    return request({
        url: `/decorationApply/decorationInvoiceInfo`,
        method: "get",
        params: data
    });
}

// 导出装修申请列表
export function exportDecorationApplyAudit(data) {
    return request({
        url: `/decorationApply/exportDecorationApplyAudit`,
        method: "post",
        data: data
    });
}
// 导出装修申请列表
export function exportDecorationApplyAuditExcel(data) {
    return request({
        url: `/decoration/audit/exportDecorationApplyAudit`,
        method: "post",
        data: data
    });
}

// 装修申请列表
export function listAuditPage(data) {
    return request({
        url: `/decoration/audit/page`,
        method: "post",
        data
    });
}
