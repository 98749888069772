<!--
 * @Author: chengleilei
 * @Date: 2021-09-29 16:21:33
 * @Description: 企业\个体工商户 开户
-->
<template>
    <div class="open-account" v-loading="pageLoading">
        <PageHeader :title="userType == '12' ? '企业开户' : '个体工商户开户'" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage">
                <Icon type="md-arrow-back" />
            </div>
        </PageHeader>
        <Card class="ivu-mt" :bordered="false" dis-hover>
            <Alert show-icon class="com-alert"
                >请务必提供真实信息并确保正确无误，否则会导致开户失败或影响后续签约、进账等流程</Alert
            >
            <business-item ref="businessItem" :isEnterprise="userType == '12'" :businessBaseForm="businessBaseForm" />
            <legal-item ref="legalItem" :isEnterprise="userType == '12'" :legalForm="legalForm" />
        </Card>
        <FooterToolbar>
            <Button @click="jumpPrevPage">返回</Button>
            <Button type="primary" :loading="pageLoading" @click="submitData">提交</Button>
        </FooterToolbar>
    </div>
</template>

<script>
import { clearEmpty, transPathToName, jumpPage } from '@/libs/util';
import businessItem from './components/businessItem';
import legalItem from './components/legalItem';
import moment from 'moment';
import { accountDetail, openAccount } from '@/api/dealerAccount';

export default {
    data() {
        return {
            pageLoading: false,
            userType: this.$route.query.userType || '', // 账户类型【 12=企业用户 13=个体工商用户】
            cpcnAccountId: this.$route.query.cpcnAccountId || '', // 中金账户id
            type: this.$route.query.type || '', // 是否重新开户
            userId: '', // 用户id
            // 企业或个体户基本信息
            businessBaseForm: {
                businessName: '', // 营业执照名称/企业名称
                bankAccountNumber: '', // 企业对公账号
                businessLicenseNumber: '', // 个体工商注册号码/统一社会信用代码
                businessStartTime: '', // 证件有效期开始
                businessEndtime: '', // 证件有效期结束
                money: '', // 注册资本
                businessProvince: '', // 注册省份
                businessCity: '', // 注册城市
                businessDistrict: '', // 注册区县
                businessDetailAddress: '', // 详细地址
                businessEmail: '', // 企业邮箱
                // provinceCity: [], // 省市对应级联数据
            },
            // 法人基本信息
            legalForm: {
                legalPersonName: '', // 法人姓名
                legalPersonNumber: '', // 法人身份证号
                legalCardStartTime: '', // 证件有效期开始
                legalCardEndTime: '', // 证件有效期结束
                legalPersonMobile: '', // 法人手机号
                legalPersonEmail: '', // 法人邮箱
            },
        };
    },

    components: {
        businessItem,
        legalItem,
    },

    mounted() {
        this.pageLoading = true;
        this.getData();
    },

    methods: {
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/assets/dealerAccount/list' });
        },

        /**
         * @description 格式化时间
         * @param {String} str 时间字符串 `20211013`
         */
        resolveTime(str) {
            return new Date(`${str.substr(0, 4)}-${str.substr(4, 2)}-${str.substr(6, 2)}`);
        },

        /**
         * @description 查看账户详情
         */
        getData() {
            const params = { cpcnAccountId: this.cpcnAccountId };
            accountDetail(params)
                .then((res) => {
                    this.userId = res.userId || '';
                    // 重新开户回填所有信息
                    if (this.type) {
                        this.$refs['businessItem'].getCityListByCode(res.businessProvince, false).then(() => {
                            this.$refs['businessItem'].getDistrictListByCode(res.businessCity, false);
                        });
                        this.businessBaseForm = {
                            businessName: res.businessName || '', // 营业执照名称/企业名称
                            bankAccountNumber: res.bankAccountNumber || '', // 企业对公账号
                            businessLicenseNumber: res.businessLicenseNumber || '', // 个体工商注册号码/统一社会信用代码
                            businessStartTime: res.businessStartTime && this.resolveTime(res.businessStartTime), // 证件有效期开始
                            businessEndtime: res.businessEndTime && this.resolveTime(res.businessEndTime), // 证件有效期结束
                            money: res.money || '', // 注册资本
                            businessProvince: res.businessProvince || '', // 注册省份
                            businessCity: res.businessCity || '', // 注册城市
                            businessDistrict: res.businessDistrict || '', // 注册区县
                            businessDetailAddress: res.businessDetailAddress || '', // 详细地址
                            businessEmail: res.businessEmail || '', // 企业邮箱
                        };
                        this.legalForm = {
                            legalPersonName: res.legalPersonName || '', // 法人姓名
                            legalPersonNumber: res.legalPersonNumber || '', // 法人身份证号
                            legalCardStartTime: res.legalCardStartTime && this.resolveTime(res.legalCardStartTime), // 证件有效期开始
                            legalCardEndTime: res.legalCardEndTime && this.resolveTime(res.legalCardEndTime), // 证件有效期结束
                            legalPersonMobile: res.legalPersonMobile || '', // 法人手机号
                            legalPersonEmail: res.legalPersonEmail || '', // 法人邮箱
                        };
                        // 处理证件长期有效回填
                        this.$refs['businessItem'].isLongBusiness = res.businessEndTime === '20991231';
                        this.$refs['legalItem'].isLongLegal = res.legalCardEndTime === '20991231';
                        return;
                    }
                    // 首次开户回填部分信息
                    if (this.userType == '12') {
                        this.businessBaseForm.businessName = res.businessName || '';
                        this.businessBaseForm.bankAccountNumber = res.bankAccountNumber || '';
                    } else {
                        this.legalForm.legalPersonMobile = res.legalPersonMobile || '';
                    }
                    this.legalForm.legalPersonName = res.legalPersonName || '';
                    this.legalForm.legalPersonNumber = res.legalPersonNumber || '';
                })
                .finally(() => {
                    this.pageLoading = false;
                });
        },

        /**
         * @description 校验企业或个体户基本信息
         */
        validateBusiness() {
            return new Promise((resolve, reject) => {
                this.$refs['businessItem'].$refs['businessBaseForm'].validate((valid) => {
                    this.$refs['businessItem'].$refs['formDataEl'].validate((validEl) => {
                        if (!valid || !validEl) {
                            this.$Modal.warning({
                                title: '提示',
                                content: '请完善表单内容',
                            });
                            reject();
                            return false;
                        }
                        if (!this.businessBaseForm.businessStartTime || !this.businessBaseForm.businessEndtime) {
                            this.$Modal.warning({
                                title: '提示',
                                content: '请选择证件有效期',
                            });
                            reject();
                            return false;
                        }
                        resolve();
                    });
                });
            });
        },

        /**
         * @description 校验法人信息
         */
        validateLegal() {
            return new Promise((resolve, reject) => {
                this.$refs['legalItem'].$refs['legalForm'].validate((valid) => {
                    if (!valid) {
                        this.$Modal.warning({
                            title: '提示',
                            content: '请完善表单内容',
                        });
                        reject();
                        return false;
                    }
                    if (!this.legalForm.legalCardStartTime || !this.legalForm.legalCardEndTime) {
                        this.$Modal.warning({
                            title: '提示',
                            content: '请选择证件有效期',
                        });
                        reject();
                        return false;
                    }
                    resolve();
                });
            });
        },

        /**
         * @description 提交表单数据
         */
        submitData() {
            const p1 = this.validateBusiness();
            const p2 = this.validateLegal();
            Promise.all([p1, p2])
                .then(() => {
                    this.$Modal.confirm({
                        title: '确认提交',
                        content: '提交后不可修改，请确认信息准确无误',
                        onOk: () => {
                            let p = Object.assign({}, this.businessBaseForm, this.legalForm, {
                                userId: this.userId,
                                userType: this.userType,
                                preId: this.type ? this.cpcnAccountId : '', // 重新开户需传
                                businessStartTime: moment(this.businessBaseForm.businessStartTime).format('yyyyMMDD'),
                                businessEndtime: moment(this.businessBaseForm.businessEndtime).format('yyyyMMDD'),
                                legalCardStartTime: moment(this.legalForm.legalCardStartTime).format('yyyyMMDD'),
                                legalCardEndTime: moment(this.legalForm.legalCardEndTime).format('yyyyMMDD'),
                            });
                            p = clearEmpty(p);
                            this.pageLoading = true;
                            this.$Message.loading({
                                content: '提交中...',
                                duration: 0,
                            });

                            openAccount(p)
                                .then(() => {
                                    this.$Message.success('提交成功');
                                    window.$basePageStore.closeTab(transPathToName(location.pathname));
                                    jumpPage({
                                        path: '/sub-vue2/assets/dealerAccount/openAccountResult',
                                        params: {
                                            userType: this.userType,
                                            cpcnAccountId: this.cpcnAccountId,
                                        },
                                    });
                                })
                                .catch((err) => {
                                    console.error('开户失败：', err);
                                })
                                .finally(() => {
                                    this.$Message.destroy();
                                    this.pageLoading = false;
                                });
                        },
                    });
                })
                .catch(() => {
                    console.log('没通过');
                });
        },
    },
};
</script>
<style lang="less" scoped>
.open-account {
}
</style>
