<template>
    <Modal :width="785" v-model="model" :title="'店检详情'" class="auditModal">
        <Spin size="large" fix v-if="loading">加载中...</Spin>

        <div class="store-detail">
            <Form label-colon v-if="detail" :label-width="105">
                <Row>
                    <Col push="2" span="10">
                        <FormItem label="门店名称">
                            <p>{{ detail.storeName }}</p>
                        </FormItem>
                    </Col>
                    <Col push="2" span="10">
                        <FormItem label="联系人">
                            <p>{{ detail.ownerName }}</p>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col push="2" span="10">
                        <FormItem label="联系方式">
                            <p>{{ detail.ownerPhone }}</p>
                        </FormItem>
                    </Col>
                    <Col push="2" span="10">
                        <FormItem label="提交时间">
                            <p>{{ detail.applyTime }}</p>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col push="2" span="10">
                        <FormItem label="门头照片">
                            <img class="img-box" :src="detail.storeHeadImgUrl" :preview="1" preview-text="门头照片" />
                        </FormItem>
                    </Col>
                    <Col push="2" span="10" v-if="detail.lastHeadImgUrl">
                        <FormItem label="上次门头照">
                            <img class="img-box" :src="detail.lastHeadImgUrl" :preview="2" preview-text="上次店检照" />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col push="2" span="10">
                        <FormItem label="本次店检照">
                            <div class="img-list">
                                <img
                                    v-for="(item, index) in detail.currentImgUrlList"
                                    :key="index"
                                    class="img-box"
                                    :src="item"
                                    :preview="3"
                                    preview-text="本次店检照"
                                />
                            </div>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        </div>

        <Row>
            <Col push="2" span="20">
                <div id="storeMapCurrent" class="store-map ivu-mt-16 ivu-mb-16"></div>
            </Col>
        </Row>

        <Form label-colon v-if="detail" :label-width="160">
            <Row>
                <Col push="2" span="20" v-if="detail && detail.auditStatus > 3">
                    <Alert>
                        <Row v-if="[4, 5].includes(detail.auditStatus)">
                            <Col span="12">渠道员审核结果：{{ detail.channelManAuditResult }}</Col>
                            <Col span="12">审核门店类型：{{ detail.auditStoreTypeStr }}</Col>
                        </Row>
                        <Row v-else-if="[6].includes(detail.auditStatus)">
                            <Col span="12">驳回原因：{{ detail.rejectReason }}</Col>
                        </Row>
                    </Alert>
                </Col>
            </Row>
        </Form>

        <div slot="footer">
            <Button type="warning" @click="onCancel">{{ modeType == 1 ? '关闭' : '取消' }}</Button>
            <Button type="primary" :loading="loading" :disabled="mode === 'CHECK'" @click="submit" v-if="modeType == 2"
                >确认</Button
            >
        </div>

        <Modal title="图片预览" v-model="visible" width="700px" footer-hide>
            <img :src="activeImg" style="width: 100%" />
        </Modal>
    </Modal>
</template>

<script>
import { storeInspectionDetail } from '@/api/statistics';
let map = null;

export default {
    data() {
        return {
            model: false,
            pointEnum: '',
            loading: true,
            detail: null,
            visible: false,
            activeImg: '',
            modeType: false,
        };
    },
    beforeDestroy() {
        map = null;
    },
    mounted() {
        // eslint-disable-next-line no-undef
        map = new AMap.Map('storeMapCurrent', {
            zoom: 12,
            scrollWheel: true,
        });
    },
    methods: {
        checkImg(activeImg) {
            this.activeImg = activeImg;
            this.visible = true;
        },
        open(row, mode) {
            this.modeType = mode;
            let inspectionId = row.inspectionId;
            this.id = inspectionId;
            let getDetail = storeInspectionDetail;
            this.loading = true;
            getDetail(inspectionId).then(
                (res) => {
                    this.detail = res;
                    this.loading = false;
                    let { storeLatitude: lat, storeLongitude: lng } = res;
                    map.setCenter([lng, lat]); // 设置地图中心点

                    // eslint-disable-next-line no-undef
                    let marker = new AMap.Marker({
                        icon: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_r.png',
                        position: [lng, lat],
                        // eslint-disable-next-line no-undef
                        offset: new AMap.Pixel(-13, -30),
                    });
                    marker.setMap(map);
                    marker.setPosition([lng, lat]); //更新点标记位置
                },
                () => {
                    this.loading = false;
                },
            );
            this.model = true;
        },
        onCancel() {
            this.pointEnum = '';
            this.loading = true;
            this.detail = null;
            this.visible = false;
            this.activeImg = '';
            this.model = false;
        },
    },
};
</script>

<style lang="less">
.auditModal {
    .ivu-form-item {
        margin-bottom: 0;
        &:nth-child(1) {
            width: auto;
        }
    }
    .vxe-edit-icon.vxe-icon--edit-outline {
    }
    .store-detail {
    }
    .img-box {
        width: 75px;
        height: 75px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 10px;
        cursor: pointer;
        border-radius: 4px;
    }
    .img-list {
        width: 255px;
        display: flex;
        .img-box {
            margin-right: 10px;
        }
    }
}
.store-map {
    height: 250px;
}
</style>
