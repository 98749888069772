/** * @Author: forguo * @Date: 2021/10/6 16:13 * @Description: 重新上传 */
<template>
    <div class="account-edit" v-loading="loading">
        <PageHeader :title="title" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage">
                <Icon type="md-arrow-back" />
            </div>
        </PageHeader>

        <Card title="" class="ivu-mt" :dis-hover="true" shadow>
            <Alert closable>
                <div class="upload-tips" v-if="userType == 12">
                    <p><span style="color: rgba(0, 0, 0, 0.647058823529412)">照片上传要求：</span></p>
                    <p>
                        <span style="color: rgba(0, 0, 0, 0.647058823529412)">1、要求传</span>
                        <span style="color: rgba(217, 0, 27, 0.996078431372549)"> jpg 格式</span>
                        <span style="color: rgba(0, 0, 0, 0.647058823529412)">图片，每个影印件大小</span>
                        <span style="color: rgba(217, 0, 27, 0.996078431372549)"> 500KB 以内</span>
                        <span style="color: rgba(0, 0, 0, 0.647058823529412)">。图片在线压缩工具：</span>
                        <a href="https://www.bejson.com/ui/compress_img/" target="_blank" style="color: #449afe"
                            >https://www.bejson.com/ui/compress_img/</a
                        >
                    </p>
                    <p>
                        <span style="color: rgba(0, 0, 0, 0.647058823529412)"
                            >2、企业用户影印件盖章要求：彩色扫描件或黑白复印件上</span
                        ><span style="color: rgba(217, 0, 27, 0.968627450980392)">加盖公章鲜章</span
                        ><span style="color: rgba(0, 0, 0, 0.647058823529412)">，每个文件盖章要求展示完整，</span
                        ><span style="color: rgba(217, 0, 27, 0.996078431372549)">一定要盖到文字部分</span
                        ><span style="color: rgba(0, 0, 0, 0.647058823529412)">，不允许空白处盖章</span>
                    </p>
                    <p><span style="color: rgba(0, 0, 0, 0.647058823529412)">3、有效证件应在有效期内</span></p>
                    <p>
                        <span style="color: rgba(0, 0, 0, 0.647058823529412)"
                            >4、原件及影印件不得涂改（除标注证件用途外），需确保文字、 图片、证件号码清晰可辨</span
                        >
                    </p>
                    <p>
                        <span style="color: rgba(0, 0, 0, 0.647058823529412)"
                            >5、不可在影印件标记其他使用用途，如必须标记，可标记只用于中金支付开户使用</span
                        >
                    </p>
                </div>
                <div class="upload-tips" v-else>
                    <p><span style="color: rgba(0, 0, 0, 0.647058823529412)">照片上传要求：</span></p>
                    <p>
                        <span style="color: rgba(0, 0, 0, 0.647058823529412)">1、要求传</span
                        ><span style="color: rgba(217, 0, 27, 0.996078431372549)"> jpg 格式</span
                        ><span style="color: rgba(0, 0, 0, 0.647058823529412)">图片，每个影印件大小</span
                        ><span style="color: rgba(217, 0, 27, 0.996078431372549)"> 500KB 以内</span
                        ><span style="color: rgba(0, 0, 0, 0.647058823529412)">。图片在线压缩工具：</span>
                        <a href="https://www.bejson.com/ui/compress_img/" target="_blank" style="color: #449afe"
                            >https://www.bejson.com/ui/compress_img/</a
                        >
                    </p>
                    <p>
                        <span style="color: rgba(0, 0, 0, 0.647058823529412)">2、请提供</span
                        ><span style="color: rgba(217, 0, 27, 0.996078431372549)">原件彩色照片</span>
                    </p>
                    <p><span style="color: rgba(0, 0, 0, 0.647058823529412)">3、有效证件应在有效期内</span></p>
                    <p>
                        <span style="color: rgba(0, 0, 0, 0.647058823529412)"
                            >4、原件照片不得涂改（除标注证件用途外），需确保文字、 图片、证件号码清晰可辨</span
                        >
                    </p>
                    <p>
                        <span style="color: rgba(0, 0, 0, 0.647058823529412)"
                            >5、不可在影印件标记其他使用用途，如必须标记，可标记只用于中金支付开户使用</span
                        >
                    </p>
                </div>
            </Alert>
        </Card>

        <Form ref="formData" :model="formData" :rules="ruleValidate" :label-width="130">
            <Card title="请上传企业资质照片" :bordered="false" dis-hover style="margin-top: -18px">
                <FormItem label="工商营业执照：">
                    <div class="ivu-row-flex">
                        <div class="ivu-mr-16">
                            <ImagUpload
                                :maxlength="1"
                                bigSize
                                :upload-list="businessLicenseUrl"
                                :accept="accept"
                                :format="format"
                                :maxSize="500"
                            >
                                <div slot="content" class="upload-btn">
                                    <p class="upload-icons">
                                        <Icon type="md-add" size="26" color="#c3c3c3"></Icon>
                                    </p>
                                    <p class="upload-type">上传</p>
                                </div>
                            </ImagUpload>
                        </div>
                        <Col>
                            <demoImg
                                multiple
                                style="width: 300px"
                                :list="['https://zc-oss.zcrubber.com/upload/files/2021/10/10/yinye.1633849181347.png']"
                            />
                        </Col>
                    </div>
                </FormItem>

                <!-- 企业账号上传信息-->
                <FormItem label="基本户开户证明：" v-if="userType == 12">
                    <div class="ivu-row-flex">
                        <div class="ivu-mr-16">
                            <ImagUpload
                                :maxlength="1"
                                bigSize
                                :upload-list="openingPermitUrl"
                                :accept="accept"
                                :format="format"
                                :maxSize="500"
                            >
                                <div slot="content" class="upload-btn">
                                    <p class="upload-icons">
                                        <Icon type="md-add" size="26" color="#c3c3c3"></Icon>
                                    </p>
                                    <p class="upload-type">上传</p>
                                </div>
                            </ImagUpload>
                        </div>
                        <Col>
                            <demoImg
                                multiple
                                style="width: 400px"
                                :list="[
                                    'https://zc-oss.zcrubber.com/upload/files/2021/10/10/kaihu.1633849202577.png',
                                    'https://zc-oss.zcrubber.com/upload/files/2021/10/10/zhanghu.1633849223007.png',
                                ]"
                            />
                        </Col>
                    </div>
                </FormItem>
            </Card>

            <Card title="请上传法人身份证照片" :bordered="false" dis-hover style="margin-top: -18px">
                <FormItem label="上传身份证正面：">
                    <div class="ivu-row-flex">
                        <div class="ivu-mr-16">
                            <ImagUpload
                                :maxlength="1"
                                :upload-list="frontCardUrl"
                                :accept="accept"
                                :format="format"
                                :maxSize="500"
                            >
                                <div slot="content" class="upload-btn">
                                    <p class="upload-icons">
                                        <Icon type="md-add" size="26" color="#c3c3c3"></Icon>
                                    </p>
                                    <p class="upload-type">上传身份证正面</p>
                                </div>
                            </ImagUpload>
                        </div>
                        <Col>
                            <demoImg
                                multiple
                                style="width: 204px; height: 299px"
                                :list="['https://zc-oss.zcrubber.com/upload/files/2021/10/10/id.1633849161844.png']"
                            />
                        </Col>
                    </div>
                </FormItem>

                <FormItem label="上传身份证反面：">
                    <div class="ivu-row-flex">
                        <div class="ivu-mr-16">
                            <ImagUpload
                                :maxlength="1"
                                :upload-list="reverseSideCardUrl"
                                :accept="accept"
                                :format="format"
                                :maxSize="500"
                            >
                                <div slot="content" class="upload-btn">
                                    <p class="upload-icons">
                                        <Icon type="md-add" size="26" color="#c3c3c3"></Icon>
                                    </p>
                                    <p class="upload-type">上传身份证正面</p>
                                </div>
                            </ImagUpload>
                        </div>
                        <Col>
                            <demoImg
                                multiple
                                style="width: 204px; height: 299px"
                                :list="['https://zc-oss.zcrubber.com/upload/files/2021/10/10/id.1633849161844.png']"
                            />
                        </Col>
                    </div>
                </FormItem>
            </Card>
            <Card :bordered="false" dis-hover style="margin-top: -18px">
                <FormItem style="margin-bottom: 12px">
                    <Button type="primary" :loading="loading" size="large" @click="handleSubmit('formData')"
                        >提交</Button
                    >
                    <Button size="large" @click="handleReset('formData')" style="margin-left: 8px">返回</Button>
                </FormItem>
            </Card>
        </Form>
    </div>
</template>

<script>
import ImagUpload from '@/components/ImageListUpload';
import demoImg from './components/demoImg';
import { commitImageBankAgain, accountBindBack } from '@/api/dealerAccount';
import { CARD_REG, MOBILE_REG, NAME_REG, BANK_CARD, clearEmpty, transPathToName } from '@/libs/util';

export default {
    components: {
        ImagUpload,
        demoImg,
    },
    data() {
        const validatePersonName = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请填写姓名'));
            } else if (!NAME_REG.test(value)) {
                // 对第二个密码框单独验证
                callback(new Error('姓名中不能含有阿拉伯数字、标点符号'));
            } else {
                callback();
            }
        };

        const validateCredentialNumber = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入法人身份证号'));
            } else if (value.length < 18) {
                // 对第二个密码框单独验证
                callback(new Error('身份证号码不得少于18位，请核对信息'));
            } else if (!CARD_REG.test(value)) {
                // 对第二个密码框单独验证
                callback(new Error('请输入合法的身份证号码'));
            } else {
                callback();
            }
        };

        const validateAccountName = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请填写账户名称'));
            } else if (!NAME_REG.test(value)) {
                // 对第二个密码框单独验证
                callback(new Error('账户名称中中不能含有阿拉伯数字、标点符号'));
            } else {
                callback();
            }
        };

        const validateBankCard = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入银行账号'));
            } else if (!BANK_CARD.test(value)) {
                callback(new Error('请填写正确的银行账号'));
            } else {
                callback();
            }
        };

        const validatePhoneNumber = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入手机号'));
            } else if (!MOBILE_REG.test(value)) {
                callback(new Error('请填写正确的手机号'));
            } else {
                callback();
            }
        };
        return {
            format: ['jpg', 'jpeg'],
            accept: '.jpg,.jpeg,image/jpg,image/jpeg',
            title: {
                12: '企业绑卡认证',
                13: '个体工商户绑卡认证',
            }[this.$route.query.userType || ''],
            currentStep: 0,
            bankList: [],
            bankLoading: false,
            provinceList: [],
            provinceLoading: true,
            cityList: [],
            cityLoading: true,
            userType: this.$route.query.userType || '', // 影印件账户类型【11=个人账户 12=企业账户 13 = 个体工商户】
            bankAccountType: this.$route.query.bankAccountType || '', // 银行账户类型【11=个人账户 12=企业账户】
            formData: {
                legalPersonName: '', // 法人姓名
                credentialNumber: '', // 法人身份证号
                bankAccountType: this.$route.query.bankAccountType || '', // 银行账户类型【11=个人账户 12=企业账户】
                userType: this.$route.query.userType || '', // 影印件账户类型【11=个人账户 12=企业账户 13 = 个体工商户】
                bankAccountName: '', // 持卡人姓名/企业账户名称
                bankAccountNumber: '', // 银行账号
                bankId: '', // 开户银行
                branchName: '', // 开户支行
                province: '', // 开户行省份
                provinceCode: '', // 开户行省份编码
                city: '', // 开户行城市
                cityCode: '', // 开户行城市编码
                provinceCity: [], // 省市对应级联数据
                cityProvince: '',
                cityProvinceCode: '',
            },
            businessLicenseUrl: [], // 营业执照
            openingPermitUrl: [], // 开户许可证
            frontCardUrl: [], // 身份证正面
            reverseSideCardUrl: [], // 身份证反面
            ruleValidate: {
                legalPersonName: [{ required: true, validator: validatePersonName, trigger: 'change' }],
                credentialNumber: [{ required: true, validator: validateCredentialNumber, trigger: 'change' }],
                bankAccountName: [{ required: true, validator: validateAccountName, trigger: 'change' }],
                bankAccountNumber: [{ required: true, validator: validateBankCard, trigger: 'change' }],
                bankId: [{ required: true, message: '请选择开户银行', trigger: 'change' }],
                branchName: [{ required: true, message: '请输入开户支行', trigger: 'change' }],
                provinceCode: [{ required: true, message: '请选择开户行身份', trigger: 'change' }],
                provinceCity: [{ required: true, type: 'array', message: '请选择开户行城市', trigger: 'change' }],
                bankPhoneNumber: [{ required: true, validator: validatePhoneNumber, trigger: 'change' }],
            },
            loading: false,
            detail: {},
        };
    },
    async created() {
        await this.getData();
    },
    methods: {
        /**
         * 获取省市对应code及value
         */
        getCityName(item, selectData) {
            if (selectData.length > 0) {
                this.formData.province = selectData[0].label;
                this.formData.provinceCode = selectData[0].value;
                this.formData.city = selectData[1].label;
                this.formData.cityCode = selectData[1].value;
            }
        },
        /**
         * 获取基础数据
         */
        async getData() {
            try {
                // const _bankList = await bankList();
                // this.bankList = _bankList || [];
                // this.bankLoading = false;
                //
                // const _cityList = await cityList();
                // this.cityList = this.reduceCityList(_cityList[0].childCategoryList);
                // this.cityLoading = false;
                //
                // const _provinceList = await provinceList();
                // this.provinceList = _provinceList || [];
                // this.provinceLoading = false;

                const cpcnAccountId = this.$route.query.cpcnAccountId;
                if (cpcnAccountId) {
                    this.loading = true;
                    const formData = await accountBindBack({
                        cpcnAccountId,
                    });
                    this.loading = false;
                    this.setFormData(formData || {});
                }
            } catch (e) {
                this.bankLoading = false;
                this.cityLoading = false;
                this.loading = false;
            }
        },
        /**
         * 反填formData
         **/
        setFormData(formData) {
            const {
                legalPersonName: legalPersonName, // 法人姓名
                legalPersonNumber: credentialNumber, // 法人身份证号
                bankAccountName: bankAccountName, // 持卡人姓名/企业账户名称
                bankCardNumber: bankAccountNumber, // 银行账号
                bankId, // 开户银行
                bankDetailName: branchName, // 开户支行
                preUserId: userId = '',
                bankProvince: province, // 开户行省份
                bankProvinceCode: provinceCode = '', // 开户行省份编码
                bankCity: city, // 开户行城市
                bankCityCode: cityCode = '', // 开户行城市编码
                bankProvince: cityProvince = '', // 开户行城市对应省份
                bankCityProvinceCode: cityProvinceCode = '', // 开户行城市对应省份Code
                bankPhone: bankPhoneNumber = '',
            } = formData;

            const provinceCity = [cityProvinceCode || '', cityCode || '']; // 省市对应级联数据
            const userType = this.userType; // 影印件账户类型【11=个人账户 12=企业账户 13 = 个体工商户】
            // // 营业执照
            // this.businessLicenseUrl = [
            //     {
            //         url: businessLicenseUrl
            //     }
            // ];
            // // 开户许可证
            // this.openingPermitUrl = [
            //     {
            //         url: openingPermitUrl
            //     }
            // ];
            // // 身份证反面
            // this.frontCardUrl = [
            //     {
            //         url: frontCardUrl
            //     }
            // ];
            // // 身份证反面
            // this.reverseSideCardUrl = [
            //     {
            //         url: reverseSideCardUrl
            //     }
            // ];
            this.formData = {
                legalPersonName, // 法人姓名
                credentialNumber: credentialNumber || '', // 法人身份证号
                bankAccountType: userType == 12 ? 12 : 11, // 银行账户类型【11=个人账户 12=企业账户】
                userType, // 影印件账户类型【11=个人账户 12=企业账户 13 = 个体工商户】
                bankAccountName, // 持卡人姓名/企业账户名称
                bankAccountNumber, // 银行账号
                bankId, // 开户银行
                branchName, // 开户支行
                province, // 开户行省份
                provinceCode, // 开户行省份编码
                city, // 开户行城市
                cityCode, // 开户行城市编码
                cityProvince, // 省市对应级联数据
                cityProvinceCode,
                userId,
                bankPhoneNumber,
                preUserId: userId,
            };

            if (cityProvinceCode && cityCode) {
                this.formData.provinceCity = provinceCity;
            }
            console.log('反填表单信息', this.formData);
        },
        /**
         * 重组省市数据，用于级联选择
         * @param arr
         * @return {*}
         */
        reduceCityList(arr) {
            for (let i = 0; i < arr.length; i++) {
                const item = arr[i];
                item['label'] = item.gaodeAreaDTO['name'];
                item['value'] = item.gaodeAreaDTO['id'];
                if (item.childCategoryList && item.gaodeAreaDTO.level !== 'city') {
                    item.children = this.reduceCityList(item.childCategoryList);
                    delete item.childCategoryList;
                } else {
                    delete item.childCategoryList;
                }
            }
            return arr;
        },
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
        },
        /**
         * 提交
         * @param name
         * @return {boolean}
         */
        handleSubmit() {
            console.log(this.formData);
            const userType = this.userType;
            let {
                businessLicenseUrl = [], // 营业执照
                openingPermitUrl = [], // 开户许可证
                frontCardUrl = [], // 身份证正面
                reverseSideCardUrl = [], // 身份证反面
            } = this;
            if (businessLicenseUrl.length <= 0) {
                this.$Modal.warning({
                    title: '提示',
                    content: '请上传工商营业执照',
                });
                return false;
            }
            // 企业账号需要基本开户证明
            if (userType == 12 && openingPermitUrl.length <= 0) {
                this.$Modal.warning({
                    title: '提示',
                    content: '请上传开户证明',
                });
                return false;
            }
            if (frontCardUrl.length <= 0) {
                this.$Modal.warning({
                    title: '提示',
                    content: '请上传身份证正面',
                });
                return false;
            }
            if (reverseSideCardUrl.length <= 0) {
                this.$Modal.warning({
                    title: '提示',
                    content: '请上传身份证反面',
                });
                return false;
            }

            businessLicenseUrl = businessLicenseUrl.map((item) => item.url).join(','); // 营业执照
            openingPermitUrl = openingPermitUrl.map((item) => item.url).join(','); // 开户许可证
            frontCardUrl = frontCardUrl.map((item) => item.url).join(','); // 身份证正面
            reverseSideCardUrl = reverseSideCardUrl.map((item) => item.url).join(','); // 身份证反面

            const params = {
                ...this.formData,
                businessLicenseUrl,
                frontCardUrl,
                reverseSideCardUrl,
            };
            // 企业账号需要基本开户证明
            if (userType == 12) {
                params.openingPermitUrl = openingPermitUrl;
                // 个人需要预留手机号
                delete params.bankPhoneNumber;
            } else {
                // 个人账号持卡人和法人姓名一致
                params.bankAccountName = params.legalPersonName;
            }
            this.loading = true;
            this.$Message.loading({
                content: '提交中...',
                duration: 0,
            });
            commitImageBankAgain(
                clearEmpty({
                    ...params,
                }),
            ).then(
                () => {
                    this.$Message.destroy();
                    this.$Message.success('提交成功!');
                    this.jumpPrevPage();
                },
                () => {
                    this.$Message.destroy();
                    this.loading = false;
                },
            );
        },
        handleReset(name) {
            this.$Modal.confirm({
                title: '提示',
                content: '确认返回吗，数据将不会保留！',
                onOk: () => {
                    this.businessLicenseUrl = []; // 营业执照
                    this.openingPermitUrl = []; // 开户许可证
                    this.frontCardUrl = []; // 身份证正面
                    this.reverseSideCardUrl = []; // 身份证反面
                    this.$refs[name].resetFields();
                    this.jumpPrevPage();
                },
            });
        },
    },
};
</script>

<style lang="less">
@import '../../../styles/variable.less';

.account-edit {
    .upload-tips {
        font-size: 14px;
        font-weight: bold;
        line-height: 26px;
    }

    .upload-btn {
        width: 100%;
        height: 100%;
        .center();
        .flexCenter();
        flex-wrap: wrap;

        .upload-icons {
            width: 100%;
            margin-top: 90px;
        }

        .upload-type {
            width: 100%;
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 95px;
        }
    }

    .demo-img {
        width: 300px;
        height: 200px;
        margin-top: 10px;
    }
}
</style>
