<template>
    <Form :label-width="150">
        <!-- 影印件文件-->
        <!-- 0=待绑卡认证、1=待开户、2=开户处理中、3=待签约、4=签约待完成、5=正常使用中 6=开户失败-->
        <template>
            <Divider orientation="left"
                >影印件文件
                <!-- 影印件审核状态: 10=审核中 20=成功 30=失败-->
                <Badge
                    v-show="imageStatusTxt[detail.imageStatus]"
                    :color="detail.imageStatus == 30 ? 'red' : 'green'"
                    :text="imageStatusTxt[detail.imageStatus]"
                    style="margin-right: 8px"
                />
                <Button
                    size="small"
                    type="primary"
                    @click="tiedCardFormAgain(detail.imageStatus)"
                    v-show="detail.imageStatus == 30"
                    >重新上传</Button
                >
            </Divider>
            <Card
                :title="userType == 12 ? '企业资质信息' : '资质信息'"
                class="ivu-mt"
                :dis-hover="true"
                shadow
                style="margin-bottom: 40px"
            >
                <FormItem label="工商营业执照：">
                    <div
                        class="dealer-account-img img-preview"
                        :style="{ backgroundImage: 'url(' + detail.businessLicenseUrl + ')' }"
                    >
                        <img
                            :src="detail.businessLicenseUrl"
                            :preview="detail.businessLicenseUrl"
                            preview-text="工商营业执照"
                        />
                    </div>
                </FormItem>
                <FormItem label="基本户开户证明：" v-if="userType == 12">
                    <div
                        class="dealer-account-img img-preview"
                        :style="{ backgroundImage: 'url(' + detail.openingPermitUrl + ')' }"
                    >
                        <img
                            :src="detail.openingPermitUrl"
                            :preview="detail.openingPermitUrl"
                            preview-text="基本户开户证明"
                        />
                    </div>
                </FormItem>
            </Card>
            <Card title="法人信息" class="ivu-mt" :dis-hover="true" shadow style="margin-bottom: 40px">
                <FormItem label="法人身份证照片：">
                    <div class="ivu-row-flex">
                        <div
                            class="dealer-account-img img-preview ivu-mr-16 ivu-mb-16"
                            :style="{ backgroundImage: 'url(' + detail.frontCardUrl + ')' }"
                        >
                            <img :src="detail.frontCardUrl" :preview="detail.frontCardUrl" preview-text="身份证正面" />
                        </div>
                        <div
                            class="dealer-account-img img-preview"
                            :style="{ backgroundImage: 'url(' + detail.reverseSideCardUrl + ')' }"
                        >
                            <img
                                :src="detail.reverseSideCardUrl"
                                :preview="detail.reverseSideCardUrl"
                                preview-text="身份证反面"
                            />
                        </div>
                    </div>
                </FormItem>
            </Card>
        </template>

        <!-- 企业银行账户信息-->
        <!-- 0=待绑卡认证、1=待开户、2=开户处理中、3=待签约、4=签约待完成、5=正常使用中 6=开户失败-->
        <template>
            <Divider orientation="left">
                {{ userType == 12 ? '企业银行账户信息' : '银行账户信息' }}
                <Badge
                    v-show="bindStatusTxt[detail.bindStatus]"
                    :color="detail.bindStatus == 30 ? 'red' : 'green'"
                    :text="bindStatusTxt[detail.bindStatus]"
                    style="margin-right: 8px"
                />
            </Divider>
            <Card title="法人信息" class="ivu-mt" :dis-hover="true" shadow style="margin-bottom: 40px">
                <FormItem label="法人姓名：">
                    {{ detail.legalPersonName || '-' }}
                </FormItem>
                <FormItem label="法人身份证号：">
                    {{ detail.legalPersonNumber || '-' }}
                </FormItem>
            </Card>
            <Card
                :title="userType == 12 ? '对公账户信息' : '法人银行账户信息'"
                class="ivu-mt"
                :dis-hover="true"
                shadow
                style="margin-bottom: 40px"
            >
                <FormItem :label="userType == 12 ? '账户名称：' : '持卡人姓名：'">
                    {{ detail.cardholderName || '-' }}
                </FormItem>
                <FormItem label="银行账号：">
                    {{ detail.bankAccountNumber || '-' }}
                </FormItem>
                <FormItem label="开户银行：">
                    {{ detail.bankNumberName || '-' }}
                </FormItem>
                <FormItem label="开户支行：">
                    {{ detail.bankName || '-' }}
                </FormItem>
                <FormItem label="开户行省份：" v-show="userType == 12">
                    {{ detail.bankProvinceName || '-' }}
                </FormItem>
                <FormItem label="开户行城市：" v-show="userType == 12">
                    {{ detail.bankCityName || '-' }}
                </FormItem>
                <FormItem label="银行预留手机号：" v-show="userType != 12">
                    {{ detail.legalPersonMobile || '-' }}
                </FormItem>
            </Card>
        </template>

        <!-- 开户信息-->
        <!-- 0=待绑卡认证、1=待开户、2=开户处理中、3=待签约、4=签约待完成、5=正常使用中 6=开户失败-->
        <template v-if="[2, 3, 4, 5, 6].includes(detail.accountStatus)">
            <Divider orientation="left">
                开户信息
                <Badge
                    v-show="openAccountStatusTxt[detail.accountStatus]"
                    :color="openAccountStatusColor[detail.accountStatus]"
                    :text="openAccountStatusTxt[detail.accountStatus]"
                    style="margin-right: 8px"
                />
            </Divider>
            <Card
                :title="userType == 12 ? '企业基本信息' : '个体工商户基本信息'"
                class="ivu-mt"
                :dis-hover="true"
                shadow
                style="margin-bottom: 40px"
            >
                <FormItem label="企业名称：" v-show="userType == 12">
                    {{ detail.businessName || '-' }}
                </FormItem>
                <FormItem label="统一社会信用代码：" v-show="userType == 12">
                    {{ detail.businessLicenseNumber || '-' }}
                </FormItem>
                <FormItem label="个体工商注册号码：" v-show="userType != 12">
                    {{ detail.businessLicenseNumber || '-' }}
                </FormItem>
                <FormItem label="营业执照名称：" v-show="userType != 12">
                    {{ detail.businessName || '-' }}
                </FormItem>
                <FormItem label="证件有效期：">
                    {{ detail.businessStartTime || '-' }} ~ {{ detail.businessEndTime || '-' }}
                </FormItem>
                <FormItem label="注册资本（元）：" v-show="userType == 12">
                    {{ detail.money || '-' }}
                </FormItem>
                <FormItem label="注册省份：" v-show="userType == 12">
                    {{ detail.businessProvinceName || '-' }}
                </FormItem>
                <FormItem label="注册城市：" v-show="userType == 12">
                    {{ detail.businessCityName || '-' }}
                </FormItem>
                <FormItem label="详细地址：">
                    {{ detail.businessDetailAddress || '-' }}
                </FormItem>
                <FormItem label="企业邮箱：" v-show="userType == 12">
                    {{ detail.businessEmail || '-' }}
                </FormItem>
            </Card>
            <Card title="法人信息" class="ivu-mt" :dis-hover="true" shadow style="margin-bottom: 40px">
                <FormItem label="法人姓名：">
                    {{ detail.legalPersonName || '-' }}
                </FormItem>
                <FormItem label="法人身份证号：">
                    {{ detail.legalPersonNumber || '-' }}
                </FormItem>
                <FormItem label="证件有效期：">
                    {{ detail.legalCardStartTime || '-' }} ~ {{ detail.legalCardEndTime || '-' }}
                </FormItem>
                <FormItem label="法人手机号：">
                    {{ detail.legalPersonMobile || '-' }}
                </FormItem>
                <FormItem label="法人邮箱：" v-show="userType == 12">
                    {{ detail.legalPersonEmail || '-' }}
                </FormItem>
            </Card>
        </template>
    </Form>
</template>

<script>
import { jumpPage } from '@/libs/util';

export default {
    data() {
        return {
            userType: this.$route.query.userType || '', // 影印件账户类型【11=个人账户 12=企业账户 13 = 个体工商户】
            bankAccountType: this.$route.query.bankAccountType || '', // 银行账户类型【11=个人账户 12=企业账户】
            cpcnAccountId: this.$route.query.cpcnAccountId || '', // 账户id
            cardId: this.$route.query.cardId || '',
            userId: this.$route.query.userId || '',
            // 影印件审核状态: 10=审核中 20=成功 30=失败
            imageStatusTxt: {
                10: '已提交',
                20: '已提交',
                30: '未通过',
            },
            // 银行卡绑定状态: 10=审核中 20=成功 30=失败
            bindStatusTxt: {
                10: '待认证',
                20: '已通过',
                30: '失败',
                40: '待绑卡认证',
            },
            // 开户信息：0=待绑卡认证、1=待开户、2=开户处理中、3=待签约、4=签约待完成、5=正常使用中 6=开户失败
            openAccountStatusTxt: {
                0: '待绑卡认证',
                1: '待开户',
                2: '处理中',
                3: '已通过',
                4: '已通过',
                5: '已通过',
                6: '未通过',
            },
            openAccountStatusColor: {
                0: 'yellow', // '待绑卡认证'
                1: 'yellow', // '待开户',
                2: 'blue', // '开户处理中'
                3: 'green', // '待签约',
                4: 'green', // '签约待完成'
                5: 'green', // '正常使用中'
                6: 'red', // '开户失败',
            },
        };
    },
    props: {
        detail: {
            type: Object,
        },
    },
    methods: {
        tiedCardFormAgain(status) {
            // 未通过，重新上传
            if (status == 30) {
                jumpPage({
                    path: '/sub-vue2/assets/dealerAccount/tiedCardFormAgain',
                    params: {
                        cpcnAccountId: this.$route.query.cpcnAccountId,
                        userType: this.$route.query.userType,
                        cardId: this.detail.cardId,
                        userId: this.detail.userId,
                    },
                });
            }
        },
    },
};
</script>
<style lang="less"></style>
