<template>
    <div class="pd" v-loading="loading">
        <PageHeader title="门店签署详情" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage">
                <Icon type="md-arrow-back" />
            </div>
        </PageHeader>
        <Card dis-hover :bordered="false" class="ivu-mt-16">
            <Form :label-width="155" label-position="right">
                <Card title="合同" :bordered="false" dis-hover class="ivu-mt">
                    <Row>
                        <Col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
                            <a
                                href="javascript:;"
                                class="file-link"
                                :title="pdf.fileName || '暂无合同'"
                                @click="checkPdf"
                            >
                                <Icon type="md-document" size="52" style="color: red" />
                                <span class="file-link-url">{{ pdf.fileName || '暂无合同' }}</span>
                                <span class="file-link-url ivu-ml-16" v-if="pdf.fileUrl">点击查看</span>
                            </a>
                        </Col>
                        <Col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
                            <p class="ivu-mt-16">合同有效期：{{ data.dateTime || '-' }}</p>
                        </Col>
                    </Row>
                </Card>
                <Card title="门店签署信息" :bordered="false" dis-hover class="ivu-mt">
                    <p class="ivu-mr-16 ivu-mt-16 store-sign-desc">签署人：{{ data.storeSignerName || '-' }}</p>
                    <p class="ivu-mr-16 ivu-mt-16 store-sign-desc">联系方式：{{ data.storeSignerContact || '-' }}</p>
                    <p class="ivu-mr-16 ivu-mt-16 store-sign-desc">身份证号码：{{ data.storeSignerCard || '-' }}</p>
                    <p class="ivu-mr-16 ivu-mt-16 store-sign-desc">奖励档：{{ data.reward || '-' }}</p>
                </Card>
                <Card title="经销商签署信息" :bordered="false" dis-hover class="ivu-mt">
                    <p class="ivu-mr-16 ivu-mt-16 store-sign-desc">经销商签署：{{ data.companyName || '-' }}</p>
                    <p class="ivu-mr-16 ivu-mt-16 store-sign-desc">工商注册号：{{ data.companyIdNumber || '-' }}</p>
                </Card>

                <Card title="用章人信息" :bordered="false" dis-hover class="ivu-mt">
                    <p class="ivu-mr-16 ivu-mt-16 store-sign-desc">用章人：{{ data.managerName || '-' }}</p>
                    <p class="ivu-mr-16 ivu-mt-16 store-sign-desc">联系方式：{{ data.managermobile || '-' }}</p>
                    <p class="ivu-mr-16 ivu-mt-16 store-sign-desc">身份证号码：{{ data.managerIdNumber || '-' }}</p>
                </Card>
            </Form>
        </Card>
    </div>
</template>

<script>
import { esignStoreDetail, esignStoreFile } from '@/api/esign';
import { jumpPage, transPathToName } from '@/libs/util';

export default {
    name: 'customer-version-edit',
    components: {},
    data() {
        return {
            id: this.$route.query.id || null,
            mode: this.$route.query.mode,
            loading: false,
            data: {},
            pdf: {},
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        checkPdf() {
            if (this.pdf.fileUrl) {
                const url = `${window.location.origin}/pdf/view?url=${this.pdf.fileUrl}&title=${this.pdf.fileName}`;
                window.open(url, '_blank');
            }
        },
        getData() {
            if (!this.id) return;
            this.loading = true;
            esignStoreDetail({
                contractId: this.id,
            })
                .then((data) => {
                    this.data = data;
                    esignStoreFile({
                        flowId: data.flowId,
                    }).then(
                        (pdf) => {
                            if (pdf && pdf.docs.length > 0) {
                                this.pdf = pdf.docs[0];
                            }
                            this.loading = false;
                        },
                        () => {
                            this.loading = false;
                        },
                    );
                })
                .catch(() => {});
        },
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/customer/storeSign/list' });
        },
    },
};
</script>

<style lang="less">
.store-sign-desc {
    display: inline-block;
}
</style>
