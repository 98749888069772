<!--
 * @Author: chengleilei
 * @Date: 2024-01-04 16:14:56
 * @Description: 经销商扫码积分
-->
<template>
    <div v-loading="pageLoading">
        <PageHeader title="经销商扫码积分" hidden-breadcrumb> </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" @on-submit="onSubmit" @on-export="exportInfo" ref="table-form">
                <Button class="ivu-ml-8" slot="extraBtn" type="error">导出</Button>
            </table-form>
            <table-page
                ref="selection-table"
                @onPageChange="onPageChange"
                @onPageSizeChange="onPageSizeChange"
                :list="list"
            />
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { scanScoreList, scanScoreExport } from '@/api/statistics';
import { nowData, listCol } from './const';
import dayjs from 'dayjs';

export default {
    components: {
        tableForm,
        tablePage,
    },
    data() {
        return {
            nowData, // 当前月份
            pageLoading: false,
            isChangePage: false,
            formList: [
                {
                    title: '时间',
                    type: 'month',
                    key: 'date',
                    placeholder: '请选择月份',
                    options: {
                        disabledDate(date) {
                            const time = dayjs(date).format('YYYY-MM').valueOf();
                            const now = dayjs().format('YYYY-MM').valueOf();
                            return time && time > now;
                        },
                    },
                },
            ],
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [...listCol],
                data: [],
            },
        };
    },

    mounted() {
        this.getData();
    },
    methods: {
        resetData() {
            this.getData();
            this.selectedData = [];
        },
        /**
         * @description 查询列表
         */
        getData() {
            this.list.loading = true;
            let p = this.$refs['table-form'].getFormData();
            let params = {
                current: this.list.current,
                size: this.list.size,
                ...p,
                date: p.date || nowData,
            };
            console.log('params after =', params);
            scanScoreList(params).then(
                (res) => {
                    this.list.total = Number(res.total);
                    this.list.data = res.list || [];
                    this.isChangePage = false;
                    this.list.loading = false;
                },
                () => {
                    this.isChangePage = false;
                    this.list.loading = false;
                },
            );
        },
        /**
         * @description 导出列表
         */
        exportData() {
            let p = this.$refs['table-form'].getFormData();
            let params = {
                ...p,
                date: p.date || nowData,
            };
            this.$Message.loading({
                content: '导出中...',
                duration: 0,
            });
            scanScoreExport(params).then(
                () => {
                    this.$Message.destroy();
                    this.$Modal.confirm({
                        title: '提示',
                        content: `您好！“${this.$route.meta.title}”正在导出，请稍后在“导出列表”模块中查看，谢谢！`,
                        onOk: () => {
                            console.log('export');
                        },
                    });
                },
                () => {
                    this.$Message.destroy();
                },
            );
        },
        onPageChange(val) {
            if (this.isChangePage) return;
            this.list.current = val;
            this.getData();
        },
        onPageSizeChange(val) {
            this.list.current = 1;
            this.isChangePage = true;
            this.list.size = val;
            this.getData();
        },
        onSubmit() {
            this.list.current = 1;
            this.selectedData = [];
            this.getData();
        },
        exportInfo() {
            this.exportData();
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .ivu-form-item {
    &:nth-child(1) {
        width: 325px;
    }
}
</style>
