<template>
  <Select
    v-model="currValue"
    filterable
    remote
    ref="storeSelect"
    :disabled='disabled'
    :loading="loading"
    clearable
    :label-in-value='isLabelInvalue'
    @on-change="onchange"
    @on-query-change="remote"
    @on-open-change="onOpen">
    <Option v-for="item in storeList" :value="item.id" :key="item.id" :label="item.label">
        <span>{{item.label}}</span>
        <span style="float:right;">联系人： {{item.ownerName}}</span>
    </Option>
  </Select>
</template>
<script>
    import { debounce } from 'lodash'
    import { SignStoreList } from '@/api/store'
    export default {
        props: {
            value: {
                type: [String, Number],
                default: ''
            },
            isLabelInvalue: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {
                storeList: [],
                loading: false,
                name: '',
                storeName: ''
            }
        },
        computed: {
            currValue: {
                get () {
                    return this.value
                },
                set (v) {
                    this.$emit('input', v || '')
                }
            }
        },
        methods: {
            onchange (val) {
                this.$emit('onchange', val)
            },
            onOpen (isOpen) {
                if (isOpen) this.getDetailData(this.name)
            },
            remote (query = '') {
                this.name = query
                this.getDetailData(query)
            },
            getDetailData: debounce(function d (query = '') {
                this.loading = true
                this.name = query
                let param = {
                    storeName: query
                }
                return SignStoreList(param).then(data => {
                    this.loading = false
                    this.storeList = []
                    this.storeList = (data.records || []).map(item => {
                        return {
                            id: item.storeId,
                            label: item.storeName,
                            ownerName: item.ownerName
                        }
                    })
                })
            }, 400),
            selectDefault (val) { // 如需默认选中第一项，手动调用该方法
                this.storeName = val
                this.getDetailData(this.storeName).then(() => {
                    if (!this.storeName) return
                    let item = this.storeList[0] || {}
                    this.$refs.storeSelect.onOptionClick({
                        value: item.id,
                        label: item.label
                    })
                    this.$emit('onchange', { value: item.id, label: item.label })
                })
            }
        }
    }
</script>
