<template>
    <div v-loading="list.loading" class="scan-table-list">
        <PageHeader title="扫码流水" hidden-breadcrumb>
            <Button
                slot="action"
                type="info"
                class="ivu-ml"
                icon="md-cloud-download"
                @click="onExport"
                :loading="isExporting"
                >{{ isExporting ? '正在导出...' : '导出' }}</Button
            >
        </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" @on-submit="onSubmit" ref="table-form" />
            <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list" />
        </Card>
        <StoreEdit @getData="getData" ref="storeAdd" />
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import StoreEdit from './components/StoreEdit';
import { renderButton } from '@/libs/util';
import { getScanList, getScanListParam, scanListExport } from '@/api/scan';
import TableExpand from './components/TableExpand.vue';
import { clearEmpty, jumpPage } from '@/libs/util';
const isPdaChooseStore = Boolean(localStorage.getItem('Base-isPdaChooseStore'));

export default {
    name: 'scan-flow-list',
    components: {
        tableForm,
        tablePage,
        StoreEdit,
    },
    data() {
        return {
            isExporting: false,
            list: {
                stripe: false,
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    {
                        width: 50,
                        align: 'right',
                        type: 'expand',
                        render: (h, params) => {
                            console.log(params);
                            return h(TableExpand, {
                                props: {
                                    row: params.row,
                                },
                            });
                        },
                    },
                    {
                        title: '商品名称',
                        key: 'sn',
                        width: 280,
                        align: 'center',
                    },
                    { title: '时间', key: 'scanCodeDate', width: 170, align: 'center' },
                    { title: '类型', key: 'scanCodeTypeMeaning', width: 140, align: 'center' },
                    { title: '数量', key: 'totalItemQuantity', width: 80, align: 'center' },
                    { title: '扫码人', key: 'scanCodeOperateName', width: 160, align: 'center' },
                    { title: '门店名称', key: 'storeName', width: 150, align: 'center' },
                    { title: '联系人', key: 'storeOwnerName', width: 100, align: 'center' },
                    { title: '联系方式', key: 'storeOwnerPhone', width: 130, align: 'center' },
                    {
                        title: '操作',
                        key: 'oprate',
                        minWidth: 180,
                        align: 'left',
                        fixed: 'right',
                        render: (h, p) => {
                            let addBtn = null;
                            if (isPdaChooseStore && p.row.scanCodeTypeMeaning !== '经销商退货扫码' && !p.row.storeId) {
                                addBtn = renderButton(h, { text: '添加门店', type: 'info' }, () => {
                                    this.addStore(p.row);
                                });
                            }
                            return [
                                renderButton(h, { text: '详情', type: 'primary' }, () => {
                                    this.toDetail(p.row);
                                }),
                                addBtn,
                            ];
                        },
                    },
                ],
                data: [],
            },
            formList: [
                {
                    title: '类型',
                    type: 'select',
                    key: 'scanCodeType',
                    placeholder: '请选择类型',
                    clearable: true,
                    options: [],
                },
                {
                    title: '扫码人',
                    type: 'distributorSelect',
                    key: 'scanCodeOperateId',
                    loading: false,
                },
                {
                    title: '时间',
                    type: 'daterange',
                    key: ['scanCodeBeginTime', 'scanCodeEndTime'],
                },
                {
                    title: '门店信息',
                    type: 'input',
                    key: 'storeKeyWords',
                    placeholder: '请输入门店名称/联系人/联系方式',
                },
                {
                    title: '商品名称',
                    type: 'input',
                    key: 'productName',
                    placeholder: '请输入商品名称',
                },
            ],
        };
    },
    mounted() {
        this.getTypeOption();
    },
    activated() {
        this.getData();
    },
    methods: {
        addStore(row) {
            this.$refs['storeAdd'].open('ADD', row.id);
        },
        toDetail(row) {
            jumpPage({
                path: '/sub-vue2/scan/flow/detail',
                params: {
                    id: row.id,
                },
            });
        },
        getTypeOption() {
            getScanListParam().then((res) => {
                let types = [
                    {
                        id: '',
                        name: '全部',
                    },
                ];
                res.map((item) => {
                    types.push({
                        id: item.key,
                        name: item.value,
                    });
                });
                this.formList[0].options = types;
            });
        },
        getData() {
            let p = this.$refs['table-form'].getFormData();
            let copyP = { ...clearEmpty(p) };
            this.list.loading = true;
            const param = {
                current: this.list.current,
                size: this.list.size,
                ...copyP,
            };
            getScanList(param)
                .then((res) => {
                    this.list.loading = false;
                    this.list.total = Number(res.total);
                    this.list.data = (res.records || []).map((item) => {
                        let list = {
                            data: item.scanBarcodeOrderDetailVos,
                            columns: [
                                {
                                    title: '商品名称',
                                    key: 'productName',
                                    width: 380,
                                    align: 'center',
                                },
                                { title: '', key: '', width: 130, align: 'center' },
                                { title: '', key: '', width: 130, align: 'center' },
                                { title: '数量', key: 'count', width: 80, align: 'center' },
                                {
                                    title: '',
                                    key: '',
                                    width: 250,
                                    align: 'center',
                                    render: (h, p) => {
                                        return h('div', {
                                            domProps: {
                                                innerHTML:
                                                    item.scanCodeType == 53
                                                        ? ''
                                                        : `<div>（已入库<span class="alreadyCount">${p.row.alreadyCount}</span>条/未入库<span class="noCount">${p.row.noCount}</span>条）</div>`,
                                            },
                                        });
                                    },
                                },
                                {
                                    title: '',
                                    key: '',
                                    align: 'left',
                                    render: (h, p) => {
                                        let $cods = p.row.alreadyTyreCodeDetails.map((item) => {
                                            return `<span class="${item.isAbnormal ? 'noCount' : ''} ? ''">${
                                                item.tyreCode
                                            }</span>`;
                                        });
                                        return h('div', {
                                            domProps: {
                                                innerHTML: $cods,
                                            },
                                            style: {
                                                'white-space': 'no-wrap',
                                                'over-flow': 'hidden',
                                            },
                                            class: 'code-list',
                                        });
                                    },
                                },
                            ],
                        };
                        return {
                            id: item.id,
                            sn: item.sn,
                            scanCodeDate: item.scanCodeDate,
                            scanCodeType: item.scanCodeType,
                            totalItemQuantity: item.totalItemQuantity,
                            scanCodeOperateName: item.scanCodeOperateName,
                            scanCodeTypeMeaning: item.scanCodeTypeMeaning,
                            storeId: item.storeId,
                            storeName: item.storeName || '-',
                            storeOwnerName: item.storeOwnerName || '-',
                            storeOwnerPhone: item.storeOwnerPhone || '-',
                            list: list,
                        };
                    });
                })
                .catch(() => {
                    this.list.loading = false;
                });
        },
        onSubmit(param) {
            this.list.current = 1;
            this.getData(param);
        },
        onPageChange(val) {
            this.list.current = val;
            let form = this.$refs['table-form'].getFormData();
            this.getData(form);
        },
        onPageSizeChange(val) {
            this.list.current = 1;
            this.list.size = val;
            let form = this.$refs['table-form'].getFormData();
            this.getData(form);
        },
        onExport() {
            this.list.loading = true;
            this.isExporting = true;
            let form = this.$refs['table-form'].getFormData();
            let params = {
                current: this.list.current,
                size: this.list.size,
                ...form,
            };
            scanListExport(params)
                .then((res) => {
                    let aLink = document.createElement('a');
                    aLink.href = res || '';
                    // console.log('href ===', res)
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    this.$Notice.success({
                        title: '导出成功',
                    });
                    this.list.loading = false;
                    this.isExporting = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.$Notice.error({
                        title: '导出失败 ！',
                    });
                    this.list.loading = false;
                    this.isExporting = false;
                });
        },
    },
};
</script>
<style lang="less">
.alreadyCount {
    color: green;
}
.noCount {
    color: red;
}
.code-list {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.scan-table-list {
    /deep/ .ivu-table table {
        background: #fff;
    }
}
</style>
