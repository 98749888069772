<template>
    <div class="store-sign-list">
        <PageHeader title="门店店检年度统计" hidden-breadcrumb />
        <Card :bordered="false" dis-hover class="ivu-mt">
            <Tabs :value="currentTab" :animated="true" @on-click="handleTabClick" style="margin-top: -12px">
                <TabPane label="2024年" name="2024">
                    <table-form
                        :formList="formList"
                        @on-submit="onSubmit"
                        @on-export="exportData"
                        ref="table-form-2024"
                    >
                        <Button class="ivu-ml-8" slot="extraBtn" type="error">导出</Button>
                    </table-form>
                    <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list" />
                </TabPane>
                <TabPane label="2023年" name="2023">
                    <table-form
                        :formList="formList"
                        @on-submit="onSubmit"
                        @on-export="exportData"
                        ref="table-form-2023"
                    >
                        <Button class="ivu-ml-8" slot="extraBtn" type="error">导出</Button>
                    </table-form>
                    <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list" />
                </TabPane>
                <TabPane label="2022年" name="2022">
                    <table-form
                        :formList="formList"
                        @on-submit="onSubmit"
                        @on-export="exportData"
                        ref="table-form-2022"
                    >
                        <Button class="ivu-ml-8" slot="extraBtn" type="error">导出</Button>
                    </table-form>
                    <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list" />
                </TabPane>
                <TabPane label="2021年" name="2021">
                    <table-form
                        :formList="formList"
                        @on-submit="onSubmit"
                        @on-export="exportData"
                        ref="table-form-2021"
                    >
                        <Button class="ivu-ml-8" slot="extraBtn" type="error">导出</Button>
                    </table-form>
                    <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list2021" />
                </TabPane>
            </Tabs>
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { clearEmpty } from '@/libs/util';
import {
    storeInspectionYear,
    storeInspectionYearExport,
    storeAssessmentStatisticsYear,
    storeAssessmentStatisticsYearExport,
} from '@/api/statistics';

let columns = [
    {
        title: '门店名称',
        key: 'storeName',
        align: 'center',
        minWidth: 220,
        fixed: 'left',
    },
    {
        title: '签约档次',
        key: 'signLevel',
        align: 'center',
        minWidth: 130,
    },
    {
        title: '联系人',
        key: 'ownerName',
        align: 'center',
        minWidth: 130,
    },
    {
        title: '联系方式',
        key: 'ownerPhone',
        align: 'center',
        minWidth: 130,
    },
];

export default {
    components: {
        tableForm,
        tablePage,
    },
    data() {
        return {
            currentTab: '2024',
            activeImg: '',
            visible: false,
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [],
                data: [],
            },
            list2021: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [],
                data: [],
            },
            formList: [
                {
                    title: '门店',
                    type: 'input',
                    key: 'keyword',
                    placeholder: '请输入门店名称/联系人/联系方式',
                },
            ],
        };
    },
    computed: {
        requestType() {
            const arr = {
                2024: {
                    listCmd: storeInspectionYear,
                    exportCmd: storeInspectionYearExport,
                    listName: 'list',
                    form: 'table-form-2024',
                    year: '2024',
                },
                2023: {
                    listCmd: storeInspectionYear,
                    exportCmd: storeInspectionYearExport,
                    listName: 'list',
                    form: 'table-form-2023',
                    year: '2023',
                },
                2022: {
                    listCmd: storeInspectionYear,
                    exportCmd: storeInspectionYearExport,
                    listName: 'list',
                    form: 'table-form-2022',
                    year: '2022',
                },
                2021: {
                    listCmd: storeAssessmentStatisticsYear,
                    exportCmd: storeAssessmentStatisticsYearExport,
                    listName: 'list2021',
                    form: 'table-form-2021',
                    year: '2021',
                },
            };
            return arr[this.currentTab];
        },
    },
    activated() {
        let form = this.$refs[this.requestType.form].getFormData();
        this.getData(form);
    },
    mounted() {
        this.$bus.$on('getData', () => {
            this.getData();
        });
    },
    methods: {
        // 切换tab
        handleTabClick(val) {
            if (val === this.currentTab) {
                return false;
            }
            this.currentTab = val;
            this.onPageChange(1);
        },
        // 获取-列表
        getData(p) {
            this[this.requestType.listName].loading = true;
            p = clearEmpty(p);
            if (this.currentTab !== 2021) {
                p.storeInfo = p['keyword'];
                delete p['keyword'];
            }
            let params = {
                current: this[this.requestType.listName].current,
                size: this[this.requestType.listName].size,
                year: this.requestType.year,
                ...p,
            };
            this.requestType
                .listCmd(params)
                .then((res) => {
                    this[this.requestType.listName].columns = columns;
                    this[this.requestType.listName].total = Number(res.total);

                    if (this.currentTab !== 2021) {
                        // 2022、2023、2024
                        this[this.requestType.listName].data = res.records;
                        this[this.requestType.listName].columns = [
                            ...this[this.requestType.listName].columns,
                            ...[
                                {
                                    title: 'Q1',
                                    key: 'q1',
                                    align: 'center',
                                    minWidth: 120,
                                    render: (h, p) => {
                                        return [p.row.q1 || '--'];
                                    },
                                },
                                {
                                    title: 'Q2',
                                    key: 'q2',
                                    align: 'center',
                                    minWidth: 100,
                                    render: (h, p) => {
                                        return [p.row.q2 || '--'];
                                    },
                                },
                                {
                                    title: 'Q3',
                                    key: 'q3',
                                    align: 'center',
                                    minWidth: 80,
                                    render: (h, p) => {
                                        return [p.row.q3 || '--'];
                                    },
                                },
                                {
                                    title: 'Q4',
                                    key: 'q4',
                                    align: 'center',
                                    minWidth: 100,
                                    render: (h, p) => {
                                        return [p.row.q4 || '--'];
                                    },
                                },
                            ],
                        ];
                        for (let i = 1; i <= 12; i++) {
                            // 表头渲染
                            this[this.requestType.listName].columns = [
                                ...this[this.requestType.listName].columns,
                                ...[
                                    {
                                        title: `${i}月`,
                                        key: `m${i}`,
                                        align: 'center',
                                        width: 90,
                                        minWidth: 90,
                                        render: (h, p) => {
                                            return [p.row[`m${i}`] || '--'];
                                        },
                                    },
                                ],
                            ];
                        }
                    } else {
                        // 2021
                        this[this.requestType.listName].data = res.records.map((item) => {
                            let obj = {
                                ...item,
                            };
                            item.itemDTOS.map((month) => {
                                let key = `score${month.month}`;
                                obj = {
                                    [key]: month.score || '--',
                                    ...obj,
                                };
                            });
                            return {
                                ...obj,
                            };
                        });
                        let mouthDays = res.records[0].itemDTOS.length;
                        for (let i = 1; i <= mouthDays; i++) {
                            // 表头渲染
                            this[this.requestType.listName].columns = [
                                ...this[this.requestType.listName].columns,
                                ...[
                                    {
                                        title: `${i}月`,
                                        key: `score${i}`,
                                        align: 'center',
                                        width: 90,
                                        minWidth: 90,
                                    },
                                ],
                            ];
                        }

                        // 合计
                        this[this.requestType.listName].columns = [
                            ...this[this.requestType.listName].columns,
                            ...[
                                {
                                    title: '月度店检总分',
                                    key: 'monthTotalScore',
                                    align: 'center',
                                    minWidth: 120,
                                },
                                {
                                    title: '附加分',
                                    key: 'propagandaTotalScore',
                                    align: 'center',
                                    minWidth: 100,
                                },
                                {
                                    title: '扣分',
                                    key: 'deductionTotalScore',
                                    align: 'center',
                                    minWidth: 80,
                                },
                                {
                                    title: '年度总分',
                                    key: 'yearTotalScore',
                                    align: 'center',
                                    minWidth: 100,
                                },
                            ],
                        ];
                    }

                    this.isChangePage = false;
                    this[this.requestType.listName].loading = false;
                })
                .catch(() => {
                    this.isChangePage = false;
                    this[this.requestType.listName].loading = false;
                });
        },
        // 导出
        exportData(searchParams) {
            let p = clearEmpty(searchParams);
            if (this.currentTab !== 2021) {
                p.storeInfo = p['keyword'];
                delete p['keyword'];
            }
            let params = {
                ...p,
                year: this.requestType.year,
            };
            this.$Message.loading({
                content: '导出中...',
                duration: 0,
            });
            this.requestType.exportCmd(params).then(
                (res) => {
                    this.$Message.destroy();
                    this[this.requestType.listName].loading = false;
                    this.isExporting = false;
                    if (!res) {
                        this.$Message.warning('暂无数据');
                        return false;
                    }
                    let aLink = document.createElement('a');
                    aLink.href = res || '';
                    // console.log('href ===', res)
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    this.$Notice.success({
                        title: '导出成功',
                    });
                },
                () => {
                    this.$Message.destroy();
                },
            );
        },
        // 查询
        onSubmit(params) {
            this[this.requestType.listName].current = 1;
            this.getData(params);
        },
        // change-分页
        onPageChange(val) {
            if (this.isChangePage) return;
            this[this.requestType.listName].current = val;
            let form = this.$refs[this.requestType.form].getFormData();
            this.getData(form);
        },
        // change-页码
        onPageSizeChange(val) {
            this[this.requestType.listName].current = 1;
            this.isChangePage = true;
            this[this.requestType.listName].size = val;
            let form = this.$refs[this.requestType.form].getFormData();
            this.getData(form);
        },
    },
};
</script>

<style lang="less">
.store-sign-list {
    .sign-status {
        word-break: break-word;
        white-space: pre-line;
        color: unset;
    }

    .processing {
        color: #2d8cf0;
    }

    .success {
        color: #19be6b;
    }

    .warning {
        color: #f90;
    }

    .error {
        color: #ed4014;
    }

    .ivu-avatar {
        cursor: pointer;
    }
}
</style>
