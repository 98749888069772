<template>
    <div v-loading="list.loading">
        <PageHeader title="侠系列签约返利明细" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage"><Icon type="md-arrow-back" /></div>
        </PageHeader>
        <div class="" style="padding: 10px 0 0 32px">门店名称：{{ row.storeName }}</div>

        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" @on-submit="onSubmit" @on-export="exportInfo" ref="table-form">
                <Button class="ivu-ml-8" :loading="isExporting" :disabled="isExporting" slot="extraBtn" type="error"
                    >导出</Button
                >
            </table-form>
            <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list" />
        </Card>
    </div>
</template>
<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { clearEmpty, jumpPage, transPathToName } from '@/libs/util';
import { xiaRebateDetail, xiaRebateDetailExport } from '@/api/statistics';

export default {
    name: 'swordsmanSignRebate-detail',
    components: {
        tableForm,
        tablePage,
    },
    data() {
        return {
            isExporting: false,
            row: {},
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    {
                        title: '商品名称',
                        fixed: 'left',
                        key: 'productName',
                        align: 'center',
                        minWidth: 180,
                    },
                    {
                        title: '胎号',
                        key: 'tyreCode',
                        align: 'center',
                        minWidth: 100,
                    },
                    {
                        title: '条码',
                        key: 'barcode',
                        align: 'center',
                        minWidth: 100,
                    },
                    {
                        title: '侠系列签约返利',
                        key: 'storeRebate',
                        align: 'center',
                        minWidth: 140,
                    },
                    {
                        title: '扫码时间',
                        key: 'scanTime',
                        align: 'center',
                        minWidth: 180,
                    },
                    {
                        title: '备注',
                        key: 'remark',
                        align: 'center',
                        minWidth: 180,
                    },
                ],
                data: [],
            },
            formList: [
                {
                    title: '商品名称',
                    type: 'input',
                    key: 'productName',
                },
                {
                    title: '胎号',
                    type: 'input',
                    key: 'tyreCode',
                },
                {
                    title: '条码',
                    type: 'input',
                    key: 'barcode',
                },
            ],
            total: {},
        };
    },
    mounted() {
        let stringRow = this.$route.query.row || {};
        this.row = JSON.parse(stringRow);
        this.getData();
    },
    methods: {
        exportInfo() {
            this.exportData();
        },
        exportData() {
            if (this.isExporting) {
                return false;
            }
            let p = this.$refs['table-form'].getFormData();
            let params = clearEmpty({
                ...p,
            });
            let { signBrandId: brandId, distributorId, storeId, verifyMonth } = this.row;
            let urlParams = {
                brandId,
                distributorId,
                storeId,
                verifyMonth,
            };
            params = { ...params, ...urlParams };
            this.$Message.loading({
                content: '导出中...',
                duration: 0,
            });
            this.isExporting = true;
            // delete params.provinceId
            xiaRebateDetailExport(params).then(
                (res) => {
                    this.$Message.destroy();
                    this.list.loading = false;
                    this.isExporting = false;
                    if (!res) {
                        this.$Message.warning('暂无数据');
                        return false;
                    }
                    let aLink = document.createElement('a');
                    aLink.href = res || '';
                    // console.log('href ===', res)
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    this.$Notice.success({
                        title: '导出成功',
                    });
                },
                () => {
                    this.isExporting = false;
                    this.$Message.destroy();
                },
            );
        },
        onPageChange(val) {
            if (this.isChangePage) return;
            this.list.current = val;
            this.getData();
        },
        onPageSizeChange(val) {
            this.list.current = 1;
            this.isChangePage = true;
            this.list.size = val;
            this.getData();
        },
        getData() {
            this.list.loading = true;
            let p = this.$refs['table-form'].getFormData();

            let param = clearEmpty({
                current: this.list.current,
                size: this.list.size,
                ...p,
            });
            let { signBrandId: brandId, distributorId, storeId, verifyMonth } = this.row;
            let urlParams = {
                brandId,
                distributorId,
                storeId,
                verifyMonth,
            };
            param = { ...param, ...urlParams };
            console.log(param);
            xiaRebateDetail(param)
                .then((res) => {
                    this.list.loading = false;
                    this.list.total = Number(res.total);
                    this.list.data = (res.list || []).map((item) => {
                        for (let key in item) {
                            if (item[key] == null) {
                                item[key] = '-';
                            }
                        }
                        return {
                            ...item,
                        };
                    });
                })
                .catch(() => {
                    this.list.loading = false;
                });
        },
        onSubmit() {
            this.storeId = '';
            this.list.current = 1;
            this.getData();
        },
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/statistics/swordsmanSignRebate/list' });
        },
    },
};
</script>
