<!-- eslint-disable vue/no-mutating-props -->
<!--
 * @Author: chengleilei
 * @Date: 2021-09-30 13:55:13
 * @Description: 企业基本信息组件
-->
<template>
    <Card
        :title="isEnterprise ? '企业基本信息' : '个体工商户基本信息'"
        :bordered="false"
        dis-hover
        class="ivu-mt i-table-no-border"
    >
        <Form
            hide-required-mark
            :model="businessBaseForm"
            ref="businessBaseForm"
            :rules="isEnterprise ? enterpriseRules : selfRules"
            :label-width="180"
        >
            <ElForm
                hide-required-asterisk
                :model="businessBaseForm"
                ref="formDataEl"
                label-width="180px"
                :rules="isEnterprise ? enterpriseRules : selfRules"
            >
                <FormItem label="企业名称：" prop="businessName" v-if="isEnterprise">
                    <Input v-model="businessBaseForm.businessName" placeholder="请输入企业名称" disabled></Input>
                </FormItem>
                <FormItem label="个体工商注册号码：" prop="businessLicenseNumber" v-else>
                    <Input
                        v-model="businessBaseForm.businessLicenseNumber"
                        maxlength="18"
                        placeholder="请输入统一社会信用代码或营业执照注册号"
                    ></Input>
                </FormItem>
                <FormItem label="企业对公账号：" prop="bankAccountNumber" v-if="isEnterprise">
                    <Input
                        v-model="businessBaseForm.bankAccountNumber"
                        placeholder="请输入企业对公账号"
                        disabled
                    ></Input>
                </FormItem>
                <FormItem label="统一社会信用代码：" prop="businessLicenseNumber" v-if="isEnterprise">
                    <Input
                        v-model="businessBaseForm.businessLicenseNumber"
                        maxlength="18"
                        placeholder="请输入统一社会信用代码/组织机构代码"
                    ></Input>
                </FormItem>
                <FormItem label="营业执照名称：" prop="businessName" v-else>
                    <Input
                        v-model="businessBaseForm.businessName"
                        maxlength="40"
                        placeholder="如名称为空，填写经营者姓名"
                    ></Input>
                </FormItem>
                <FormItem label="证件有效期：">
                    <DatePicker
                        style="width: 200px"
                        v-model="businessBaseForm.businessStartTime"
                        type="date"
                        placeholder="请选择开始日期"
                    ></DatePicker>
                    <span class="txt">至</span>
                    <DatePicker
                        style="width: 200px"
                        v-model="businessBaseForm.businessEndtime"
                        :disabled="isLongBusiness"
                        type="date"
                        placeholder="请选择结束日期"
                    ></DatePicker>
                    <Checkbox class="txt" v-model="isLongBusiness">长期有效</Checkbox>
                </FormItem>
                <FormItem label="注册资本（元）：" prop="money" v-if="isEnterprise">
                    <Input v-model="businessBaseForm.money" maxlength="15" placeholder="请输入注册资本"></Input>
                </FormItem>
                <ElFormItem label="注册省份：" prop="businessProvince">
                    <ElSelect
                        v-model="businessBaseForm.businessProvince"
                        @change="provinceChange"
                        :loading="provinceLoading"
                        filterable
                        clearable
                        style="width: 100%"
                    >
                        <ElOption
                            :value="item.value"
                            :label="item.label"
                            v-for="item in provinceList"
                            :key="item.value"
                        >
                            {{ item.label }}
                        </ElOption>
                    </ElSelect>
                </ElFormItem>
                <ElFormItem label="注册城市：" prop="businessCity">
                    <ElSelect
                        v-model="businessBaseForm.businessCity"
                        @change="cityChange"
                        :loading="cityLoading"
                        filterable
                        clearable
                        style="width: 100%"
                    >
                        <ElOption :value="item.value" :label="item.label" v-for="item in cityList" :key="item.value">
                            {{ item.label }}
                        </ElOption>
                    </ElSelect>
                </ElFormItem>
                <ElFormItem label="注册区县：" prop="businessDistrict">
                    <ElSelect
                        v-model="businessBaseForm.businessDistrict"
                        :loading="districtLoading"
                        filterable
                        clearable
                        style="width: 100%"
                    >
                        <ElOption
                            :value="item.value"
                            :label="item.label"
                            v-for="item in districtList"
                            :key="item.value"
                        >
                            {{ item.label }}
                        </ElOption>
                    </ElSelect>
                </ElFormItem>
                <FormItem label="详细地址：" prop="businessDetailAddress">
                    <Input
                        v-model="businessBaseForm.businessDetailAddress"
                        maxlength="50"
                        placeholder="请输入详细地址"
                    ></Input>
                </FormItem>
                <FormItem label="企业邮箱：" prop="businessEmail" v-if="isEnterprise">
                    <Input v-model="businessBaseForm.businessEmail" maxlength="50" placeholder="请输入企业邮箱"></Input>
                </FormItem>
            </ElForm>
        </Form>
    </Card>
</template>
<script>
import { provinceList, cpcnCityList, cpcnDistrictList } from '@/api/dealerAccount';
import { Form, FormItem, Select, Option } from 'element-ui';
export default {
    props: {
        // true企业 false个体户
        isEnterprise: {
            type: Boolean,
            default: true,
        },
        businessBaseForm: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        const validatePrice = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入注册资本'));
            } else if (!/(^[0-9]+(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(value)) {
                callback(new Error('仅输入数字和小数点，可精确到小数点后2位'));
            } else {
                callback();
            }
        };
        const emailCheck = (rule, value, callback) => {
            const reg =
                /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
            if (!value) {
                callback(new Error(`请输入企业邮箱`));
            } else if (!reg.test(value.trim())) {
                callback(new Error(`邮箱格式不正确`));
            } else {
                callback();
            }
        };
        return {
            provinceLoading: false,
            cityLoading: false,
            districtLoading: false,
            isLongBusiness: false, // 是否长期有效-企业证件
            provinceList: [], // 省份列表
            cityList: [], // 城市列表
            districtList: [], // 区县列表
            // 企业基本信息校验规则
            enterpriseRules: {
                businessLicenseNumber: [
                    {
                        required: true,
                        message: '请输入统一社会信用代码',
                        trigger: 'blur',
                    },
                ],
                money: [{ validator: validatePrice, trigger: 'blur' }],
                businessProvince: [
                    {
                        required: true,
                        message: '请选择注册省份',
                        trigger: 'change',
                    },
                ],
                businessCity: [
                    {
                        required: true,
                        message: '请选择注册城市',
                        trigger: 'change',
                    },
                ],
                businessDetailAddress: [
                    {
                        required: true,
                        message: '请输入详细地址',
                        trigger: 'blur',
                    },
                ],
                businessEmail: [{ validator: emailCheck, trigger: 'blur' }],
            },
            // 个体户基本信息校验规则
            selfRules: {
                businessLicenseNumber: [
                    {
                        required: true,
                        message: '请输入个体工商注册号码',
                        trigger: 'blur',
                    },
                ],
                businessName: [
                    {
                        required: true,
                        message: '请输入营业执照名称',
                        trigger: 'blur',
                    },
                ],
                businessProvince: [
                    {
                        required: true,
                        message: '请选择注册省份',
                        trigger: 'change',
                    },
                ],
                businessCity: [
                    {
                        required: true,
                        message: '请选择注册城市',
                        trigger: 'change',
                    },
                ],
                businessDetailAddress: [
                    {
                        required: true,
                        message: '请输入详细地址',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },
    components: {
        ElForm: Form,
        ElFormItem: FormItem,
        ElSelect: Select,
        ElOption: Option,
    },
    watch: {
        isLongBusiness(to) {
            if (to) {
                // eslint-disable-next-line vue/no-mutating-props
                this.businessBaseForm.businessEndtime = new Date('2099-12-31');
            }
        },
    },
    created() {
        this.getProvinces();
    },
    methods: {
        /**
         * @description 查省份列表
         */
        getProvinces() {
            this.provinceLoading = true;
            provinceList().then((res) => {
                this.provinceLoading = false;
                const list = [];
                res.map((k) => {
                    list.push({
                        value: k.code,
                        label: k.province,
                    });
                });
                this.provinceList = list;
            });
        },

        /**
         * @description 查城市列表
         */
        getCitys(code) {
            return new Promise((resolve, reject) => {
                cpcnCityList({ provinceCode: code })
                    .then((res) => {
                        resolve(res);
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },

        /**
         * @description 查区县列表
         */
        getDistricts(code) {
            return new Promise((resolve, reject) => {
                cpcnDistrictList({ cityCode: code })
                    .then((res) => {
                        resolve(res);
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },

        /**
         * @description 省份改变时
         */
        provinceChange(code) {
            if (code) {
                this.cityList = [];
                this.districtList = [];
                this.getCityListByCode(code, true);
            }
        },

        /**
         * @description 城市改变时
         */
        cityChange(code) {
            if (code) {
                const cityCode = this.cityList.find((item) => item.value == code).cityCode;
                this.getDistrictListByCode(cityCode, true);
            }
        },

        /**
         * @description 通过省份查找市的列表
         */
        getCityListByCode(code, clear) {
            return new Promise((resolve, reject) => {
                if (clear) {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.businessBaseForm.businessCity = '';
                    // eslint-disable-next-line vue/no-mutating-props
                    this.businessBaseForm.businessDistrict = '';
                }
                this.cityLoading = true;
                this.getCitys(code)
                    .then((res) => {
                        this.cityList = this.reduceCityList(res);
                        this.cityLoading = false;
                        resolve();
                    })
                    .catch(() => {
                        this.cityLoading = false;
                        reject();
                    });
            });
        },

        /**
         * @description 通过城市查找区的列表
         */
        getDistrictListByCode(code, clear) {
            return new Promise((resolve, reject) => {
                if (clear) {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.businessBaseForm.businessDistrict = '';
                }
                this.districtLoading = true;
                this.getDistricts(code)
                    .then((res) => {
                        this.districtList = this.reduceCityList(res);
                        this.districtLoading = false;
                        resolve();
                    })
                    .catch(() => {
                        this.districtLoading = false;
                        reject();
                    });
            });
        },

        /**
         * @description 重组城市区县数据
         * @param arr 城市/区县列表
         */
        reduceCityList(arr) {
            arr.map((k) => {
                k.label = k.city;
                k.value = k.code;
            });
            return arr;
        },
    },
};
</script>
<style lang="less" scoped>
.txt {
    margin: 0 8px;
}
</style>
