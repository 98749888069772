import Vue from 'vue';
import cookies from './util.cookies';
import Cookies from 'js-cookie';
import log from './util.log';

import { Button, Poptip } from 'view-design';

const util = {
    cookies,
    log,
};
window.$pageSize = 10;

/**
 * @param {function} h 渲染函数
 * @param { disabled, type, text, confirm  } // 是否禁用，按钮类型，按钮文本，删除确认
 * @param { ()=>{} }fn  // 点击触发的回调函数，
 */
const renderButton = function (h, params, fn) {
    let cb = () => {
        fn && fn();
    };
    let btn = (cb) => {
        return (
            <Button
                size="small"
                class="ivu-mr-8"
                disabled={params.disabled}
                onClick={cb}
                type={params.type ? params.type : 'default'}
            >
                {params.text}
            </Button>
        );
    };
    let btnWithCfm = (cb) => {
        return (
            <Poptip confirm transfer title={params.confirmText ? params.confirmText : '确定删除吗?'} onOn-ok={cb}>
                {btn(() => {})}
            </Poptip>
        );
    };
    if (params.confirm) {
        return btnWithCfm(cb);
    }
    return btn(cb);
};

const downloadFile = (url, title = '导出成功') => {
    if (!url) {
        Vue.prototype.$Notice.warning({
            title: '暂无文件',
        });
        return false;
    }
    let aLink = document.createElement('a');
    aLink.href = url;
    document.body.appendChild(aLink);
    aLink.click();
    document.body.removeChild(aLink);
    Vue.prototype.$Notice.success({
        title,
    });
};

const clearEmpty = (obj, isClearUndefined = false) => {
    let param = {
        ...obj,
    };
    for (let key in param) {
        if (param[key] === '' || param[key] === null) {
            delete param[key];
        }
        if (isClearUndefined && param[key] === undefined) {
            delete param[key];
        }
    }
    return param;
};
const getQuarterByMonth = () => {
    let today = new Date(); //获取当前时间
    let month = today.getMonth() + 1; //getMonth返回0-11
    //下面是几个if
    if (month >= 1 && month <= 3) {
        return 1;
    } else if (month >= 4 && month <= 6) {
        return 2;
    } else if (month >= 7 && month <= 9) {
        return 3;
    } else {
        return 4;
    }
};

/** 正则相关 S**/
// 身份证
const CARD_REG = /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
// 姓名正则
const NAME_REG = /^[\u4E00-\u9FA5A-Za-z\s]+(·[\u4E00-\u9FA5A-Za-z]+)*$/;
// 手机正则
const MOBILE_REG = /^[1]\d{10}$/;

// 验证码正则
const CODE_REG = /^\d{6}$/;

// 银行卡正则
const BANK_CARD = /^([0-9])(\d{3,35})$/;

// 社会信用代码震泽
const DIS_NUMBER_REG = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/;
/** 正则相关 E**/

/**
 * 跳转页面
 * @param route 跳转的路由对象 path-路由，title-标题，params-参数，applyName-应用名称, routeType-路由类型(replaceState/pushState)
 */
const jumpPage = (route) => {
    const { path = '', title = '', params = null, applyName = 'sub-vue2', routeType = 'pushState' } = route;
    const _paramsStr =
        params &&
        Object.keys(params)
            .map((key) => {
                if (Array.isArray(params[key])) {
                    let list = params[key].map((item) => {
                        return `${key}=${encodeURI(item)}`;
                    });
                    return list.join('&');
                } else {
                    return `${key}=${encodeURI(params[key])}`;
                }
            })
            .join('&');
    let _path = `${path}`;
    if (_paramsStr) {
        _path = `${_path}?${_paramsStr}`;
    }
    window.history[routeType](null, title, _path);
    if (applyName === 'sub-vue2' && Cookies.get('apply-name') != 'sub-vue2') {
        location.reload();
    }
    Cookies.set('apply-name', applyName);
};

/**
 * 获取url参数
 * @param url-页面地址
 */
const getUrlParams = (url = '') => {
    if (url?.indexOf('?') < 0) {
        return {};
    }
    return transParams(joinParams(url));
    function joinParams(url) {
        const _obj = {};
        url?.split('?')[1]
            .split('&')
            .forEach((item) => {
                let _key = item?.split('=')[0];
                let _val = decodeURI(item?.split('=')[1]);
                if (_obj[_key]) {
                    _obj[_key].push(_val);
                } else {
                    _obj[_key] = [_val];
                }
            });
        return _obj;
    }
    function transParams(obj) {
        for (let key in obj) {
            if (obj[key].length === 1) {
                obj[key] = obj[key][0];
            }
        }
        return obj;
    }
};

/**
 * 转换路由的path为name
 * @param url的path
 */
const transPathToName = (path) => {
    return path.substring(1).replace(new RegExp('/', 'g'), '-');
};

export {
    renderButton,
    downloadFile,
    clearEmpty,
    getQuarterByMonth,
    CARD_REG,
    NAME_REG,
    MOBILE_REG,
    CODE_REG,
    BANK_CARD,
    DIS_NUMBER_REG,
    jumpPage,
    getUrlParams,
    transPathToName,
};
export default util;
