<!--
 * @Name: demoImg.vue
 * @Author: forguo
 * @Date: 2021/9/30 15:37
 * @Description: demoImg
-->
<template>
    <div class="demo-img"
         :style="style"
    >
        <div class="img-list">
            <div v-for="(src, idx) in list" :key="src" class="img-item">
                <div class="img-item-img img-preview" :style="{backgroundImage: 'url(' + src + ')'}" v-if="src">
                    <img :src="src" :preview="src" preview-text='示例图' />
                </div>
                <p class="img-item-text" v-show="(multiple && idx !== list.length - 1)">或</p>
            </div>
        </div>

        <div class="img-tip">示例图</div>

    </div>
</template>

<script>
    export default {
        name: "demoImg",
        props: {
            multiple: {
                type: Boolean,
                default: false,
            },
            style: {
                default: 'width: 300px;height: 204px;',
            },
            list: {
                type: Array,
                default: () => {
                    return []
                },
            },
        },

    }
</script>

<style lang="less" scoped>
.demo-img {
    padding: 10px 16px 0px;
    box-sizing: border-box;
    border: 1px dashed #cecece;
    border-radius: 4px;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    .img-list {
        flex: 1;
        width: 100%;
        height: calc(100% - 22px);
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        .img-item {
            flex: 1;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            justify-content: space-between;
            &-img {
                flex: 1;
                width: 100%;
                height: 100%;
                border-radius: 4px;
                cursor: pointer;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }
            &-text {
                text-align: center;
                margin: 0 5px;
            }
        }
    }

    .img-tip {
        width: 100%;
        text-align: center;
        margin-top: 4px;
    }
}
</style>
