<template>
    <Select v-model="currentValue" v-width='"100%"' clearable @on-change='onChange'>
        <Option v-for="item in list" :value="item.value" :key="item.value">{{ item.label }}</Option>
    </Select>
</template>
<script>
    export default {
        data () {
            return {
                list: [
                    {
                        value: '1',
                        label: '1月'
                    },
                    {
                        value: '2',
                        label: '2月'
                    },
                    {
                        value: '3',
                        label: '3月'
                    },
                    {
                        value: '4',
                        label: '4月'
                    },
                    {
                        value: '5',
                        label: '5月'
                    },
                    {
                        value: '6',
                        label: '6月'
                    },
                    {
                        value: '7',
                        label: '7月'
                    },
                    {
                        value: '8',
                        label: '8月'
                    },
                    {
                        value: '9',
                        label: '9月'
                    },
                    {
                        value: '10',
                        label: '10月'
                    },
                    {
                        value: '11',
                        label: '11月'
                    },
                    {
                        value: '12',
                        label: '12月'
                    }
                ]
            }
        },
        computed: {
            currentValue: {
                get () {
                    return this.value
                },
                set (v) {
                    this.$emit('input', v)
                }
            }
        },
        mounted () {
        },
        methods: {
            onChange (v, o) {
                this.$emit('on-change', v, o)
            }
        }
    }
</script>
