<template>
    <div v-loading="list.loading">
        <PageHeader title="经销商扫码有效统计" hidden-breadcrumb></PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" @on-submit="onSubmit" ref="table-form" />
            <table-page :list="list" :hasPage="false" />
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { renderButton, clearEmpty, jumpPage } from '@/libs/util';
import { MonthRebateList, MonthRebateListExpand } from '@/api/statistics';

export default {
    name: 'statistics-rebate-list',
    components: {
        tableForm,
        tablePage,
    },
    data() {
        return {
            isExporting: false,
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    {
                        title: '经销商名称',
                        key: 'distributorName',
                        align: 'center',
                        minWidth: 180,
                        fixed: 'left',
                        tree: true,
                    },
                    { title: '扫码时间', key: 'date', align: 'center', minWidth: 180 },
                    { title: '扫码出库次数', key: 'outboundSuccessTimes', align: 'center', minWidth: 120 },
                    { title: '历史出库次数', key: 'hisOutboundTimes', align: 'center', minWidth: 120 },
                    { title: '扫码退货次数', key: 'returnTimes', align: 'center', minWidth: 120 },
                    { title: '异常条数', key: 'failBarcodeNum', align: 'center', minWidth: 110 },
                    { title: '有效次数', key: 'effectiveTimes', align: 'center', minWidth: 100 },
                    { title: '朝系有效次数', key: 'seriesSuccessNum', align: 'center', minWidth: 120 },
                    { title: '朝系19.5及以上', key: 'aboveEffectiveTimes', align: 'center', minWidth: 140 },
                    { title: '朝系17.5及以下', key: 'cxBelowNumber', align: 'center', minWidth: 140 },
                    { title: '挂车胎有效次数', key: 'trailerEffectiveNumber', align: 'center', minWidth: 140 },
                    { title: '金系有效次数', key: 'jinxiEffectiveTimes', align: 'center', minWidth: 120 },
                    { title: '欧系有效次数', key: 'ouxiEffectiveTimes', align: 'center', minWidth: 120 },
                    {
                        title: '操作',
                        fixed: 'right',
                        key: 'oprate',
                        width: 200,
                        align: 'center',
                        render: (h, p) => {
                            if (p.row.children) {
                                return [
                                    renderButton(h, { text: '详情', type: 'primary' }, () => {
                                        this.toDetail(p.row);
                                    }),
                                ];
                            } else {
                                return null;
                            }
                        },
                    },
                ],
                data: [],
                handleLoadData: this.handleLoadData,
            },
            formList: [
                {
                    title: '时间',
                    type: 'month',
                    key: 'date',
                },
            ],
        };
    },

    activated() {
        this.getData();
    },
    mounted() {},
    methods: {
        getNowMonthTime() {
            let date = new Date();
            let y = date.getFullYear();
            let m = date.getMonth() + 1;
            return y + '-' + m + '-01 00:00:00';
        },
        toDetail(row) {
            jumpPage({ path: '/sub-vue2/statistics/rebate/detail', params: { date: row.date } });
        },
        getData(p) {
            this.list.loading = true;
            p = clearEmpty(p);
            let date = p.date ? p.date + '-01 00:00:00' : this.getNowMonthTime();
            const param = {
                current: this.list.current,
                size: this.list.size,
                ...p,
                date,
            };
            MonthRebateList(param)
                .then((res) => {
                    this.list.loading = false;
                    this.list.total = Number(res.total);
                    if (!res.date && !res.outboundSuccessTimes && !res.outboundTimes) {
                        this.list.data = [];
                    } else {
                        res._loading = false;
                        res.children = [];
                        this.list.data = [res];
                    }
                })
                .catch(() => {
                    this.list.loading = false;
                });
        },
        handleLoadData(item, callback) {
            let p = this.$refs['table-form'].getFormData();
            let date = p.date ? p.date + '-01 00:00:00' : this.getNowMonthTime();
            const params = {
                current: this.list.current,
                size: this.list.size,
                ...p,
                date,
            };
            MonthRebateListExpand(params).then(
                (res) => {
                    callback(
                        res.map((item) => {
                            return {
                                ...item,
                                date: item.date || '-',
                                distributorName: item.scanCodeOperateName,
                                outboundSuccessTimes: item.outboundSucTimes,
                            };
                        }) || [],
                    );
                },
                () => {
                    callback([]);
                    item._loading = false;
                },
            );
        },
        onSubmit(param) {
            console.log('param :', param);
            this.list.current = 1;
            this.getData(param);
        },
    },
};
</script>
