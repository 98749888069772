import StockList from '@/pages/statistics/stock/index';
import RebateList from '@/pages/statistics/rebate/index';
import RebateDetail from '@/pages/statistics/rebate/detail';
import StoreRebate from '@/pages/statistics/store/scanRebateList';
import StoreRebateDetail from '@/pages/statistics/store/scanRebateDetail';
import StoreIntegralDetail from '@/pages/statistics/store/scanIntegralDetail';
import StoreTask from '@/pages/statistics/store/finishTaskNumDetail';
import GroupList from '@/pages/statistics/group/index';
import GroupDetail from '@/pages/statistics/group/detail';
import CruiseList from '@/pages/statistics/cruise/index';
import CruiseDetail from '@/pages/statistics/cruise/detail';
import CruiseStore from '@/pages/statistics/cruise/store';
import StoreSignList from '@/pages/statistics/storeSign/index';
import StoreSignCheck from '@/pages/statistics/storeSign/check';
import StoreSignCheckCurrent from '@/pages/statistics/storeSign/checkCurrent';
import InspectionList from '@/pages/statistics/storeInspection/index';
import YearInspectionList from '@/pages/statistics/storeYearInspection/index';
import RentSubsidy from '@/pages/statistics/rentSubsidy/index';
import CreditHandingFee from '@/pages/statistics/credit/handingFeeList';
import SwordsmanSignRebate from '@/pages/statistics/swordsmanSignRebate/index';
import SwordsmanSignRebateDetail from '@/pages/statistics/swordsmanSignRebate/detail';
import DealerScanScore from '@/pages/statistics/dealerScanScore/index';
import { applyRoute } from '../const';

const statisticsRoutes = [
    {
        path: `${applyRoute}/statistics/stock/list`,
        meta: {
            auth: ['statistics'],
            title: '扫码库存',
            cache: true,
        },
        component: StockList,
    },
    {
        path: `${applyRoute}/statistics/rebate/list`,
        meta: {
            auth: ['statistics'],
            title: '经销商扫码有效统计',
            cache: true,
        },
        component: RebateList,
    },
    {
        path: `${applyRoute}/statistics/rebate/detail`,
        meta: {
            auth: ['statistics'],
            title: '经销商扫码有效统计详情',
            cache: false,
        },
        component: RebateDetail,
    },
    {
        path: `${applyRoute}/statistics/store/rebate`,
        meta: {
            auth: ['statistics'],
            title: '门店积分返利',
            cache: true,
        },
        component: StoreRebate,
    },
    {
        path: `${applyRoute}/statistics/store/rebateDetail`,
        meta: {
            auth: ['statistics'],
            title: '返利明细',
            cache: false,
        },
        component: StoreRebateDetail,
    },
    {
        path: `${applyRoute}/statistics/store/integralDetail`,
        meta: {
            auth: ['statistics'],
            title: '积分明细',
            cache: false,
        },
        component: StoreIntegralDetail,
    },
    {
        path: `${applyRoute}/statistics/store/task`,
        meta: {
            auth: ['statistics'],
            title: '门店任务完成数详情',
            cache: false,
        },
        component: StoreTask,
    },
    {
        path: `${applyRoute}/statistics/group/list`,
        meta: {
            auth: ['statistics'],
            title: '集团单位发票/发货单统计',
            cache: true,
        },
        component: GroupList,
    },
    {
        path: `${applyRoute}/statistics/group/detail`,
        meta: {
            auth: ['statistics'],
            title: '集团单位发票/发货单统计详情',
            cache: false,
        },
        component: GroupDetail,
    },
    {
        path: `${applyRoute}/statistics/cruise/list`,
        meta: {
            auth: ['statistics'],
            title: '业务员巡店统计表',
            cache: true,
        },
        component: CruiseList,
    },
    {
        path: `${applyRoute}/statistics/cruise/detail`,
        meta: {
            auth: ['statistics'],
            title: '业务员巡店统计表明细',
            cache: false,
        },
        component: CruiseDetail,
    },
    {
        path: `${applyRoute}/statistics/cruise/store`,
        meta: {
            auth: ['statistics'],
            title: '巡店记录',
            cache: true,
        },
        component: CruiseStore,
    },
    {
        path: `${applyRoute}/statistics/storeSign/list`,
        meta: {
            auth: ['statistics'],
            title: '签署门店销售任务量',
            cache: true,
        },
        component: StoreSignList,
    },
    {
        path: `${applyRoute}/statistics/storeSign/check`,
        meta: {
            auth: ['statistics'],
            title: '签署门店销售任务量详情',
            cache: false,
        },
        component: StoreSignCheck,
    },
    {
        path: `${applyRoute}/statistics/storeSign/checkCurrent`,
        meta: {
            auth: ['statistics'],
            title: '签署门店销售任务量详情',
            cache: false,
        },
        component: StoreSignCheckCurrent,
    },
    {
        path: `${applyRoute}/statistics/inspection/list`,
        meta: {
            auth: ['statistics'],
            title: '门店店检统计',
            cache: true,
        },
        component: InspectionList,
    },
    {
        path: `${applyRoute}/statistics/yearInspection/list`,
        meta: {
            auth: ['statistics'],
            title: '门店店检年度统计',
            cache: true,
        },
        component: YearInspectionList,
    },
    {
        path: `${applyRoute}/statistics/rentSubsidy/list`,
        meta: {
            auth: ['statistics'],
            title: '门店新零售补贴',
            cache: true,
        },
        component: RentSubsidy,
    },
    {
        path: `${applyRoute}/statistics/credit/handingFeeList`,
        meta: {
            auth: ['statistics'],
            title: '授信手续收费统计',
            cache: true,
        },
        component: CreditHandingFee,
    },
    {
        path: `${applyRoute}/statistics/swordsmanSignRebate/list`,
        meta: {
            auth: ['statistics'],
            title: '侠系列签约返利',
            cache: true,
        },
        component: SwordsmanSignRebate,
    },
    {
        path: `${applyRoute}/statistics/swordsmanSignRebate/detail`,
        meta: {
            auth: ['statistics'],
            title: '查看侠系列返利明细',
            cache: false,
        },
        component: SwordsmanSignRebateDetail,
    },
    {
        path: `${applyRoute}/statistics/dealerScanScore/index`,
        meta: {
            auth: ['statistics'],
            title: '经销商扫码积分',
            cache: true,
        },
        component: DealerScanScore,
    },
];

export { statisticsRoutes };
