<template>
    <Modal
        :closable="false"
        :mask-closable="false"
        :width="750"
        scrollable
        @on-cancel="onCancel"
        v-model="modal"
        :title="title"
    >
        <Spin fix v-if="loading">加载中...</Spin>
        <h3 class="ivu-mb">{{ first ? '初次使用请设置授权密码' : '修改密码' }}</h3>
        <Alert type="info" class="ivu-mb"> 已向您的手机号（{{ mobile }}）发送验证码，请输入以验证您的身份 </Alert>
        <Form autocomplete="off" ref="modalForm" :model="modalForm" :rules="modalRules" :label-width="130">
            <FormItem prop="code" label="短信验证码：" class="ivu-mt">
                <Row>
                    <Col span="12">
                        <Input
                            v-model="modalForm.code"
                            :maxlength="4"
                            placeholder="请输入短信验证码"
                            name="code"
                            autocomplete="off"
                        ></Input>
                    </Col>
                    <Col span="10" push="1">
                        <Button class="ml-10" type="primary" :disabled="codeDisabled" @click="handleSendCode">{{
                            codeMsg
                        }}</Button>
                    </Col>
                </Row>
            </FormItem>
            <FormItem prop="creditPassword" label="设置密码：">
                <Row>
                    <Col span="12">
                        <Input
                            v-model="modalForm.creditPassword"
                            :maxlength="6"
                            placeholder="请设置6位数字密码"
                            type="password"
                            password
                            autocomplete="new-password"
                        ></Input>
                    </Col>
                </Row>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="text" @click="onCancel">取消</Button>
            <Button
                type="primary"
                :disabled="modalForm.code.length < 4 || modalForm.creditPassword.length < 6 || loading"
                :loading="loading"
                @click="confirmSubmit"
                >确认</Button
            >
        </div>
    </Modal>
</template>
<script>
import { creditCode, creditUpdatePwd } from '@/api/credit';
import { CODE_REG } from '@/libs/util';
export default {
    name: 'modalFilter',
    props: {
        first: {
            default: false, // 是否是初次设置密码，默认为false，用来标记是否是修改密码
            type: Boolean,
        },
    },
    data() {
        return {
            loading: false,
            title: '授权密码设置',
            modal: false,
            type: 'ADD',
            modalForm: {
                code: '',
                creditPassword: '',
            },
            modalRules: {
                code: [{ required: true, message: '请输入验证码', trigger: 'change' }],
                creditPassword: [{ required: true, message: '请输入数字密码', trigger: 'change' }],
            },
            mobile: '',
            codeDisabled: false, // 是否禁用按钮
            countdown: 60, // 倒计时秒数
            codeMsg: '获取验证码', // 按钮上的文字
            timer: null, // 定时器
        };
    },
    computed: {},
    methods: {
        /**
         * @description 开启modal
         */
        open() {
            this.modal = true;
            this.handleSendCode();
        },
        /**
         * 重置表单
         */
        reset() {
            this.$refs['modalForm'].resetFields();
            this.modalForm = {
                code: '',
                creditPassword: '',
            };
            this.codeDisabled = false; // 是否禁用按钮
            this.countdown = 60; // 倒计时秒数
            this.codeMsg = '获取验证码'; // 按钮上的文字
            this.timer && clearInterval(this.timer);
            this.timer = null; // 定时器
            this.modal = false;
        },
        /**
         * 关闭modal
         */
        onCancel() {
            this.reset();
        },
        /**
         * 确认提交
         */
        confirmSubmit() {
            this.$refs['modalForm'].validate((valid) => {
                let form = { ...this.modalForm };
                if (!valid) {
                    return false;
                }
                if (!CODE_REG.test(this.modalForm.creditPassword)) {
                    this.$Modal.error({
                        title: '提示',
                        content: '请输入6位数字密码',
                    });
                    return false;
                }
                this.loading = true;
                creditUpdatePwd({
                    ...form,
                }).then(
                    () => {
                        this.$Message.success('操作成功~');
                        this.loading = false;
                        this.reset();
                        this.$emit('success', this.first);
                    },
                    () => {
                        this.loading = false;
                    },
                );
            });
        },
        /**
         * 发送验证码
         */
        handleSendCode() {
            this.loading = true;
            this.codeDisabled = true;
            this.codeMsg = '发送中...';
            creditCode().then(
                (res) => {
                    this.mobile = res || '';
                    this.$Message.success('验证码发送成功，请注意查收~');
                    this.getCodeCountDown();
                    this.loading = false;
                },
                () => {
                    this.codeDisabled = false;
                    this.codeMsg = '获取验证码';
                    this.loading = false;
                },
            );
        },
        /**
         * @description 获取验证码
         */
        getCodeCountDown() {
            if (!this.timer) {
                this.timer = setInterval(() => {
                    if (this.countdown > 0 && this.countdown <= 60) {
                        this.countdown--;
                        if (this.countdown !== 0) {
                            this.codeDisabled = true;
                            this.codeMsg = `重新获取${this.countdown}s`;
                        } else {
                            clearInterval(this.timer);
                            this.codeDisabled = false;
                            this.codeMsg = '获取验证码';
                            this.countdown = 60;
                            this.timer = null;
                        }
                    }
                }, 1000);
            }
        },
    },
};
</script>

<style lang="less" scoped></style>
