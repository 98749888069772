<template>
    <div v-loading="list.loading">
        <PageHeader title="对账" hidden-breadcrumb></PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" @on-submit="onSubmit" @on-export="exportInfo" ref="table-form">
                <Button class="ivu-ml-8" :loading="isExporting" :disabled="isExporting" slot="extraBtn" type="error"
                    >导出</Button
                >
            </table-form>
            <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list" />
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { reconciliationList, reconciliationExport, accountList } from '@/api/assets';
import { jumpPage } from '@/libs/util';

export default {
    name: 'statistics-rebate-list',
    components: {
        tableForm,
        tablePage,
    },
    data() {
        return {
            isExporting: false,
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    {
                        title: '中策订单号',
                        key: 'orderSn',
                        align: 'center',
                        minWidth: 240,
                        fixed: 'left',
                    },
                    {
                        title: '交易流水号',
                        key: 'txSn',
                        align: 'center',
                        minWidth: 250,
                    },
                    {
                        title: '交易时间',
                        key: 'payBusinessTime',
                        align: 'center',
                        minWidth: 180,
                    },
                    { title: '是否异常', key: 'abnormalInfo', align: 'center', minWidth: 120 },
                    { title: '交易类型', key: 'payBusinessTypeStr', align: 'center', minWidth: 120 },
                    { title: '支付方式', key: 'payTypeStr', align: 'center', minWidth: 120 },
                    { title: '交易金额（元）', key: 'payAmount', align: 'center', minWidth: 120 },
                    {
                        title: '手续费（元）',
                        key: 'payFee',
                        align: 'center',
                        minWidth: 110,
                        render: (h, p) => {
                            return h('div', `${p.row.payFee == null ? '-' : p.row.payFee}`);
                        },
                    },
                    {
                        title: '手续费退款（元）',
                        key: 'refundPayFee',
                        align: 'center',
                        minWidth: 120,
                        render: (h, p) => {
                            return h('div', `${p.row.refundPayFee == null ? '-' : p.row.refundPayFee}`);
                        },
                    },
                    { title: '交易账户名称', key: 'cpcnBusinessAccountName', align: 'center', minWidth: 150 },
                    { title: '门店名称', key: 'storeName', align: 'center', minWidth: 150 },
                    { title: '联系人', key: 'ownerName', align: 'center', minWidth: 120 },
                    { title: '联系方式', key: 'ownerPhone', align: 'center', minWidth: 130 },
                    { title: '门店维护人', key: 'salesMan', align: 'center', minWidth: 120 },
                    {
                        title: '备注',
                        key: 'remark',
                        align: 'center',
                        minWidth: 130,
                        height: 48,
                        ellipsis: true,
                        tooltip: true,
                    },
                ],
                data: [],
            },
            formList: [
                {
                    title: '中策订单号',
                    type: 'input',
                    key: 'orderSn',
                },
                {
                    title: '交易流水号',
                    type: 'input',
                    key: 'txSn',
                },
                {
                    title: '交易时间',
                    type: 'daterange',
                    key: ['startTime', 'endTime'],
                },
                {
                    title: '交易类型',
                    type: 'select',
                    key: 'payBusinessType',
                    options: [
                        {
                            id: '0',
                            name: '支付',
                        },
                        {
                            id: '1',
                            name: '提现',
                        },
                        {
                            id: '2',
                            name: '退款',
                        },
                    ],
                },
                {
                    title: '支付方式',
                    type: 'select',
                    key: 'payType',
                    options: [
                        {
                            id: '4',
                            name: '储蓄卡',
                        },
                        {
                            id: '5',
                            name: '信用卡',
                        },
                        {
                            id: '1',
                            name: '微信支付',
                        },
                        {
                            id: '2',
                            name: '支付宝',
                        },
                    ],
                },
                {
                    title: '交易账户',
                    type: 'select',
                    key: 'cpcnUserId',
                    options: [],
                    clearable: true,
                    filterable: true,
                },
                {
                    title: '是否异常',
                    type: 'select',
                    key: 'abnormalType', // 【0：无异常（正常），10：状态不匹配，20：交易金额不匹配，30：手续费不匹配，40：无匹配订单，50：重复支付，60：取消后支付】
                    options: [
                        {
                            id: '0',
                            name: '正常',
                        },
                        {
                            id: '40',
                            name: '无匹配订单',
                        },
                        {
                            id: '20',
                            name: '交易金额不匹配',
                        },
                        {
                            id: '30',
                            name: '手续费不匹配',
                        },
                        {
                            id: '10',
                            name: '支付状态不匹配',
                        },
                        {
                            id: '50',
                            name: '重复支付',
                        },
                        {
                            id: '60',
                            name: '取消后支付',
                        },
                    ],
                },
                {
                    title: '门店信息',
                    placeholder: '请输入门店名称/联系人/联系方式',
                    type: 'input',
                    key: 'storeInfo',
                },
            ],
        };
    },
    activated() {
        this.getData();
    },
    mounted() {
        this.getAccountList();
    },
    methods: {
        exportInfo() {
            this.exportData();
        },
        exportData() {
            if (this.isExporting) {
                return false;
            }
            let p = this.$refs['table-form'].getFormData();
            this.verifyStatus = p.status;
            delete p.date;
            if (p['dept']) {
                p.deptId = p.dept[0];
                p.regionId = p.dept[1];
                delete p['dept'];
            }
            if (p.provinceId) p.provinceId = p.provinceId[0];
            let params = {
                ...p,
            };
            this.$Message.loading({
                content: '导出中...',
                duration: 0,
            });
            this.isExporting = true;
            // delete params.provinceId
            reconciliationExport(params).then(
                (res) => {
                    this.$Message.destroy();
                    this.list.loading = false;
                    this.isExporting = false;
                    if (!res) {
                        this.$Message.warning('暂无数据');
                        return false;
                    }
                    let aLink = document.createElement('a');
                    aLink.href = res || '';
                    // console.log('href ===', res)
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    this.$Notice.success({
                        title: '导出成功',
                    });
                },
                () => {
                    this.isExporting = false;
                    this.$Message.destroy();
                },
            );
        },
        getAccountList() {
            accountList({}).then(
                (res) => {
                    const list = res.accountVOList || [];
                    this.formList[5].loading = false;
                    this.formList[5].options = list
                        .filter((item) => {
                            return !![3, 4, 5].includes(item.accountStatus);
                        })
                        .map((item) => {
                            return {
                                name: item.accountName || '-',
                                id: item.userId,
                            };
                        });
                },
                () => {
                    this.formList[5].options = [
                        {
                            name: '暂无数据',
                            disabled: true,
                        },
                    ];
                    this.formList[5].loading = false;
                },
            );
        },
        getNowMonthTime() {
            let date = new Date();
            let y = date.getFullYear();
            let m = date.getMonth() + 1;
            return y + '-' + m + '-01 00:00:00';
        },
        toDetail(row) {
            jumpPage({ path: '/sub-vue2/statistics/rebate/detail', params: { date: row.date } });
        },
        onPageChange(val) {
            if (this.isChangePage) return;
            this.list.current = val;
            this.getData();
        },
        onPageSizeChange(val) {
            this.list.current = 1;
            this.isChangePage = true;
            this.list.size = val;
            this.getData();
        },
        getData() {
            this.list.loading = true;
            let p = this.$refs['table-form'].getFormData();
            const param = {
                current: this.list.current,
                size: this.list.size,
                ...p,
            };
            reconciliationList(param)
                .then((res) => {
                    this.list.loading = false;
                    this.list.total = Number(res.total);
                    this.list.data = res.records || [];
                })
                .catch(() => {
                    this.list.loading = false;
                });
        },
        onSubmit(param) {
            console.log('param :', param);
            this.list.current = 1;
            this.getData(param);
        },
    },
};
</script>
