<template>
    <div>
        <PageHeader title="门店线上支付明细" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage"><Icon type="md-arrow-back" /></div>
        </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" @on-submit="onSubmit" @on-export="exportInfo" ref="table-form">
                <Button class="ivu-ml-8" :loading="exportLoading" :disabled="exportLoading" slot="extraBtn" type="error"
                    >导出</Button
                >
            </table-form>
            <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list" />
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { clearEmpty, renderButton, jumpPage, transPathToName } from '@/libs/util';
import { payDetail, payDetailExport } from '@/api/statistics';

export default {
    components: {
        tableForm,
        tablePage,
    },
    data() {
        return {
            exportLoading: false,
            isChangePage: false,
            formList: [
                {
                    title: '订单编号',
                    type: 'input',
                    key: 'sn',
                },
                {
                    title: '支付时间',
                    type: 'daterange',
                    key: ['payStartDate', 'payEndDate'],
                },
            ],
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    {
                        title: '订单编号',
                        key: 'sn',
                        align: 'center',
                        minWidth: 290,
                        fixed: 'left',
                    },
                    {
                        title: '下单时间',
                        key: 'createDate',
                        align: 'center',
                        minWidth: 150,
                    },
                    {
                        title: '支付时间',
                        key: 'payDate',
                        align: 'center',
                        minWidth: 150,
                        render: (h, p) => {
                            return h('div', p.row.payDate || '-');
                        },
                    },
                    {
                        title: '订单总金额',
                        key: 'totalOrderAmount',
                        align: 'center',
                        minWidth: 100,
                    },
                    {
                        title: '返利抵用',
                        key: 'rebate',
                        align: 'center',
                        minWidth: 140,
                        render: (h, p) => {
                            return h('div', p.row.rebate || '-');
                        },
                    },
                    {
                        title: '实付金额',
                        key: 'actualPayedAmount',
                        align: 'center',
                        minWidth: 100,
                        render: (h, p) => {
                            return h('div', p.row.actualPayedAmount || '-');
                        },
                    },
                    {
                        title: '操作',
                        key: 'oprate',
                        align: 'center',
                        width: 145,
                        fixed: 'right',
                        render: (h, p) => {
                            return [
                                renderButton(h, { text: '查看订单详情', type: 'primary' }, () => {
                                    this.checkDetail(p.row);
                                }),
                            ];
                        },
                    },
                ],
                data: [],
            },
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        checkDetail(row) {
            jumpPage({
                path: '/sub-vue2/sales/storePay/orderDetail',
                params: {
                    id: row.id,
                },
            });
        },
        getData() {
            this.list.loading = true;
            let p = {
                ...this.$refs['table-form'].getFormData(),
            };
            let params = clearEmpty({
                current: this.list.current,
                size: this.list.size,
                month: this.$route.query.month,
                storeId: this.$route.query.storeId,
                ...p,
            });
            payDetail(params).then(
                (data) => {
                    let scanType = {
                        61: '入库',
                        62: '门店出库扫码',
                        63: '经销商退货扫码',
                        53: '退货',
                    };
                    this.list.data = (data.records || []).map((item) => {
                        item.scanType = scanType[item.scanType];
                        return item;
                    });
                    this.list.total = Number(data.total);
                    this.isChangePage = false;
                    this.list.loading = false;
                },
                () => {
                    this.isChangePage = false;
                    this.list.loading = false;
                },
            );
        },
        exportData() {
            let params = {
                ...this.$refs['table-form'].getFormData(),
                month: this.$route.query.month,
                storeId: this.$route.query.storeId,
            };

            this.$Message.loading({
                content: '导出中...',
                duration: 0,
            });
            this.exportLoading = true;
            payDetailExport(params).then(
                (res) => {
                    this.exportLoading = false;
                    if (!res) {
                        this.$Message.warning('暂无数据');
                        return false;
                    }
                    let aLink = document.createElement('a');
                    aLink.href = res || '';
                    // console.log('href ===', res)
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    this.$Message.destroy();
                    this.$Message.success('导出成功');
                },
                () => {
                    this.$Message.destroy();
                    this.exportLoading = false;
                },
            );
        },
        onPageChange(val) {
            if (this.isChangePage) return;
            this.list.current = val;
            this.getData();
        },
        onPageSizeChange(val) {
            this.list.current = 1;
            this.isChangePage = true;
            this.list.size = val;
            this.getData();
        },
        check(row) {
            this.$refs['records'].open(row);
        },
        onSubmit() {
            this.getData();
        },
        exportInfo(params) {
            console.log(params, 'params');
            this.exportData();
        },
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/sales/storePay/list' });
        },
    },
};
</script>
