<template>
    <div v-loading="list.loading">
        <PageHeader title="侠系列签约返利" hidden-breadcrumb></PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" @on-submit="onSubmit" @on-export="exportInfo" ref="table-form">
                <Button class="ivu-ml-8" :loading="isExporting" :disabled="isExporting" slot="extraBtn" type="error"
                    >导出</Button
                >
            </table-form>
            <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list" />
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { renderButton, clearEmpty, jumpPage } from '@/libs/util';
import { xiaRebateList, xiaRebateListExport } from '@/api/statistics';
import dayjs from 'dayjs';

export default {
    name: 'swordsmanSignRebate-list',
    components: {
        tableForm,
        tablePage,
    },
    data() {
        return {
            isExporting: false,
            defaultDate: dayjs().format('YYYYMM'),
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                columns: [
                    {
                        title: '门店名称',
                        fixed: 'left',
                        key: 'storeName',
                        align: 'center',
                        minWidth: 180,
                    },
                    {
                        title: '联系人',
                        key: 'linkMan',
                        align: 'center',
                        minWidth: 180,
                    },
                    {
                        title: '联系方式',
                        key: 'linkPhone',
                        align: 'center',
                        minWidth: 180,
                    },
                    {
                        title: '月份',
                        key: 'verifyMonth',
                        align: 'center',
                        minWidth: 180,
                    },
                    {
                        title: '签约品牌',
                        key: 'signBrandName',
                        align: 'center',
                        minWidth: 180,
                    },
                    {
                        title: '完成签约时间',
                        key: 'finishSignTime',
                        align: 'center',
                        minWidth: 180,
                    },

                    { title: '侠系列任务数', key: 'taskNumber', align: 'center', minWidth: 200 },
                    { title: '侠系列完成数', key: 'finishNumber', align: 'center', minWidth: 150 },
                    { title: '预计签约返利', key: 'estimateRebate', align: 'center', minWidth: 150 },
                    { title: '审核签约返利', key: 'verifyRebate', align: 'center', minWidth: 150 },
                    {
                        title: '审核状态',
                        key: 'verifyStatus',
                        align: 'center',
                        minWidth: 130,
                        render: (h, p) => {
                            return h('span', p.row.verifyStatus == 1 ? '已审核' : '待审核');
                        },
                    },
                    {
                        title: '操作',
                        align: 'center',
                        width: 140,
                        fixed: 'right',
                        render: (h, params) => {
                            return [
                                renderButton(h, { text: '查看', type: 'primary', size: 'small' }, () => {
                                    this.onCheckDetail(params.row);
                                }),
                            ];
                        },
                    },
                ],
                data: [],
            },
            formList: [
                {
                    title: '门店',
                    type: 'input',
                    key: 'storeInfo',
                    placeholder: '请输入门店名称/联系人/联系方式',
                },
                {
                    title: '月份',
                    type: 'month',
                    key: 'yearMonth',
                    value: '2024-06',
                    options: {
                        disabledDate(date) {
                            const time = dayjs(date).format('YYYY-MM').valueOf();
                            return time && time > '2024-06';
                        },
                    },
                },
            ],
            total: {},
        };
    },
    mounted() {
        this.$refs['table-form'].data.yearMonth = '2024-06';
        this.getData();
    },
    methods: {
        exportInfo() {
            this.exportData();
        },
        exportData() {
            if (this.isExporting) {
                return false;
            }
            let p = this.$refs['table-form'].getFormData();
            if (p.yearMonth) {
                p.yearMonth = p.yearMonth.replace('-', '');
            } else {
                p.yearMonth = `${this.defaultDate}`;
            }

            let params = clearEmpty({
                ...p,
            });
            this.$Message.loading({
                content: '导出中...',
                duration: 0,
            });
            this.isExporting = true;
            // delete params.provinceId
            xiaRebateListExport(params).then(
                (res) => {
                    this.$Message.destroy();
                    this.list.loading = false;
                    this.isExporting = false;
                    if (!res) {
                        this.$Message.warning('暂无数据');
                        return false;
                    }
                    let aLink = document.createElement('a');
                    aLink.href = res || '';
                    // console.log('href ===', res)
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    this.$Notice.success({
                        title: '导出成功',
                    });
                },
                () => {
                    this.isExporting = false;
                    this.$Message.destroy();
                },
            );
        },
        getNowMonthTime() {
            let date = new Date();
            let y = date.getFullYear();
            let m = date.getMonth() + 1;
            return y + '-' + m + '-01 00:00:00';
        },
        onPageChange(val) {
            this.list.current = val;
            this.getData();
        },
        onPageSizeChange(val) {
            this.list.current = 1;
            this.list.size = val;
            this.getData();
        },
        getData() {
            this.list.loading = true;
            let p = this.$refs['table-form'].getFormData();
            if (p.yearMonth) {
                p.yearMonth = p.yearMonth.replace('-', '');
            } else {
                p.yearMonth = `${this.defaultDate}`;
            }
            const param = clearEmpty({
                current: this.list.current,
                size: this.list.size,
                ...p,
            });
            console.log(param);
            xiaRebateList(param)
                .then((res) => {
                    this.list.loading = false;
                    this.list.total = Number(res.total);
                    this.list.data = (res.list || []).map((item) => {
                        for (let key in item) {
                            if (item[key] == null) {
                                item[key] = '-';
                            }
                        }
                        return {
                            ...item,
                        };
                    });
                })
                .catch(() => {
                    this.list.loading = false;
                });
        },
        onSubmit() {
            this.list.current = 1;
            this.getData();
        },
        onCheckDetail(row) {
            jumpPage({ path: '/sub-vue2/statistics/swordsmanSignRebate/detail', params: { row: JSON.stringify(row) } });
        },
    },
};
</script>
