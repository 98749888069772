/*
 * @Author: chengleilei
 * @Date: 2021-11-10 15:44:06
 * @Description: 提现接口
 */
import request from "@/plugins/request";

// 查询经销商的指定的中金账户
export function getAccountBalance (userId) {
    return request({
        url: `/withDraw/accountBalance/${userId}`,
        method: 'get',
    });
}

// 查询经销商的中金账户
export function getAccountList () {
    return request({
        url: `/withDraw/accountList`,
        method: 'get',
    });
}

// 中金账户绑定的银行卡
export function getCardList (userId) {
    return request({
        url: `/withDraw/cardList/${userId}`,
        method: 'get',
    });
}

// 查询经销商的提现记录
export function listRecords (data) {
    return request({
        url: `/withDraw/records`,
        method: 'post',
        data
    });
}

// 发起提现
export function submitWithDraw (data) {
    return request({
        url: `/withDraw/withDraw`,
        method: 'post',
        data
    });
}

