<template>
    <div v-loading="pageLoading">
        <PageHeader title="购胎入库返利" hidden-breadcrumb> </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" @on-submit="onSubmit" @on-export="exportInfo" ref="table-form">
                <Button class="ivu-ml-8" :loading="exportLoading" :disabled="exportLoading" slot="extraBtn" type="error"
                    >导出</Button
                >
            </table-form>

            <Button
                :disabled="selectedData.length <= 0 || nowMonth === verifyMonth"
                type="primary"
                class="ivu-mr-16"
                @click="batchAudit"
                >批量发放返利</Button
            >

            <Alert show-icon class="ivu-mt com-alert">
                <div v-font="14">
                    已选择 <strong v-color="'#2d8cf0'">{{ selectedData.length }}</strong
                    >项
                    <a class="ivu-ml" @click="handleClearSelect">清空</a>
                </div>
            </Alert>
            <table-page
                ref="selection-table"
                @onPageChange="onPageChange"
                @onPageSizeChange="onPageSizeChange"
                @handleSelect="handleSelect"
                @handleSelectCancel="handleSelectCancel"
                @handleSelectAll="handleSelectAll"
                @handleSelectAllCancel="handleSelectAllCancel"
                :list="list"
            />
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { renderButton, jumpPage } from '@/libs/util';
import { rebateList, rebateListExport, rebateSpecialPayMoney } from '@/api/sales';

let mouthNow = new Date().getMonth() + 1;
mouthNow = mouthNow < 10 ? '0' + mouthNow : mouthNow;
let nowMonth = `${new Date().getFullYear()}${mouthNow}`;

export default {
    name: 'store-scan-rebate',
    components: {
        tableForm,
        tablePage,
    },
    data() {
        return {
            pageLoading: false,
            selectedData: [],
            nowMonth: nowMonth,
            exportLoading: false,
            verifyMonth: '',
            verifyStatus: null,
            isChangePage: false,
            formList: [
                {
                    title: '门店',
                    key: 'storeName',
                    width: '230px',
                    type: 'input',
                    placeholder: '请输入门店名称/联系人/联系方式',
                },
                {
                    title: '月份',
                    type: 'month',
                    key: 'date',
                    placeholder: '请选择月份',
                },
                {
                    title: '状态',
                    key: 'verifyStatus',
                    type: 'select',
                    placeholder: '请选择状态',
                    options: [
                        {
                            name: '全部',
                            id: '',
                        },
                        {
                            name: '待审核',
                            id: '0',
                        },
                        {
                            name: '已审核',
                            id: '1',
                        },
                    ],
                    loading: false,
                    filterable: true,
                },
            ],
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    {
                        title: '全选',
                        type: 'selection',
                        width: 60,
                        align: 'center',
                        fixed: 'left',
                    },
                    { title: '门店名称', key: 'storeName', align: 'center', minWidth: 150, fixed: 'left' },
                    { title: '联系人', key: 'storeContacts', align: 'center', minWidth: 90 },
                    { title: '联系方式', key: 'storeMobile', align: 'center', minWidth: 140 },
                    {
                        title: '门店类型',
                        key: 'storeType',
                        align: 'center',
                        minWidth: 140,
                        render: (h, p) => {
                            return h('div', p.row.storeType || '-');
                        },
                    },
                    { title: '月份', key: 'verifyMonth', align: 'center', minWidth: 140 },
                    { title: '下单条数', key: 'downOrderNum', align: 'center', minWidth: 120 },
                    { title: '已退款条数', key: 'refundOrderNumber', align: 'center', minWidth: 120 },
                    { title: '入库条数', key: 'inNum', align: 'center', minWidth: 120 },
                    { title: '激活返利', key: 'rebateSpecial', align: 'center', minWidth: 100 },
                    { title: '审核返利', key: 'verifyRebate', align: 'center', minWidth: 100 },
                    {
                        title: '审核状态',
                        key: 'verifyStatus',
                        align: 'center',
                        minWidth: 100,
                        render: (h, p) => {
                            let badge = h('Badge', {
                                props: {
                                    color: {
                                        0: 'red',
                                        1: 'green',
                                        2: 'blue',
                                    }[p.row.verifyStatus],
                                    text: {
                                        0: '未审核',
                                        1: '已审核',
                                        2: '审核中',
                                    }[p.row.verifyStatus],
                                },
                            });
                            return h('div', [badge]);
                        },
                    },
                    {
                        title: '经销商发放',
                        key: 'distributorConfirm',
                        align: 'center',
                        minWidth: 120,
                        render: (h, p) => {
                            let badge = h('Badge', {
                                props: {
                                    color: {
                                        null: 'red',
                                        0: 'red',
                                        1: 'green',
                                    }[p.row.distributorConfirm],
                                    text: {
                                        null: '未发放',
                                        0: '未发放',
                                        1: '已发放',
                                    }[p.row.distributorConfirm],
                                },
                            });
                            return h('div', [badge]);
                        },
                    },
                    {
                        title: '门店领取',
                        key: 'storeConfirm',
                        align: 'center',
                        minWidth: 100,
                        render: (h, p) => {
                            let badge = h('Badge', {
                                props: {
                                    color: {
                                        null: 'red',
                                        0: 'red',
                                        1: 'green',
                                    }[p.row.storeConfirm],
                                    text: {
                                        null: '未收到',
                                        0: '未收到',
                                        1: '已收到',
                                    }[p.row.storeConfirm],
                                },
                            });
                            return h('div', [badge]);
                        },
                    },
                    {
                        title: '操作',
                        key: 'oprate',
                        align: 'center',
                        width: 150,
                        fixed: 'right',
                        render: (h, p) => {
                            return [
                                renderButton(h, { text: '查看', type: 'primary' }, () => {
                                    this.rebateDetail(p.row);
                                }),
                            ];
                        },
                    },
                ],
                data: [],
            },
        };
    },
    mounted() {
        this.$bus.$on('getData', () => {
            this.getData();
        });
        this.getData();
    },
    methods: {
        handleSelection(val) {
            this.selectedData = val;
        },
        // 选中一项，将数据添加至已选项中
        handleSelect(selection, row) {
            this.selectedData.push(row);
        },
        // 取消选中一项，将取消的数据从已选项中删除
        handleSelectCancel(selection, row) {
            const index = this.selectedData.findIndex((item) => item.id === row.id);
            this.selectedData.splice(index, 1);
        },
        // 当前页全选时，判断已选数据是否存在，不存在则添加
        handleSelectAll(selection) {
            selection.forEach((item) => {
                if (this.selectedData.findIndex((i) => i.id === item.id) < 0) {
                    this.selectedData.push(item);
                }
            });
        },
        // 取消当前页全选时，将当前页的数据（即 dataWithPage）从已选项中删除
        handleSelectAllCancel() {
            const selection = this.list.data;
            selection.forEach((item) => {
                const index = this.selectedData.findIndex((i) => i.id === item.id);
                if (index >= 0) {
                    this.selectedData.splice(index, 1);
                }
            });
        },
        // 清空所有已选项
        handleClearSelect() {
            this.selectedData = [];
            this.list.data = (this.list.data || []).map((item) => {
                return {
                    ...item,
                    _checked: false,
                };
            });
        },
        // 一键审核
        batchAudit() {
            let taskNumberIds = this.selectedData.filter((item) => item.verifyStatus === 1).map((item) => item.id);
            let params = {
                taskNumberIds,
                verifyMonth: this.verifyMonth,
            };
            if (taskNumberIds.length <= 0) {
                this.$Modal.warning({
                    title: '提示',
                    content: '当前选择暂无可发放数据！',
                });
                return false;
            }
            this.$Modal.confirm({
                title: '提示',
                content: '确定将经销商发放返利状态更改为已发放？',
                onOk: () => {
                    this.$Message.loading({
                        content: '审核中...',
                        duration: 0,
                    });

                    this.pageLoading = true;
                    rebateSpecialPayMoney(params).then(
                        () => {
                            this.isChangePage = true;
                            this.resetData();
                            this.$Message.destroy();
                            this.$Message.success('发放返利完成~');
                            this.pageLoading = false;
                        },
                        () => {
                            this.pageLoading = false;
                            this.$Message.destroy();
                        },
                    );
                },
            });
        },
        resetData() {
            this.getData();
            this.selectedData = [];
        },
        getData() {
            this.list.loading = true;
            let p = this.$refs['table-form'].getFormData();
            if (p.date) {
                p.verifyMonth = p.date.replace('-', '');
            } else {
                let mouth = new Date().getMonth() + 1;
                mouth = mouth < 10 ? '0' + mouth : mouth;
                p.verifyMonth = `${new Date().getFullYear()}${mouth}`;
            }
            this.verifyMonth = p.verifyMonth;
            this.verifyStatus = p.status;
            delete p.date;
            if (p.provinceId) p.provinceId = p.provinceId[0];
            let params = {
                current: this.list.current,
                size: this.list.size,
                ...p,
            };
            rebateList(params).then(
                (data) => {
                    this.list.total = Number(data.total);
                    // 判断是否有选中的数据
                    const selectedIds = this.selectedData.map((item) => item.id);
                    this.list.data = (data.records || []).map((item) => {
                        return {
                            ...item,
                            verifyRebate: item.verifyRebate || '-',
                            // 判断是否有选中的数据
                            _checked: selectedIds.indexOf(item.id) >= 0,
                            _disabled: nowMonth === this.verifyMonth,
                        };
                    });
                    this.isChangePage = false;
                    this.list.loading = false;
                },
                () => {
                    this.isChangePage = false;
                    this.list.loading = false;
                },
            );
        },
        exportData() {
            let p = this.$refs['table-form'].getFormData();
            if (p.date) {
                p.verifyMonth = p.date.replace('-', '');
            } else {
                let mouth = new Date().getMonth() + 1;
                mouth = mouth < 10 ? '0' + mouth : mouth;
                p.verifyMonth = `${new Date().getFullYear()}${mouth}`;
            }
            this.verifyMonth = p.verifyMonth;
            this.verifyStatus = p.status;
            delete p.date;
            if (p.provinceId) p.provinceId = p.provinceId[0];
            let params = {
                ...p,
            };
            this.$Message.loading({
                content: '导出中...',
                duration: 0,
            });
            // delete params.provinceId
            this.exportLoading = true;
            rebateListExport(params).then(
                (res) => {
                    this.exportLoading = false;
                    if (!res) {
                        this.$Message.warning('暂无数据');
                        return false;
                    }
                    let aLink = document.createElement('a');
                    aLink.href = res || '';
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    this.$Message.destroy();
                    this.$Message.success('导出成功');
                },
                () => {
                    this.$Message.destroy();
                    this.exportLoading = false;
                },
            );
        },
        onPageChange(val) {
            if (this.isChangePage) return;
            this.list.current = val;
            this.getData();
        },
        onPageSizeChange(val) {
            this.list.current = 1;
            this.isChangePage = true;
            this.list.size = val;
            this.getData();
        },
        rebateDetail(row) {
            jumpPage({
                path: '/sub-vue2/sales/rebateAudit/detail',
                params: {
                    storeId: row.storeId,
                    verifyMonth: this.verifyMonth,
                    distributorId: row.distributorId,
                },
            });
        },
        onSubmit() {
            this.list.current = 1;
            this.selectedData = [];
            this.getData();
        },
        exportInfo() {
            this.exportData();
        },
    },
};
</script>

<style lang="less" scoped>
.ivu-form-item {
    &:nth-child(1) {
        width: 325px;
    }
}
</style>
