import Withdrawal from '@/pages/assets/withdrawal/index';
import WithdrawalForm from '@/pages/assets/withdrawal/form';
import WithdrawalResult from '@/pages/assets/withdrawal/result';
import WithdrawalList from '@/pages/assets/withdrawal/list';
import Reconciliation from '@/pages/assets/reconciliation/index';
import DealerAccount from '@/pages/assets/dealerAccount/index';
import DealerAccountDetail from '@/pages/assets/dealerAccount/detail';
import DealerAccountTypeChose from '@/pages/assets/dealerAccount/typeChose';
import DealerAccountTiedCardForm from '@/pages/assets/dealerAccount/tiedCardForm';
import DealerAccountTiedCardFormAgain from '@/pages/assets/dealerAccount/tiedCardFormAgain';
import DealerAccountTiedCardApprove from '@/pages/assets/dealerAccount/tiedCardApprove';
import DealerAccountTiedCardResult from '@/pages/assets/dealerAccount/tiedCardResult';
import DealerAccountOpenAccount from '@/pages/assets/dealerAccount/openAccount';
import DealerAccountOpenAccountResult from '@/pages/assets/dealerAccount/openAccountResult';
import DealerAccountSignContract from '@/pages/assets/dealerAccount/signContract';
import DealerAccountSignContractResult from '@/pages/assets/dealerAccount/signContractResult';
import DealerAccountAddBankCard from '@/pages/assets/dealerAccount/addBankCard';
import DealerAccountAddBankCardResult from '@/pages/assets/dealerAccount/addBankCardResult';
import { applyRoute } from '../const';

const assetsRoutes = [
    {
        path: `${applyRoute}/assets/withdrawal/index`,
        meta: {
            auth: ['assets'],
            title: '提现',
            cache: true,
        },
        component: Withdrawal,
    },
    {
        path: `${applyRoute}/assets/withdrawal/form`,
        meta: {
            auth: ['assets'],
            title: '余额提现',
            cache: false,
        },
        component: WithdrawalForm,
    },
    {
        path: `${applyRoute}/assets/withdrawal/result`,
        meta: {
            auth: ['assets'],
            title: '余额提现',
            cache: false,
        },
        component: WithdrawalResult,
    },
    {
        path: `${applyRoute}/assets/withdrawal/list`,
        meta: {
            auth: ['assets'],
            title: '提现记录',
            cache: false,
        },
        component: WithdrawalList,
    },
    {
        path: `${applyRoute}/assets/reconciliation/index`,
        meta: {
            auth: ['assets'],
            title: '对账',
            cache: true,
        },
        component: Reconciliation,
    },
    {
        path: `${applyRoute}/assets/dealerAccount/list`,
        meta: {
            auth: ['assets'],
            title: '账户管理',
            cache: true,
        },
        component: DealerAccount,
    },
    {
        path: `${applyRoute}/assets/dealerAccount/detail`,
        meta: {
            auth: ['assets'],
            title: '账户管理',
            cache: false,
        },
        component: DealerAccountDetail,
    },
    {
        path: `${applyRoute}/assets/dealerAccount/typeChose`,
        meta: {
            auth: ['assets'],
            title: '账户管理',
            cache: false,
        },
        component: DealerAccountTypeChose,
    },
    {
        path: `${applyRoute}/assets/dealerAccount/tiedCardForm`,
        meta: {
            auth: ['assets'],
            title: '账户管理',
            cache: false,
        },
        component: DealerAccountTiedCardForm,
    },
    {
        path: `${applyRoute}/assets/dealerAccount/tiedCardFormAgain`,
        meta: {
            auth: ['assets'],
            title: '账户管理',
            cache: false,
        },
        component: DealerAccountTiedCardFormAgain,
    },
    {
        path: `${applyRoute}/assets/dealerAccount/tiedCardApprove`,
        meta: {
            auth: ['assets'],
            title: '账户管理',
            cache: false,
        },
        component: DealerAccountTiedCardApprove,
    },
    {
        path: `${applyRoute}/assets/dealerAccount/tiedCardResult`,
        meta: {
            auth: ['assets'],
            title: '账户管理',
            cache: false,
        },
        component: DealerAccountTiedCardResult,
    },
    {
        path: `${applyRoute}/assets/dealerAccount/openAccount`,
        meta: {
            auth: ['assets'],
            title: '开户',
            cache: false,
        },
        component: DealerAccountOpenAccount,
    },
    {
        path: `${applyRoute}/assets/dealerAccount/openAccountResult`,
        meta: {
            auth: ['assets'],
            title: '开户结果',
            cache: false,
        },
        component: DealerAccountOpenAccountResult,
    },
    {
        path: `${applyRoute}/assets/dealerAccount/signContract`,
        meta: {
            auth: ['assets'],
            title: '签约',
            cache: false,
        },
        component: DealerAccountSignContract,
    },
    {
        path: `${applyRoute}/assets/dealerAccount/signContractResult`,
        meta: {
            auth: ['assets'],
            title: '签约结果',
            cache: false,
        },
        component: DealerAccountSignContractResult,
    },
    {
        path: `${applyRoute}/assets/dealerAccount/addBankCard`,
        meta: {
            auth: ['assets'],
            title: '添加银行卡',
            cache: false,
        },
        component: DealerAccountAddBankCard,
    },
    {
        path: `${applyRoute}/assets/dealerAccount/addBankCardResult`,
        meta: {
            auth: ['assets'],
            title: '添加银行卡结果',
            cache: false,
        },
        component: DealerAccountAddBankCardResult,
    },
];

export { assetsRoutes };
