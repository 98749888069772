<template>
    <div class="cruise-detail">
        <PageHeader title="业务员巡店统计表明细" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage"><Icon type="md-arrow-back" /></div>
        </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <Tabs type="card">
                <TabPane label="已巡">
                    <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list" />
                </TabPane>
                <TabPane label="未巡">
                    <table-page @onPageChange="onPageChange2" @onPageSizeChange="onPageSizeChange2" :list="list2" />
                </TabPane>
            </Tabs>
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import { hasTour, noTour } from '../../../api/cruise';
import { mapActions } from 'vuex';
import { renderButton, jumpPage, transPathToName } from '@/libs/util';

export default {
    name: 'store-scan-rebate',
    components: {
        tablePage,
    },
    data() {
        return {
            isChangePage: false,
            isChangePage2: false,
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    { title: '巡店时间', key: 'createDate', align: 'center', minWidth: 150 },
                    { title: '店名', key: 'storeName', align: 'center', minWidth: 150 },
                    {
                        title: '操作',
                        key: 'oprate',
                        align: 'center',
                        width: 300,
                        fixed: 'right',
                        render: (h, p) => {
                            return [
                                renderButton(h, { text: '查看', type: 'primary' }, () => {
                                    this.checkDetail(p.row);
                                }),
                            ];
                        },
                    },
                ],
                data: [],
            },
            list2: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [{ title: '店名', key: 'storeName', align: 'center', minWidth: 150 }],
                data: [],
            },
        };
    },

    mounted() {
        this.getData();
        this.getData2();
    },
    methods: {
        ...mapActions('admin/page', ['close']),
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/statistics/cruise/list' });
        },
        getData() {
            this.list.loading = true;
            let params = {
                current: this.list.current,
                size: this.list.size,
                salesmanId: this.$route.query.id,
            };
            // delete params.provinceId
            hasTour(params).then(
                (data) => {
                    this.list.total = Number(data.total);
                    this.list.data = (data.records || []).map((item) => {
                        return {
                            ...item,
                        };
                    });
                    this.isChangePage = false;
                    this.list.loading = false;
                },
                () => {
                    this.isChangePage = false;
                    this.list.loading = false;
                },
            );
        },
        getData2() {
            this.list2.loading = true;
            let params = {
                current: this.list2.current,
                size: this.list2.size,
                salesmanId: this.$route.query.id,
            };
            noTour(params).then(
                (data) => {
                    this.list2.total = Number(data.total);
                    this.list2.data = (data.records || []).map((item) => {
                        return {
                            ...item,
                        };
                    });
                    this.isChangePage2 = false;
                    this.list2.loading = false;
                },
                () => {
                    this.isChangePage2 = false;
                    this.list2.loading = false;
                },
            );
        },
        onPageChange(val) {
            if (this.isChangePage) return;
            this.list.current = val;
            this.getData();
        },
        onPageSizeChange(val) {
            this.list.current = 1;
            this.isChangePage = true;
            this.list.size = val;
            this.getData();
        },
        onPageChange2(val) {
            if (this.isChangePage2) return;
            this.list2.current = val;
            this.getData();
        },
        onPageSizeChange2(val) {
            this.list.current = 1;
            this.isChangePage = true;
            this.list2.size = val;
            this.getData2();
        },
        checkDetail(row) {
            jumpPage({
                path: '/sub-vue2/statistics/cruise/store',
                params: {
                    id: row.id,
                },
            });
        },
    },
};
</script>

<style lang="less">
.cruise-detail {
    .ivu-tabs-nav-scroll {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
    }
}
</style>
