import request from '@/plugins/request';
import qs from 'qs';

/**
 *  客户管理
 */
// 门店列表
export function StoreList (data) {
    return request({
        url: `/store/storeList`,
        method: 'post',
        data
    });
}
export function SalesmanList () {
    return request({
        url: `/store/storeSalesmanList`,
        method: 'post'
    });
}
export function StoreDetail (id) {
    return request({
        url: `/store/storeDetail?id=${id}`,
        method: 'post'
    });
}
// 修改门店维护人
export function UpdateSalesMan (data) {
    return request({
        url: `/store/updateSalesman`,
        method: 'post',
        params: data
    });
}

// 修改门店维护人(集团客户)
export function UpdateSalesManGroup (data) {
    return request({
        url: `/groupCustomer`,
        method: 'put',
        data
    });
}

// 批量修改
export function BatchUpdateSalesMan (data) {
    return request({
        url: `/store/updateBatch`,
        method: 'post',
        params: data
    });
}
// 门店导出
export function ExportStore (data) {
    return request({
        url: `/store/export`,
        method: 'post',
        data
    });
}

// 门店类型列表
export function storeTypeList (data) {
    return request({
        url: `/common/${data.supplierId}/store_types`,
        method: 'get',
        params: data
    });
}

export function CheckAuthCode (params) {
    return request({
        url: `/distributor/store/checkAuthCode`,
        method: 'get',
        params
    });
}

export function ExportStoreInfoList (params) {
    return request({
        url: '/distributor/store/export',
        method: 'get',
        params
    });
}

/**
 *  客户签约管理接口
 */
// 签约客户管理列表
export function SignStoreList (data) {
    return request({
        url: '/distributor/store_contract/page',
        method: 'post',
        data
    });
}

// 签约客户详情
export function SignStoreDetail (data) {
    return request({
        url: `/distributor/store_contract/detail/${data.contractId}`,
        method: 'get',
        params: data
    });
}

// 签约客户-签约记录
export function SignStoreSignRecords (data) {
    return request({
        url: `/distributor/store_contract/detail_agreement/${data.contractId}`,
        method: 'get',
        params: data
    });
}

// 签约客户详情
export function SignStoreContract (id) {
    return request({
        url: `/supplierAgreement/detail/${id}`,
        method: 'get'
    });
}

// 签约客户-变更记录
export function SignStoreChangelog (data) {
    return request({
        url: `/distributor/store_contract/detail_changelog/${data.contractId}`,
        method: 'get',
        params: data
    });
}

// 签约客户-积分记录
export function SignStoreIntegralList (data) {
    return request({
        url: `/distributor/store_contract/detail_integral_list/${data.storeId}`,
        method: 'get',
        params: data
    });
}

// 签约客户-积分记录
export function SignStoreRebateList (data) {
    return request({
        url: `/distributor/store_contract/detail_rebate_list/${data.storeId}`,
        method: 'get',
        params: data
    });
}

// 获得某品牌商下的经销商签约的品牌池
export function DistributorBrandsList (data) {
    return request({
        url: `/common/brand/getContractBrandsBySupplierId`,
        method: 'get',
        params: data
    });
}

// 获取当前经销商授权品牌方的签约品牌列表
export function DistributorAuthBrandsList () {
    return request({
        url: `/distributor/brand/getAuthBrands`,
        method: 'get'
    });
}

// 获取当前品牌方及经销商签约的业务员
export function DistributorStoreSalesmanList (data) {
    return request({
        url: '/distributor/store_contract/listSalesman',
        method: 'get',
        paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: 'repeat' })
        },
        params: data
    });
}

// 门店签约信息变更_变更门店维护人
export function DistributorStoreSalesmanChange (data) {
    return request({
        url: '/distributor/store_contract/change_salesman',
        method: 'post',
        data
    });
}

// 门店公共资源库列表
export function DistributorPublicStoreList (data) {
    return request({
        url: '/store_public_resource/page',
        method: 'post',
        data
    });
}

/**
 *  签约客户消息推送接口
 */
// 签约客户消息推送列表
export function SignStoreMessageList (data) {
    return request({
        url: '/distributor/push/list',
        method: 'post',
        data
    });
}

// 签约客户消息推送
export function SignStoreMessagePush (data) {
    return request({
        url: '/distributor/push/save',
        method: 'post',
        data
    });
}

/**
 * 业务员签约相关接口
 */

//  经销商业务员列表
export function GetSalesmanList (data) {
    return request({
        url: '/distributor_salesman/page',
        method: 'post',
        data
    });
}

// 当前经销商授权的品牌列表
export function GetEmpowerBrandsList (data) {
    return request({
        url: '/distributor_salesman/listEmpowerBrands',
        method: 'get',
        params: data
    });
}

// 当前经销商签约的品牌商列表
export function GetContractBrandsList (data) {
    return request({
        url: '/distributor_salesman/listContractBrands',
        method: 'get',
        params: data
    });
}

// 品牌商角色列表
export function GetSupplierRolesList (data) {
    return request({
        url: '/distributor_salesman/findRoles',
        method: 'get',
        params: data
    });
}

// 根据手机号获取业务员信息
export function GetInfoByPhone (data) {
    return request({
        url: '/distributor_salesman/mobile/exists',
        method: 'post',
        data
    });
}

// 经销商下签约和授权品牌门店信息
export function GetUnsignStoreList (data) {
    return request({
        url: '/distributor_salesman/listStores',
        method: 'post',
        paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: 'repeat' })
        },
        params: data
    });
}

// 经销商下签约和授权品牌门店信息(已签约)
export function GetsignStoreList (data) {
    return request({
        url: '/distributor_salesman/contractBySalesman',
        method: 'post',
        paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: 'repeat' })
        },
        params: data
    });
}

// 经销商业务员签约
export function SalesmanContract (data) {
    return request({
        url: '/distributor_salesman/contract',
        method: 'post',
        data
    });
}

// 经销商业务员签约详情
export function SalesmanContractDetail (data) {
    return request({
        url: '/distributor_salesman/contractInfo',
        method: 'get',
        params: data
    });
}

// 经销商业务员解约
export function SalesmanDiscontract (data) {
    return request({
        url: '/distributor_salesman/discontract',
        method: 'get',
        params: data
    });
}

// 经销商业务员再签约
export function SalesmanContractStatusUpdate (data) {
    return request({
        url: '/distributor_salesman/updateContractStatus',
        method: 'get',
        params: data
    });
}

/**
 * 散户通讯录接口
 */
// 散户通讯录列表
export function GetRetailList (data) {
    return request({
        url: '/distributor/retail/list',
        method: 'post',
        data
    });
}

// 散户通讯录新增
export function RetailAdd (data) {
    return request({
        url: '/distributor/retail/save',
        method: 'post',
        data
    });
}

// 散户通讯录修改
export function RetailUpdate (data) {
    return request({
        url: '/distributor/retail/update',
        method: 'post',
        data
    });
}

// 散户通讯录导出
export function RetailExport (data) {
    return request({
        url: '/distributor/retail/export',
        method: 'get',
        params: data
    });
}

// 门店授信额度变更日志
export function StoreCreditAmountList (data) {
    return request({
        url: '/distributor/store_credit_amount_log/page',
        method: 'post',
        data
    });
}

// 门店授信额度变更日志-导出
export function StoreCreditAmountExport (data) {
    return request({
        url: '/distributor/store_credit_amount_log/export',
        method: 'post',
        data
    });
}

// 门店授信账户变更日志
export function StoreCreditAccountList (data) {
    return request({
        url: '/distributor/store_credit_account_log/page',
        method: 'post',
        data
    });
}

// 门店授信账户变更日志
export function StoreCreditAccountExport (data) {
    return request({
        url: '/distributor/store_credit_account_log/export',
        method: 'post',
        data
    });
}

// 门店授信账户-变更类型列表
export function StoreCreditAccountChangeTypeList () {
    return request({
        url: '/distributor/store_credit_account/change_types',
        method: 'get'
    });
}

// let brandList = [
//     {
//         brandId: '',
//         brandName: '',
//         areaIds: []
//     }
// ]

/**
 * 返利账户
 */

// 返利账户
export function rebateAccount (data) {
    return request({
        url: `/store/rebateAccount`,
        method: 'post',
        params: data
    });
}

// 返利账户明细列表
export function rebateAccountCredit (data) {
    return request({
        url: `/store/rebateAccountCredit`,
        method: 'post',
        params: data
    });
}

// 数据字典
export function dictionaryListByType (data) {
    return request({
        url: `/dictionary/listByType`,
        method: 'get',
        params: data
    });
}

/**
 * 返利账户
 */
// 积分账户明细
export function scoreAccountList (data) {
    return request({
        url: `/store/scoreDetail`,
        method: 'post',
        data
    });
}

// 积分账户统计
export function scoreAccountData (storeId) {
    return request({
        url: `/store/scoreAccount/${storeId}`,
        method: 'get',
    });
}