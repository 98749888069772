import OrderList from '@/pages/sales/order/index';
import OrderDetail from '@/pages/sales/order/orderDetail';
import RebateAudit from '@/pages/sales/rebateAudit/index';
import RebateAuditDetail from '@/pages/sales/rebateAudit/detail';
import StorePay from '@/pages/sales/storePay/index';
import StorePayDetail from '@/pages/sales/storePay/detail';
import StorePayRefundDetail from '@/pages/sales/storePay/refundDetail';
import StorePayOrderDetail from '@/pages/sales/storePay/orderDetail';
import { applyRoute } from '../const';

const salesRoutes = [
    {
        path: `${applyRoute}/sales/order/list`,
        meta: {
            auth: ['sales'],
            title: '销售订单',
            cache: true,
        },
        component: OrderList,
    },
    {
        path: `${applyRoute}/sales/order/orderDetail`,
        meta: {
            auth: ['sales'],
            title: '销售订单详情',
            cache: false,
        },
        component: OrderDetail,
    },
    {
        path: `${applyRoute}/sales/rebateAudit/list`,
        meta: {
            auth: ['sales'],
            title: '购胎入库返利',
            cache: true,
        },
        component: RebateAudit,
    },
    {
        path: `${applyRoute}/sales/rebateAudit/detail`,
        meta: {
            auth: ['sales'],
            title: '购胎入库返利详情',
            cache: false,
        },
        component: RebateAuditDetail,
    },
    {
        path: `${applyRoute}/sales/storePay/list`,
        meta: {
            auth: ['sales'],
            title: '月度门店线上支付统计',
            cache: true,
        },
        component: StorePay,
    },
    {
        path: `${applyRoute}/sales/storePay/detail`,
        meta: {
            auth: ['sales'],
            title: '门店线上支付明细',
            cache: false,
        },
        component: StorePayDetail,
    },
    {
        path: `${applyRoute}/sales/storePay/refundDetail`,
        meta: {
            auth: ['sales'],
            title: '门店线上退款明细',
            cache: false,
        },
        component: StorePayRefundDetail,
    },
    {
        path: `${applyRoute}/sales/storePay/orderDetail`,
        meta: {
            auth: ['sales'],
            title: '销售订单详情',
            cache: false,
        },
        component: StorePayOrderDetail,
    },
];

export { salesRoutes };
