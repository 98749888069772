import request from '@/plugins/request';

/**
 *  门店签署管理
 */

// 签署门店管理
export function listEsignStore (data) {
    return request({
        url: `/esgin/signInfo/store`,
        method: 'post',
        data,
    });
}

// 签署详情
export function esignStoreDetail (data) {
    return request({
        url: `/esgin/signInfo/storeDetail`,
        method: 'post',
        data,
    });
}


// 全部签署详情
export function esignStoreAllDetail (data) {
    return request({
        url: `/esgin/signInfo/storeAllDetail`,
        method: 'post',
        data,
    });
}

// 签署详情
export function esignStoreFile (data) {
    return request({
        url: `/esgin/contract/findContractDetail`,
        method: 'get',
        params: data,
    });
}

/*
 * 直营门店签署管理 S
 */

// 直营门店签署列表
export function directlySignList (data) {
    return request({
        url: `/distributor/directly/store/sign/list`,
        method: 'post',
        data
    });
}

// 直营门店获取签署url
export function directlySignUrl (storeId) {
    return request({
        url: `/distributor/directly/store/sign/getUrl/${storeId}`,
        method: 'get',
    });
}

// 查询门店签约管理详情
export function signDetail (contractId) {
    return request({
        url: `/distributor/directly/store/sign/detail/${contractId}`,
        method: 'get',
    });
}

/*
 * 直营门店签署管理 E
 */
