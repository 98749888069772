/*
 * @Author: chengleilei
 * @Date: 2021-12-02 13:44:28
 * @Description: 授信管理api
 */
import request from "@/plugins/request";

// 查询授信账户
export function creditList (data) {
    return request({
        url: `/credit/accountList`,
        method: 'post',
        data
    });
}

// 查询授信账户-导出
export function creditExport (data) {
    return request({
        url: `/credit/accountList/export`,
        method: 'post',
        data
    });
}

// 维护人提醒关闭
export function closeNotice (data) {
    return request({
        url: `/credit/notice/close`,
        method: 'post',
        data
    });
}

// 维护人提醒开启
export function openNotice (data) {
    return request({
        url: `/credit/notice/open`,
        method: 'post',
        data
    });
}

// 授信账户关闭
export function closeCreditAccount (data) {
    return request({
        url: `/credit/status/close`,
        method: 'post',
        data
    });
}

// 授信账户开通
export function openCreditAccount (data) {
    return request({
        url: `/credit/status/open`,
        method: 'post',
        data
    });
}

// 授信账户开通变更记录
export function listCreditAccount (data) {
    return request({
        url: `/credit/status/record`,
        method: 'post',
        data
    });
}

// 管理员授信账户信息
export function creditAccountInfo (data) {
    return request({
        url: `/credit/self/info`,
        method: 'post',
        data
    });
}

// 验证密码
export function authPwd (data) {
    return request({
        url: `/credit/self/auth`,
        method: 'post',
        data
    });
}

// 授信使用明细
export function creditUseRecord (data) {
    return request({
        url: `/credit/usable/record`,
        method: 'post',
        data
    });
}

// 授信使用明细导出
export function creditUseExport (data) {
    return request({
        url: `/credit/usable/export`,
        method: 'post',
        data
    });
}

// 授信使用明细小计
export function creditUseTotal (data) {
    return request({
        url: `/credit/usable/total`,
        method: 'post',
        data
    });
}

// 授信额度变更明细
export function creditQutoaRecord (data) {
    return request({
        url: `/credit/qutoa/record`,
        method: 'post',
        data
    });
}

// 授信额度变更明细导出
export function creditQutoaExport (data) {
    return request({
        url: `/credit/qutoa/export`,
        method: 'post',
        data
    });
}

// 授信额度变更明细小计
export function creditQutoaTotal (data) {
    return request({
        url: `/credit/qutoa/total`,
        method: 'post',
        data
    });
}

// 授信收款明细
export function creditRepaymentRecord (data) {
    return request({
        url: `/credit/repayment/record`,
        method: 'post',
        data
    });
}

// 授信收款明细导出
export function creditRepaymentExport (data) {
    return request({
        url: `/credit/repayment/export`,
        method: 'post',
        data
    });
}

// 授信收款明细小计
export function creditRepaymentTotal (data) {
    return request({
        url: `/credit/repayment/total`,
        method: 'post',
        data
    });
}

/**
 * @description 授信账户设置
 */

// 修改授信获取验证码
export function creditCode (data) {
    return request({
        url: `/credit/code`,
        method: 'post',
        data
    });
}

// 关闭授信账户-关闭
export function creditStatusClose (data) {
    return request({
        url: `/credit/self/close`,
        method: 'post',
        data
    });
}

// 关闭授信账户-开启
export function creditStatusOpen (data) {
    return request({
        url: `/credit/self/open`,
        method: 'post',
        data
    });
}

// 修改授信密码
export function creditUpdatePwd (data) {
    return request({
        url: `/credit/update/password`,
        method: 'post',
        data
    });
}

// 调整授信额度
export function updateQuota (data) {
    return request({
        url: `/credit/modify/quota`,
        method: 'post',
        data
    });
}

// 收款
export function creditRepayment (data) {
    return request({
        url: `/credit/repayment`,
        method: 'post',
        data
    });
}
