import AccountList from '@/pages/setting/account/index';
import AccountEdit from '@/pages/setting/account/accountEdit';
import RoleList from '@/pages/setting/role/index';
import RoleEdit from '@/pages/setting/role/roleEdit';
import CompanyAdd from '@/pages/setting/company/add';
import CompanyResult from '@/pages/setting/company/result';
import { applyRoute } from '../const';

const settingRoutes = [
    {
        path: `${applyRoute}/setting/account/list`,
        meta: {
            auth: ['setting'],
            title: '账号管理',
            cache: true,
        },
        component: AccountList,
    },
    {
        path: `${applyRoute}/setting/account/edit`,
        meta: {
            auth: ['setting'],
            title: '账号详情',
            cache: false,
        },
        component: AccountEdit,
    },
    {
        path: `${applyRoute}/setting/role/list`,
        meta: {
            auth: ['setting'],
            title: '角色管理',
            cache: true,
        },
        component: RoleList,
    },
    {
        path: `${applyRoute}/setting/role/edit`,
        meta: {
            auth: ['setting'],
            title: '角色管理详情',
            cache: false,
        },
        component: RoleEdit,
    },
    {
        path: `${applyRoute}/setting/company/add`,
        meta: {
            auth: ['setting'],
            title: '企业认证',
            cache: true,
        },
        component: CompanyAdd,
    },
    {
        path: `${applyRoute}/setting/company/result`,
        meta: {
            auth: ['setting'],
            title: '企业认证结果',
            cache: false,
        },
        component: CompanyResult,
    },
];

export { settingRoutes };
