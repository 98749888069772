const recordListCol = [
    {
        title: '交易流水号',
        key: 'txSn',
        minWidth: 200,
    },
    {
        title: '退款发起时间',
        key: 'startTime',
        minWidth: 150,
    },
    {
        title: '退款完成时间',
        minWidth: 150,
        render: (h, p) => {
            return [p.row.successTime || '-'];
        },
    },
    {
        title: '退款渠道',
        minWidth: 100,
        render: (h, p) => {
            const payTypeMap = {
                0: '快捷',
                1: '微信',
                2: '支付宝',
                3: '返利',
                4: '储蓄卡',
                5: '信用卡',
                6: '授信',
            };
            return [payTypeMap[p.row.payType] || '-'];
        },
    },
    {
        title: '退款金额',
        key: 'totalAmount',
        minWidth: 100,
    },
    {
        title: '受理状态',
        key: 'statusStr',
        minWidth: 100,
    },
    {
        title: '备注',
        minWidth: 200,
        render: (h, p) => {
            return [p.row.remark || '-'];
        },
    },
];

export { recordListCol };
