var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"group-detail"},[_c('PageHeader',{attrs:{"title":"集团单位发票/发货单统计详情","hidden-breadcrumb":""}},[_c('div',{attrs:{"slot":"back"},on:{"click":_vm.jumpPrevPage},slot:"back"},[_c('Icon',{attrs:{"type":"md-arrow-back"}})],1),_c('div',{attrs:{"slot":"action"},slot:"action"},[_c('Button',{staticClass:"ivu-ml-8",attrs:{"type":"primary"},on:{"click":_vm.downLoad}},[_vm._v("下载附件")])],1)]),_c('Card',{staticClass:"ivu-mt",attrs:{"bordered":false,"dis-hover":""}},[_c('Form',{attrs:{"labelWitth":"100"}},[_c('Row',[_c('Col',{attrs:{"span":"16"}},[_c('FormItem',{attrs:{"label":"集团客户名称:"}},[_vm._v(" "+_vm._s(_vm.detail.name || '-')+" ")])],1)],1),_c('Row',[_c('Col',{attrs:{"span":"4"}},[_c('FormItem',{attrs:{"label":"科室:"}},[_vm._v(" "+_vm._s(_vm.dept || '-')+" ")])],1),_c('Col',{attrs:{"span":"4"}},[_c('FormItem',{attrs:{"label":"部门:"}},[_vm._v(" "+_vm._s(_vm.region || '-')+" ")])],1),_c('Col',{attrs:{"span":"5"}},[_c('FormItem',{attrs:{"label":"经销商:"}},[_vm._v(" "+_vm._s(_vm.distributor || '-')+" ")])],1)],1),_c('Row',[_c('Col',{attrs:{"span":"16"}},[_c('FormItem',{attrs:{"label":"单位抬头:"}},[_vm._v(" "+_vm._s(_vm.detail.groupCustomerTitleName || '-')+" ")])],1)],1),_c('FormItem',{attrs:{"label":"发票:"}}),_c('div',{staticClass:"img-list"},_vm._l((_vm.invoices),function(item,index){return _c('span',{key:index,staticClass:"img-item-bg",style:({
                        display: 'inline-block',
                        width: '120px',
                        height: '120px',
                        backgroundImage: 'url(' + item + ')',
                    }),on:{"click":function($event){return _vm.handlePreview(item)}}})}),0),_c('FormItem',{attrs:{"label":"发货清单:"}}),_c('div',{staticClass:"img-list"},_vm._l((_vm.deliveries),function(item,index){return _c('span',{key:index,staticClass:"img-item-bg",style:({
                        display: 'inline-block',
                        width: '120px',
                        height: '120px',
                        backgroundImage: 'url(' + item + ')',
                    }),on:{"click":function($event){return _vm.handlePreview(item)}}})}),0),_c('Row',[_c('Col',{attrs:{"span":"6"}},[_c('FormItem',{attrs:{"label":"轮胎数量："}},[_vm._v(" "+_vm._s(_vm.detail.tyreQuantity || '0')+" ")])],1),_c('Col',{attrs:{"span":"6"}},[_c('FormItem',{attrs:{"label":"发票金额："}},[_vm._v(" "+_vm._s(_vm.detail.invoiceAmount || '0')+" ")])],1)],1)],1)],1),_c('FooterToolbar',[_c('Button',{staticClass:"ml",attrs:{"type":"success"},on:{"click":_vm.jumpPrevPage}},[_vm._v("返回")])],1),_c('Modal',{attrs:{"title":"图片预览","width":"700px","footer-hide":""},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.activeImg}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }