<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div class="ivu-mt ivu-mb">
        <Result type="success" title="认证成功"> </Result>
        <Form :label-width="155" label-position="right">
            <Card title="经销商用章人" :bordered="false" dis-hover class="ivu-mt">
                <Row :gutter="24">
                    <Col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
                        <FormItem label="姓名：">
                            <Input v-model="data.name" readonly />
                        </FormItem>
                    </Col>
                </Row>
                <Row :gutter="24">
                    <Col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
                        <FormItem label="身份证号：">
                            <Input v-model="data.idNumber" readonly />
                        </FormItem>
                    </Col>
                </Row>
                <Row :gutter="24">
                    <Col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
                        <FormItem label="手机号码：">
                            <Input v-model="data.mobile" readonly />
                        </FormItem>
                    </Col>
                </Row>
            </Card>
            <Card title="机构信息" :bordered="false" dis-hover class="ivu-mt">
                <Row :gutter="24">
                    <Col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
                        <FormItem label="法人代表：">
                            <Input v-model="data.legalPersonName" readonly />
                        </FormItem>
                    </Col>
                </Row>
                <Row :gutter="24">
                    <Col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
                        <FormItem label="机构名称：">
                            <Input v-model="data.disName" readonly />
                        </FormItem>
                    </Col>
                </Row>
                <Row :gutter="24">
                    <Col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
                        <FormItem label="统一社会信用代码：">
                            <Input v-model="data.disIdNumber" readonly />
                        </FormItem>
                    </Col>
                </Row>
            </Card>
        </Form>
    </div>
</template>
<script>
export default {
    name: 'result-success',
    props: {
        data: {
            type: Object,
            default: () => {
                return {
                    name: '',
                    idNumber: '',
                    mobile: '',
                    legalPersonName: '',
                    disName: '',
                    disIdNumber: '',
                    invitationUrl: '',
                };
            },
        },
    },
};
</script>
