<template>
    <div class="group-detail" v-loading="loading">
        <PageHeader :title="title" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage">
                <Icon type="md-arrow-back" />
            </div>
        </PageHeader>
        <Card class="ivu-mt" :dis-hover="true" shadow style="margin-bottom: 40px">
            <Tabs type="card" class="bg-white" :animated="true">
                <TabPane label="基础信息">
                    <Form style="clear: both; overflow: hidden" class="ivu-mt-16">
                        <Col v-bind="grid2" class="ivu-mb-16">
                            <FormItem label="客户名称：">{{ baseInfo.buyerName || '-' }}</FormItem>
                        </Col>
                        <Col v-bind="grid2" class="ivu-mb-16">
                            <FormItem label="识别号：">{{ baseInfo.buyerIdentificationNumber || '-' }}</FormItem>
                        </Col>
                        <Col v-bind="grid2" class="ivu-mb-16">
                            <FormItem label="客户维护人：">{{ baseInfo.customerMaintenanceMan || '-' }}</FormItem>
                        </Col>
                        <Col span="24" v-if="baseInfo.contractUrls && baseInfo.contractUrls.length">
                            <FormItem label="合同：">
                                <div class="contract-wrapper">
                                    <img
                                        v-for="item in baseInfo.contractUrls"
                                        style="padding: 10px"
                                        :key="item"
                                        :src="item"
                                        :class="['show-slide', 'img-preview']"
                                        fit="cover"
                                        preview="1"
                                        width="120px"
                                        height="130px"
                                    />
                                </div>
                            </FormItem>
                        </Col>
                    </Form>
                </TabPane>
                <TabPane label="信息维护">
                    <Form style="clear: both; overflow: hidden" class="ivu-mt-16">
                        <Col v-bind="grid2" class="ivu-mb-16">
                            <FormItem label="单位类型：">{{ maintenance.orgTypeDictionaryName || '-' }}</FormItem>
                        </Col>
                        <Col v-bind="grid2" class="ivu-mb-16">
                            <FormItem label="主要车型：">
                                <span
                                    class="ivu-inline ivu-mr-16"
                                    v-for="item in maintenance.carList"
                                    :key="item.carTypeDictionaryId"
                                >
                                    <span>
                                        {{ item.carTypeDictionaryName }}
                                    </span>
                                    <span style="color: #06b9f5">
                                        {{ item.carNum || 0 }}<span class="field-value-more">辆</span>
                                    </span>
                                </span>
                            </FormItem>
                        </Col>
                        <Col v-bind="grid2" class="ivu-mb-16">
                            <FormItem label="轮胎年使用量：">{{ maintenance.tireCountUsedPerYear || '-' }}</FormItem>
                        </Col>
                        <Col v-bind="grid2" class="ivu-mb-16">
                            <FormItem label="单位联系人：">{{ maintenance.linkmanName || '-' }}</FormItem>
                        </Col>
                        <Col v-bind="grid2" class="ivu-mb-16">
                            <FormItem label="联系方式：">{{ maintenance.linkmanPhone || '-' }}</FormItem>
                        </Col>
                        <Col v-bind="grid2" class="ivu-mb-16">
                            <FormItem label="单位地址：">{{ maintenance.orgAddress || '-' }}</FormItem>
                        </Col>
                    </Form>
                </TabPane>
                <TabPane label="上传记录">
                    <Row v-for="(item, index) in list" :key="index" :gutter="24">
                        <Col :span="24">
                            <Icon type="md-time" />
                            <span class="ivu-m-8">{{ item.createDate }}</span>
                        </Col>
                        <Col :span="24">
                            <span class="group-img img-preview" :style="{ backgroundImage: 'url(' + item.url + ')' }">
                                <img :src="item.url" :preview="index" alt="" />
                            </span>
                        </Col>
                        <Col :span="24">
                            <Form style="clear: both; overflow: hidden">
                                <Col v-bind="grid2">
                                    <FormItem label="客户名称：">{{ item.buyerName || '-' }}</FormItem>
                                </Col>
                                <Col v-bind="grid2">
                                    <!--                                    <FormItem label="经销商：">{{item.distributorName|| '-' }}</FormItem>-->
                                </Col>
                                <Col v-bind="grid2">
                                    <FormItem label="识别号：">{{ item.buyerIdentificationNumber || '-' }}</FormItem>
                                </Col>
                                <Col v-bind="grid2">
                                    <FormItem label="单位地址、电话：">{{
                                        item.buyerAddressTelephone || '-'
                                    }}</FormItem>
                                </Col>
                                <Col v-bind="grid2">
                                    <FormItem label="开户账号：">{{ item.buyerBankAccountNumber || '-' }}</FormItem>
                                </Col>
                                <Col v-bind="grid2">
                                    <FormItem label="发票类型：">{{
                                        {
                                            '01': '增值税专用发票',
                                            '03': '机动车销售统一发票',
                                            '04': '增值税普通发票',
                                            '10': '增值税普通发票（电子）',
                                            '11': '增值税普通发票（卷式）',
                                            '14': '增值税普通发票（通行费）',
                                            '15': '二手车销售统一发票',
                                        }[item.invoiceType]
                                    }}</FormItem>
                                </Col>
                                <Col v-bind="grid2">
                                    <FormItem label="校验码：">{{ item.checkCode || '-' }}</FormItem>
                                </Col>
                            </Form>
                        </Col>
                        <Col :span="24" class="ivu-mt-8">
                            <Collapse>
                                <Panel>
                                    查看更多
                                    <Form slot="content" style="clear: both; overflow: hidden">
                                        <Col v-bind="grid2">
                                            <FormItem label="发票代码：">{{ item.invoiceCode || '-' }}</FormItem>
                                        </Col>
                                        <Col v-bind="grid2">
                                            <FormItem label="发票号码：">{{ item.invoiceNumber || '-' }}</FormItem>
                                        </Col>
                                        <Col v-bind="grid2">
                                            <FormItem label="开票日期：">{{ item.billingDate || '-' }}</FormItem>
                                        </Col>
                                        <Col v-bind="grid2">
                                            <FormItem label="金额合计：">{{ item.totalAmount || '-' }}</FormItem>
                                        </Col>
                                        <Col v-bind="grid2">
                                            <FormItem label="价税合计：">{{ item.totalPriceTax || '-' }}</FormItem>
                                        </Col>
                                        <Col v-bind="grid2">
                                            <FormItem label="税额合计：">{{ item.totalTax || '-' }}</FormItem>
                                        </Col>

                                        <Col v-bind="grid2">
                                            <FormItem label="备注：">{{ item.remark || '-' }}</FormItem>
                                        </Col>

                                        <Col v-bind="grid2">
                                            <FormItem label="销方名称：">{{ item.sellerName || '-' }}</FormItem>
                                        </Col>
                                        <Col v-bind="grid2">
                                            <FormItem label="销方识别号：">{{
                                                item.sellerIdentificationNumber || '-'
                                            }}</FormItem>
                                        </Col>
                                        <Col v-bind="grid2">
                                            <FormItem label="销方单位地址、电话：">{{
                                                item.sellerAddressTelephone || '-'
                                            }}</FormItem>
                                        </Col>
                                        <Col v-bind="grid2">
                                            <FormItem label="销方开户账号：">{{
                                                item.sellerBankAccountNumber || '-'
                                            }}</FormItem>
                                        </Col>

                                        <Col v-bind="grid2">
                                            <FormItem label="发票ID：">{{ item.invoiceIdentifyId || '-' }}</FormItem>
                                        </Col>

                                        <Col
                                            span="24"
                                            v-show="item.invoiceQueryItemDTOS && item.invoiceQueryItemDTOS.length > 0"
                                        >
                                            <p style="margin: 12px 0 8px; font-weight: bold; color: #111">货物明细表</p>
                                        </Col>

                                        <Card
                                            v-for="goods in item.invoiceQueryItemDTOS"
                                            :key="goods.id"
                                            style="clear: both; overflow: hidden"
                                        >
                                            <Col v-bind="grid2">
                                                <FormItem label="货物名称：">{{ goods.goodsName || '-' }}</FormItem>
                                            </Col>
                                            <Col v-bind="grid2">
                                                <FormItem label="规格型号：">{{
                                                    goods.specificationModel || '-'
                                                }}</FormItem>
                                            </Col>
                                            <Col v-bind="grid2">
                                                <FormItem label="单位：">{{ goods.unit || '-' }}</FormItem>
                                            </Col>
                                            <Col v-bind="grid2">
                                                <FormItem label="数量：">{{ goods.quantity || '-' }}</FormItem>
                                            </Col>
                                            <Col v-bind="grid2">
                                                <FormItem label="单价：">{{ goods.unitPrice || '-' }}</FormItem>
                                            </Col>
                                            <Col v-bind="grid2">
                                                <FormItem label="金额：">{{ goods.amount || '-' }}</FormItem>
                                            </Col>
                                            <Col v-bind="grid2">
                                                <FormItem label="税率：">{{ goods.taxRate || '-' }}</FormItem>
                                            </Col>
                                        </Card>
                                    </Form>
                                </Panel>
                            </Collapse>
                        </Col>
                        <Col :span="24">
                            <Divider />
                        </Col>
                    </Row>
                </TabPane>
            </Tabs>
        </Card>
        <FooterToolbar>
            <Button @click="jumpPrevPage" style="margin-left: 15px">返回</Button>
        </FooterToolbar>
    </div>
</template>

<script>
import { detailGroupBaseInfo, detailGroupMaintenance, detailGroupCustomer } from '@/api/group';
import { jumpPage, transPathToName } from '@/libs/util';

export default {
    name: 'editOrDetailStore',
    data() {
        return {
            grid2: {
                xl: { span: 12, order: 1 },
                lg: { span: 24, order: 2 },
                md: { span: 24, order: 2 },
                sm: { span: 24, order: 2 },
                xs: { span: 24, order: 2 },
            },
            list: [],
            loading: true,
            id: this.$route.query.id || '',
            title: this.$route.query.mode === 'EDIT' ? '编辑集团客户' : '查看集团客户',
            mode: this.$route.query.mode,
            type: this.$route.query.type,
            baseInfo: {},
            maintenance: {},
        };
    },

    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.loading = true;
            detailGroupBaseInfo({
                id: this.id,
                distributorId: this.$route.query.distributorId,
            }).then(
                (res) => {
                    this.loading = false;
                    this.baseInfo = res;
                },
                () => {
                    this.loading = false;
                },
            );
            detailGroupMaintenance({
                id: this.id,
                distributorId: this.$route.query.distributorId,
            }).then(
                (res) => {
                    this.maintenance = res;
                    this.loading = false;
                },
                () => {
                    this.loading = false;
                },
            );
            detailGroupCustomer({
                id: this.id,
                distributorId: this.$route.query.distributorId,
                size: 999,
            }).then(
                (res) => {
                    this.list = [...this.list, ...res.records];
                    this.loading = false;
                },
                () => {
                    this.loading = false;
                },
            );
        },
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/group/groupCus/list' });
        },
    },
};
</script>

<style lang="less">
.group-detail {
    .ivu-form-item {
        margin-bottom: 0;
    }
    .ivu-form-item-label {
        padding: 7px 5px 7px 0;
    }
    .ivu-form-item-content {
        line-height: 28px;
    }
    .group-img {
        position: relative;
        width: 240px;
        height: 100px;
        display: inline-block;
        margin: 10px 10px 10px 0;
        border-radius: 6px;
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}
</style>
