<!-- eslint-disable vue/no-mutating-props -->
<!--
 * @Author: chengleilei
 * @Date: 2021-12-01 14:09:08
 * @Description: 调整额度弹窗组件
-->
<template>
    <Modal v-model="modal" title="调整额度" :mask-closable="false" @on-cancel="onCancel">
        <Form ref="adjustLimitForm" :model="adjustLimitForm" :rules="rules" :label-width="140" @submit.native.prevent>
            <FormItem label="门店名称：">
                {{ item.storeName || '-' }}
            </FormItem>
            <FormItem label="待收金额(元)："> {{ item.waitCollectionAmount }}元 </FormItem>
            <FormItem label="当前额度(元)："> {{ item.creditQuota }}元 </FormItem>
            <FormItem label="变更后额度(元)：" prop="amount">
                <Input style="width: 200px" v-model="adjustLimitForm.amount" placeholder="请输入"></Input>
                <span class="ivu-ml-8">元</span>
            </FormItem>
            <FormItem label="备注：" prop="remark">
                <Input
                    v-model="adjustLimitForm.remark"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 3 }"
                    :maxlength="200"
                    placeholder="请输入"
                />
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="text" @click="onCancel">取消</Button>
            <Button type="primary" @click="onOk" :loading="loading">确定</Button>
        </div>
    </Modal>
</template>

<script>
import { mapState } from 'vuex';
import { updateQuota } from '@/api/credit';
import { clearEmpty } from '@/libs/util';

export default {
    props: {
        // 调整额度弹窗显隐
        modal: {
            type: Boolean,
            default: false,
        },
        // 行数据
        item: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        const validatePrice = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入变更后额度'));
            } else if (!/(^[0-9]+(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(value)) {
                callback(new Error('仅输入数字和小数点，可精确到小数点后2位'));
            } else if (value == this.item.creditQuota) {
                callback(new Error('新额度不可和当前额度一致'));
            } else {
                callback();
            }
        };
        return {
            adjustLimitForm: {
                amount: '', // 变更后额度
                remark: '', // 备注
            },
            loading: false,
            // 校验规则
            rules: {
                amount: [
                    {
                        required: true,
                        validator: validatePrice,
                    },
                ],
            },
        };
    },
    computed: {
        ...mapState({
            creditPassword: (state) => state.admin.credit.creditPassword,
        }),
    },
    watch: {
        modal(newValue) {
            !newValue && this.$store.commit('admin/credit/clearPwd');
        },
    },
    methods: {
        /**
         * @description 调整额度弹窗确定
         */
        onOk() {
            if (this.loading) return;
            this.$refs['adjustLimitForm'].validate((valid) => {
                if (!valid) return;
                this.loading = true;
                let p = {
                    ...this.adjustLimitForm,
                    creditPassword: this.creditPassword,
                    storeId: this.item.storeId,
                };
                p = clearEmpty(p);
                console.log(p);

                updateQuota(p)
                    .then((res) => {
                        this.onCancel();
                        if (res < 0) {
                            // 额度变更导致余额为负时
                            this.$Modal.warning({
                                title: '额度变更成功',
                                content: `调整后，<span class='red-font'>授信余额为${res}元，</span>门店将不可使用授信支付。`,
                                onOk: () => {
                                    this.$emit('refreshList');
                                },
                            });
                        } else {
                            this.$Message.success('操作成功');
                            this.$emit('refreshList');
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },

        /**
         * @description 调整额度弹窗取消
         */
        onCancel() {
            this.$refs['adjustLimitForm'].resetFields();
            this.$emit('update:modal', false);
        },
    },
};
</script>

<style lang="less">
.red-font {
    color: #e70707;
}
</style>
