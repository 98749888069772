<template>
    <div class="product-mange-list">
        <PageHeader title="集团单位发票/发货单统计" hidden-breadcrumb>
            <div slot="action">
                <Button type="warning" class="ivu-ml-8" :loading="isExporting" @click="showExportModal">导出</Button>
            </div>
        </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" ref="table-form" @on-submit="onSubmit" />
            <table-page :list="list" @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" />
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { titleUploadList, titleUploadListExport } from '../../../api/group';
import { renderButton, clearEmpty, jumpPage } from '@/libs/util';

export default {
    name: 'store-store-manage',
    components: { tableForm, tablePage },
    data() {
        return {
            productIds: [],
            isExporting: false,
            list: {
                page: 1,
                size: window.$pageSize,
                total: 0,
                columns: [
                    {
                        title: '单位名称',
                        key: 'name',
                        align: 'center',
                        fixed: 'left',
                        minWidth: 160,
                    },
                    {
                        title: '抬头',
                        key: 'groupCustomerTitleName',
                        align: 'center',
                        minWidth: 160,
                    },
                    {
                        title: '条数/条',
                        key: 'tyreQuantity',
                        align: 'center',
                        minWidth: 100,
                    },
                    {
                        title: '金额/元',
                        key: 'invoiceAmount',
                        align: 'center',
                        minWidth: 100,
                    },
                    {
                        title: '时间',
                        key: 'createDate',
                        align: 'center',
                        minWidth: 170,
                    },
                    {
                        title: '详情',
                        align: 'center',
                        width: 200,
                        fixed: 'right',
                        render: (h, params) => {
                            return [
                                renderButton(h, { text: '查看', type: 'success', size: 'small' }, () => {
                                    this.onCheck(params.row);
                                }),
                                renderButton(h, { text: '下载附件', type: 'primary', size: 'small' }, () => {
                                    this.onDownLoad(params.row);
                                }),
                            ];
                        },
                    },
                ],
                data: [],
                loading: false,
            },
            formList: [
                {
                    title: '公司名称',
                    type: 'input',
                    key: 'name',
                    placeholder: '请输入集团客户名称',
                },
                {
                    title: '时间',
                    type: 'daterange',
                    key: ['startDate', 'endDate'],
                    placeholder: '请输入天数',
                },
            ],
        };
    },
    activated() {
        this.getData();
    },
    mounted() {},
    methods: {
        getData() {
            let p = this.$refs['table-form'].getFormData();
            let dept = p.dept || [];
            p.deptId = dept[0] || '';
            p.regionId = dept[1] || '';
            p = clearEmpty(p);
            let copyP = { ...p };
            if (copyP['dept']) {
                delete copyP['dept'];
            }
            this.productIds = [];
            this.list.loading = true;
            let params = {
                ...copyP,
                page: this.list.page,
                size: this.list.size,
            };
            titleUploadList(params)
                .then((res) => {
                    this.list.data = [];
                    this.list.total = Number(res.total);
                    this.list.loading = false;
                    this.list.data = res.list || [];
                })
                .catch(() => {
                    this.list.loading = false;
                });
        },
        onPageChange(val) {
            this.list.page = val;
            this.getData();
        },
        onPageSizeChange(val) {
            this.list.page = 1;
            this.list.size = val;
            this.getData();
        },
        onSubmit() {
            this.list.page = 1;
            this.getData();
        },
        showExportModal() {
            let p = this.$refs['table-form'].getFormData();
            let dept = p.dept || [];
            p.deptId = dept[0] || '';
            p.regionId = dept[1] || '';
            p = clearEmpty(p);
            let copyP = { ...p };
            if (copyP['dept']) {
                delete copyP['dept'];
            }
            let params = {
                ...copyP,
            };
            this.$Message.loading({
                content: '导出中...',
                duration: 0,
            });
            this.isExporting = true;
            titleUploadListExport(params).then(
                (res) => {
                    this.$Message.destroy();
                    this.list.loading = false;
                    this.isExporting = false;
                    if (!res) {
                        this.$Message.warning('暂无数据');
                        return false;
                    }
                    let aLink = document.createElement('a');
                    aLink.href = res || '';
                    // console.log('href ===', res)
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    this.$Notice.success({
                        title: '导出成功',
                    });
                },
                () => {
                    this.isExporting = false;
                    this.$Message.destroy();
                },
            );
        },
        onCheck(row) {
            jumpPage({
                path: '/sub-vue2/statistics/group/detail',
                params: {
                    id: row.id,
                    detail: encodeURIComponent(JSON.stringify(row)),
                },
            });
        },
        onDownLoad(row) {
            if (!row.compressedPackageUrl) {
                this.$Message.warning('暂无附件！');
                return false;
            }
            let aLink = document.createElement('a');
            aLink.href = row.compressedPackageUrl || '';
            // console.log('href ===', res)
            document.body.appendChild(aLink);
            aLink.click();
            document.body.removeChild(aLink);
            this.$Message.destroy();
            this.$Message.success('导出成功');
        },
    },
};
</script>

<style scoped lang="less"></style>
