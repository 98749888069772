<template>
  <div>
    <Select
        v-width='"100%"'
        v-model="currValue"
        :multiple="multiple"
        filterable
        clearable
        placeholder="请选择品牌"
        @on-change='onSelectChange'
    >
      <Option
        v-for="item in list"
        :key="item.value"
        :label="item.text"
        :value="item.value.toString()">
      </Option>
    </Select>
  </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    export default {
        props: {
            value: {
                type: [String, Number, Array],
                default: '' || (() => [])
            },
            multiple: {
                type: Boolean,
                default: false
            },
            listType: {
                type: String,
                default: 'brandList'
            }
        },
        mounted () {
            switch (this.listType) {
            case 'brandList':
                this.getBrandList()
                break;
            case 'supplierList':
                this.selectDefault()
                break;
            default:
                break;
            }
        },
        computed: {
            ...mapGetters('admin/store', [
                'brandList',
                'supplierList'
            ]),
            currValue: {
                get () {
                    return this.value
                },
                set (v) {
                    this.$emit('input', v)
                    // this.$emit('on-change', v)
                }
            },
            list () {
                return this[this.listType]
            }
        },
        methods: {
            ...mapActions('admin/store', [
                'getBrandList',
                'getSupplierList'
            ], true),
            selectDefault () { // 如需默认选中第一项，手动调用该方法
                this.getSupplierList().then((data) => {
                    if (data && data.length) {
                        this.currValue = data[0].value
                        this.onSelectChange(this.currValue)
                    }
                })
            },
            onSelectChange (cur) {
                this.$emit('on-change', cur)
            }
        }
    }
</script>
