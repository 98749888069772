<!--
 * @Author: forguo
 * @Date: 2021-11-30 14:10:25
 * @Description: 授信收款明细
-->
<template>
    <div v-loading="list.loading">
        <PageHeader title="授信收款明细" hidden-breadcrumb></PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" @on-submit="onSubmit" @on-export="exportInfo" ref="table-form">
                <Button class="ivu-ml-8" :loading="isExporting" :disabled="isExporting" slot="extraBtn" type="error"
                    >导出</Button
                >
            </table-form>
            <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list" />
        </Card>
        <Alert class="ivu-mt"> 小计：当前门店共收款{{ total == null ? '-' : total }}元 </Alert>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { clearEmpty } from '@/libs/util';
import { creditRepaymentRecord, creditRepaymentExport, creditRepaymentTotal } from '@/api/credit';

export default {
    components: {
        tableForm,
        tablePage,
    },
    data() {
        return {
            isExporting: false,
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    {
                        title: '收款单号',
                        key: 'creditCollectionNo',
                        align: 'center',
                        minWidth: 240,
                        fixed: 'left',
                    },
                    {
                        title: '操作时间',
                        key: 'createDate',
                        align: 'center',
                        minWidth: 180,
                    },
                    {
                        title: '门店名称',
                        key: 'storeName',
                        align: 'center',
                        minWidth: 180,
                    },
                    {
                        title: '联系人',
                        key: 'ownerName',
                        align: 'center',
                        minWidth: 180,
                    },
                    {
                        title: '联系方式',
                        key: 'ownerPhone',
                        align: 'center',
                        minWidth: 180,
                    },
                    {
                        title: '收款金额（元）',
                        key: 'payFee',
                        align: 'center',
                        minWidth: 150,
                        render: (h, p) => {
                            return h('div', `${p.row.collectionAmount == null ? '-' : p.row.collectionAmount}`);
                        },
                    },
                    {
                        title: '合计收款（元）',
                        key: 'refundPayFee',
                        align: 'center',
                        minWidth: 150,
                        render: (h, p) => {
                            return h('div', `${p.row.totalCollectAmount == null ? '-' : p.row.totalCollectAmount}`);
                        },
                    },
                    { title: '收款渠道', key: 'collectionChannel', align: 'center', minWidth: 150 },
                    { title: '收款账户', key: 'collectionAccount', align: 'center', minWidth: 150 },
                    { title: '交易流水号', key: 'tradeTxSn', align: 'center', minWidth: 150 },
                    { title: '后台操作人', key: 'operationName', align: 'center', minWidth: 150 },
                    {
                        title: '备注',
                        key: 'remark',
                        align: 'center',
                        minWidth: 130,
                        height: 48,
                        ellipsis: true,
                        tooltip: true,
                    },
                ],
                data: [],
            },
            formList: [
                {
                    title: '收款单号',
                    type: 'input',
                    key: 'creditCollectionNo',
                },
                {
                    title: '时间',
                    type: 'daterange',
                    key: ['startTime', 'endTime'],
                },
                {
                    title: '门店信息',
                    placeholder: '请输入门店名称/联系人/联系方式',
                    type: 'input',
                    key: 'storeName',
                },
                {
                    title: '后台操作人',
                    type: 'input',
                    key: 'operationName',
                },
                {
                    title: '备注',
                    type: 'input',
                    key: 'remark',
                },
            ],
            total: null,
            storeId: this.$route.query.storeId || '',
        };
    },
    mounted() {
        this.storeId = this.$route.query.storeId || '';
        this.storeName = this.$route.query.storeName || '';
        this.$refs['table-form'].data.storeName = this.storeName;
        this.getData();
    },
    methods: {
        exportInfo() {
            this.exportData();
        },
        exportData() {
            if (this.isExporting) {
                return false;
            }
            let p = this.$refs['table-form'].getFormData();
            this.verifyStatus = p.status;
            delete p.date;
            if (p['dept']) {
                p.deptId = p.dept[0];
                p.regionId = p.dept[1];
                delete p['dept'];
            }
            if (p.provinceId) p.provinceId = p.provinceId[0];
            let params = clearEmpty({
                ...p,
            });
            this.$Message.loading({
                content: '导出中...',
                duration: 0,
            });
            this.isExporting = true;
            // delete params.provinceId
            creditRepaymentExport(params).then(
                (res) => {
                    this.$Message.destroy();
                    this.list.loading = false;
                    this.isExporting = false;
                    if (!res) {
                        this.$Message.warning('暂无数据');
                        return false;
                    }
                    let aLink = document.createElement('a');
                    aLink.href = res || '';
                    // console.log('href ===', res)
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    this.$Notice.success({
                        title: '导出成功',
                    });
                },
                () => {
                    this.isExporting = false;
                    this.$Message.destroy();
                },
            );
        },
        getNowMonthTime() {
            let date = new Date();
            let y = date.getFullYear();
            let m = date.getMonth() + 1;
            return y + '-' + m + '-01 00:00:00';
        },
        onPageChange(val) {
            if (this.isChangePage) return;
            this.list.current = val;
            this.getData();
        },
        onPageSizeChange(val) {
            this.list.current = 1;
            this.isChangePage = true;
            this.list.size = val;
            this.getData();
        },
        getData() {
            this.list.loading = true;
            let p = this.$refs['table-form'].getFormData();
            const param = clearEmpty({
                current: this.list.current,
                size: this.list.size,
                ...p,
            });
            if (this.storeId) {
                param.storeId = this.storeId;
            }
            console.log(param);
            creditRepaymentRecord(param)
                .then((res) => {
                    this.list.loading = false;
                    this.list.total = Number(res.total);
                    this.list.data = (res.records || []).map((item) => {
                        for (let key in item) {
                            if (item[key] == null) {
                                item[key] = '-';
                            }
                        }
                        return {
                            ...item,
                        };
                    });
                })
                .catch(() => {
                    this.list.loading = false;
                });
            creditRepaymentTotal(param).then((res) => {
                this.total = res || '-';
            });
        },
        onSubmit(param) {
            this.storeId = '';
            this.list.current = 1;
            this.getData(param);
        },
    },
};
</script>
