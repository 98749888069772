<!--
 * @Author: forguo
 * @Date: 2021-10-20 16:18:34
 * @Description: 返利账户
-->
<template>
    <div class="rebate-record" v-loading="pageLoading">
        <Card :bordered="false" title="账户统计" dis-hover class="ivu-mt-16 i-table-no-border">
            <DescriptionList title="">
                <Description term="可用余额：">{{
                    detailInfo.totalMoney != null ? detailInfo.totalMoney : '-'
                }}</Description>
                <Description term="累计收入：">{{
                    detailInfo.cumulativeIncome != null ? detailInfo.cumulativeIncome : '-'
                }}</Description>
                <Description term="累计支出：">{{
                    detailInfo.cumulativeExpenditure != null ? detailInfo.cumulativeExpenditure : '-'
                }}</Description>
            </DescriptionList>
        </Card>
        <Card :bordered="false" title="账户明细" dis-hover class="i-table-no-border">
            <table-page :list="list" @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" />
        </Card>
    </div>
</template>

<script>
import { rebateAccount, rebateAccountCredit } from '@/api/store';
import { renderButton, jumpPage } from '@/libs/util';
import tablePage from '@/components/tablePage';

export default {
    name: 'AdvertPositionAdd',
    components: { tablePage },
    data() {
        return {
            id: this.$route.query.id || '',
            distributorId: this.$route.query?.distributorId || '',
            pageLoading: false,
            detailInfo: {},
            // 表格数据列表
            list: {
                page: 1,
                size: window.$pageSize,
                total: 0,
                columns: [
                    {
                        title: '入账时间',
                        key: 'createDate',
                        align: 'center',
                        minWidth: 120,
                    },
                    {
                        title: '收入（元）',
                        key: 'creditIn',
                        align: 'center',
                        minWidth: 80,
                        render: (h, p) => {
                            return h('div', p.row.creditIn || '-');
                        },
                    },
                    {
                        title: '支出（元）',
                        key: 'creditOut',
                        align: 'center',
                        minWidth: 80,
                        render: (h, p) => {
                            return h('div', p.row.creditOut || '-');
                        },
                    },
                    {
                        title: '账户结余',
                        key: 'accountRest',
                        align: 'center',
                        minWidth: 80,
                    },
                    {
                        title: '备注',
                        key: 'remark',
                        align: 'center',
                        minWidth: 80,
                        render: (h, p) => {
                            return h('div', p.row.remark || '-');
                        },
                    },
                    {
                        title: '操作',
                        key: 'oprate',
                        align: 'center',
                        width: 120,
                        fixed: 'right',
                        render: (h, p) => {
                            return [
                                p.row.orderId
                                    ? renderButton(h, { text: '查看', type: 'primary' }, () => {
                                          this.checkDetail(p.row);
                                      })
                                    : '-',
                            ];
                        },
                    },
                ],
                data: [],
                loading: false,
            },
            productColumns: [
                {
                    title: '入账时间',
                    key: 'createDate',
                    align: 'center',
                    minWidth: 120,
                },
                {
                    title: '收入（元）',
                    key: 'creditIn',
                    align: 'center',
                    minWidth: 80,
                    render: (h, p) => {
                        return h('div', p.row.creditIn || '-');
                    },
                },
                {
                    title: '支出（元）',
                    key: 'creditOut',
                    align: 'center',
                    minWidth: 80,
                    render: (h, p) => {
                        return h('div', p.row.creditOut || '-');
                    },
                },
                {
                    title: '账户结余',
                    key: 'accountRest',
                    align: 'center',
                    minWidth: 80,
                },
                {
                    title: '备注',
                    key: 'remark',
                    align: 'center',
                    minWidth: 80,
                    render: (h, p) => {
                        return h('div', p.row.remark || '-');
                    },
                },
                {
                    title: '操作',
                    key: 'oprate',
                    align: 'center',
                    width: 120,
                    fixed: 'right',
                    render: (h, p) => {
                        return [
                            p.row.orderId
                                ? renderButton(h, { text: '查看', type: 'primary' }, () => {
                                      this.checkDetail(p.row);
                                  })
                                : '-',
                        ];
                    },
                },
            ],
            productList: [],
        };
    },
    created() {
        this.getData();
    },
    methods: {
        checkDetail(row) {
            jumpPage({ path: '/sub-vue2/customer/manage/orderDetail', params: { id: row.orderId } });
        },
        /**
         * @description 查特价订单详情
         */
        getData() {
            const params = {
                id: this.id,
                distributorId: this.distributorId,
                current: this.list.page,
                size: this.list.size,
            };
            this.pageLoading = true;
            rebateAccount(params).then(
                (res) => {
                    this.detailInfo = res || {};
                    this.pageLoading = false;
                },
                () => {
                    this.pageLoading = false;
                },
            );
            this.pageLoading = true;
            rebateAccountCredit(params).then(
                (res) => {
                    this.list.data = res.records || [];
                    this.list.total = Number(res.total);
                    this.pageLoading = false;
                },
                () => {
                    this.pageLoading = false;
                },
            );
        },
        /**
         * @description 页码改变
         * @param {Number} val 页码
         */
        onPageChange(val) {
            this.list.page = val;
            this.getData();
        },

        /**
         * @description x条/页改变
         * @param {Number} val 条数
         */
        onPageSizeChange(val) {
            this.list.page = 1;
            this.list.size = val;
            this.getData();
        },
    },
};
</script>
<style lang="less" scoped>
.rebate-record {
    padding-bottom: 50px;
    /deep/ .i-table-no-border .ivu-table th {
        background-color: #f8f8f9;
    }
}
</style>
