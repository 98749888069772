<template>
    <div class="product-mange-list">
        <PageHeader title="销售订单" hidden-breadcrumb />
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" @on-submit="onSubmit" @on-export="onExport" ref="table-form">
                <Button class="ivu-ml-8" :loading="exportLoading" :disabled="exportLoading" slot="extraBtn" type="error"
                    >导出</Button
                >
            </table-form>
        </Card>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <div class="dataAnalysis-section">
                <Icon type="md-analytics" color="#3b91f7" size="24px" />
                <div class="text" style="margin-left: 6px">
                    数据统计： 参与门店 {{ orderListDataAnalysis.storeNum }}、 已支付轮胎总数
                    {{ orderListDataAnalysis.hasPayTyreNum }}、 总销售额
                    {{ orderListDataAnalysis.totalOrderAmount }}元、 总支付金额
                    {{ orderListDataAnalysis.totalOnlineOrderAmount }}元、 返利抵用
                    {{ orderListDataAnalysis.deductionAmount }}元、 线上支付
                    {{ orderListDataAnalysis.thirdPayAmount }}元、 授信支付{{ orderListDataAnalysis.creditAmount }}元
                </div>
            </div>
        </Card>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <Tabs :value="currentTab" :animated="true" @on-click="handleTabClick" style="margin-top: -12px">
                <TabPane label="全部" name="0">
                    <FlexCustomTable
                        :list="list"
                        @onSelectionChange="handleSelection"
                        @onPageChange="onPageChange"
                        @onPageSizeChange="onPageSizeChange"
                        @onRefundBtnClick="onRefundBtnClick"
                        @onOffOrderBtnClick="onOffOrderBtnClick"
                        @onReturnBtnClick="handleReturnBtnClick"
                    />
                </TabPane>
                <TabPane label="待付款" name="1">
                    <FlexCustomTable
                        :list="list"
                        @onSelectionChange="handleSelection"
                        @onPageChange="onPageChange"
                        @onPageSizeChange="onPageSizeChange"
                        @onRefundBtnClick="onRefundBtnClick"
                        @onOffOrderBtnClick="onOffOrderBtnClick"
                        @onReturnBtnClick="handleReturnBtnClick"
                    />
                </TabPane>
            </Tabs>
        </Card>
        <refund-modal :modal.sync="modal" :item="item" @confirmOk="confirmOk"></refund-modal>
        <reTurnModal :modal.sync="reTurnModal" :item="item" @onfirmOk="reTurnOk"></reTurnModal>
        <offOrderModal :modal.sync="offOrderModal" :item="item" @confirmOk="offOrderConfirmOk"></offOrderModal>
        <Modal v-model="exportModal" title="导出报表" @on-ok="handleModalOk" @on-cancel="handleModalCancel">
            <div class="modal-wrapper">
                <div class="radio-label">导出维度：</div>
                <RadioGroup v-model="exportDimension" vertical>
                    <Radio label="1">
                        <span>以订单维度导出（报表中每个订单号一行）</span>
                    </Radio>
                    <Radio label="2">
                        <span>以商品维度导出（报表中每个商品名称一行）</span>
                    </Radio>
                </RadioGroup>
            </div>
        </Modal>
    </div>
</template>

<script>
import tableForm from '@/components/tableForm';
import FlexCustomTable from './customTable/flex-custom-table';
import refundModal from './components/refundModal';
import reTurnModal from '@/pages/sales/order/components/reTurnModal';
import offOrderModal from '@/pages/sales/order/components/offOrderModal';
import { orderList, orderListExport, orderListDataAnalysis } from '@/api/sales';
import { clearEmpty } from '@/libs/util';

export default {
    name: 'order-list',
    components: { tableForm, refundModal, offOrderModal, FlexCustomTable, reTurnModal },
    data() {
        return {
            exportDimension: '1', // 导出维度
            exportModal: false, // 导出弹框
            currentTab: 0, // 当前tab 0全部 1待付款
            modal: false, // 退款弹窗显隐
            offOrderModal: false, // 取消订单弹窗显隐
            reTurnModal: false, // 标记发货弹窗显隐
            productIds: [],
            exportLoading: false,
            item: {}, // 选择退款的列表行数据
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                columns: [
                    {
                        title: '商品信息',
                        align: 'center',
                        minWidth: 330,
                        resizable: true,
                        percentage: '25%',
                        lg: 6,
                    },
                    {
                        title: '单价/下单数量',
                        align: 'center',
                        minWidth: 100,
                        percentage: '15%',
                        lg: 3,
                    },
                    {
                        title: '入库/活动',
                        align: 'center',
                        minWidth: 200,
                        percentage: '15%',
                        lg: 3,
                    },
                    {
                        title: '收款信息',
                        align: 'center',
                        minWidth: 200,
                        percentage: '15%',
                        lg: 4,
                    },
                    {
                        title: '门店信息',
                        align: 'center',
                        minWidth: 250,
                        width: 250,
                        resizable: true,
                        percentage: '15%',
                        lg: 5,
                        render: (h, p) => {
                            return h('div', [
                                h('p', { class: 'order-item-column store-info' }, '门店名称：' + p.row.storeName),
                                h('p', { class: 'order-item-column store-info' }, '门店联系人：' + p.row.ownerName),
                                h('p', { class: 'order-item-column store-info' }, '门店联系方式：' + p.row.ownerPhone),
                                h('p', { class: 'order-item-column store-info' }, '经销商：' + p.row.distributorName),
                            ]);
                        },
                    },
                    {
                        title: '操作',
                        align: 'center',
                        minWidth: 160,
                        percentage: '15%',
                        lg: 3,
                    },
                    //     {
                    //         title: '商品名称',
                    //         align: 'center',
                    //         minWidth: 330,
                    //         resizable: true,
                    //     },
                    //     {
                    //         title: '单价',
                    //         align: 'center',
                    //         minWidth: 100,
                    //     },
                    //     {
                    //         title: '定金',
                    //         align: 'center',
                    //         minWidth: 100,
                    //     },
                    //     {
                    //         title: '数量',
                    //         align: 'center',
                    //         minWidth: 100,
                    //     },
                    //     {
                    //         title: '金额',
                    //         align: 'center',
                    //         minWidth: 100,
                    //     },
                    //     {
                    //         title: '返利',
                    //         align: 'center',
                    //         minWidth: 120,
                    //     },
                    //     {
                    //         title: '活动',
                    //         align: 'center',
                    //         minWidth: 200,
                    //     },
                    //     {
                    //         title: '订单总额',
                    //         key: 'orderAmount',
                    //         align: 'center',
                    //         minWidth: 180,
                    //     },
                    //     {
                    //         title: '订单状态',
                    //         align: 'center',
                    //         minWidth: 100,
                    //     },
                    //     {
                    //         title: '售后状态',
                    //         key:'afterSaleStatusStr',
                    //         align: 'center',
                    //         minWidth: 100,
                    //     },
                    //     {
                    //         title: '门店信息',
                    //         align: 'center',
                    //         minWidth: 250,
                    //         width: 250,
                    //         resizable: true,
                    //         render: (h, p) => {
                    //             return h('div', [
                    //                 h(
                    //                     'p',
                    //                     { class: 'order-item-column store-info' },
                    //                     '门店名称：' + p.row.storeName
                    //                 ),
                    //                 h(
                    //                     'p',
                    //                     { class: 'order-item-column store-info' },
                    //                     '门店联系人：' + p.row.ownerName
                    //                 ),
                    //                 h(
                    //                     'p',
                    //                     { class: 'order-item-column store-info' },
                    //                     '门店联系方式：' + p.row.ownerPhone
                    //                 ),
                    //                 h(
                    //                     'p',
                    //                     { class: 'order-item-column store-info' },
                    //                     '经销商：' + p.row.distributorName
                    //                 ),
                    //             ])
                    //         },
                    //     },
                    //     {
                    //         title: '操作',
                    //         align: 'center',
                    //         minWidth: 160,
                    //     },
                ],
                data: [],
                loading: false,
            },
            formList: [
                {
                    title: '订单编号',
                    type: 'input',
                    key: 'orderNo',
                    placeholder: '请输入订单编号',
                },
                {
                    title: '活动类型',
                    type: 'select',
                    key: 'specialType',
                    options: [
                        {
                            name: '工厂促销',
                            id: '0',
                        },
                        {
                            name: '区域促销',
                            id: '1',
                        },
                    ],
                },
                {
                    title: '活动编号',
                    type: 'input',
                    key: 'activitySn',
                    placeholder: '请输入活动编号',
                },
                {
                    title: '活动名称',
                    type: 'input',
                    key: 'activityName',
                    placeholder: '请输入活动名称',
                },
                {
                    title: '商品名称',
                    type: 'input',
                    key: 'productName',
                    placeholder: '请输入商品名称',
                },
                {
                    title: '门店信息',
                    type: 'input',
                    key: 'storeName',
                    placeholder: '请输入门店名称/联系人/联系方式',
                },
                {
                    title: '支付状态',
                    type: 'select',
                    key: 'payStatus',
                    options: [
                        {
                            name: '已完成支付',
                            id: '1',
                        },
                        {
                            name: '未完成支付',
                            id: '2',
                        },
                    ],
                },

                {
                    title: '发货状态',
                    type: 'select',
                    key: 'sendGoodsStatus',
                    options: [
                        {
                            name: '待发货',
                            id: '0',
                        },
                        {
                            name: '已部分发货',
                            id: '1',
                        },
                        {
                            name: '已全部发货',
                            id: '2',
                        },
                    ],
                },
                {
                    title: '售后状态',
                    type: 'select',
                    key: 'afterSaleStatus',
                    options: [
                        {
                            name: '无售后',
                            id: '1',
                        },
                        {
                            name: '已部分退款',
                            id: '2',
                        },
                        {
                            name: '已全额退款',
                            id: '3',
                        },
                        {
                            name: '已部分取消',
                            id: '4',
                        },
                        {
                            name: '已全部取消',
                            id: '5',
                        },
                    ],
                },
                {
                    title: '下单时间',
                    type: 'daterange',
                    key: ['startTime', 'endTime'],
                },
            ],
            tableSelection: [],
            orderListDataAnalysis: {},
        };
    },
    mounted() {
        let form = this.$refs['table-form'].getFormData();
        this.getData(form);
        this.$bus.$on('getData', () => {
            this.getData();
        });
    },
    methods: {
        /**
         * @description 确认退款刷新页面
         */
        confirmOk() {
            let form = this.$refs['table-form'].getFormData();
            this.getData(form);
        },
        /**
         * @description 确认取消订单刷新页面
         */
        offOrderConfirmOk() {
            let form = this.$refs['table-form'].getFormData();
            this.getData(form);
        },
        /**
         * @description 标记发货
         *
         */
        reTurnOk() {
            let form = this.$refs['table-form'].getFormData();
            this.getData(form);
        },

        /**
         * @description 退款按钮单击事件
         * @param {Object} item 列表行数据
         */
        onRefundBtnClick(item) {
            this.modal = true;
            this.item = item;
        },
        /**
         * @description 取消订单按钮单击事件
         * @param {Object} item 列表行数据
         */
        onOffOrderBtnClick(item) {
            this.offOrderModal = true;
            this.item = item;
        },
        /**
         * @description 标记发货
         * @param {Object} item 列表行数据
         */
        handleReturnBtnClick(item) {
            this.reTurnModal = true;
            this.item = item;
        },

        /**
         * @description 切换tab
         * @param {Number} e tab标识 0全部 1待付款
         */
        handleTabClick(e) {
            if (e === this.currentTab) {
                return false;
            }
            this.currentTab = e;
            this.onPageChange(1);
        },

        getData(p) {
            this.productIds = [];
            this.list.loading = true;
            let copyP = { ...clearEmpty(p) };
            if (copyP['dept']) {
                delete copyP['dept'];
            }

            let params = {
                status: this.currentTab == 1 ? 0 : null,
                current: this.list.current,
                size: this.list.size,
                ...copyP,
            };
            this.getOrderListDataAnalysis(params);
            return orderList(params)
                .then((data) => {
                    this.list.data = [];
                    this.list.total = Number(data.total);
                    this.list.loading = false;
                    const unitMap = {
                        0: '',
                        1: '条',
                        2: '套',
                    };
                    this.list.data = (data.records || []).map((item) => {
                        item.itemsvo = item.itemsvo.map((k) => {
                            return {
                                ...k,
                                labels: k.label ? k.label.split(';') : [],
                                unitStr: unitMap[k.unit],
                            };
                        });
                        return item;
                    });
                })
                .catch(() => {
                    this.list.loading = false;
                });
        },
        // 订单数据统计
        async getOrderListDataAnalysis(params) {
            const data = await orderListDataAnalysis(params);
            this.orderListDataAnalysis = data;
        },
        // table 选择改变
        handleSelection(val) {
            this.tableSelection = val;
        },
        onSelectionChange(selection) {
            this.productIds = (selection || []).map((item) => item.id);
        },
        onPageChange(val) {
            this.list.current = val;
            let form = this.$refs['table-form'].getFormData();
            this.getData(form);
        },
        onPageSizeChange(val) {
            this.list.current = 1;
            this.list.size = val;
            let form = this.$refs['table-form'].getFormData();
            this.getData(form);
        },
        onSubmit(params) {
            console.log('before 查询 === ', params);
            let dept = params.dept || [];
            params.deptId = dept[0] || '';
            params.regionId = dept[1] || '';
            this.list.current = 1;
            this.getData(params);
        },
        // 导出弹框点击确定
        handleModalOk() {
            this.onExportFn();
        },
        // 导出弹框取消
        handleModalCancel() {},
        onExport() {
            this.exportModal = true;
        },
        onExportFn() {
            this.exportLoading = true;
            let form = this.$refs['table-form'].getFormData();
            let data = {
                ...form,
                status: this.currentTab == 1 ? 0 : null,
                exportDimension: this.exportDimension,
            };
            let copy = { ...clearEmpty(data) };
            let dept = copy.dept || [];
            copy.deptId = dept[0] || '';
            copy.regionId = dept[1] || '';
            if (copy['dept']) {
                delete copy['dept'];
            }
            this.$Message.loading({
                content: '导出中...',
                duration: 0,
            });
            let params = {
                ...clearEmpty(copy),
            };
            orderListExport(params)
                .then((res) => {
                    let aLink = document.createElement('a');
                    aLink.href = res || '';
                    // console.log('href ===', res)
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    this.$Message.destroy();
                    this.$Message.success('导出成功');
                    this.exportLoading = false;
                })
                .catch(() => {
                    this.$Message.destroy();
                    this.exportLoading = false;
                });
        },
    },
};
</script>

<style lang="less">
.product-mange-list {
    .dataAnalysis-section {
        display: flex;
        align-items: center;
    }
    .order-item-column {
        margin: 0 auto;
    }
    .store-info {
        margin: 3px auto;
        font-size: 13px;
        text-align: left;
    }
}
</style>
