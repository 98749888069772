<template>
    <div>
        <PageHeader title="签署门店销售任务量" hidden-breadcrumb> </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <Tabs :value="currentTab" :animated="true" @on-click="handleTabClick" style="margin-top: -12px">
                <TabPane label="2024年" name="2024">
                    <table-form
                        :formList="formList2024"
                        @on-submit="onSubmit"
                        @on-export="exportInfo"
                        ref="table-form-2024"
                    >
                        <Button :disabled="isExporting" class="ivu-ml-8" slot="extraBtn" type="error">导出</Button>
                    </table-form>
                    <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list2024" />
                </TabPane>
                <TabPane label="2023年" name="2023">
                    <table-form
                        :formList="formList2023"
                        @on-submit="onSubmit"
                        @on-export="exportInfo"
                        ref="table-form-2023"
                    >
                        <Button :disabled="isExporting" class="ivu-ml-8" slot="extraBtn" type="error">导出</Button>
                    </table-form>
                    <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list2023" />
                </TabPane>
                <TabPane label="2022年" name="2022">
                    <table-form
                        :formList="formList2022"
                        @on-submit="onSubmit"
                        @on-export="exportInfo"
                        ref="table-form-2022"
                    >
                        <Button :disabled="isExporting" class="ivu-ml-8" slot="extraBtn" type="error">导出</Button>
                    </table-form>
                    <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list2022" />
                </TabPane>
                <TabPane label="2021年" name="2021">
                    <table-form
                        :formList="formList2021"
                        @on-submit="onSubmit"
                        @on-export="exportInfo"
                        ref="table-form-2021"
                    >
                        <Button :disabled="isExporting" class="ivu-ml-8" slot="extraBtn" type="error">导出</Button>
                    </table-form>
                    <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list2021" />
                </TabPane>
            </Tabs>
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { esignTaskPage, esignTaskPageCurrent, esignTaskExport, esignTaskCurrentExport } from '@/api/statistics';
import { renderButton, clearEmpty, jumpPage } from '@/libs/util';
import { AllSeriesList, esign2023Level, getLevelConfig } from '@/api/common';
import { formList, commonCol, zhilunSignDataCol, yearDataCol, monthAndQuarterDataCol } from './const';

export default {
    components: {
        tableForm,
        tablePage,
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
            currentTab: '2024', // 2021年 2022年 2023年 2024年
            yearArr: ['2021', '2022', '2023', '2024'],
            list2024: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    ...commonCol,
                    ...zhilunSignDataCol,
                    ...monthAndQuarterDataCol,
                    ...yearDataCol,
                    {
                        title: '操作',
                        key: 'oprate',
                        align: 'center',
                        width: 150,
                        fixed: 'right',
                        render: (h, p) => {
                            return [
                                renderButton(h, { text: '详情', type: 'primary' }, () => {
                                    this.checkDetail(p.row);
                                }),
                            ];
                        },
                    },
                ],
                data: [],
            },
            list2023: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    ...commonCol,
                    ...zhilunSignDataCol,
                    ...monthAndQuarterDataCol,
                    ...yearDataCol,
                    {
                        title: '操作',
                        key: 'oprate',
                        align: 'center',
                        width: 150,
                        fixed: 'right',
                        render: (h, p) => {
                            return [
                                renderButton(h, { text: '详情', type: 'primary' }, () => {
                                    this.checkDetail(p.row);
                                }),
                            ];
                        },
                    },
                ],
                data: [],
            },
            list2022: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    ...commonCol,
                    ...zhilunSignDataCol,
                    ...yearDataCol,
                    {
                        title: '操作',
                        key: 'oprate',
                        align: 'center',
                        width: 150,
                        fixed: 'right',
                        render: (h, p) => {
                            return [
                                renderButton(h, { text: '详情', type: 'primary' }, () => {
                                    this.checkDetail(p.row);
                                }),
                            ];
                        },
                    },
                ],
                data: [],
            },
            list2021: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    ...commonCol,
                    {
                        title: '年度完成量/年度总任务量',
                        key: 'yearNumStr',
                        align: 'center',
                        minWidth: 205,
                        render: (h, p) => {
                            return h('div', {}, `${p.row.yearNumStr || '--'}`);
                        },
                    },
                    {
                        title: '年度完成率',
                        key: 'yearRate',
                        align: 'center',
                        minWidth: 160,
                        render: (h, p) => {
                            return h('div', {}, `${p.row.yearRate || '--'}`);
                        },
                    },
                    {
                        title: '操作',
                        key: 'oprate',
                        align: 'center',
                        width: 150,
                        fixed: 'right',
                        render: (h, p) => {
                            return [
                                renderButton(h, { text: '详情', type: 'primary' }, () => {
                                    this.checkDetail(p.row);
                                }),
                            ];
                        },
                    },
                ],
                data: [],
            },
            formList2024: [
                ...formList,
                {
                    title: '主品牌/档次',
                    type: 'cascaderData',
                    key: 'brand',
                    filterable: true,
                    options: [],
                    loadData: this.loadData,
                },
            ],
            formList2023: [
                ...formList,
                {
                    title: '主品牌/档次',
                    type: 'cascaderData',
                    key: 'brand',
                    filterable: true,
                    options: [],
                    loadData: this.loadData,
                },
            ],
            formList2022: [
                ...formList,
                {
                    title: '主品牌/档次',
                    type: 'cascaderData',
                    key: 'brand',
                    filterable: true,
                    options: [],
                    loadData: this.loadData,
                },
            ],
            formList2021: [
                ...formList,
                {
                    title: '主品牌/档次',
                    type: 'cascaderData',
                    key: 'brand',
                    filterable: true,
                    options: [],
                    loadData: this.loadData,
                },
            ],
            isExporting: false,
        };
    },
    computed: {
        requestType() {
            const arr = {
                2024: {
                    listCmd: esignTaskPageCurrent,
                    exportCmd: esignTaskCurrentExport,
                    listName: 'list2024',
                    form: 'table-form-2024',
                },
                2023: {
                    listCmd: esignTaskPageCurrent,
                    exportCmd: esignTaskCurrentExport,
                    listName: 'list2023',
                    form: 'table-form-2023',
                },
                2022: {
                    listCmd: esignTaskPageCurrent,
                    exportCmd: esignTaskCurrentExport,
                    listName: 'list2022',
                    form: 'table-form-2022',
                },
                2021: {
                    listCmd: esignTaskPage,
                    exportCmd: esignTaskExport,
                    listName: 'list2021',
                    form: 'table-form-2021',
                },
            };
            return arr[this.currentTab];
        },
    },
    activated() {
        let form = this.$refs[this.requestType.form].getFormData();
        this.getData(form);
    },
    mounted() {
        this.handle2023YearTable();
        this.getBrand();
    },
    methods: {
        // 2024年1月1日起，23年的列表去掉月度、季度完成情况；仅展示年度相关数据
        handle2023YearTable() {
            if (this.currentYear > 2023) {
                this.list2023.columns.splice(8, 4);
            }
        },
        /**
         * @description 切换tab
         * @param {Number} e tab标识
         */
        handleTabClick(e) {
            if (e === this.currentTab) {
                return false;
            }
            this.currentTab = e;
            this.onPageChange(1);
            this.getBrand();
        },

        async loadData(item, callback) {
            let res = {};
            item.loading = true;
            try {
                if (this.currentTab >= 2023) {
                    res = await esign2023Level(item.id, this.currentTab);
                } else {
                    res = await getLevelConfig(item.id, this.currentTab);
                }
                item.children = res.map((item) => {
                    return {
                        name: item.levelName,
                        id: item.id,
                        value: item.id,
                        label: item.levelName,
                    };
                });
                item.loading = false;
                callback();
            } catch (e) {
                item.loading = false;
                callback();
            }
        },
        async getBrand() {
            const res = await AllSeriesList();
            for (let y of this.yearArr) {
                this[`formList${y}`][2].options = res.map((item) => {
                    return {
                        name: item.name,
                        id: item.id,
                        value: item.id,
                        label: item.name,
                        children: [],
                        loading: false,
                    };
                });
            }
        },
        getData(p) {
            this[this.requestType.listName].loading = true;
            p = clearEmpty(p);
            if (p['dept']) {
                p.deptId = p.dept[0];
                p.regionId = p.dept[1];
                delete p['dept'];
            }
            if (p['brand']) {
                if (Array.isArray(p['brand'])) {
                    p.brandMainId = p.brand[0];
                    p.gradeId = p.brand[1];
                } else {
                    p.gradeId = p.brand;
                }
                delete p['brand'];
            }
            let params = {
                current: this[this.requestType.listName].current,
                size: this[this.requestType.listName].size,
                startYear: this.currentTab,
                ...p,
            };
            this.requestType
                .listCmd(params)
                .then((res) => {
                    this[this.requestType.listName].total = Number(res.total);
                    this[this.requestType.listName].data = res.records.map((item) => {
                        return {
                            ...item,
                        };
                    });
                })
                .finally(() => {
                    this.isChangePage = false;
                    this[this.requestType.listName].loading = false;
                });
        },
        onSubmit(params) {
            this[this.requestType.listName].current = 1;
            this.getData(params);
        },
        onPageChange(val) {
            if (this.isChangePage) return;
            this[this.requestType.listName].current = val;
            let form = this.$refs[this.requestType.form].getFormData();
            this.getData(form);
        },
        onPageSizeChange(val) {
            this[this.requestType.listName].current = 1;
            this.isChangePage = true;
            this[this.requestType.listName].size = val;
            let form = this.$refs[this.requestType.form].getFormData();
            this.getData(form);
        },
        checkDetail(row) {
            jumpPage({
                path: `/sub-vue2/statistics/storeSign/${this.currentTab == 2021 ? 'check' : 'checkCurrent'}`,
                params: {
                    storeId: row.storeId,
                    mode: 2,
                    year: this.currentTab,
                },
            });
        },
        exportInfo() {
            this.exportData();
        },
        exportData() {
            let p = this.$refs[this.requestType.form].getFormData();
            if (p['brand']) {
                if (Array.isArray(p['brand'])) {
                    p.brandMainId = p.brand[0];
                    p.gradeId = p.brand[1];
                } else {
                    p.gradeId = p.brand;
                }
                delete p['brand'];
            }
            this.$Message.loading({
                content: '导出中...',
                duration: 0,
            });
            this.isExporting = true;
            this.requestType
                .exportCmd({
                    startYear: this.currentTab,
                    ...p,
                })
                .then(
                    (res) => {
                        this[this.requestType.listName].loading = false;
                        this.isExporting = false;
                        this.$Message.destroy();
                        if (this.currentTab !== 2021) {
                            this.$Modal.confirm({
                                title: '提示',
                                content: '您好！“签署门店销售任务量”正在导出，请稍后在“导出列表”模块中查看，谢谢！',
                                onOk: () => {
                                    console.log('export');
                                },
                            });
                            return;
                        }
                        if (!res) {
                            this.$Message.warning('暂无数据');
                            return false;
                        }
                        let aLink = document.createElement('a');
                        aLink.href = res || '';
                        // console.log('href ===', res)
                        document.body.appendChild(aLink);
                        aLink.click();
                        document.body.removeChild(aLink);
                        this.$Message.success('导出成功');
                    },
                    () => {
                        this.$Message.destroy();
                        this.$Message.warning({
                            title: '导出失败 ！',
                        });
                        this[this.requestType.listName].loading = false;
                        this.isExporting = false;
                    },
                );
        },
    },
};
</script>
