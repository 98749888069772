import GroupCus from '@/pages/group/groupCus/index.vue';
import GroupCusEdit from '@/pages/group/groupCus/edit.vue';
import GroupBidding from '@/pages/group/groupBidding/index.vue';
import GroupBiddingDetail from '@/pages/group/groupBidding/detail.vue';
import { applyRoute } from '../const';

const groupRoutes = [
    {
        path: `${applyRoute}/group/groupCus/list`,
        meta: {
            auth: ['group'],
            title: '集团客户',
            cache: true,
        },
        component: GroupCus,
    },
    {
        path: `${applyRoute}/group/groupCus/edit`,
        meta: {
            auth: ['group'],
            title: '集团客户详情',
            cache: false,
        },
        component: GroupCusEdit,
    },
    {
        path: `${applyRoute}/group/groupBidding/index`,
        meta: {
            auth: ['group'],
            title: '集团客户招标',
            cache: true,
        },
        component: GroupBidding,
    },
    {
        path: `${applyRoute}/group/groupBidding/detail`,
        meta: {
            auth: ['group'],
            title: '集团客户招标查看',
            cache: false,
        },
        component: GroupBiddingDetail,
    },
];

export { groupRoutes };
