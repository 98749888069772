<template>
    <div class="page-loader" v-show="loading">
        <span style="position: relative; top: 50%">
            <Spin size="large" fix></Spin>
        </span>
    </div>
</template>

<script>
export default {
    name: "Loader",
    props: {
        loading: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style lang="less">
.page-loader {
    position: fixed;
    left: 80px;
    top: 110px;
    bottom: 0;
    right: 0;
    text-align: center;
    background: rgba(255, 255, 255, 0.8);
    z-index: 10;
}
</style>
