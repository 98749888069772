<template>
    <div class="dealer-sign-edit">
        <Spin fix v-if="loading"> 加载中... </Spin>
        <PageHeader :title="title" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage">
                <Icon type="md-arrow-back" />
            </div>
        </PageHeader>
        <Card class="ivu-mt" :dis-hover="true" shadow style="margin-bottom: 40px">
            <Tabs type="card" class="bg-white" :animated="true">
                <TabPane label="基本信息">
                    <ItemStoreInfo ref="ItemStoreInfo" :detail="detail" />
                </TabPane>
                <TabPane label="装修信息">
                    <itemDecorateInfo ref="itemDecorateInfo" :detail="detail" />
                </TabPane>
                <TabPane label="审核进度">
                    <auditProcess />
                </TabPane>
            </Tabs>
        </Card>
        <FooterToolbar>
            <Button @click="jumpPrevPage" style="margin-left: 15px">返回</Button>
        </FooterToolbar>
    </div>
</template>

<script>
import ItemStoreInfo from './components/itemStoreInfo';
import itemDecorateInfo from './components/itemDecorateInfo';
import auditProcess from './components/auditProcess';
import { decorationInfo } from '@/api/decorate';
import { jumpPage, transPathToName } from '@/libs/util';

export default {
    components: {
        ItemStoreInfo,
        itemDecorateInfo,
        auditProcess,
    },
    data() {
        return {
            loading: true,
            id: this.$route.query.id || '',
            title: '装修审核详情',
            detail: {},
        };
    },
    mounted() {
        this.getDetail();
    },
    methods: {
        getDetail() {
            decorationInfo({
                id: this.$route.query.id,
            }).then(
                (res) => {
                    this.loading = false;
                    res.majorBrand = (res.majorBrand || []).map((item) => item.brandName);
                    res.secondaryBrand = (res.secondaryBrand || []).map((item) => item.brandName);
                    this.detail = res || {};
                    this.$refs['ItemStoreInfo'].getDetail(res);
                    this.$refs['itemDecorateInfo'].getDetail(res);
                },
                () => {
                    this.loading = false;
                },
            );
        },
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/decoration/decorateAudit/list' });
        },
    },
};
</script>
<style></style>
