import CreditAccount from '@/pages/credit/creditAccount/index.vue';
import CreditDetailUse from '@/pages/credit/creditDetail/use.vue';
import CreditDetailCollection from '@/pages/credit/creditDetail/collection.vue';
import CreditDetailAmount from '@/pages/credit/creditDetail/amount.vue';
import CreditSetting from '@/pages/credit/creditSetting/index.vue';
import { applyRoute } from '../const';

const creditRoutes = [
    {
        path: `${applyRoute}/credit/creditAccount/list`,
        meta: {
            auth: ['credit'],
            title: '授信账户',
            cache: true,
        },
        component: CreditAccount,
    },
    {
        path: `${applyRoute}/credit/creditDetail/use`,
        meta: {
            auth: ['credit'],
            title: '授信使用明细',
            cache: false,
        },
        component: CreditDetailUse,
    },
    {
        path: `${applyRoute}/credit/creditDetail/collection`,
        meta: {
            auth: ['credit'],
            title: '授信使用明细',
            cache: false,
        },
        component: CreditDetailCollection,
    },
    {
        path: `${applyRoute}/credit/creditDetail/amount`,
        meta: {
            auth: ['credit'],
            title: '授信额度变更明细',
            cache: false,
        },
        component: CreditDetailAmount,
    },
    {
        path: `${applyRoute}/credit/creditSetting/list`,
        meta: {
            auth: ['credit'],
            title: '授信账户设置',
            cache: true,
        },
        component: CreditSetting,
    },
];

export { creditRoutes };
