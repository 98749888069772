import request from "@/plugins/request";

/**
 * @Author: forguo
 * @Date: 2021/11/9 09:29
 * @Description: 资产
 */

// 对账记录列表
export function reconciliationList (data) {
    return request({
        url: `/reconciliation/list`,
        method: 'post',
        data
    });
}

// 对账记录列表
export function reconciliationExport (data) {
    return request({
        url: `/reconciliation/export`,
        method: 'post',
        data
    });
}

// 对账记录列表
export function accountList () {
    return request({
        url: `/cpcn/accountList`,
        method: 'get',
    });
}
