<!-- eslint-disable vue/no-mutating-props -->
<!--
 * @Author: chengleilei
 * @Date: 2021-12-01 16:54:15
 * @Description: 修改授信账户状态弹窗组件
-->
<template>
    <Modal v-model="modal" :width="600" title="修改授信账户状态" :mask-closable="false" @on-cancel="onCancel">
        <Form ref="updateCreditForm" :model="updateCreditForm" :label-width="140" @submit.native.prevent>
            <FormItem label="门店名称：">
                {{ item.storeName || '-' }}
            </FormItem>
            <FormItem label="授信账户：" prop="switch">
                <el-switch v-model="updateCreditForm.switch"></el-switch>
            </FormItem>
            <FormItem label="备注：" prop="remark">
                <Input
                    v-model="updateCreditForm.remark"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 3 }"
                    :maxlength="200"
                    placeholder="请输入"
                />
            </FormItem>
        </Form>
        <Table
            :columns="columns"
            :data="list"
            max-height="200"
            :no-data-text="'亲，没有找到相关记录哦！~'"
            :no-filtered-data-text="'亲，没有找到相关记录哦！~'"
        ></Table>
        <div slot="footer">
            <Button type="text" @click="onCancel">取消</Button>
            <Button type="primary" @click="onOk" :loading="loading">确定</Button>
        </div>
    </Modal>
</template>

<script>
import { mapState } from 'vuex';
import { closeCreditAccount, openCreditAccount, listCreditAccount } from '@/api/credit';
import { clearEmpty } from '@/libs/util';

export default {
    props: {
        // 修改授信账户状态弹窗显隐
        modal: {
            type: Boolean,
            default: false,
        },
        // 行数据
        item: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            updateCreditForm: {
                switch: false, // 授信账户开关
                remark: '', // 备注
            },
            loading: false,
            // 表头
            columns: [
                {
                    title: '操作时间',
                    key: 'modifyDate',
                    align: 'center',
                    minWidth: 140,
                },
                {
                    title: '操作人',
                    key: 'operationName',
                    align: 'center',
                    minWidth: 80,
                },
                {
                    title: '操作',
                    key: 'creditAccountStatusDesc',
                    align: 'center',
                    minWidth: 80,
                },
                {
                    title: '备注',
                    minWidth: 140,
                    key: 'remark',
                    ellipsis: true,
                    tooltip: true,
                },
            ],
            list: [], // 表格数据
        };
    },
    computed: {
        ...mapState({
            creditPassword: (state) => state.admin.credit.creditPassword,
        }),
    },
    watch: {
        modal(newValue) {
            if (newValue) {
                this.updateCreditForm.switch = this.item.creditAccountStatus;
                this.getList();
            } else {
                this.$store.commit('admin/credit/clearPwd');
            }
        },
    },
    methods: {
        /**
         * @description 修改授信账户操作列表
         */
        getList() {
            listCreditAccount({
                creditAccountId: this.item.id,
                current: 1,
                size: 999,
            }).then((res) => {
                this.list = res.records || [];
            });
        },

        /**
         * @description 修改授信账户状态弹窗确定
         */
        onOk() {
            if (this.loading) return;
            this.$refs['updateCreditForm'].validate((valid) => {
                if (!valid) return;
                this.loading = true;
                let p = {
                    creditAccountId: this.item.id,
                    creditPassword: this.creditPassword,
                    remark: this.updateCreditForm.remark,
                };
                p = clearEmpty(p);
                console.log(p);

                const cmd = this.updateCreditForm.switch ? openCreditAccount : closeCreditAccount;

                cmd(p)
                    .then(() => {
                        this.$Message.success('操作成功');
                        this.onCancel();
                        this.$emit('refreshList');
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },

        /**
         * @description 修改授信账户状态弹窗取消
         */
        onCancel() {
            this.$refs['updateCreditForm'].resetFields();
            this.$emit('update:modal', false);
        },
    },
};
</script>

<style lang="less" scoped></style>
