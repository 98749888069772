<template>
    <div>
        <PageHeader title="购胎入库返利明细" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage"><Icon type="md-arrow-back" /></div>
        </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" @on-submit="onSubmit" @on-export="exportInfo" ref="table-form">
                <Button class="ivu-ml-8" slot="extraBtn" :loading="exportLoading" :disabled="exportLoading" type="error"
                    >导出</Button
                >
            </table-form>
            <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list" />
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { clearEmpty, jumpPage, transPathToName } from '@/libs/util';
import { singleAuditRebateDetail, singleAuditRebateDetailExport } from '../../../api/sales';

export default {
    components: {
        tableForm,
        tablePage,
    },
    data() {
        return {
            exportLoading: false,
            isChangePage: false,
            formList: [
                {
                    title: '商品名称',
                    type: 'input',
                    key: 'productName',
                    placeholder: '请输入商品名称',
                },
                {
                    title: '条码',
                    type: 'input',
                    key: 'barcode',
                    placeholder: '请输入条码',
                },
                {
                    title: '胎号',
                    type: 'input',
                    key: 'tyreCode',
                    placeholder: '请输入胎号',
                },
                {
                    title: '订单编号',
                    type: 'input',
                    key: 'orderSn',
                    placeholder: '请输入订单编号',
                },
                {
                    title: '扫码时间',
                    type: 'daterange',
                    key: ['startTime', 'endTime'],
                    placeholder: '请选择',
                },
                {
                    title: '活动信息',
                    type: 'input',
                    key: 'detail',
                    placeholder: '请输入活动编号/活动名称',
                },
                {
                    title: '类型',
                    key: 'scanType',
                    type: 'select',
                    placeholder: '请选择类型',
                    options: [
                        {
                            name: '全部',
                            id: '',
                        },
                        {
                            name: '入库',
                            id: 61,
                        },
                        {
                            name: '退货',
                            id: 53,
                        },
                    ],
                    loading: false,
                    filterable: true,
                },
            ],
            searchParams: {
                verifyMonth: this.$route.query.verifyMonth,
                storeId: this.$route.query.storeId || '',
                distributorId: this.$route.query.distributorId,
                tiresName: '',
                tyrecode: '',
                scanCodeType: '',
                barcode: '',
                isHistory: '',
            },
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    {
                        title: '商品名称',
                        key: 'productName',
                        align: 'center',
                        minWidth: 290,
                        fixed: 'left',
                    },
                    {
                        title: '胎号',
                        key: 'tyreCode',
                        align: 'center',
                        minWidth: 120,
                    },
                    {
                        title: '条码',
                        key: 'barcode',
                        align: 'center',
                        minWidth: 130,
                    },
                    {
                        title: '系列',
                        key: 'series',
                        align: 'center',
                        minWidth: 100,
                    },
                    {
                        title: '品牌',
                        key: 'brandName',
                        align: 'center',
                        minWidth: 140,
                    },
                    {
                        title: '规格',
                        key: 'tireSpec',
                        align: 'center',
                        minWidth: 90,
                    },
                    {
                        title: '尺寸',
                        key: 'size',
                        align: 'center',
                        minWidth: 80,
                    },
                    {
                        title: '返利',
                        key: 'storeRebate',
                        align: 'center',
                        minWidth: 80,
                    },
                    {
                        title: '订单号',
                        key: 'orderSn',
                        align: 'center',
                        minWidth: 160,
                    },
                    {
                        title: '活动编号',
                        key: 'activitySn',
                        align: 'center',
                        minWidth: 160,
                    },
                    {
                        title: '活动名称',
                        key: 'activityName',
                        align: 'center',
                        minWidth: 160,
                    },
                    {
                        title: '类型',
                        key: 'scanType',
                        align: 'center',
                        minWidth: 100,
                    },
                    {
                        title: '扫码时间',
                        key: 'scanTime',
                        align: 'center',
                        minWidth: 200,
                    },
                    {
                        title: '备注',
                        key: 'remark',
                        align: 'center',
                        minWidth: 100,
                    },
                ],
                data: [],
            },
        };
    },
    mounted() {
        this.getData();
        this.$bus.$on('refresh', this.getData);
    },
    beforeDestroy() {
        this.$bus.$off();
    },

    methods: {
        getData() {
            this.list.loading = true;
            let p = {
                ...this.$refs['table-form'].getFormData(),
                ...clearEmpty(this.searchParams),
            };
            let params = clearEmpty({
                current: this.list.current,
                size: this.list.size,
                ...p,
            });
            singleAuditRebateDetail(params).then(
                (data) => {
                    console.log(data.records);
                    let scanType = {
                        61: '入库',
                        62: '门店出库扫码',
                        63: '经销商退货扫码',
                        53: '退货',
                    };
                    this.list.data = (data.records || []).map((item) => {
                        item.scanType = scanType[item.scanType];
                        return item;
                    });
                    this.list.total = Number(data.total);
                    this.isChangePage = false;
                    this.list.loading = false;
                },
                () => {
                    this.isChangePage = false;
                    this.list.loading = false;
                },
            );
        },
        exportData() {
            let p = {
                ...this.$refs['table-form'].getFormData(),
                ...clearEmpty(this.searchParams),
            };
            let params = {
                current: this.list.current,
                size: this.list.size,
                ...p,
            };
            this.$Message.loading({
                content: '导出中...',
                duration: 0,
            });
            this.exportLoading = true;
            singleAuditRebateDetailExport(params).then(
                (res) => {
                    this.exportLoading = false;
                    if (!res) {
                        this.$Message.warning('暂无数据');
                        return false;
                    }
                    let aLink = document.createElement('a');
                    aLink.href = res || '';
                    // console.log('href ===', res)
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    this.$Message.destroy();
                    this.$Message.success('导出成功');
                },
                () => {
                    this.$Message.destroy();
                    this.exportLoading = false;
                },
            );
        },
        onPageChange(val) {
            if (this.isChangePage) return;
            this.list.current = val;
            this.getData();
        },
        onPageSizeChange(val) {
            this.list.current = 1;
            this.isChangePage = true;
            this.list.size = val;
            this.getData();
        },
        check(row) {
            this.$refs['records'].open(row);
        },
        onSubmit() {
            this.getData();
        },
        exportInfo(params) {
            console.log(params, 'params');
            this.searchParams = {
                ...this.searchParams,
                ...params,
            };
            this.exportData();
        },
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/sales/rebateAudit/list' });
        },
    },
};
</script>
