<!--
 * @Author: chengleilei
 * @Date: 2021-10-19 16:18:34
 * @Description: 销售订单详情
-->
<template>
    <div class="special-order-detail">
        <OrderDetail />
    </div>
</template>

<script>
import OrderDetail from '../../sales/order/orderDetail';

export default {
    data() {
        return {};
    },
    components: {
        OrderDetail,
    },
    created() {},
};
</script>

<style lang="less" scoped>
.special-order-detail {
    padding-bottom: 50px;
    /deep/ .i-table-no-border .ivu-table th {
        background-color: #f8f8f9;
    }
}
</style>
