<template>
    <div class="store-edit">
        <Modal
            v-model="showModal"
            title="变更类型"
            width="600px"
            height="500px"
            ok-text="提交"
            footer-hide
            :mask-closable="false"
            @on-ok="handleSaveStore"
            @on-cancel="handleCancel"
        >
            <Form :label-width="90" ref="form" style="margin-bottom: 5px">
                <Row :gutter="24">
                    <Col>
                        <FormItem label="ERP单号：" prop="storeId">
                            <p>{{ row.sn || '-' }}</p>
                        </FormItem>
                    </Col>
                </Row>

                <Row :gutter="24">
                    <Col>
                        <FormItem label="地址：" prop="storeId">
                            <p>{{ row.receiveAddress || '-' }}</p>
                        </FormItem>
                    </Col>
                </Row>

                <Row :gutter="24">
                    <Col>
                        <FormItem label="门店名称：" prop="storeId">
                            {{ storeName || '请选择' }}
                        </FormItem>
                    </Col>
                </Row>
            </Form>

            <p class="store-tips">请点击选择门店~</p>
            <Input
                @input="handleGetStore"
                v-model="searchVal"
                placeholder="请输入门店名称搜索"
                clearable
                :maxlength="16"
                style="margin: 10px auto"
            />
            <div class="store-list">
                <Spin v-if="loading" fix></Spin>
                <div
                    class="store-item"
                    v-for="(item, index) in storeList"
                    :key="index"
                    @click="checkStore(item, index)"
                    :class="{ active: item.checked }"
                >
                    <p>门店名称：{{ item.storeName }}</p>
                    <p>联系人：{{ item.ownerName }}</p>
                    <p>联系方式：{{ item.ownerPhone }}</p>
                    <p>门店地址：{{ item.storeAddress }}</p>
                </div>
                <div class="no-data" v-if="!storeList || storeList.length <= 0">没有门店信息，请重新搜索~</div>
            </div>

            <div style="text-align: end; margin-top: 30px">
                <Button type="error" @click="handleCancel" style="margin-right: 15px">取消</Button>
                <Button type="primary" @click="handleSaveStore">提交</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import { getStoreList } from '../../../api/scan';
import { WareHouseEntryDirctSend } from '../../../api/warehouse';
import { debounce } from 'lodash';
let storeList = [];
export default {
    name: 'store-edit',
    data() {
        return {
            loading: false,
            type: 'ADD',
            showModal: false,
            storeList: [],
            storeId: '',
            storeName: '请选择',
            searchVal: '',
            row: {
                sn: '',
                receiveAddress: '',
            },
        };
    },
    created() {
        getStoreList().then(
            (res) => {
                this.loading = false;
                let list =
                    res.records.map((item) => ({
                        storeId: item.id,
                        storeName: item.storeName || '-',
                        ownerName: item.ownerName || '-',
                        ownerPhone: item.ownerPhone || '-',
                        storeAddress: item.storeAddress || '-',
                        checked: false,
                    })) || [];
                this.storeList = list;
                storeList = list;
            },
            () => {
                this.loading = true;
                this.storeList = [];
                storeList = [];
            },
        );
    },
    methods: {
        checkStore(item, index) {
            this.storeId = item.storeId;
            this.storeName = item.storeName;
            let { storeList } = this;
            storeList.map((item, itemIndex) => {
                item.checked = index === itemIndex;
            });
            this.storeList = storeList;
        },
        open(type, row) {
            this.storeId = '';
            this.storeName = '请选择';
            this.searchVal = '';
            this.storeList = storeList.map((item) => {
                return {
                    ...item,
                    checked: false,
                };
            });
            this.type = type;
            this.showModal = true;
            this.row = row;
        },
        handleSaveStore() {
            if (!this.storeId) {
                this.$Message.warning({
                    content: '请选择门店',
                });
                return false;
            }
            this.$Message.loading({
                content: '保存中...',
                duration: 0,
            });
            this.loading = true;
            WareHouseEntryDirctSend({
                storeId: this.storeId,
                orderId: this.row.id,
            }).then(
                () => {
                    this.loading = false;
                    this.$Message.destroy();
                    this.$Message.success('变更成功！');
                    this.showModal = false;
                    this.$emit('getData');
                },
                () => {
                    this.$Message.destroy();
                    this.loading = false;
                },
            );
        },
        handleCancel() {
            this.showModal = false;
            this.storeId = '';
        },
        handleGetStore: debounce(function (keyword) {
            this.storeId = '';
            this.loading = true;
            getStoreList({
                keyword,
            }).then(
                (res) => {
                    this.loading = false;
                    this.storeList =
                        res.records.map((item) => ({
                            storeId: item.id,
                            storeName: item.storeName || '-',
                            ownerName: item.ownerName || '-',
                            ownerPhone: item.ownerPhone || '-',
                            storeAddress: item.storeAddress || '-',
                            checked: false,
                        })) || [];
                },
                () => {
                    this.loading = true;
                    this.storeList = [];
                },
            );
        }, 300),
    },
};
</script>

<style scoped lang="less">
.store-tips {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
}
.store-list {
    position: relative;
    border: 1px solid #e6e6e6;
    width: 100%;
    height: 400px;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 5px;
    .store-item {
        padding: 15px 20px;
        line-height: 33px;
        border-bottom: 1px solid #e8e8e8;
        transition: ease all 300ms;
        &:hover {
            cursor: pointer;
        }
        &.active {
            background: #eee;
            color: #2d8cf0;
        }
    }
    .no-data {
        width: 100%;
        height: 80px;
        line-height: 80px;
        text-align: center;
    }
}
.ivu-form-item {
    margin-bottom: 10px;
}
.ivu-form {
    margin-bottom: 25px;
}
</style>
