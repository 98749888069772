<template>
    <div>
        <PageHeader title="返利详情" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage"><Icon type="md-arrow-back" /></div>
            <Button
                slot="action"
                type="info"
                class="ivu-ml"
                icon="md-cloud-download"
                @click="onExport"
                :loading="isExporting"
                >{{ isExporting ? '正在导出...' : '导出' }}</Button
            >
        </PageHeader>
        <Card dis-hover class="ivu-mt">
            <table-form :formList="formList" @on-submit="onSubmit" ref="table-form" />
            <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list" />
        </Card>

        <!--<Modal v-model="showModal" :title="title" @on-cancel="closeModal" footer-hide width="1000px">-->
        <!--<table-page :hasPage="false" :list='listRecord' />-->
        <!--</Modal>-->
    </div>
</template>

<script>
// import { renderButton } from '@/libs/util'
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { MonthDeatail, ExportDetail } from '@/api/statistics';
import { jumpPage, transPathToName } from '@/libs/util';

export default {
    name: 'statistics-rebate-detail',
    components: {
        tableForm,
        tablePage,
    },
    data() {
        return {
            isExporting: false,
            date: this.$route.query.date,
            title: '扫码记录',
            showModal: false,
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    { title: '商品名称', key: 'productName', minWidth: 280, align: 'center', fixed: 'left' },
                    { title: '胎号', key: 'tyrecode', minWidth: 170, align: 'center' },
                    { title: '条码', key: 'barcode', minWidth: 200, align: 'center' },
                    { title: '扫码类型  ', key: 'scancodeType', minWidth: 150, align: 'center' },
                    {
                        title: '状态',
                        key: 'status',
                        align: 'center',
                        width: '80',
                        render: (h, p) => {
                            let badge = h('Badge', {
                                props: {
                                    status: p.row.status ? 'success' : 'error',
                                    text: p.row?.status ? '正常' : '异常',
                                },
                            });
                            return h('div', [badge]);
                        },
                    },
                    { title: '扫码时间', key: 'createDate', minWidth: 250, align: 'center' },
                    { title: '备注', key: 'abnormalValue', align: 'center', minWidth: 100 },
                    // {
                    //     title: '扫码记录',
                    //     key: 'oprate',
                    //     width: 220,
                    //     fixed: 'right',
                    //     align: 'center',
                    //     render: (h, p) => {
                    //         return [
                    //             renderButton(h, { text: '查看', type: 'primary' }, () => { this.seeRecord(p.row) })
                    //         ]
                    //     }
                    // }
                ],
                data: [],
            },
            formList: [
                {
                    title: '商品名称',
                    type: 'input',
                    key: 'tiresName',
                    placeholder: '模糊搜索',
                },
                {
                    title: '胎号',
                    type: 'input',
                    key: 'tyrecode',
                    placeholder: '模糊搜索',
                },
                {
                    title: '条码',
                    type: 'input',
                    key: 'barcode',
                    placeholder: '模糊搜索',
                },
                {
                    title: '扫码类型',
                    type: 'select',
                    key: 'scanCodeType',
                    placeholder: '请选择',
                    clearable: true,
                    options: [
                        { id: '', name: '全部' },
                        { id: 52, name: '出库' },
                        { id: 53, name: '退货' },
                    ],
                },
            ],
            listRecord: {
                loading: false,
                columns: [
                    { title: '订单号', key: 'sn', minWidth: 220, align: 'center', fixed: 'left' },
                    { title: '经销商/门店', key: 'scanCodeDate', minWidth: 170, align: 'center' },
                    { title: '时间', key: 'scanCodeTypeMeaning', minWidth: 200, align: 'center' },
                    { title: '扫码类型  ', key: 'totalItemQuantity', minWidth: 100, align: 'center' },
                    { title: '备注', key: 'scanCodeOperateName', minWidth: 250, align: 'center' },
                ],
                data: [],
            },
        };
    },
    mounted() {
        this.getData();
    },

    methods: {
        getData(p) {
            this.list.loading = true;
            const param = {
                current: this.list.current,
                size: this.list.size,
                date: this.date,
                ...p,
            };
            MonthDeatail(param)
                .then((res) => {
                    this.list.loading = false;
                    this.list.total = Number(res.total);
                    this.list.data = res.records || [];
                })
                .catch(() => {
                    this.list.loading = false;
                });
        },
        onSubmit(param) {
            this.list.current = 1;
            this.getData(param);
        },
        onPageChange(val) {
            this.list.current = val;
            let form = this.$refs['table-form'].getFormData();
            this.getData(form);
        },
        onPageSizeChange(val) {
            this.list.current = 1;
            this.list.size = val;
            let form = this.$refs['table-form'].getFormData();
            this.getData(form);
        },
        seeRecord() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/statistics/rebate/list' });
        },
        onExport() {
            this.list.loading = true;
            this.isExporting = true;
            let form = this.$refs['table-form'].getFormData();
            if (!form.date) {
                form.date = this.date;
            }
            let params = {
                current: this.list.current,
                size: this.list.size,
                ...form,
            };
            ExportDetail(params)
                .then(() => {
                    this.list.loading = false;
                    this.isExporting = false;
                    this.$Message.destroy();
                    this.$Modal.confirm({
                        title: '提示',
                        content: `您好！“${this.$route.meta.title}”正在导出，请稍后在“导出列表”模块中查看，谢谢！`,
                        onOk: () => {
                            console.log('export');
                        },
                    });
                })
                .catch((err) => {
                    console.log(err);
                    this.$Notice.error({
                        title: '导出失败 ！',
                    });
                    this.list.loading = false;
                    this.isExporting = false;
                });
        },
    },
};
</script>

<style scoped></style>
