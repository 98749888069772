<template>
    <div class="dealer-account-detail" v-loading="loading">
        <PageHeader :title="title" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage">
                <Icon type="md-arrow-back" />
            </div>
        </PageHeader>
        <Card class="ivu-mt" :dis-hover="true" shadow style="margin-bottom: 40px">
            <h3 class="ivu-mb-16">{{ detail.cardholderName || '-' }}</h3>
            <div class="ivu-mb-16">账户类型：{{ userType == 12 ? '企业' : '个体工商户' }}</div>
            <!-- 状态-->
            <div class="ivu-mb-16">
                状态：
                <Badge
                    :color="accountStatusColor[detail.accountStatus]"
                    :text="accountStatusTxt[detail.accountStatus]"
                    style="margin-right: 8px"
                />
                <Button
                    type="primary"
                    size="small"
                    @click="handleStatus(detail.accountStatus)"
                    v-show="accountStatusBtn[detail.accountStatus]"
                >
                    {{ accountStatusBtn[detail.accountStatus] }}
                </Button>
            </div>
            <!-- 当前进度 除了完成的都有进度 -->
            <Row class="ivu-mt-8 ivu-mb-16" v-if="detail.accountStatus !== 5">
                <Col span="16">
                    <Steps :current="currentStep">
                        <Step title="填写基本信息" content=""></Step>
                        <Step title="绑卡认证" content=""></Step>
                        <Step title="开户" content=""></Step>
                        <Step title="完成签约" content=""></Step>
                    </Steps>
                </Col>
            </Row>

            <Tabs type="card" class="bg-white" :animated="true" :value="type">
                <TabPane label="基础信息" name="base">
                    <baseInfo :detail="detail"></baseInfo>
                </TabPane>
                <TabPane label="银行卡" name="card">
                    <bankInfo :detail="detail" :cardList="cardList"></bankInfo>
                </TabPane>
            </Tabs>
        </Card>
        <!--        <FooterToolbar>-->
        <!--            <Button @click="jumpPrevPage" style="margin-left: 15px;">返回</Button>-->
        <!--        </FooterToolbar>-->
    </div>
</template>

<script>
import { accountDetail, getAuditStep } from '@/api/dealerAccount';
import baseInfo from './detail/baseInfo';
import bankInfo from './detail/bankInfo';
import { transPathToName, jumpPage } from '@/libs/util';

export default {
    data() {
        return {
            title: '账户管理',
            currentStep: 0,
            type: this.$route.query.type || 'base', // 是基本信息，还是银行卡列表
            userType: this.$route.query.userType || '', // 影印件账户类型【11=个人账户 12=企业账户 13 = 个体工商户】
            bankAccountType: this.$route.query.bankAccountType || '', // 银行账户类型【11=个人账户 12=企业账户】
            cpcnAccountId: this.$route.query.cpcnAccountId || '', // 账户id
            cardId: this.$route.query.cardId || '',
            // 0=待绑卡认证、1=待开户、2=开户处理中、3=待签约、4=签约待完成、5=正常使用中 6=开户失败
            accountStatusTxt: {
                0: '待绑卡认证',
                1: '待开户',
                2: '开户处理中',
                3: '待签约',
                4: '签约待完成',
                5: '正常使用中',
                6: '开户失败',
            },
            accountStatusColor: {
                0: 'yellow', // '待绑卡认证'
                1: 'yellow', // '待开户',
                2: 'blue', // '开户处理中'
                3: 'blue', // '待签约',
                4: 'blue', // '签约待完成'
                5: 'green', // '正常使用中'
                6: 'red', // '开户失败',
            },
            accountStatusBtn: {
                '': '',
                0: '绑卡认证', // '待绑卡认证'
                1: '前往开户', // '待开户',
                2: '', // '开户处理中'
                3: '前往签约', // '待签约',
                4: '继续签约', // '签约待完成'
                5: '', // '正常使用中'
                6: '重新开户', // '开户失败',
            },
            // 影印件审核状态: 10=审核中 20=成功 30=失败
            imageStatusTxt: {
                10: '',
                20: '已提交',
                30: '',
            },
            // 银行卡绑定状态: 10=审核中 20=成功 30=失败
            bindStatusTxt: {
                10: '',
                20: '已通过',
                30: '',
            },
            // 开户信息：0=待绑卡认证、1=待开户、2=开户处理中、3=待签约、4=签约待完成、5=正常使用中 6=开户失败
            openAccountStatusTxt: {
                0: '待绑卡认证',
                1: '待开户',
                2: '处理中',
                3: '已通过',
                4: '已通过',
                5: '已通过',
                6: '未通过',
            },
            openAccountStatusColor: {
                0: 'yellow', // '待绑卡认证'
                1: 'yellow', // '待开户',
                2: 'blue', // '开户处理中'
                3: 'green', // '待签约',
                4: 'green', // '签约待完成'
                5: 'green', // '正常使用中'
                6: 'red', // '开户失败',
            },
            detail: {},
            cardList: [],
            loading: false,
        };
    },
    components: {
        baseInfo,
        bankInfo,
    },
    mounted() {
        this.getData();
    },
    methods: {
        /**
         * 获取详情数据
         **/
        getData() {
            this.loading = true;
            const cpcnAccountId = this.cpcnAccountId;
            accountDetail({
                cpcnAccountId,
            }).then(
                (res) => {
                    this.detail = res || {};
                    if (res) {
                        this.cardList = res.cardList;
                        getAuditStep(res.userId).then((currentStep) => {
                            this.currentStep = currentStep || 0;
                        });
                    }
                    this.loading = false;
                    this.$previewRefresh();
                },
                () => {
                    this.loading = false;
                },
            );
        },
        /**
         * 根据状态判断跳转页面
         */
        handleStatus(status) {
            const routeName = {
                0: 'tiedCardApprove', // '待绑卡认证',
                1: 'openAccount', // '待开户',
                2: '', // '开户处理中',
                3: 'signContract', // '待签约',
                4: 'signContract', // '签约待完成',
                5: '', // '正常使用中',
                6: 'openAccount', // '开户失败',
            };
            if (routeName) {
                if (status == 0) {
                    // 认证需要关闭当前页面，因为新数据ID变了
                    jumpPage({
                        path: `/sub-vue2/assets/dealerAccount/${routeName[status]}`,
                        params: {
                            userType: this.userType,
                            cpcnAccountId: this.cpcnAccountId,
                            cardId: this.detail.bankCardId,
                            userId: this.detail.userId,
                            type: status === 6 ? 'reOpen' : '', // 开户失败,重新开户
                        },
                    });
                } else {
                    jumpPage({
                        path: `/sub-vue2/assets/dealerAccount/${routeName[status]}`,
                        params: {
                            userType: this.userType,
                            cpcnAccountId: this.cpcnAccountId,
                            cardId: this.detail.bankCardId,
                            userId: this.detail.userId,
                            type: status === 6 ? 'reOpen' : '', // 开户失败,重新开户
                        },
                    });
                }
            }
        },
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/assets/dealerAccount/list' });
        },
    },
};
</script>

<style scoped>
.dealer-account-detail .dealer-account-img {
    width: 200px;
    height: 160px;
}
</style>
