import request from "@/plugins/request";

// 条码库存列表
export function WareHouseBarcodeList(data) {
    return request({
        url: "/distributor_barcode/listBarcodeStock",
        method: "post",
        params: data,
    });
}

// 获取得到所有的花纹
export function WareHouseFigureData() {
    return request({
        url: "/distributor/product_common/list/TiRE_FIGURE",
        method: "get",
    });
}

// 盘存记录列表
export function WareHouseInventoryList(data) {
    return request({
        url: "/distributor/inventory/query",
        method: "post",
        data,
    });
}

// 盘存记录详情
export function WareHouseInventoryEdit(id) {
    return request({
        url: "/distributor/inventory/detail/" + id,
        method: "get",
    });
}

// 入库管理
export function WareHouseEntryList(data) {
    return request({
        url: "/distributor/outbound/page",
        method: "post",
        data,
    });
}

// 入库管理详情
export function WareHouseEntryEdit(id) {
    return request({
        url: "/distributor/outbound/detail/" + id,
        method: "get",
    });
}

// 入库管理导出
export function WareHouseEntryExport(data) {
    return request({
        url: "/distributor/outbound/export",
        method: "post",
        data,
    });
}

// 入库管理订单直发
export function WareHouseEntryDirctSend(data) {
    return request({
        url: `/distributor/outbound/directSend`,
        method: "get",
        params: data,
    });
}

/************
 * 获取条码
 * **********/
// 获取条码
export function getBarcodes(data) {
    return request({
        url: `/distributor/outbound/getBarcodes/`,
        method: "get",
        params: data,
    });
}

/************
 * 入库单补录
 * **********/
// 获取条码
export function erpOrderFill(data) {
    return request({
        url: `/distributor/outbound/erp_order_make_up/`,
        method: "get",
        params: data,
    });
}

/************
 * 导出胎号
 * **********/
export function exportTyreCode(data) {
    return request({
        url: `/distributor/outbound/export/tyreCode`,
        method: "get",
        params: data,
    });
}

/************
 * 查询审核通过、未通过、审核中的数量
 * **********/
export function outboundCount() {
    return request({
        url: `/distributor/outbound/count`,
        method: "get",
    });
}
