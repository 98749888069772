<!--
 * @Author: chengleilei
 * @Date: 2024-05-20 14:34:17
 * @Description: 退款单渠道退款记录弹窗
-->
<template>
    <Modal v-model="modal" title="退款单渠道退款记录" width="900">
        <h4 class="mb10">退款单号：{{ item.refundOrderNo }}</h4>
        <table-page :hasPage="false" :list="list" />
        <div slot="footer">
            <Button type="text" @click="onCancel">取消</Button>
        </div>
    </Modal>
</template>

<script>
import tablePage from '@/components/tablePage';
import { getRefundRecord } from '@/api/sales';
import { recordListCol } from './const';

export default {
    props: {},
    components: { tablePage },
    data() {
        return {
            modal: false, // 弹窗显隐
            item: {}, // 退款单数据
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                columns: [...recordListCol],
                data: [],
            },
        };
    },
    methods: {
        /**
         * @description 弹窗打开
         */
        open(row) {
            this.modal = true;
            this.item = row;
            this.getData(row.orderId);
        },

        /**
         * @description 查询数据
         */
        getData(orderId) {
            getRefundRecord(orderId).then((res) => {
                this.list.data = res || [];
            });
        },

        /**
         * @description 弹窗取消
         */
        onCancel() {
            this.modal = false;
        },
    },
};
</script>

<style lang="less" scoped>
.info {
    span {
        color: #e70707;
    }
}
</style>
