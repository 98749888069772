import DecorateAudit from '@/pages/decoration/decorateAudit/index.vue';
import DecorateAuditDetail from '@/pages/decoration/decorateAudit/detail.vue';
import DirectlyStoreSign from '@/pages/decoration/directlyStoreSign/index.vue';
import DirectlyStoreSignCheck from '@/pages/decoration/directlyStoreSign/Check.vue';
import { applyRoute } from '../const';

const decorationRoutes = [
    {
        path: `${applyRoute}/decoration/decorateAudit/list`,
        meta: {
            auth: ['decoration'],
            title: '装修审核',
            cache: true,
        },
        component: DecorateAudit,
    },
    {
        path: `${applyRoute}/decoration/decorateAudit/detail`,
        meta: {
            auth: ['decoration'],
            title: '装修审核',
            cache: false,
        },
        component: DecorateAuditDetail,
    },
    {
        path: `${applyRoute}/decoration/directlyStoreSign/list`,
        meta: {
            auth: ['decoration'],
            title: '装修审核',
            cache: true,
        },
        component: DirectlyStoreSign,
    },
    {
        path: `${applyRoute}/decoration/directlyStoreSign/check`,
        meta: {
            auth: ['decoration'],
            title: '装修审核',
            cache: false,
        },
        component: DirectlyStoreSignCheck,
    },
];

export { decorationRoutes };
