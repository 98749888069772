<template>
    <div class="product-mange-list">
        <PageHeader title="集团客户" hidden-breadcrumb>
            <div slot="action">
                <Button type="warning" class="ivu-ml-8" :loading="isExporting" @click="showExportModal">导出</Button>
            </div>
        </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" ref="table-form" @on-submit="onSubmit" />
            <table-page :list="list" @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" />
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { listGroupCustomer, exportGroupCustomer } from '@/api/group';
import { renderButton, clearEmpty, jumpPage } from '@/libs/util';
import { SalesmanList } from '@/api/store';

export default {
    components: { tableForm, tablePage },
    data() {
        return {
            productIds: [],
            isExporting: false,
            list: {
                page: 1,
                size: window.$pageSize,
                total: 0,
                columns: [
                    // { type: 'selection', width: 60, align: 'center' },
                    {
                        title: '集团客户名称',
                        key: 'buyerName',
                        align: 'center',
                        fixed: 'left',
                        minWidth: 160,
                    },
                    {
                        title: '维护人',
                        key: 'uploader',
                        align: 'center',
                        minWidth: 160,
                    },
                    {
                        title: '发票',
                        key: 'isEnabled',
                        align: 'center',
                        width: 190,
                        render: (h, p) => {
                            return h(
                                'div',
                                {
                                    class: 'show-slide img-preview',
                                    style: {
                                        backgroundImage: 'url(' + p.row.url + ')',
                                    },
                                },
                                [
                                    h('img', {
                                        class: 'show-img',
                                        attrs: {
                                            preview: Math.random() + p.index,
                                            src: p.row.url,
                                        },
                                    }),
                                ],
                            );
                        },
                    },
                    {
                        title: '操作',
                        align: 'center',
                        width: 200,
                        fixed: 'right',
                        render: (h, params) => {
                            return [
                                renderButton(h, { text: '查看', type: 'success', size: 'small' }, () => {
                                    this.onEdit(params.row);
                                }),
                            ];
                        },
                    },
                ],
                data: [],
                loading: false,
            },
            formList: [
                {
                    title: '公司名称',
                    type: 'input',
                    key: 'buyerName',
                    placeholder: '请输入公司名称',
                },
                {
                    title: '维护人',
                    type: 'select',
                    key: 'salesmanId',
                    options: [],
                },
            ],
        };
    },
    mounted() {
        this.getSalesmanList();
    },
    activated() {
        this.getData();
    },
    methods: {
        getSalesmanList(id) {
            SalesmanList(id).then(
                (res) => {
                    this.loading = false;
                    this.formList[1].options = res || [];
                },
                () => {
                    this.loading = false;
                },
            );
        },
        getData() {
            let p = this.$refs['table-form'].getFormData();
            let dept = p.dept || [];
            p.deptId = dept[0] || '';
            p.regionId = dept[1] || '';
            p = clearEmpty(p);

            let copyP = { ...p };
            if (copyP['dept']) {
                delete copyP['dept'];
            }
            this.productIds = [];
            this.list.loading = true;
            let params = {
                ...copyP,
                current: this.list.page,
                size: this.list.size,
            };
            listGroupCustomer(params)
                .then((res) => {
                    this.list.data = [];
                    this.list.total = Number(res.total);
                    this.list.loading = false;
                    this.list.data = res.records || [];
                })
                .catch(() => {
                    this.list.loading = false;
                });
        },
        onPageChange(val) {
            this.list.page = val;
            this.getData();
        },
        onPageSizeChange(val) {
            this.list.page = 1;
            this.list.size = val;
            this.getData();
        },
        onSubmit() {
            this.list.page = 1;
            this.getData();
        },
        showExportModal() {
            let p = this.$refs['table-form'].getFormData();
            let dept = p.dept || [];
            p.deptId = dept[0] || '';
            p.regionId = dept[1] || '';
            p = clearEmpty(p);
            let copyP = { ...p };
            if (copyP['dept']) {
                delete copyP['dept'];
            }
            let params = {
                ...copyP,
            };
            this.$Message.loading({
                content: '导出中...',
                duration: 0,
            });
            this.isExporting = true;
            exportGroupCustomer(params).then(
                (res) => {
                    this.$Message.destroy();
                    this.list.loading = false;
                    this.isExporting = false;
                    if (!res) {
                        this.$Message.warning('暂无数据');
                        return false;
                    }
                    let aLink = document.createElement('a');
                    aLink.href = res || '';
                    // console.log('href ===', res)
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    this.$Notice.success({
                        title: '导出成功',
                    });
                },
                () => {
                    this.isExporting = false;
                    this.$Message.destroy();
                },
            );
        },
        onEdit(row) {
            jumpPage({
                path: '/sub-vue2/group/groupCus/edit',
                params: {
                    id: row.id,
                    distributorId: row.distributorId,
                    type: 'GROUP-MANAGE',
                    mode: 'SEE',
                },
            });
        },
    },
};
</script>

<style lang="less">
.show-slide {
    position: relative;
    width: 120px;
    height: 55px;
    display: inline-block;
    margin: 10px 10px 10px 0;
    border-radius: 6px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.show-img {
    opacity: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: inline-block;
    border-radius: 6px;
    float: left;
    position: absolute;
    &:hover {
        cursor: pointer;
    }
}
</style>
