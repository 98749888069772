<template>
    <div v-loading="loading">
        <PageHeader title="企业认证" hidden-breadcrumb />
        <Card :bordered="false" dis-hover class="ivu-mt" v-if="!loading">
            <wait :link="link" v-if="type === 'wait'" />
            <success :data="data" v-else />
        </Card>
    </div>
</template>

<script>
import wait from './components/result/wait';
import success from './components/result/success';
import { queryCompany } from '@/api/setting';
export default {
    name: 'result-success',
    components: {
        wait,
        success,
    },
    data() {
        return {
            type: 'wait',
            link: '',
            loading: true,
            data: {
                name: '',
                idNumber: '',
                mobile: '',
                legalPersonName: '',
                disName: '',
                disIdNumber: '',
                invitationUrl: '',
            },
        };
    },
    computed: {},
    mounted() {
        this.loading = true;
        queryCompany({}).then(
            (res) => {
                this.loading = false;
                this.data = res;
                if (res.name && res.mobile) {
                    this.type = 'ok';
                } else {
                    this.type = 'wait';
                    this.link = res.invitationUrl || '';
                }
            },
            () => {
                this.loading = false;
            },
        );
    },
};
</script>
