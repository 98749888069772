<template>
  <div>
    <TreeSelect
        v-width='"100%"'
        v-model="currValue"
        :data="shopList"
        :disabled="disabled"
        placeholder="请选择店铺分类"/>
  </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    export default {
        props: {
            value: {
                type: [String, Number],
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        mounted () {
            this.getShopList()
        },
        computed: {
            ...mapGetters('admin/store', ['shopList']),
            currValue: {
                get () {
                    return this.value
                },
                set (v) {
                    this.$emit('input', v)
                }
            }
        },
        methods: {
            ...mapActions('admin/store', [
                'getShopList'
            ], true)
        }
    }
</script>
