<!-- eslint-disable vue/no-mutating-props -->
<!--
 * @Author: chengleilei
 * @Date: 2021-11-18 10:58:55
 * @Description: 标记发货弹窗组件
-->
<template>
    <div class="send-goods-modal">
        <Modal v-model="modal" title="标记发货" :mask-closable="false" width="980" :closable="false">
            <div v-show="list.length">
                <h4 class="ivu-mb-8">订单编号：{{ specialOrderNo }}</h4>
                <Table
                    :columns="columns"
                    :data="list"
                    :no-data-text="'暂无商品信息'"
                    :no-filtered-data-text="'暂无商品信息'"
                ></Table>
                <div class="ivu-mt-8">
                    <p class="info">
                        本次发货总数：<span>{{ refundNum }}</span>
                    </p>
                </div>
            </div>
            <div slot="footer">
                <Button type="text" @click="refundCancel">取消</Button>
                <Button type="primary" @click="refundOk">确定</Button>
            </div>
        </Modal>
        <Modal v-model="confirmModal" title="确认操作" :mask-closable="false" :closable="false">
            <div class="ivu-mt-8">
                <p class="info">本次发货总数：{{ refundNum }}，是否确认操作？</p>
            </div>
            <div slot="footer">
                <Button type="text" @click="confirmCancel">取消</Button>
                <Button type="primary" @click="confirmOk">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import { getReturnInfo, recodeSendGoods } from '@/api/sales';

export default {
    props: {
        // 标记发货弹窗显隐
        modal: {
            type: Boolean,
            default: false,
        },
        // 订单数据
        item: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            confirmModal: false, // 确认标记发货弹窗显隐
            isRefunding: false, // 是否标记发货中
            // 商品信息表头
            columns: [
                {
                    title: '商品名称',
                    key: 'productName',
                    align: 'center',
                    minWidth: 180,
                    fixed: 'left',
                    render: (h, p) => {
                        return h(
                            'p',
                            {
                                class: {
                                    lineTwo: true,
                                },
                            },
                            p.row.productName,
                        );
                    },
                },
                {
                    title: '单价',
                    align: 'center',
                    minWidth: 100,
                    render: (h, p) => {
                        return h('div', p.row.amount == null ? '-' : p.row.amount);
                    },
                },
                {
                    title: '下单数量',
                    key: 'quantity',
                    align: 'center',
                    minWidth: 80,
                },
                {
                    title: '已退/已取消数量',
                    key: 'refundOrCancelQuantity',
                    align: 'center',
                    minWidth: 120,
                },
                {
                    title: '已发货数量',
                    key: 'sendGoodsQuantity',
                    align: 'center',
                    minWidth: 100,
                },
                {
                    title: '待发货数量',
                    key: 'canSendGoodQuantity',
                    align: 'center',
                    minWidth: 100,
                    render: (h, p) => {
                        return h('div', p.row.canSendGoodQuantity < 0 ? 0 : p.row.canSendGoodQuantity);
                    },
                },
                {
                    title: '本次发货数量',
                    align: 'center',
                    minWidth: 120,
                    fixed: 'right',
                    render: (h, p) => {
                        const maxNum = this.list[p.index].canSendGoodQuantity;
                        return h('InputNumber', {
                            props: {
                                max: maxNum,
                                min: 0,
                                value: p.row.num,
                                disabled: maxNum <= 0,
                                formatter: (value) => value && `${parseInt(value)}`,
                            },
                            on: {
                                input: (v) => {
                                    this.$set(this.list[p.index], 'num', v);
                                },
                            },
                        });
                    },
                },
            ],
            list: [], // 商品信息列表
            specialOrderNo: '', // 订单编号
            // 标记发货金额明细
            amountInfo: {
                allRefundAmount: 0, // 标记发货总金额
                rebateRefundAmount: 0, // 返利标记发货
                thirdRefundAmount: 0, // 三方支付标记发货
            },
        };
    },
    filters: {
        filterNum(value) {
            const arr = String(value).split('.');
            if (arr[1]) {
                return value.toFixed(2);
            }
            return value;
        },
    },
    computed: {
        // 标记发货数量
        refundNum() {
            const num = this.list.reduce((total, currentValue) => {
                return Number(total || 0) + Number(currentValue.num || 0);
            }, 0);
            return num;
        },
        // 标记发货金额
        refundAmount() {
            const num = this.list.reduce((total, currentValue) => {
                return (
                    Number(total || 0) +
                    Number((currentValue.num || 0) * (currentValue.amount || currentValue.payDeposit))
                );
            }, 0);
            return num;
        },
    },
    watch: {
        modal(newValue) {
            if (newValue) {
                this.list = [];
                this.getOrderDetail();
            }
        },
    },
    methods: {
        /**
         * @description 查询发货详情
         */
        getOrderDetail() {
            const params = { orderId: this.item.id };
            getReturnInfo(params).then((res) => {
                this.specialOrderNo = res.specialOrderNo || '-';
                this.list =
                    res.orderItemSendGoodList.map((item) => {
                        item.num = item.canSendGoodQuantity < 0 ? 0 : item.canSendGoodQuantity;
                        return item;
                    }) || [];
            });
        },

        /**
         * @description 处理标记发货列表数据
         */
        resolveRefundList() {
            const refundDetailList = [];
            this.list.map((k) => {
                if (k.num >= 0) {
                    refundDetailList.push({
                        num: k.num,
                        orderItemId: k.orderItemId,
                    });
                }
            });
            return refundDetailList;
        },

        /**
         * @description 标记发货弹窗确定
         */
        refundOk() {
            // const sum = this.list.reduce((prev, current) => {
            //     return prev + current.canRefundQuantity
            // }, 0);
            // if (sum <= 0) {
            //     this.$emit('update:modal', false)
            //     return;
            // }
            // if (!this.refundAmount) {
            //     this.$Message.error('请输入标记发货数量')
            //     return
            // }

            this.confirmModal = true;
        },

        /**
         * @description 标记发货弹窗取消
         */
        refundCancel() {
            this.$emit('update:modal', false);
        },

        /**
         * @description 确认弹窗确定
         */
        confirmOk() {
            // if (this.isRefunding) return
            this.isRefunding = true;
            const params = {
                sendItemList: this.resolveRefundList(),
                specialOrderId: this.item.id,
            };
            if (params.sendItemList.length == 0) {
                this.$Message.warning('还没有填写发货数量');
                return;
            }
            recodeSendGoods(params)
                .then(() => {
                    this.confirmModal = false;
                    this.$emit('update:modal', false);
                    this.$Message.success('操作成功');
                    this.$emit('confirmOk');
                    this.isRefunding = false;
                })
                .catch(() => {
                    this.isRefunding = false;
                });
        },

        /**
         * @description 确认弹窗取消
         */
        confirmCancel() {
            this.confirmModal = false;
        },
    },
};
</script>

<style lang="less" scoped>
.send-goods-modal {
    .info {
        span {
            color: #e70707;
        }
    }

    /deep/.lineTwo {
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 这里是超出几行省略 */
        overflow: hidden;
    }
}
</style>
<style>
.lineTwo {
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 这里是超出几行省略 */
    overflow: hidden;
}
</style>
