import request from '@/plugins/request';

// 获取导出列表
export function GetExportList(data) {
    return request({
        url: '/download/page',
        method: 'get',
        params: data,
    });
}

// 批量删除
export function Delete(data) {
    return request({
        url: `/download/delete_by_ids?ids=${data.ids.join(',')}`, //
        method: 'post',
    });
}

// 导出
export function ExportList(id) {
    return request({
        url: `/download/${id}`,
        method: 'post',
    });
}
