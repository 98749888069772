/**
 * @Description: 促销相关/特教轮胎/特价订单
 * @author: forguo
 * @date: 2020/7/31
 */
import request from "@/plugins/request";

// 订单数据统计
export function orderListDataAnalysis (data) {
    return request({
        url: `/special/order/statistic`,
        method: "post",
        params: data
    });
}

// 订单查询
export function orderList (data) {
    return request({
        url: `/special/order/findPage`,
        method: "post",
        params: data
    });
}

// 订单导出
export function orderListExport (data) {
    return request({
        url: `/special/order/export`,
        method: "post",
        params: data
    });
}

// 特价订单详情
export function specialOrderDetail (data) {
    return request({
        url: `/special/order/detail`,
        method: "post",
        params: data
    });
}

// 特价返利审核查询
export function rebateList (data) {
    return request({
        url: `/rebate/special/findPage`,
        method: "post",
        data
    });
}

// 特价返利审核导出
export function rebateListExport (data) {
    return request({
        url: `/rebate/special/export`,
        method: "post",
        data
    });
}

// 特价返利审核详情
export function singleAuditRebateDetail (data) {
    return request({
        url: `/special/detail/findPage`,
        method: "post",
        data
    });
}

// 特价返利审核详情导出
export function singleAuditRebateDetailExport (data) {
    return request({
        url: `/special/detail/export`,
        method: "post",
        data
    });
}

// 门店返利已发放-批量
export function rebateSpecialPayMoney (data) {
    return request({
        url: `/rebate/special/payMoney`,
        method: "post",
        data
    });
}

// 退款单列表
export function listRefundOrder (specialOrderId) {
    return request({
        url: `/special/order/refundOrder/${specialOrderId}`,
        method: "get"
    });
}

// 特价订单渠道退款记录
export function getRefundRecord (orderId) {
    return request({
        url: `/special/order/order/channel/refund/${orderId}`,
        method: "get"
    });
}

// 特价订单退款step1-查询订单详情和退货情况
export function refundStep1 (data) {
    return request({
        url: `/refund/specialOrder/step1`,
        method: "get",
        params: data
    });
}

// 特价订单退款step2-计算退款金额明细
export function refundStep2 (data) {
    return request({
        url: `/refund/specialOrder/step2`,
        method: "post",
        data
    });
}

// 特价订单退款step3-确认退款
export function refundStep3 (data) {
    return request({
        url: `/refund/specialOrder/step3`,
        method: "post",
        data
    });
}

// 线下订单，获取取消订单详情数据
export function getOfflineCancelOrderRecord (data) {
    return request({
        url: `/special/order/getOfflineCancelOrderRecord`,
        method: "get",
        params: data
    });
}

// 线下订单，取消订单
export function offlineCancelOrder (data) {
    return request({
        url: `/special/order/offlineCancelOrder`,
        method: "post",
        data
    });
}

// 发货第1步，查询相关信息
export function getReturnInfo ({ orderId }) {
    return request({
        url: `/special/order/send/good/info/${orderId}`,
        method: "get"
    });
}

/**
 * 线下订单，取消订单
 * @param data
 * @returns {AxiosPromise}
 */

export function recodeSendGoods (data) {
    return request({
        url: `/special/order/send/good/confirm`,
        method: "post",
        data
    });
}
