<template>
    <div class="dealer-account-list">
        <div class="dealer-account-inner">
            <PageHeader title="账户管理" hidden-breadcrumb />
            <Button
                type="primary"
                icon="md-add"
                class="ivu-mt-16"
                :disabled="canOpen === 0"
                @click="addAccount"
                slot="action"
                >创建账户</Button
            >
            <p class="ivu-mt-16">
                {{ canOpen > 0 ? '还可以创建' + (canOpen || 0) + '个账户' : '开通账户数量已达上限' }}
            </p>
            <Card :bordered="false" dis-hover class="ivu-mt">
                <table-page
                    :has-page="false"
                    :list="list"
                    @onPageChange="onPageChange"
                    @onPageSizeChange="onPageSizeChange"
                />
            </Card>
        </div>

        <Card :bordered="false" title="业务流程介绍" dis-hover class="ivu-mt open-tips">
            <Steps :current="-1">
                <Step title="绑卡认证" content="提交营业执照、身份证、银行账户等基本信息，并按指引完成账户验证"></Step>
                <Step title="开户" content="完善企业和法人信息，等待平台审核"></Step>
                <Step title="完成签约" content="完成短信验证码验证，进入链接完成签约"></Step>
            </Steps>
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import { accountList, checkBindingCardStatus, getSignContractRecord } from '@/api/dealerAccount';
import { renderButton, jumpPage } from '@/libs/util';

export default {
    components: { tablePage },
    data() {
        return {
            isExporting: false,
            list: {
                page: 1,
                size: window.$pageSize,
                total: 0,
                columns: [
                    {
                        title: '企业全称',
                        key: 'accountName',
                        align: 'center',
                        minWidth: 160,
                        height: 48,
                        ellipsis: true,
                        tooltip: true,
                    },
                    {
                        title: '法人姓名',
                        key: 'legalPersonName',
                        align: 'center',
                        minWidth: 160,
                    },
                    {
                        title: '账户类型',
                        key: 'userType',
                        align: 'center',
                        minWidth: 160,
                        render: (h, p) => {
                            return h(
                                'div',
                                {
                                    '': '-',
                                    12: '企业',
                                    13: '个体户',
                                }[p.row.userType] || '-',
                            );
                        },
                    },
                    {
                        title: '收款账户',
                        key: 'activeStatus',
                        align: 'center',
                        minWidth: 160,
                        render: (h, p) => {
                            return h(
                                'div',
                                {
                                    '': '-',
                                    0: '否',
                                    1: '是',
                                }[p.row.activeStatus] || '-',
                            );
                        },
                    },
                    {
                        title: '状态',
                        key: 'accountStatus',
                        align: 'center',
                        width: 190,
                        render: (h, p) => {
                            // 账户状态: ：0=待绑卡认证、1=待开户、2=开户处理中、3=待签约、4=签约待完成、5=正常使用中
                            let badge = h('Badge', {
                                props: {
                                    color: {
                                        0: 'yellow',
                                        1: 'yellow',
                                        2: 'blue',
                                        3: 'yellow',
                                        4: 'yellow',
                                        5: 'green',
                                        6: 'red',
                                        7: 'blue',
                                    }[p.row.accountStatus],
                                    text: {
                                        0: '待绑卡认证',
                                        1: '待开户',
                                        2: '开户处理中',
                                        3: '待签约',
                                        4: '签约待完成',
                                        5: '正常使用中',
                                        6: '开户失败',
                                        7: '绑卡处理中',
                                    }[p.row.accountStatus],
                                },
                            });
                            return h('div', [badge]);
                        },
                    },
                    {
                        title: '操作',
                        align: 'center',
                        width: 300,
                        fixed: 'right',
                        render: (h, params) => {
                            // 0=待绑卡认证、1=待开户、2=开户处理中、3=待签约、4=签约待完成、5=正常使用中 6: '开户失败', 7: '绑卡处理中',
                            const btns = [
                                renderButton(h, { text: '查看', type: 'success', size: 'small' }, () => {
                                    this.onCheck(params.row);
                                }),
                            ];
                            params.row.accountStatus === 0 &&
                                btns.push(
                                    renderButton(h, { text: '绑卡认证', type: 'primary', size: 'small' }, () => {
                                        this.onTiedCard(params.row);
                                    }),
                                );
                            params.row.accountStatus === 1 &&
                                btns.push(
                                    renderButton(h, { text: '开户', type: 'primary', size: 'small' }, () => {
                                        this.onOpenAccount(params.row, '');
                                    }),
                                );
                            params.row.accountStatus === 3 &&
                                btns.push(
                                    renderButton(h, { text: '签约', type: 'primary', size: 'small' }, () => {
                                        this.onSignContract(params.row);
                                    }),
                                );
                            params.row.accountStatus === 4 &&
                                btns.push(
                                    renderButton(h, { text: '继续签约', type: 'primary', size: 'small' }, () => {
                                        this.onContinueSign(params.row);
                                    }),
                                );
                            params.row.accountStatus === 6 &&
                                btns.push(
                                    renderButton(h, { text: '重新开户', type: 'primary', size: 'small' }, () => {
                                        this.onOpenAccount(params.row, 'reOpen');
                                    }),
                                );
                            return btns;
                        },
                    },
                ],
                data: [],
                loading: false,
            },
            canOpen: 0,
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        addAccount() {
            jumpPage({ path: '/sub-vue2/assets/dealerAccount/typeChose', routeType: 'replaceState' });
        },
        getData() {
            this.list.loading = true;
            let params = {
                current: this.list.page,
                size: this.list.size,
            };
            accountList(params)
                .then((res) => {
                    this.list.loading = false;
                    if (res) {
                        this.list.data =
                            res.accountVOList.map((item) => {
                                return {
                                    ...item,
                                    accountName: item.accountName || '-',
                                };
                            }) || [];
                        this.canOpen = res.canOpen || 0;
                    }
                })
                .catch(() => {
                    this.list.loading = false;
                });
        },
        onPageChange(val) {
            this.list.page = val;
            this.getData();
        },
        onPageSizeChange(val) {
            this.list.page = 1;
            this.list.size = val;
            this.getData();
        },
        onSubmit() {
            this.list.page = 1;
            this.getData();
        },
        // 查看
        onCheck(row) {
            jumpPage({
                path: '/sub-vue2/assets/dealerAccount/detail',
                params: {
                    cpcnAccountId: row.cpcnAccountId,
                    userType: row.userType,
                    cardId: row.cardId,
                    userId: row.userId,
                },
            });
        },
        // 绑卡
        onTiedCard(row) {
            this.list.loading = true;
            this.$Message.loading({
                content: '状态查询中...',
                duration: 0,
            });
            /**
             * 根据状态判断跳转哪个页面
             */
            checkBindingCardStatus(row.cardId).then(
                (res) => {
                    // 绑卡的状态
                    // 1000：正常，
                    // 1001：企业绑卡验证次数超过3次，
                    // 1002：企业绑卡发起时间超过3天，
                    // 1003：个人绑卡验证码超过10分钟，
                    // 1004：个人绑卡验证码错误，
                    // 1005：企业转账金额错误，
                    // 1006：处理中，请稍后查看，
                    // 1007：请等待支付平台打款，请稍后查看
                    if (res && res.cardStatus) {
                        const cardStatus = res.cardStatus;
                        // 处理中也可以去输入,
                        // 个人验证码错误或者超时，进入到认证页面，重新去获取验证码
                        if ([1000, 1006, 1007, 1003, 1004].includes(cardStatus)) {
                            jumpPage({
                                path: '/sub-vue2/assets/dealerAccount/tiedCardApprove',
                                params: {
                                    cpcnAccountId: row.cpcnAccountId,
                                    userType: row.userType,
                                    cardId: row.cardId,
                                    userId: row.userId,
                                    firstCardStatus: true,
                                    cardStatus,
                                },
                            });
                        }
                        // 超时或者错误，进入结果页面
                        if ([1001, 1002, 1005].includes(cardStatus)) {
                            jumpPage({
                                path: '/sub-vue2/assets/dealerAccount/tiedCardResult',
                                params: {
                                    cpcnAccountId: row.cpcnAccountId,
                                    userType: row.userType,
                                    cardId: row.cardId,
                                    userId: row.userId,
                                    firstCardStatus: true,
                                    cardStatus,
                                },
                            });
                        }
                    }
                    this.list.loading = false;
                    this.$Message.destroy();
                },
                () => {
                    this.list.loading = false;
                    this.$Message.destroy();
                },
            );
        },
        // 开户, type: 'reOpen' 重新开户
        onOpenAccount(row, type) {
            jumpPage({
                path: '/sub-vue2/assets/dealerAccount/openAccount',
                params: {
                    cpcnAccountId: row.cpcnAccountId,
                    userType: row.userType,
                    cardId: row.cardId,
                    userId: row.userId,
                    type,
                },
            });
        },
        // 签约
        onSignContract(row) {
            jumpPage({
                path: '/sub-vue2/assets/dealerAccount/signContract',
                params: {
                    cpcnAccountId: row.cpcnAccountId,
                    userType: row.userType,
                    cardId: row.cardId,
                    userId: row.userId,
                },
            });
        },
        // 继续签约
        onContinueSign(row) {
            this.list.loading = true;
            this.$Message.loading({
                content: '状态查询中...',
                duration: 0,
            });
            /**
             * 根据状态判断跳转哪个页面
             */
            getSignContractRecord(row.userId || '').then(
                (res) => {
                    // 签约状态【10=未签约，20=已签约】
                    if (res) {
                        const { signStatus = 10, signContractUrl } = res;
                        if (signStatus == 20) {
                            // 已签约
                            jumpPage({
                                path: '/sub-vue2/assets/dealerAccount/signContractResult',
                                params: {
                                    cpcnAccountId: row.cpcnAccountId,
                                    userType: row.userType,
                                    cardId: row.cardId,
                                    userId: row.userId,
                                    signStatus,
                                    signContractUrl,
                                },
                            });
                        } else {
                            // 未签约
                            jumpPage({
                                path: '/sub-vue2/assets/dealerAccount/signContract',
                                params: {
                                    cpcnAccountId: row.cpcnAccountId,
                                    userType: row.userType,
                                    cardId: row.cardId,
                                    userId: row.userId,
                                    signStatus,
                                    signContractUrl,
                                },
                            });
                        }
                    }
                    this.list.loading = false;
                    this.$Message.destroy();
                },
                () => {
                    this.list.loading = false;
                    this.$Message.destroy();
                },
            );
        },
    },
};
</script>

<style scoped>
.dealer-account-list {
    height: calc(100vh - 140px);
    padding-bottom: 120px;
    box-sizing: border-box;
    position: relative;
}
.dealer-account-inner {
    height: 100%;
    overflow-y: auto;
}
.open-tips {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 99;
}
.dealer-account-list >>> .ivu-steps .ivu-steps-head-inner {
    background: #2d8cf0 !important;
    color: #fff !important;
    border-color: #2d8cf0 !important;
}
.dealer-account-list >>> .ivu-steps .ivu-steps-head-inner span {
    background: #2d8cf0 !important;
    color: #fff !important;
    border-color: #2d8cf0 !important;
}
</style>
