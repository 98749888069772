<template>
    <div>
        <Modal v-model="showModal" title="修改门店维护人" @on-ok="confirmProtector" @on-cancel="close">
            <p>
                门店维护人
                <Select v-model="salesManId" style="width: 200px">
                    <Option v-for="item in salesManList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
            </p>
        </Modal>
    </div>
</template>

<script>
import { BatchUpdateSalesMan, UpdateSalesMan, SalesmanList } from '@/api/store';

export default {
    name: 'modalProtector',
    props: {},
    data() {
        return {
            showModal: false,
            salesManList: [],
            salesManId: '',
            ids: [],
            storeId: '',
        };
    },
    mounted() {},
    methods: {
        getSaleMan() {
            SalesmanList().then((res) => {
                // console.log('sale man:', res)
                this.salesManList = (res || []).map((item) => {
                    return {
                        value: item.id,
                        label: item.name,
                    };
                });
                // console.log('sale man === ', res)
            });
        },
        // 确认修改
        confirmProtector() {
            if (this.updateType === 'ONE') {
                // 单次修改
                let data = {
                    storeId: this.storeId,
                    salesmanId: this.salesManId,
                };
                console.log('单次 ', data);
                UpdateSalesMan(data).then(() => {
                    this.$Message.success({
                        content: '修改成功！',
                    });
                    this.close();
                    this.$parent.getData();
                });
            } else {
                // 批量修改
                let data = {
                    ids: this.ids,
                    salesmanId: this.salesManId,
                };
                console.log('批量 ', data);
                BatchUpdateSalesMan(data).then(() => {
                    this.$Message.success({
                        content: '修改成功！',
                    });
                    this.close();
                    this.$parent.getData();
                });
            }
        },
        open(updateType, row) {
            this.showModal = true;
            this.salesManId = row.salesmanId;
            this.storeId = row.id;
            this.ids = row.ids || [];
            this.updateType = updateType;
            this.getSaleMan();
            console.log('modal open: ', updateType, row);
        },
        close() {
            this.showModal = false;
        },
    },
};
</script>

<style scoped></style>
