<template>
    <Select
        v-model="currValue"
        filterable
        remote
        ref="distributorSelect"
        :disabled="disabled"
        :loading="loading"
        placeholder="请输入扫码人姓名搜索"
        clearable
        :label-in-value="isLabelInvalue"
        @on-change="onchange"
        @on-query-change="remote"
        @on-open-change="onOpen"
    >
        <Option
            v-for="item in distributorList"
            :value="item.id"
            :key="item.id"
            >{{ item.label }}</Option
        >
    </Select>
</template>
<script>
import { debounce } from "lodash";
import { getDistributorList } from "@/api/scan";
export default {
    props: {
        value: {
            type: [String, Number],
            default: "",
        },
        isLabelInvalue: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            distributorList: [],
            loading: false,
            distributorName: "",
        };
    },
    computed: {
        currValue: {
            get() {
                return this.value;
            },
            set(v) {
                this.$emit("input", v || "");
            },
        },
    },
    methods: {
        onchange(val) {
            this.$emit("onchange", val);
        },
        onOpen(isOpen) {
            if (isOpen) this.getData();
        },
        remote(query = "") {
            this.getData(query);
        },
        getData: debounce(function d(query = "") {
            this.loading = true;
            let param = {};
            if (query) param.realName = query;
            return getDistributorList(param).then((data) => {
                this.loading = false;
                this.distributorList = [];
                this.distributorList = (data || []).map((item) => {
                    return {
                        id: item.id,
                        label: item.realName,
                    };
                });
            });
        }, 400),
        selectDefault(val) {
            // 如需默认选中第一项，手动调用该方法
            this.distributorName = val;
            this.getData(this.distributorName).then(() => {
                if (!this.distributorName) return;
                let item = this.distributorList[0] || {};
                this.$refs.storeSelect.onOptionClick({
                    value: item.id,
                    label: item.label,
                });
                this.$emit("onchange", { value: item.id, label: item.label });
            });
        },
    },
};
</script>
