<template>
    <div>
        <PageHeader title="门店积分明细" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage"><Icon type="md-arrow-back" /></div>
        </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" @on-submit="onSubmit" @on-export="exportInfo" ref="table-form">
                <Button class="ivu-ml-8" slot="extraBtn" type="error">导出</Button>
            </table-form>
            <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list" />
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { clearEmpty, jumpPage, transPathToName } from '@/libs/util';
import { storeScoreDetailCheck, storeScoreDetailExport } from '@/api/statistics';

export default {
    name: 'store-scan-storeRebateDetail',
    components: {
        tableForm,
        tablePage,
    },
    data() {
        return {
            isChangePage: false,
            formList: [
                {
                    title: '商品名称',
                    type: 'input',
                    key: 'productName',
                    placeholder: '请输入商品名称',
                },
                {
                    title: '胎号',
                    type: 'input',
                    key: 'tyreCode',
                    placeholder: '请输入胎号',
                },
                {
                    title: '条码',
                    type: 'input',
                    key: 'barcode',
                    placeholder: '请输入条码',
                },
            ],
            searchParams: {
                scanTimeStr: this.$route.query.scanTimeStr,
                storeId: this.$route.query.storeId || '',
                distributorId: this.$route.query.distributorId,
                tiresName: '',
                tyrecode: '',
                scanCodeType: '',
                barcode: '',
                isHistory: '',
            },
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    {
                        title: '商品名称',
                        key: 'productName',
                        align: 'center',
                        minWidth: 220,
                    },
                    {
                        title: '系列',
                        key: 'brandSeries',
                        align: 'center',
                        minWidth: 100,
                    },
                    {
                        title: '品牌',
                        key: 'brandName',
                        align: 'center',
                        minWidth: 100,
                    },
                    {
                        title: '尺寸',
                        key: 'porductSize',
                        align: 'center',
                        minWidth: 100,
                    },
                    {
                        title: '门店类型',
                        key: 'storeType',
                        align: 'center',
                        minWidth: 100,
                    },
                    {
                        title: '胎号',
                        key: 'tyreCode',
                        align: 'center',
                        minWidth: 100,
                    },
                    {
                        title: '条码',
                        key: 'barcode',
                        align: 'center',
                        minWidth: 100,
                    },
                    {
                        title: '积分',
                        key: 'storeRebate',
                        align: 'center',
                        minWidth: 100,
                    },
                    {
                        title: '类型',
                        key: 'scanType',
                        align: 'center',
                        minWidth: 120,
                    },
                    {
                        title: '扫码时间',
                        key: 'scanTime',
                        align: 'center',
                        minWidth: 100,
                    },
                    {
                        title: '备注',
                        key: 'remark',
                        align: 'center',
                        minWidth: 100,
                    },
                ],
                data: [],
            },
        };
    },
    mounted() {
        this.getData();
        this.$bus.$on('refresh', this.getData);
    },
    beforeDestroy() {
        this.$bus.$off();
    },

    methods: {
        getData(p) {
            this.list.loading = true;
            p = clearEmpty(this.searchParams);
            let params = {
                current: this.list.current,
                size: this.list.size,
                ...p,
            };
            storeScoreDetailCheck(params).then(
                (data) => {
                    console.log(data.records);
                    let scanType = {
                        61: '门店入库扫码',
                        62: '门店出库扫码',
                        63: '经销商退货扫码',
                        53: '退货',
                    };
                    this.list.data = (data.records || []).map((item) => {
                        item.scanType = scanType[item.scanType];
                        return item;
                    });
                    this.list.total = Number(data.total);
                    this.isChangePage = false;
                    this.list.loading = false;
                },
                () => {
                    this.isChangePage = false;
                    this.list.loading = false;
                },
            );
        },
        exportData() {
            let p = clearEmpty(this.searchParams);
            let params = {
                ...p,
            };
            this.$Message.loading({
                content: '导出中...',
                duration: 0,
            });
            storeScoreDetailExport(params).then(
                (res) => {
                    this.list.loading = false;
                    this.isExporting = false;
                    if (!res) {
                        this.$Message.warning('暂无数据');
                        return false;
                    }
                    let aLink = document.createElement('a');
                    aLink.href = res || '';
                    // console.log('href ===', res)
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    this.$Message.destroy();
                    this.$Message.success('导出成功');

                    // this.$Modal.success({
                    //     title: '「门店扫码返利详情」导出成功',
                    //     content: '请到 [ 导出列表页 ] 查看及下载'
                    // });
                },
                () => {
                    this.$Message.destroy();
                    this.$Message.warning({
                        title: '导出失败 ！',
                    });
                    this.list.loading = false;
                    this.isExporting = false;
                },
            );
        },
        onPageChange(val) {
            if (this.isChangePage) return;
            this.list.current = val;
            this.getData();
        },
        onPageSizeChange(val) {
            this.list.current = 1;
            this.isChangePage = true;
            this.list.size = val;
            this.getData();
        },
        check(row) {
            this.$refs['records'].open(row);
        },
        onSubmit(params) {
            this.list.current = 1;
            this.searchParams = {
                ...this.searchParams,
                ...params,
            };
            this.getData();
        },
        exportInfo(params) {
            this.searchParams = {
                ...this.searchParams,
                ...params,
            };
            this.exportData();
        },
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/statistics/store/rebate' });
        },
    },
};
</script>
