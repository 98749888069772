<!--
 * @Author: wforguo
 * @Date: 2021-09-30 15:54:12
 * @Description: 绑卡认证结果页
-->
<template>
    <div class="open-account-result" v-loading="pageLoading">
        <PageHeader :title="title" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage">
                <Icon type="md-arrow-back" />
            </div>
        </PageHeader>
        <Card class="ivu-mt" :bordered="false" dis-hover>
            <!-- 开户处理中 -->
            <template>
                <div class="img-wrapper" v-if="cardStatus == 1000">
                    <img src="@/assets/images/result-success.png" />
                </div>
                <div class="img-wrapper" v-else-if="cardStatus == 1006">
                    <img src="@/assets/images/result-loading.png" />
                </div>
                <div class="img-wrapper" v-else-if="cardStatus == 1007">
                    <img src="@/assets/images/result-loading.png" />
                </div>
                <div class="img-wrapper" v-else>
                    <img src="@/assets/images/result-error.png" />
                </div>
                <Result :title="cardStatusTitle[cardStatus]">
                    <div slot="desc">
                        {{ cardStatusDesc[cardStatus] }}
                    </div>
                    <div slot="actions">
                        <Button v-if="cardStatus == 1000" type="primary" @click="goPage(cardStatus)">立即开户</Button>
                        <Button v-else-if="cardStatus == 1006" type="primary" @click="jumpPrevPage(cardStatus)"
                            >返回</Button
                        >
                        <Button v-else-if="cardStatus == 1007" type="primary" @click="jumpPrevPage(cardStatus)"
                            >返回</Button
                        >
                        <Button v-else type="primary" @click="goPage(cardStatus)">重新认证</Button>
                    </div>
                </Result>
                <div class="step-wrapper" v-if="cardStatus == 1000">
                    <Steps>
                        <Step status="finish" title="填写基本信息"></Step>
                        <Step status="finish" title="绑卡认证"></Step>
                        <Step title="开户"></Step>
                        <Step title="完成签约"></Step>
                    </Steps>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import { jumpPage, transPathToName } from '@/libs/util';

export default {
    data() {
        return {
            title: {
                12: '企业绑卡认证',
                13: '个体工商户绑卡认证',
            }[this.$route.query.userType || ''],
            pageLoading: false,
            userType: this.$route.query.userType || '', // 影印件账户类型【11=个人账户 12=企业账户 13 = 个体工商户】
            bankAccountType: this.$route.query.bankAccountType || '', // 银行账户类型【11=个人账户 12=企业账户】
            cpcnAccountId: this.$route.query.cpcnAccountId || '', // 账户id
            userId: this.$route.query.userId || '', // 账户id
            cardStatus: this.$route.query.cardStatus || '',
            cardId: this.$route.query.cardId || '',
            type: this.$route.query.type || '', // 认证类型，默认为绑卡，bank：银行卡认证
            // 绑卡的状态
            // 1000：正常，
            // 1001：企业绑卡验证次数超过3次，
            // 1002：企业绑卡发起时间超过3天，
            // 1003：个人绑卡验证码超过10分钟，
            // 1004：个人绑卡验证码错误，
            // 1005：企业转账金额错误，
            // 1006：处理中，请稍后查看，
            // 1007：请等待支付平台打款，请稍后查看
            cardStatusTitle: {
                1000: '认证成功',
                1001: '认证失败',
                1002: '认证超时',
                1003: '认证超时',
                1004: '认证失败',
                1006: '处理中',
                1007: '处理中',
            },
            cardStatusDesc: {
                1000: '您已完成绑卡认证，请继续办理开户',
                1001: '转账金额已认证失败3次，请重新认证',
                1002: '认证时间已超过3天，请重新认证',
                1003: '个人绑卡验证码超过10分钟',
                1004: '个人绑卡验证码错误',
                1006: '处理中，请稍后查看',
                1007: '请等待支付平台打款，请稍后查看',
            },
        };
    },

    created() {},

    methods: {
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
        },
        /**
         * @description 查看认证状态
         */
        getData() {},
        /**
         * @description 根据状态跳转页面
         * @param cardStatus
         */
        goPage(cardStatus) {
            // 绑卡的状态
            // 1000：正常，
            // 1001：企业绑卡验证次数超过3次，
            // 1002：企业绑卡发起时间超过3天，
            // 1003：个人绑卡验证码超过10分钟，
            // 1004：个人绑卡验证码错误，
            // 1005：企业转账金额错误，
            // 1006：处理中，请稍后查看，
            // 1007：请等待支付平台打款，请稍后查看
            let page = 'tiedCardForm';
            const { userType, userId, cpcnAccountId, bankAccountType, cardId } = this;

            /**
             * 正常情况
             **/
            if ([1000].includes(cardStatus)) {
                // 绑卡认证成功，去开户
                page = 'openAccount';
            }
            /**
             * 重新提交表单[提交信息或者银行卡]
             * // 1001：企业绑卡验证次数超过3次，
             * // 1002：企业绑卡发起时间超过3天，
             **/
            if ([1001, 1002].includes(cardStatus)) {
                if (this.type) {
                    // 银行卡认证
                    page = 'addBankCard';
                } else {
                    // 重新认证，提交影印件信息
                    page = 'tiedCardForm';
                }
            }
            /**
                 // 1003：个人绑卡验证码超过10分钟，
                 // 1004：个人绑卡验证码错误，
                 // 1005：企业转账金额错误，
                 */
            if ([1005, 1003, 1004].includes(cardStatus)) {
                page = 'tiedCardApprove';
            }
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            // , 个人绑卡验证码错误 1004 重新提交表单
            jumpPage({
                path: `/sub-vue2/assets/dealerAccount/${page}`,
                params: { userType, userId, cpcnAccountId, bankAccountType, cardId, cardStatus },
            });
        },
    },
};
</script>

<style lang="less" scoped>
.open-account-result {
    .ivu-mt {
        height: calc(~'100vh - 216px');
    }
    .img-wrapper {
        margin-top: 4%;
        text-align: center;
        img {
            width: 120px;
            height: 120px;
        }
    }
    .step-wrapper {
        display: flex;
        justify-content: center;
        .ivu-steps {
            margin-top: 40px;
            width: 600px;
        }
    }
}
</style>
