<template>
    <div class="store-sign-list">
        <PageHeader title="门店店检统计" hidden-breadcrumb />
        <Card :bordered="false" dis-hover class="ivu-mt">
            <Tabs :value="currentTab" :animated="true" @on-click="handleTabClick" style="margin-top: -12px">
                <TabPane label="2024年" name="2024">
                    <table-form
                        :formList="formList2024"
                        @on-submit="onSubmit"
                        @on-export="exportData"
                        ref="table-form-2024"
                    >
                        <Button class="ivu-ml-8" slot="extraBtn" type="error">导出</Button>
                    </table-form>
                    <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list" />
                </TabPane>
                <TabPane label="2023年" name="2023">
                    <table-form
                        :formList="formList2023"
                        @on-submit="onSubmit"
                        @on-export="exportData"
                        ref="table-form-2023"
                    >
                        <Button class="ivu-ml-8" slot="extraBtn" type="error">导出</Button>
                    </table-form>
                    <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list" />
                </TabPane>
                <TabPane label="2022年" name="2022">
                    <table-form
                        :formList="formList2022"
                        @on-submit="onSubmit"
                        @on-export="exportData"
                        ref="table-form-2022"
                    >
                        <Button class="ivu-ml-8" slot="extraBtn" type="error">导出</Button>
                    </table-form>
                    <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list" />
                </TabPane>
                <TabPane label="2021年" name="2021">
                    <table-form
                        :formList="formList2021"
                        @on-submit="onSubmit"
                        @on-export="exportData"
                        ref="table-form-2021"
                    >
                        <Button class="ivu-ml-8" slot="extraBtn" type="error">导出</Button>
                    </table-form>
                    <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list2021" />
                </TabPane>
            </Tabs>
        </Card>

        <StoreModal ref="StoreModal" />
        <StoreModalCurrent ref="StoreModalCurrent" />

        <Modal title="图片预览" v-model="visible" width="700px" footer-hide>
            <img :src="activeImg" style="width: 100%" />
        </Modal>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { clearEmpty, renderButton } from '@/libs/util';
import {
    storeInspectionList,
    storeInspectionListExport,
    storeAssessmentList,
    storeAssessmentListExport,
} from '@/api/statistics';
import StoreModal from './components/StoreModal';
import StoreModalCurrent from './components/StoreModalCurrent';
import moment from 'moment';

export default {
    components: {
        tableForm,
        tablePage,
        StoreModal,
        StoreModalCurrent,
    },
    data() {
        return {
            currentTab: '2024',
            activeImg: '',
            visible: false,
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    {
                        title: '门店名称',
                        key: 'storeName',
                        align: 'center',
                        minWidth: 220,
                        fixed: 'left',
                    },
                    {
                        title: '门店类型',
                        key: 'typeName',
                        align: 'center',
                        minWidth: 130,
                    },
                    {
                        title: '签约档次',
                        key: 'signLevel',
                        align: 'center',
                        minWidth: 130,
                    },
                    {
                        title: '联系人',
                        key: 'ownerName',
                        align: 'center',
                        minWidth: 130,
                    },
                    {
                        title: '联系方式',
                        key: 'ownerPhone',
                        align: 'center',
                        minWidth: 130,
                    },
                    {
                        title: '月份',
                        key: 'applyMonth',
                        align: 'center',
                        minWidth: 100,
                    },
                    {
                        title: '提交时间',
                        key: 'applyTime',
                        align: 'center',
                        minWidth: 170,
                        render: (h, p) => {
                            return [p.row.applyTime || '-'];
                        },
                    },
                    {
                        title: '审核状态',
                        key: 'auditStatus',
                        align: 'center',
                        minWidth: 160,
                        width: 160,
                        fixed: 'right',
                        render: (h, p) => {
                            return h(
                                'div',
                                {
                                    class:
                                        {
                                            '': 'warning',
                                            1: 'error',
                                            2: 'warning',
                                            3: 'processing',
                                            4: 'success',
                                            5: 'error',
                                        }[p.row.auditStatus] + ' sign-status',
                                },
                                {
                                    '-': '',
                                    1: '门店未提交',
                                    2: '门店已提交',
                                    3: '业务主管已审核',
                                    4: '审核完成',
                                    5: '已驳回',
                                }[p.row.auditStatus] || '-',
                            );
                        },
                    },
                    {
                        title: '审核结果',
                        key: 'channelManAuditResult',
                        align: 'center',
                        minWidth: 100,
                        fixed: 'right',
                        render: (h, p) => {
                            return [p.row.channelManAuditResult || '-'];
                        },
                    },
                    {
                        title: '操作',
                        key: 'oprate',
                        width: 120,
                        fixed: 'right',
                        align: 'center',
                        render: (h, p) => {
                            let btns = [h('span', '--')];
                            if (p.row.auditStatus > 1) {
                                btns = [
                                    renderButton(h, { text: '查看', type: 'primary' }, () => {
                                        this.check(p.row, 'StoreModalCurrent');
                                    }),
                                ];
                            }
                            return btns;
                        },
                    },
                ],
                data: [],
            },
            list2021: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    {
                        title: '门店名称',
                        key: 'storeName',
                        align: 'center',
                        minWidth: 220,
                        fixed: 'left',
                    },
                    {
                        title: '门店类型',
                        key: 'typeName',
                        align: 'center',
                        minWidth: 130,
                    },
                    {
                        title: '签约档次',
                        key: 'signLevel',
                        align: 'center',
                        minWidth: 130,
                    },
                    {
                        title: '联系人',
                        key: 'ownerName',
                        align: 'center',
                        minWidth: 130,
                    },
                    {
                        title: '联系方式',
                        key: 'ownerPhone',
                        align: 'center',
                        minWidth: 130,
                    },
                    {
                        title: '月份',
                        key: 'month',
                        align: 'center',
                        minWidth: 100,
                    },
                    {
                        title: '店检类型',
                        key: 'type',
                        align: 'center',
                        minWidth: 170,
                        fixed: 'right',
                        render: (h, p) => {
                            return h(
                                'div',
                                {
                                    class: '',
                                },
                                {
                                    1: '月度店检',
                                    2: '宣传路演活动',
                                    3: '巡检扣分',
                                }[p.row.type],
                            );
                        },
                    },
                    {
                        title: '审核状态',
                        key: 'auditStatus',
                        align: 'center',
                        minWidth: 120,
                        width: 120,
                        fixed: 'center',
                        render: (h, p) => {
                            return h(
                                'div',
                                {
                                    class:
                                        {
                                            0: 'error',
                                            1: 'warning',
                                            2: 'processing',
                                            3: 'success',
                                        }[p.row.auditStatus] + ' sign-status',
                                },
                                {
                                    0: '门店未提交',
                                    1: '门店已提交',
                                    2: '业务主管已审核',
                                    3: '审核完成',
                                }[p.row.auditStatus],
                            );
                        },
                    },
                    {
                        title: '得分',
                        key: 'score',
                        align: 'center',
                        minWidth: 135,
                    },
                    {
                        title: '备注',
                        key: 'remark',
                        align: 'center',
                        minWidth: 135,
                    },
                    {
                        title: '操作',
                        key: 'oprate',
                        width: 120,
                        fixed: 'right',
                        align: 'center',
                        render: (h, p) => {
                            let btns = [h('span', '--')];
                            if (p.row.auditStatus == 3) {
                                btns = [
                                    renderButton(h, { text: '查看', type: 'primary' }, () => {
                                        this.check(p.row, 'StoreModal');
                                    }),
                                ];
                            }
                            return btns;
                        },
                    },
                ],
                data: [],
            },
            formList2021: [
                {
                    title: '门店',
                    type: 'input',
                    key: 'keyword',
                    placeholder: '请输入门店名称/联系人/联系方式',
                },
                {
                    title: '月份',
                    type: 'month',
                    key: 'applyMonth',
                },
                {
                    title: '状态',
                    type: 'select',
                    key: 'auditStatus',
                    options: [
                        {
                            name: '全部',
                            id: '',
                        },
                        {
                            name: '门店未提交',
                            id: 0,
                        },
                        {
                            name: '门店已提交',
                            id: 1,
                        },
                        {
                            name: '业务主管已审核',
                            id: 2,
                        },
                        {
                            name: '审核完成',
                            id: 3,
                        },
                    ],
                },
            ],
            formList2022: [
                {
                    title: '门店',
                    type: 'input',
                    key: 'storeInfo',
                    placeholder: '请输入门店名称/联系人/联系方式',
                },
                {
                    title: '月份',
                    type: 'month',
                    key: 'applyMonth',
                    startDate: new Date(2022, 0, 1),
                    options: {
                        disabledDate(date) {
                            if (!date) return true;
                            return !(
                                date.valueOf() >= new Date(2021, 11, 31).valueOf() &&
                                date.valueOf() < new Date(2022, 11, 31).valueOf()
                            );
                        },
                    },
                },
                {
                    title: '状态',
                    type: 'select',
                    key: 'status',
                    options: [
                        {
                            name: '全部',
                            id: '',
                        },
                        {
                            name: '门店未提交',
                            id: 1,
                        },
                        {
                            name: '门店已提交',
                            id: 2,
                        },
                        {
                            name: '业务主管已审核',
                            id: 3,
                        },
                        {
                            name: '审核完成',
                            id: 4,
                        },
                    ],
                },
            ],
            formList2023: [
                {
                    title: '门店',
                    type: 'input',
                    key: 'storeInfo',
                    placeholder: '请输入门店名称/联系人/联系方式',
                },
                {
                    title: '月份',
                    type: 'month',
                    key: 'applyMonth',
                    startDate: new Date(2023, 0, 1),
                },
                {
                    title: '状态',
                    type: 'select',
                    key: 'status',
                    options: [
                        {
                            name: '全部',
                            id: '',
                        },
                        {
                            name: '门店未提交',
                            id: 1,
                        },
                        {
                            name: '门店已提交',
                            id: 2,
                        },
                        {
                            name: '业务主管已审核',
                            id: 3,
                        },
                        {
                            name: '审核完成',
                            id: 4,
                        },
                    ],
                },
            ],
            formList2024: [
                {
                    title: '门店',
                    type: 'input',
                    key: 'storeInfo',
                    placeholder: '请输入门店名称/联系人/联系方式',
                },
                {
                    title: '月份',
                    type: 'month',
                    key: 'applyMonth',
                },
                {
                    title: '状态',
                    type: 'select',
                    key: 'status',
                    options: [
                        {
                            name: '全部',
                            id: '',
                        },
                        {
                            name: '门店未提交',
                            id: 1,
                        },
                        {
                            name: '门店已提交',
                            id: 2,
                        },
                        {
                            name: '业务主管已审核',
                            id: 3,
                        },
                        {
                            name: '审核完成',
                            id: 4,
                        },
                    ],
                },
            ],
        };
    },
    computed: {
        requestType() {
            const arr = {
                2024: {
                    listCmd: storeInspectionList,
                    exportCmd: storeInspectionListExport,
                    listName: 'list',
                    form: 'table-form-2024',
                    year: '2024',
                },
                2023: {
                    listCmd: storeInspectionList,
                    exportCmd: storeInspectionListExport,
                    listName: 'list',
                    form: 'table-form-2023',
                    year: '2023',
                },
                2022: {
                    listCmd: storeInspectionList,
                    exportCmd: storeInspectionListExport,
                    listName: 'list',
                    form: 'table-form-2022',
                    year: '2022',
                },
                2021: {
                    listCmd: storeAssessmentList,
                    exportCmd: storeAssessmentListExport,
                    listName: 'list2021',
                    form: 'table-form-2021',
                    year: '2021',
                },
            };
            return arr[this.currentTab];
        },
    },
    activated() {
        let form = this.$refs[this.requestType.form].getFormData();
        this.getData(form);
    },
    mounted() {
        this.$bus.$on('getData', () => {
            this.getData();
        });
    },
    methods: {
        // 切换tab
        handleTabClick(val) {
            if (val === this.currentTab) {
                return false;
            }
            this.currentTab = val;
            this.onPageChange(1);
        },
        // 获取-列表
        getData(p) {
            this[this.requestType.listName].loading = true;
            p = clearEmpty(p);
            if (this.currentTab == 2021) {
                p.startDate = moment(p['applyMonth'] || '2021-12').format('YYYY-MM') + '-01 00:00:00';
                delete p['applyMonth'];
            } else if (this.currentTab == 2022) {
                if (p['applyMonth']) {
                    p.month = moment(p['applyMonth'] || new Date()).format('YYYYMM');
                } else {
                    p.month = '202212';
                }
                delete p['applyMonth'];
            } else if (this.currentTab == 2023) {
                if (p['applyMonth']) {
                    p.month = moment(p['applyMonth'] || new Date()).format('YYYYMM');
                } else {
                    p.month = '202312';
                }
                delete p['applyMonth'];
            } else {
                p.month = moment(p['applyMonth'] || new Date()).format('YYYYMM');
                delete p['applyMonth'];
            }
            let params = {
                current: this[this.requestType.listName].current,
                size: this[this.requestType.listName].size,
                ...p,
            };
            this.requestType
                .listCmd(params)
                .then((res) => {
                    this[this.requestType.listName].total = Number(res.total);
                    this[this.requestType.listName].data = res.records.map((item) => {
                        return {
                            ...item,
                        };
                    });
                    this.isChangePage = false;
                    this[this.requestType.listName].loading = false;
                })
                .catch(() => {
                    this.isChangePage = false;
                    this[this.requestType.listName].loading = false;
                });
        },
        // 导出
        exportData(searchParams) {
            let p = clearEmpty(searchParams);
            if (this.currentTab == 2021) {
                p.startDate = moment(p['month'] || '2021-12').format('YYYY-MM') + '-01 00:00:00';
                delete p['month'];
            } else {
                p.month = moment(p['applyMonth'] || new Date()).format('YYYYMM');
                delete p['applyMonth'];
            }
            let params = {
                ...p,
            };
            this.$Message.loading({
                content: '导出中...',
                duration: 0,
            });
            this.requestType.exportCmd(params).then(
                (res) => {
                    this.$Message.destroy();
                    this[this.requestType.listName].loading = false;
                    this.isExporting = false;
                    if (!res) {
                        this.$Message.warning('暂无数据');
                        return false;
                    }
                    let aLink = document.createElement('a');
                    aLink.href = res || '';
                    // console.log('href ===', res)
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    this.$Notice.success({
                        title: '导出成功',
                    });
                },
                () => {
                    this.$Message.destroy();
                },
            );
        },
        // 查询
        onSubmit(params) {
            this[this.requestType.listName].current = 1;
            this.getData(params);
        },
        // change-分页
        onPageChange(val) {
            if (this.isChangePage) return;
            this[this.requestType.listName].current = val;
            let form = this.$refs[this.requestType.form].getFormData();
            this.getData(form);
        },
        // change-页码
        onPageSizeChange(val) {
            this[this.requestType.listName].current = 1;
            this.isChangePage = true;
            this[this.requestType.listName].size = val;
            let form = this.$refs[this.requestType.form].getFormData();
            this.getData(form);
        },
        // 查看
        check(row, refName) {
            this.$refs[refName].open(row, 1);
        },
    },
};
</script>

<style lang="less">
.store-sign-list {
    .sign-status {
        word-break: break-word;
        white-space: pre-line;
        color: unset;
    }

    .processing {
        color: #2d8cf0;
    }

    .success {
        color: #19be6b;
    }

    .warning {
        color: #f90;
    }

    .error {
        color: #ed4014;
    }

    .ivu-avatar {
        cursor: pointer;
    }
}
</style>
