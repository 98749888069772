<!--
 * @Author: chengleilei
 * @Date: 2021-11-03 10:26:06
 * @Description: 提现结果
-->
<template>
    <div class="withdrawal-result">
        <div class="i-layout-page-header">
            <PageHeader title="余额提现" hidden-breadcrumb> </PageHeader>
        </div>
        <Card class="ivu-mt" :bordered="false" dis-hover>
            <div class="img-wrapper">
                <img src="@/assets/images/result-loading.png" />
            </div>
            <Result title="提现已提交，等待银行处理">
                <div slot="desc">请耐心等待银行处理，若信息有误导致提现失败，提现金额会自动退还至您的账户</div>
                <div slot="actions">
                    <Button type="primary" @click="goPage">查看提现记录</Button>
                </div>
            </Result>
        </Card>
    </div>
</template>

<script>
import { jumpPage, transPathToName } from '@/libs/util';

export default {
    data() {
        return {
            userId: this.$route.query.userId || '',
        };
    },

    created() {},

    methods: {
        goPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/assets/withdrawal/list', params: { userId: this.userId } });
        },
    },
};
</script>

<style lang="less" scoped>
.withdrawal-result {
    .ivu-mt {
        height: calc(~'100vh - 216px');
    }
    .img-wrapper {
        margin-top: 4%;
        text-align: center;
        img {
            width: 120px;
            height: 120px;
        }
    }
}
</style>
