<template>
    <div class="group-detail">
        <PageHeader title="集团单位发票/发货单统计详情" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage"><Icon type="md-arrow-back" /></div>
            <div slot="action">
                <Button type="primary" class="ivu-ml-8" @click="downLoad">下载附件</Button>
            </div>
        </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <Form labelWitth="100">
                <Row>
                    <Col span="16">
                        <FormItem label="集团客户名称:">
                            {{ detail.name || '-' }}
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="4">
                        <FormItem label="科室:">
                            {{ dept || '-' }}
                        </FormItem>
                    </Col>
                    <Col span="4">
                        <FormItem label="部门:">
                            {{ region || '-' }}
                        </FormItem>
                    </Col>
                    <Col span="5">
                        <FormItem label="经销商:">
                            {{ distributor || '-' }}
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="16">
                        <FormItem label="单位抬头:">
                            {{ detail.groupCustomerTitleName || '-' }}
                        </FormItem>
                    </Col>
                </Row>
                <FormItem label="发票:"> </FormItem>
                <div class="img-list">
                    <span
                        v-for="(item, index) in invoices"
                        class="img-item-bg"
                        :key="index"
                        @click="handlePreview(item)"
                        :style="{
                            display: 'inline-block',
                            width: '120px',
                            height: '120px',
                            backgroundImage: 'url(' + item + ')',
                        }"
                    >
                    </span>
                </div>

                <FormItem label="发货清单:"> </FormItem>
                <div class="img-list">
                    <span
                        v-for="(item, index) in deliveries"
                        class="img-item-bg"
                        :key="index"
                        @click="handlePreview(item)"
                        :style="{
                            display: 'inline-block',
                            width: '120px',
                            height: '120px',
                            backgroundImage: 'url(' + item + ')',
                        }"
                    >
                    </span>
                </div>
                <Row>
                    <Col span="6">
                        <FormItem label="轮胎数量：">
                            {{ detail.tyreQuantity || '0' }}
                        </FormItem>
                    </Col>
                    <Col span="6">
                        <FormItem label="发票金额：">
                            {{ detail.invoiceAmount || '0' }}
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        </Card>

        <FooterToolbar>
            <Button type="success" class="ml" @click="jumpPrevPage">返回</Button>
        </FooterToolbar>

        <Modal title="图片预览" v-model="visible" width="700px" footer-hide>
            <img :src="activeImg" style="width: 100%" />
        </Modal>
    </div>
</template>

<script>
import { titleUploadDetail } from '../../../api/group';
import { jumpPage, transPathToName } from '@/libs/util';

export default {
    name: 'group-detail',
    components: {},
    data() {
        return {
            deliveries: [],
            invoices: [],
            detail: {},
            dept: '',
            region: '',
            distributor: '',
            activeImg: '',
            visible: false,
        };
    },
    mounted() {
        this.getData();
    },
    beforeDestroy() {
        this.$bus.$off();
    },
    methods: {
        handlePreview(activeImg) {
            console.log(activeImg);
            this.activeImg = activeImg;
            this.visible = true;
        },
        getData() {
            let id = this.$route.query.id;
            if (!id) {
                this.$Message.warning('暂无id！');
                return false;
            }
            this.detail = JSON.parse(decodeURIComponent(this.$route.query.detail));
            titleUploadDetail(id).then((res) => {
                this.dept = res.dept || '-';
                this.region = res.region || '-';
                this.distributor = res.distributor || '-';
                this.invoices = res.invoices || [];
                this.deliveries = res.deliveries || [];
            });
        },
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/statistics/group/list' });
        },
        downLoad() {
            if (!this.detail.compressedPackageUrl) {
                this.$Message.warning('暂无附件！');
                return false;
            }
            let aLink = document.createElement('a');
            aLink.href = this.detail.compressedPackageUrl || '';
            document.body.appendChild(aLink);
            aLink.click();
            document.body.removeChild(aLink);
            this.$Message.destroy();
            this.$Message.success('导出成功');
        },
    },
};
</script>

<style lang="less" scoped>
.group-detail {
    .ivu-form-item {
        margin-bottom: 20px;
    }
    .ivu-avatar {
        cursor: pointer !important;
        margin-right: 18px;
        margin-bottom: 18px;
    }
    .img-list {
        margin-top: -20px;
        margin-bottom: 22px;
    }
    .img-item-bg {
        background-repeat: no-repeat;
        background-color: #eee;
        background-position: center;
        background-size: cover;
        margin-right: 18px;
        margin-bottom: 18px;
        cursor: pointer !important;
    }
    .ivu-form-item-content {
        text-decoration: underline;
    }
}
</style>
