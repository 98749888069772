/*
 * @Author: chengleilei
 * @Date: 2024-02-04 17:13:26
 * @Description: 导出列表-路由
 */
import ExportList from '@/pages/export/index';
import { applyRoute } from '../const';

const exportRoutes = [
    {
        path: `${applyRoute}/export/list`,
        meta: {
            auth: ['export'],
            title: '导出列表',
            cache: true,
        },
        component: ExportList,
    },
];

export { exportRoutes };
