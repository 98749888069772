<!-- eslint-disable vue/no-mutating-props -->
<!--
 * @Author: chengleilei
 * @Date: 2021-12-01 09:57:45
 * @Description: 密码验证弹窗组件
-->
<template>
    <div>
        <Modal v-model="modal" title="授权密码验证" :mask-closable="false" @on-cancel="onCancel">
            <Form ref="pwdVerifyForm" :model="pwdVerifyForm" :label-width="100" @submit.native.prevent>
                <FormItem label="授权密码：" prop="password">
                    <Input
                        style="width: 200px"
                        type="password"
                        v-model="pwdVerifyForm.password"
                        password
                        maxlength="6"
                        placeholder="请输入"
                    >
                    </Input>
                    <p class="tip">授权密码在“授信管理-授信设置”中维护</p>
                </FormItem>
            </Form>
            <div slot="footer">
                <Button type="text" @click="onCancel">取消</Button>
                <Button type="primary" @click="onOk" :disabled="pwdVerifyForm.password.length < 6" :loading="loading"
                    >确定</Button
                >
            </div>
        </Modal>
        <adjust-limit-modal v-bind="$attrs" v-on="$listeners" :modal.sync="showAdjustModal"></adjust-limit-modal>
        <make-collect-modal v-bind="$attrs" v-on="$listeners" :modal.sync="showCollectModal"></make-collect-modal>
        <update-credit-modal v-bind="$attrs" v-on="$listeners" :modal.sync="showCreditModal"></update-credit-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import adjustLimitModal from './adjustLimitModal';
import makeCollectModal from './makeCollectModal';
import updateCreditModal from './updateCreditModal';
import { authPwd } from '@/api/credit';
import { CODE_REG } from '@/libs/util';

export default {
    components: {
        adjustLimitModal,
        makeCollectModal,
        updateCreditModal,
    },
    props: {
        // 密码验证弹窗显隐
        modal: {
            type: Boolean,
            default: false,
        },
        // 1调整额度 2收款 3关闭开启授信
        modalType: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            pwdVerifyForm: {
                password: '', // 授权密码
            },
            loading: false,
            showAdjustModal: false, // 调整额度弹窗显隐
            showCollectModal: false, // 收款弹窗显隐
            showCreditModal: false, // 修改授信账户状态弹窗显隐
        };
    },
    computed: {
        ...mapState({
            creditPassword: (state) => state.admin.credit.creditPassword,
        }),
    },
    methods: {
        /**
         * @description 密码验证弹窗确定
         */
        onOk() {
            if (this.loading) return;
            if (!CODE_REG.test(this.pwdVerifyForm.password)) {
                this.$Modal.error({
                    title: '提示',
                    content: '请输入6位数字密码',
                });
                return;
            }
            this.loading = true;
            authPwd({ creditPassword: this.pwdVerifyForm.password })
                .then(() => {
                    this.$Message.success('验证成功');
                    this.$store.commit('admin/credit/savePwd', this.pwdVerifyForm.password);
                    this.onCancel();
                    const modal = ['', 'showAdjustModal', 'showCollectModal', 'showCreditModal'][this.modalType];
                    this[modal] = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
         * @description 密码验证弹窗取消
         */
        onCancel() {
            this.$refs['pwdVerifyForm'].resetFields();
            this.$emit('update:modal', false);
        },
    },
};
</script>

<style lang="less" scoped>
.ivu-form-item {
    margin-bottom: 0;
}
.tip {
    color: #666;
    font-size: 12px;
}
</style>
