<template>
    <div class="warehouse-entry-edit por" style="margin-bottom: 50px" v-loading="list.loading">
        <PageHeader title="入库管理订单详情" hidden-breadcrumb>
            <template #back>
                <div @click="back"><Icon type="md-arrow-back" /></div>
            </template>
        </PageHeader>
        <Card title="订单信息" :bordered="false" dis-hover class="ivu-mt">
            <Form :label-width="120" ref="form" :model="form">
                <Row class="ivu-pr-8">
                    <Col span="12">
                        <FormItem label="来源: ">
                            <p>ERP同步导入</p>
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem label="单号: ">
                            <p>{{ detail.sn }}</p>
                        </FormItem>
                    </Col>
                </Row>
                <Row class="ivu-pr-8">
                    <Col span="12">
                        <FormItem label="类型: ">
                            <span style="margin-right: 15px">{{ detail.type === 1 ? '直发订单' : '普通订单' }}</span>
                            <!-- 初始状态且是普通订单则显示变更类型-->
                            <!-- 审核中或直发订单则不显示变更类型；-->
                            <Button v-if="detail.type === 0 && detail.status === 0" type="warning" @click="changeType"
                                >变更类型</Button
                            >
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem label="报货地址: ">
                            <p>{{ detail.receiveAddress || '-' }}</p>
                        </FormItem>
                    </Col>
                </Row>
                <Row class="ivu-pr-8">
                    <Col span="12">
                        <FormItem label="门店名称: ">
                            <p>{{ detail.storeName || '-' }}</p>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        </Card>

        <Card title="商品信息" :bordered="false" dis-hover class="ivu-mt ivu-mb-16">
            <Row type="flex" justify="end" style="margin-bottom: 18px">
                <Col>
                    <Button type="primary" @click="handleExport" :loading="exportLoading">{{
                        exportLoading ? '导出中' : '导出胎号'
                    }}</Button>
                </Col>
            </Row>
            <VxeTable
                stripe
                highlight-hover-row
                border
                align="center"
                ref="xTable"
                :data="list.data"
                @cell-click="handleExpand"
                :expand-config="{ lazy: true, loadMethod: getBarcodesData }"
            >
                <VxeTableColumn field="productName" align="center" title="商品名称"></VxeTableColumn>
                <VxeTableColumn field="quantity" align="center" title="数量" width="150"></VxeTableColumn>
                <VxeTableColumn field="unit" align="center" title="单位" width="150"></VxeTableColumn>
                <VxeTableColumn type="expand" align="center" title="胎号" width="100">
                    <template v-slot:content="{ row }">
                        <div class="tire-sn-list">
                            <span class="tire-sn" v-for="(item, index) in row.tireSnList" :key="index">
                                {{ item }}
                            </span>
                        </div>
                    </template>
                </VxeTableColumn>
            </VxeTable>
            <Alert class="ivu-mt-16 ivu-p-8">
                <slot>
                    <div v-color="'#808695'">
                        <p>总数量：{{ sum }}</p>
                    </div>
                </slot>
            </Alert>
        </Card>

        <FooterToolbar>
            <Button @click="back">返回</Button>
        </FooterToolbar>

        <TypeEdit @getData="getData" ref="typeEdit" />
    </div>
</template>

<script>
import { WareHouseEntryEdit, getBarcodes, exportTyreCode } from '../../../api/warehouse';
import TypeEdit from '../components/TypeEdit';
import { transPathToName, jumpPage } from '@/libs/util';

export default {
    name: 'warehouse-entry-edit',
    components: { TypeEdit },
    data() {
        return {
            exportLoading: false,
            id: this.$route.query.id || null,
            stockOutSn: this.$route.query.stockOutSn || null,
            form: {
                name: '',
            },
            sum: 0,
            list: {
                data: [],
                loading: false,
            },
            detail: {},
        };
    },
    methods: {
        handleExport() {
            this.exportLoading = true;
            this.$Message.loading({
                content: '导出中...',
                duration: 0,
            });
            exportTyreCode({
                stockSn: this.stockOutSn,
            }).then(
                (res) => {
                    let aLink = document.createElement('a');
                    aLink.href = res || '';
                    // console.log('href ===', res)
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    this.exportLoading = false;
                    this.$Message.destroy();
                    this.$Message.success('导出成功~');
                },
                () => {
                    this.$Message.destroy();
                    this.exportLoading = false;
                },
            );
        },
        handleExpand(e) {
            // 确保只展开一行
            let { rowIndex, column } = e;
            let { title } = column;
            if (title !== '胎号') {
                return false;
            }
            this.$refs.xTable.clearRowExpand(); // 关闭全部展开行
            this.$refs.xTable.toggleRowExpansion(this.list.data[rowIndex]); // 设置当前行展开
        },
        getBarcodesData(row) {
            let rowData = this.list.data[row.rowIndex];
            this.$refs.xTable.clearRowExpand(); // 关闭全部展开行
            return new Promise((resolve, reject) => {
                getBarcodes({
                    materialId: rowData.materialId,
                    stockOutSn: this.stockOutSn,
                }).then(
                    (res) => {
                        this.$refs.xTable.clearRowExpand(); // 关闭全部展开行
                        if (!res || res.length <= 0) {
                            row.row.tireSnList = ['暂无胎号数据'];
                        } else {
                            row.row.tireSnList = res;
                        }
                        resolve();
                    },
                    (err) => {
                        row.row.tireSnList = ['暂无胎号数据'];
                        this.$refs.xTable.clearRowExpand(); // 关闭全部展开行
                        reject(err);
                    },
                );
            });
        },
        getData() {
            this.list.loading = true;
            WareHouseEntryEdit(this.id).then(
                (res) => {
                    this.list.loading = false;
                    this.list.data = (res.items || []).map((item) => {
                        this.sum += Number(item.quantity);
                        return {
                            ...item,
                            tireSnList: [],
                            productName: item.productName || '-',
                            unit: item.unit || '-',
                        };
                    });
                    this.list.loading = false;
                    this.detail = res;
                },
                () => {
                    this.list.loading = false;
                },
            );
        },
        back() {
            this.sum = 0;
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/warehouse/entry/list' });
        },
        changeType() {
            this.$refs['typeEdit'].open('EDIT', this.detail);
        },
    },
    mounted() {
        this.getData();
    },
};
</script>

<style lang="less" scoped>
.warehouse-entry-edit {
    .container-title {
        padding: 10px 20px;
        margin: 10px 0 20px;
        border-bottom: 1px solid #ddd;
        font-size: 18px;
    }
}
.tire-sn-list {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.tire-sn {
    margin: 5px 10px;
    color: #333333;
}
</style>
