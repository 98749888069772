<!--
 * @Author: chengleilei
 * @Date: 2021-10-06 15:42:28
 * @Description: 签约结果页
-->
<template>
    <div class="sign-contract-result" v-loading="pageLoading">
        <PageHeader title="签约" hidden-breadcrumb> </PageHeader>
        <Card class="ivu-mt" :bordered="false" dis-hover>
            <div class="img-wrapper">
                <img src="@/assets/images/result-success.png" />
            </div>
            <Result title="签约成功">
                <div slot="desc">
                    您已完成签约，账户已开通，<a>{{ countdown }}s</a>后即将为您跳转至账户管理列表页。
                </div>
                <div slot="actions">
                    <Button
                        type="primary"
                        @click="
                            goPage('/sub-vue2/assets/dealerAccount/detail', {
                                userType,
                                cpcnAccountId,
                            })
                        "
                        >查看账户详情</Button
                    >
                </div>
            </Result>
        </Card>
    </div>
</template>

<script>
import { jumpPage, transPathToName } from '@/libs/util';

export default {
    data() {
        return {
            pageLoading: false,
            userType: this.$route.query.userType || '', // 账户类型【 12=企业用户 13=个体工商用户】
            cpcnAccountId: this.$route.query.cpcnAccountId || '', // 中金账户id
            timer: null,
            countdown: 3, // 倒计时秒数
            codeMsg: '',
        };
    },

    created() {
        if (!this.timer) {
            this.timer = setInterval(() => {
                if (this.countdown > 0 && this.countdown <= 3) {
                    this.countdown--;
                    if (this.countdown !== 0) {
                        //
                    } else {
                        clearInterval(this.timer);
                        this.timer = null;
                        this.goPage(`/sub-vue2/assets/dealerAccount/list`);
                    }
                }
            }, 1000);
        }
    },

    destroyed() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    },

    methods: {
        /**
         * @description 跳转页面
         * @param {String} path 页面路由
         */
        goPage(path) {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path });
        },
    },
};
</script>

<style lang="less" scoped>
.sign-contract-result {
    .ivu-mt {
        height: calc(~'100vh - 216px');
    }
    .img-wrapper {
        margin-top: 4%;
        text-align: center;
        img {
            width: 120px;
            height: 120px;
        }
    }
}
</style>
