import request from "@/plugins/request";

/**
 * @Author: forguo
 * @Date: 2021/9/29 16:30
 * @Description: 账户管理
 */

// 查询经销商的中金账户列表
export function accountList (data) {
    return request({
        url: '/cpcn/accountList',
        method: 'get',
        params: data
    });
}

// 查看账户详情
export function accountDetail (data) {
    return request({
        url: '/cpcn/accountInfo',
        method: 'post',
        data
    });
}

// 查看账户详情【用于数据回填】
export function accountBindBack (data) {
    return request({
        url: '/cpcn/accountBindBack',
        method: 'post',
        data
    });
}

// 绑卡参数-银行卡列表
export function bankList (data) {
    return request({
        url: '/cpcn/cpcnBanks',
        method: 'get',
        params: data
    });
}

// 省份列表
export function provinceList (data) {
    return request({
        url: '/cpcn/provinces',
        method: 'get',
        params: data
    });
}

// 城市列表
export function cityList (data) {
    return request({
        url: '/cpcn/citys',
        method: 'get',
        params: data
    });
}

// 中金-城市列表-开户用
export function cpcnCityList (data) {
    return request({
        url: '/cpcn/cityList',
        method: 'get',
        params: data
    });
}

// 中金-区县列表-开户用
export function cpcnDistrictList(data) {
    return request({
        url: '/cpcn/districtList',
        method: 'get',
        params: data
    });
}

// 账户-提交影印件及银行卡
export function commitImageBank (data) {
    return request({
        url: '/cpcn/commitImageBank',
        method: 'post',
        data
    });
}

// 重新提交---提交影印件及银行卡
export function commitImageBankAgain (data) {
    return request({
        url: '/cpcn/recommitImage',
        method: 'post',
        data
    });
}

// 账户-开户
export function openAccount (data) {
    return request({
        url: '/cpcn/openAccount',
        method: 'post',
        data
    });
}

// 发起签约
export function startSignContract (data) {
    return request({
        url: '/cpcn/startSignContract',
        method: 'post',
        data
    });
}

// 获取签约链接
export function getSignContractUrl (userId, code) {
    return request({
        url: `/cpcn/getSignContractUrl/${userId}/${code}`,
        method: 'get',
    });
}

// 获取第一张绑卡信息
export function getFirstCardRecord (userId) {
    return request({
        url: `/cpcn/getFirstCardRecord/${userId}`,
        method: 'get',
    });
}

// 银行卡详情
export function getBankRecordById (cardId) {
    return request({
        url: `/cpcn/getBankRecordById/${cardId}`,
        method: 'get',
    });
}

// 添加银行卡
export function addBankCard (data) {
    return request({
        url: `/cpcn/addBankCard`,
        method: 'post',
        data
    });
}

// 获取签约信息
export function getSignContractRecord (userId) {
    return request({
        url: `/cpcn/getSignContractRecord/${userId}`,
        method: 'get',
    });
}

// 绑卡认证-提交认证
export function submitToBindingAuth (data) {
    return request({
        url: `/cpcn/submitToBindingAuth`,
        method: 'post',
        data
    });
}

// 绑卡认证-校验信息
export function checkBindingCardStatus (cardId) {
    return request({
        url: `/cpcn/checkBindingCardStatus/${cardId}`,
        method: 'get',
    });
}

// 绑卡认证-当前状态
export function getAuditStep (userId) {
    return request({
        url: `/cpcn/getAuditStep/${userId}`,
        method: 'get',
    });
}

// 重新提交影印件
export function recommitImage (data) {
    return request({
        url: `/cpcn/recommitImage`,
        method: 'get',
        data
    });
}
