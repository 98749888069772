<template>
    <Card :bordered="true" dis-hover class="ivu-mt">
        <Form :label-width="180">
            <Row class="ivu-pr8">
                <Col span="12">
                    <FormItem label="装修公司：">
                        {{ detail.decorationOrgName || '-' }}
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="联系人：">
                        {{ detail.contactName || '-' }}
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="联系手机：">
                        {{ detail.contactMobile || '-' }}
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="审核进度：">
                        {{ detail.auditBusinessStatus || '-' }}
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="验收状态：">
                        {{ detail.auditCheckStatus || '-' }}
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="合同状态：">
                        {{ detail.contractStatus || '-' }}
                    </FormItem>
                </Col>
                <Col span="24">
                    <FormItem label="装修前照片：">
                        <p v-if="detail.beforeDecorationUrls && detail.beforeDecorationUrls.length > 0">
                            <span
                                class="source-img img-preview"
                                :style="{ backgroundImage: 'url(' + item + ')' }"
                                :src="item"
                                v-for="item in detail.beforeDecorationUrls || []"
                                :key="item"
                            >
                                <img :src="item" preview="11" preview-text="装修前照片" />
                            </span>
                        </p>
                        <p v-else>--</p>
                    </FormItem>
                </Col>
                <Col span="24">
                    <FormItem label="装修中照片：">
                        <p v-if="detail.decorationingUrls && detail.decorationingUrls.length > 0">
                            <span
                                class="source-img img-preview"
                                :style="{ backgroundImage: 'url(' + item + ')' }"
                                :src="item"
                                v-for="item in detail.decorationingUrls || []"
                                :key="item"
                            >
                                <img :src="item" preview="12" preview-text="装修中照片" />
                            </span>
                        </p>
                        <p v-else>--</p>
                    </FormItem>
                </Col>
                <Col span="24">
                    <FormItem label="装修后照片：">
                        <p v-if="detail.afterDecorationUrls && detail.afterDecorationUrls.length > 0">
                            <span
                                class="source-img img-preview"
                                :style="{ backgroundImage: 'url(' + item + ')' }"
                                :src="item"
                                v-for="item in detail.afterDecorationUrls || []"
                                :key="item"
                            >
                                <img :src="item" preview="13" preview-text="装修后照片" />
                            </span>
                        </p>
                        <p v-else>--</p>
                    </FormItem>
                </Col>
                <Col span="24" v-if="detail.interiorDetails && detail.interiorDetails.length > 0">
                    <FormItem label="室内细节照：">
                        <p>
                            <span
                                class="source-img img-preview"
                                :style="{ backgroundImage: 'url(' + item + ')' }"
                                :src="item"
                                v-for="item in detail.interiorDetails || []"
                                :key="item"
                            >
                                <img :src="item" preview="14" preview-text="室内细节照" />
                            </span>
                        </p>
                    </FormItem>
                </Col>
                <Col span="24">
                    <FormItem
                        :label="wuliuItem.materialName"
                        v-for="wuliuItem in detail.decorationMaterialVOList"
                        :key="wuliuItem.id"
                    >
                        <div class="title">
                            <span>{{ ['未寄出', '已寄出'][wuliuItem.status] }}</span>
                        </div>
                        <div class="wuli-list">
                            <p>物流单号：{{ wuliuItem.shipmentNumber }}</p>
                            <p>物流公司：{{ wuliuItem.shipmentCompany }}</p>
                        </div>
                    </FormItem>
                </Col>
                <Col span="24" v-if="detail.auditLists && detail.auditLists.length > 0">
                    <FormItem label="备注：">
                        <div v-for="(item, index) in detail.auditLists" :key="index">
                            <h4>
                                <span style="margin-right: 12px; display: inline-block">
                                    {{ item.typeName || '' }}
                                </span>
                                <span>
                                    {{ item.auditorName || '' }}
                                </span>
                            </h4>
                            <p>
                                {{ item.remark || '-' }}
                            </p>
                        </div>
                    </FormItem>
                </Col>
            </Row>
        </Form>
    </Card>
</template>

<script>
export default {
    props: {
        detail: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            loading: true,
        };
    },
    mounted() {},
    methods: {
        getDetail(detail) {
            (detail.beforeDecorationUrls || detail.decorationingUrls || detail.afterDecorationUrls) &&
                this.$previewRefresh();
        },
    },
};
</script>

<style lang="less">
.ellipsis {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.source-img {
    display: inline-block;
    width: 70px;
    height: 70px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 15px;
    cursor: pointer;
    position: relative;
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
    }
}
</style>
