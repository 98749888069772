import CustomerManage from '@/pages/customer/manage/index';
import CustomerManageStoreDetail from '@/pages/customer/manage/storeDetail';
import CustomerManageOrderDetail from '@/pages/customer/manage/orderDetail';
import StoreSign from '@/pages/customer/storeSign/index';
import StoreSignCheck from '@/pages/customer/storeSign/check';
import { applyRoute } from '../const';

const customerRoutes = [
    {
        path: `${applyRoute}/customer/manage/list`,
        meta: {
            auth: ['member_manage'],
            title: '客户管理',
            cache: true,
        },
        component: CustomerManage,
    },
    {
        path: `${applyRoute}/customer/manage/storeDetail`,
        meta: {
            auth: ['member_manage'],
            title: '门店详情',
            cache: false,
        },
        component: CustomerManageStoreDetail,
    },
    {
        path: `${applyRoute}/customer/manage/orderDetail`,
        meta: {
            auth: ['member_manage'],
            title: '门店详情',
            cache: false,
        },
        component: CustomerManageOrderDetail,
    },
    {
        path: `${applyRoute}/customer/storeSign/list`,
        meta: {
            auth: ['member_manage'],
            title: '门店签署管理',
            cache: true,
        },
        component: StoreSign,
    },
    {
        path: `${applyRoute}/customer/storeSign/check`,
        meta: {
            auth: ['member_manage'],
            title: '门店签署详情',
            cache: false,
        },
        component: StoreSignCheck,
    },
];

export { customerRoutes };
