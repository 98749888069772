<template>
    <div class="directly-store-sign">
        <PageHeader title="直营门店签署管理" hidden-breadcrumb> </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :customLabelWidth="120" :formList="formList" @on-submit="onSubmit" ref="table-form" />
            <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list" />
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { renderButton, clearEmpty, jumpPage } from '@/libs/util';
import { directlySignList, directlySignUrl } from '@/api/esign';

export default {
    components: {
        tableForm,
        tablePage,
    },
    data() {
        return {
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    {
                        title: '门店名称',
                        key: 'storeName',
                        align: 'center',
                        minWidth: 120,
                    },
                    {
                        title: '门店编号',
                        key: 'storeSn',
                        align: 'center',
                        minWidth: 120,
                    },
                    {
                        title: '品牌',
                        key: 'majorBrandName',
                        align: 'center',
                        minWidth: 140,
                    },
                    {
                        title: '合同名称',
                        key: 'contractName',
                        align: 'center',
                        minWidth: 140,
                    },
                    {
                        title: '签署人',
                        key: 'storeSignerName',
                        align: 'center',
                        minWidth: 80,
                    },
                    {
                        title: '签署人联系方式',
                        key: 'storeSignerContact',
                        align: 'center',
                        minWidth: 135,
                    },
                    {
                        title: '签署人身份证号码',
                        key: 'storeSignerCard',
                        align: 'center',
                        minWidth: 180,
                    },
                    {
                        title: '签署状态',
                        key: 'businessStatusStr',
                        align: 'center',
                        minWidth: 110,
                        render: (h, p) => {
                            return h(
                                'div',
                                {
                                    class:
                                        {
                                            1000: 'error',
                                            1001: 'warning',
                                            1002: 'warning',
                                            1003: 'success',
                                            1004: 'error',
                                            1005: 'error',
                                            1006: 'error',
                                            1007: 'error',
                                            1008: 'error',
                                        }[p.row.businessStatus] + ' sign-status',
                                },
                                p.row.businessStatusStr,
                            );
                        },
                    },
                    {
                        title: '签署链接',
                        align: 'center',
                        minWidth: 150,
                        render: (h, p) => {
                            const btn = renderButton(h, { text: '复制链接', type: 'primary' }, () => {
                                this.copyLink(p.row.storeId);
                            });
                            return [[1001, 1002].includes(p.row.businessStatus) ? btn : '--'];
                        },
                    },
                    {
                        title: '操作',
                        key: 'oprate',
                        align: 'center',
                        width: 150,
                        fixed: 'right',
                        render: (h, p) => {
                            return [
                                renderButton(
                                    h,
                                    {
                                        text: '详情',
                                        type: 'primary',
                                        disabled: ![1000, 1003, 1005, 1006].includes(p.row.businessStatus),
                                    },
                                    () => {
                                        this.goDetails(p.row);
                                        //this.onBtnClick(`./Check?id=${p.row.id}`);
                                    },
                                ),
                            ];
                        },
                    },
                ],
                data: [],
            },
            formList: [
                {
                    title: '合同名称',
                    type: 'input',
                    key: 'contractName',
                },
                {
                    title: '门店名称',
                    type: 'input',
                    key: 'storeName',
                },
                {
                    title: '签署人',
                    type: 'input',
                    key: 'storeSignerName',
                },
                {
                    title: '签署状态',
                    type: 'select',
                    key: 'status',
                    options: [
                        {
                            name: '签署中',
                            id: 1,
                        },
                        {
                            name: '已签署',
                            id: 2,
                        },
                        {
                            name: '合同失效',
                            id: 3,
                        },
                        {
                            name: '签署失败',
                            id: 4,
                        },
                        {
                            name: '合同作废',
                            id: 5,
                        },
                    ],
                },
                {
                    title: '签署人联系方式',
                    type: 'input',
                    key: 'storeSignerContact',
                },
            ],
        };
    },

    activated() {
        let form = this.$refs['table-form'].getFormData();
        this.getData(form);
    },

    mounted() {},

    methods: {
        /**
         * @description 按钮单击跳转
         * @param {String} url 跳转页面
         */
        goDetails(item) {
            jumpPage({ path: '/sub-vue2/decoration/directlyStoreSign/check', params: { id: item.id } });
        },

        getData(p) {
            this.list.loading = true;
            if (p['dept']) {
                p.deptId = p.dept[0];
                p.regionId = p.dept[1];
                delete p['dept'];
            }
            p = clearEmpty(p, true);
            let params = {
                current: this.list.current,
                size: this.list.size,
                ...p,
            };
            directlySignList(params)
                .then((res) => {
                    this.list.total = Number(res.total);
                    this.list.data = res.records.map((item) => {
                        return {
                            ...item,
                        };
                    });
                    this.isChangePage = false;
                    this.list.loading = false;
                })
                .catch(() => {
                    this.isChangePage = false;
                    this.list.loading = false;
                });
        },

        /**
         * @description 查询
         */
        onSubmit(params) {
            this.list.current = 1;
            this.getData(params);
        },

        /**
         * @description 页码改变
         * @param {Number} val 页码
         */
        onPageChange(val) {
            if (this.isChangePage) return;
            this.list.current = val;
            let form = this.$refs['table-form'].getFormData();
            this.getData(form);
        },

        /**
         * @description x条/页改变
         * @param {Number} val 条数
         */
        onPageSizeChange(val) {
            this.list.current = 1;
            this.isChangePage = true;
            this.list.size = val;
            let form = this.$refs['table-form'].getFormData();
            this.getData(form);
        },

        /**
         * @description 复制链接
         * @param {String} storeId 门店id
         */
        copyLink(storeId) {
            this.$Message.loading({
                content: '链接查找中...',
                duration: 0,
            });
            directlySignUrl(storeId).then((res) => {
                this.$Message.destroy();
                this.$Copy({
                    text: res || '',
                    success: () => {},
                    error: () => {
                        this.$Message.error({
                            title: '链接复制失败，请手动复制！',
                        });
                    },
                });
            });
        },
    },
};
</script>
<style lang="less">
.directly-store-sign {
    .sign-status {
        word-break: break-word;
        white-space: pre-line;
        color: unset;
    }
    .processing {
        color: #2d8cf0;
    }
    .success {
        color: #19be6b;
    }
    .warning {
        color: #f90;
    }
    .error {
        color: #ed4014;
    }
}
</style>
