<template>
    <div>
        <PageHeader title="装修审核" hidden-breadcrumb>
            <Button
                :disabled="exporting"
                :loading="exporting"
                type="info"
                icon="md-add"
                @click="exportData"
                slot="action"
                >导出</Button
            >
        </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" @on-submit="onSubmit" ref="table-form"></table-form>
            <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list" />
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { renderButton, clearEmpty, jumpPage } from '@/libs/util';
import { listAuditPage, exportDecorationApplyAuditExcel } from '@/api/decorate';
export default {
    components: {
        tableForm,
        tablePage,
    },
    data() {
        return {
            row: null,
            remarking: false,
            exporting: false,
            isChangePage: false,
            formList: [
                {
                    title: '申请类型',
                    key: 'storeTypeDetail',
                    type: 'cascader',
                    options: [
                        {
                            value: '0',
                            label: '挂牌店',
                            children: [
                                {
                                    value: '10',
                                    label: '布门头',
                                },
                                {
                                    value: '11',
                                    label: '彩钢',
                                },
                                {
                                    value: '12',
                                    label: '铝塑板+PVC ',
                                },
                                {
                                    value: '13',
                                    label: '铝塑板+发光字 ',
                                },
                            ],
                        },
                        {
                            value: '1',
                            label: '快捷服务店   ',
                            children: [
                                {
                                    value: '20',
                                    label: '标准店',
                                },
                                {
                                    value: '21',
                                    label: '高阶店',
                                },
                                {
                                    value: '22',
                                    label: '集装箱店',
                                },
                            ],
                        },
                        {
                            value: '2',
                            label: '车空间',
                            children: [
                                {
                                    value: '30',
                                    label: '经销商车空间',
                                },
                                {
                                    value: '31',
                                    label: '零售商车空间',
                                },
                                {
                                    value: '32',
                                    label: '集装箱店',
                                },
                            ],
                        },
                    ],
                },
                {
                    title: '装修公司',
                    key: 'orgName',
                    type: 'input',
                    placeholder: '装修公司名称',
                },
                {
                    title: '装修审核状态',
                    key: 'auditBusinessStatus',
                    type: 'select',
                    options: [
                        // {
                        //     id: '0',
                        //     name: '待报价'
                        // },
                        // {
                        //     id: '1',
                        //     name: '待处理'
                        // },
                        {
                            id: '2',
                            name: '待审核',
                        },
                        {
                            id: '3',
                            name: '审核通过',
                        },
                        {
                            id: '4',
                            name: '已驳回',
                        },
                    ],
                },
                {
                    title: '验收审核状态',
                    key: 'auditCheckStatus',
                    type: 'select',
                    options: [
                        {
                            id: '2',
                            name: '待审核',
                        },
                        {
                            id: '3',
                            name: '审核通过',
                        },
                        {
                            id: '4',
                            name: '已驳回',
                        },
                        {
                            id: '5',
                            name: '整改中',
                        },
                    ],
                },
                {
                    title: '门店信息',
                    key: 'storeStr',
                    type: 'input',
                    placeholder: '请输入门店名称/联系人/联系方式',
                },
                {
                    title: '关闭数据',
                    key: 'isClosed',
                    type: 'select',
                    options: [
                        {
                            id: 1,
                            name: '已关闭',
                        },
                        {
                            id: 0,
                            name: '未关闭',
                        },
                    ],
                },
            ],
            searchParams: {
                deptName: '',
                name: '',
                phone: '',
            },
            list: {
                current: 1,
                total: 0,
                size: window.$pageSize,
                columns: [
                    {
                        title: '门店名称',
                        key: 'storeName',
                        align: 'center',
                        minWidth: 180,
                        fixed: 'left',
                    },
                    {
                        title: '联系人',
                        key: 'ownerName',
                        align: 'center',
                        minWidth: 180,
                    },
                    {
                        title: '电话',
                        key: 'ownerPhone',
                        align: 'center',
                        minWidth: 180,
                    },
                    {
                        title: '申请类型',
                        key: 'storeTypeDetailStr',
                        align: 'center',
                        minWidth: 180,
                    },
                    {
                        title: '申请时间',
                        key: 'applyDate',
                        align: 'center',
                        minWidth: 180,
                    },
                    {
                        title: '门头编号',
                        key: 'storeSn',
                        align: 'center',
                        minWidth: 180,
                        render: (h, p) => {
                            return [p.row.storeSn || '-'];
                        },
                    },
                    {
                        title: '装修公司',
                        key: 'orgName',
                        align: 'center',
                        minWidth: 180,
                    },
                    {
                        title: '审核状态',
                        key: 'auditStatus',
                        align: 'center',
                        minWidth: 180,
                        render: (h, p) => {
                            return [p.row.auditStatus || '-'];
                        },
                    },
                    {
                        title: '合同状态',
                        key: 'contractStatus',
                        align: 'center',
                        minWidth: 180,
                        render: (h, p) => {
                            return [
                                h(
                                    'p',
                                    {
                                        style: {
                                            margin: '6px 0',
                                        },
                                    },
                                    p.row.contractStatus || '-',
                                ),
                            ];
                        },
                    },
                    {
                        title: '装修验收',
                        key: 'checkAcceptStatus',
                        align: 'center',
                        minWidth: 180,
                        render: (h, p) => {
                            return [p.row.checkAcceptStatus || '-'];
                        },
                    },
                    {
                        title: '是否关闭',
                        key: 'isClosed',
                        minWidth: 100,
                        align: 'center',
                        render: (h, p) => {
                            return [p.row.isClosed ? '已关闭' : '未关闭'];
                        },
                    },
                    {
                        title: '备注',
                        key: 'invoiceAmount',
                        align: 'center',
                        minWidth: 180,
                        render: (h, p) => {
                            return [p.row.submitRemark || '-'];
                        },
                    },
                    {
                        title: '操作',
                        key: 'oprate',
                        align: 'center',
                        width: 270,
                        fixed: 'right',
                        render: (h, p) => {
                            return [
                                renderButton(h, { text: '查看', type: 'success' }, () => {
                                    this.check(p.row);
                                }),
                            ];
                        },
                    },
                ],
                data: [],
                loading: false,
            },
        };
    },
    mounted() {},
    activated() {
        this.getData();
    },
    methods: {
        getData() {
            this.list.loading = true;
            let p = clearEmpty(this.searchParams);
            let params = {
                current: this.list.current,
                size: this.list.size,
                ...p,
            };
            listAuditPage(params).then(
                (data) => {
                    this.list.data = (data.records || []).map((item) => {
                        return {
                            ...item,
                            distributorNames: (item.distributorNames || []).join(','),
                        };
                    });
                    this.list.total = data.total;
                    this.isChangePage = false;
                    this.list.loading = false;
                },
                () => {
                    this.list.loading = false;
                },
            );
        },
        onPageChange(val) {
            if (this.isChangePage) return;
            this.list.current = val;
            this.getData();
        },
        onPageSizeChange(val) {
            this.list.current = 1;
            this.isChangePage = true;
            this.list.size = val;
            this.getData();
        },
        check(row) {
            jumpPage({ path: '/sub-vue2/decoration/decorateAudit/detail', params: { id: row.id } });
        },
        onSubmit(params) {
            this.list.current = 1;
            this.searchParams = {
                ...this.searchParams,
                ...params,
            };
            this.getData();
        },
        onCancel() {
            this.open = false;
            this.ruleForm = {
                remark: '',
            };
        },
        exportData() {
            let p = this.$refs['table-form'].getFormData();
            this.exporting = true;
            this.$Message.loading({
                content: '导出中...',
                duration: 0,
            });
            exportDecorationApplyAuditExcel(p).then(
                () => {
                    this.$Message.destroy();
                    this.$Modal.confirm({
                        title: '提示',
                        content: '您好！“装修审核列表”正在导出，请稍后在“导出列表”模块中查看，谢谢！',
                        onOk: () => {
                            console.log('export');
                        },
                    });
                    this.exporting = false;
                },
                () => {
                    this.$Message.destroy();
                    this.exporting = false;
                },
            );
        },
    },
};
</script>
