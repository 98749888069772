import request from '@/plugins/request';

/**
 *  集团客户
 */

// 集团客户列表
export function listGroupCustomer(data) {
    return request({
        url: `/bloc/customer/list`,
        method: 'post',
        data,
    });
}

// 集团客户列表导出
export function exportGroupCustomer(data) {
    return request({
        url: `/bloc/customer/export`,
        method: 'post',
        data,
    });
}

// 集团客户基本信息
export function detailGroupBaseInfo(data) {
    return request({
        url: `/bloc/customer/baseInfo`,
        method: 'get',
        params: data,
    });
}

// 集团客户维护信息
export function detailGroupMaintenance(data) {
    return request({
        url: `/bloc/customer/maintenance/`,
        method: 'get',
        params: data,
    });
}

// 集团客户详情
export function detailGroupCustomer(data) {
    return request({
        url: `/bloc/customer/listInvoices`,
        method: 'post',
        params: data,
    });
}

// 集团客户详情
export function detailGroupCustomerFirst(data) {
    return request({
        url: `/bloc/customer/uploadRocordFirst`,
        method: 'get',
        params: data,
    });
}

// 集团客户更新
export function updateGroupCustomer(data) {
    return request({
        url: `/groupCustomer/groupCustomerDetail`,
        method: 'put',
        data,
    });
}

// 集团客户数据字典
export function dictionaryList(data) {
    return request({
        url: `/dictionary/list`,
        method: 'get',
        params: data,
    });
}

// 查询列表 发货清单列表
export function titleUploadList(data) {
    return request({
        url: `/titleUpload/list`,
        method: 'get',
        params: data,
    });
}

// 查询列表 发货清单列表
export function titleUploadListExport(data) {
    return request({
        url: `/titleUpload/exportTitleUpload`,
        method: 'post',
        data,
    });
}

// 查询列表 发货清单详情
export function titleUploadDetail(id) {
    return request({
        url: `/titleUpload/invoiceAndDelivery/${id}`,
        method: 'get',
    });
}

/**
 * **************
 * @Description: 集团客户招标接口 S
 * **************
 */

// 集团客户招标列表
export function listCustomerBidding(data) {
    return request({
        url: `/bloc/customer/bidding/list`,
        method: 'post',
        data,
    });
}

// 集团客户招标详情
export function detailCustomerBidding(id) {
    return request({
        url: `/bloc/customer/bidding/detail/${id}`,
        method: 'get',
    });
}

/**
 * **************
 * @Description: 集团客户招标接口 E
 * **************
 */
