<template>
    <div>
        <Button type="primary" icon="md-add" class="ivu-mt-16" slot="action" :disabled="canAddCard" @click="addBankCard"
            >添加银行卡</Button
        >
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-page :has-page="false" :list="listData" />
        </Card>
    </div>
</template>

<script>
import { renderButton, jumpPage } from '@/libs/util';
import tablePage from '@/components/tablePage';

export default {
    components: { tablePage },
    data() {
        return {
            userType: this.$route.query.userType || '', // 影印件账户类型【11=个人账户 12=企业账户 13 = 个体工商户】
            title: '账户管理',
            currentStep: 0,
            // 0=待绑卡认证、1=待开户、2=开户处理中、3=待签约、4=签约待完成、5=正常使用中 6=开户失败
            accountStatusTxt: {
                0: '待绑卡认证',
                1: '待开户',
                2: '开户处理中',
                3: '待签约',
                4: '签约待完成',
                5: '正常使用中',
                6: '开户失败',
            },
            accountStatusColor: {
                0: 'yellow', // '待绑卡认证'
                1: 'yellow', // '待开户',
                2: 'blue', // '开户处理中'
                3: 'blue', // '待签约',
                4: 'blue', // '签约待完成'
                5: 'green', // '正常使用中'
                6: 'red', // '开户失败',
            },
            accountStatusBtn: {
                '': '',
                0: '绑卡认证', // '待绑卡认证'
                1: '前往开户', // '待开户',
                2: '', // '开户处理中'
                3: '前往签约', // '待签约',
                4: '继续签约', // '签约待完成'
                5: '', // '正常使用中'
                6: '开户失败', // '开户失败',
            },
            // 影印件审核状态: 10=审核中 20=成功 30=失败
            imageStatusTxt: {
                10: '',
                20: '已提交',
                30: '',
            },
            // 银行卡绑定状态: 10=审核中 20=成功 30=失败
            bindStatusTxt: {
                10: '',
                20: '已通过',
                30: '',
            },
            // 开户信息：0=待绑卡认证、1=待开户、2=开户处理中、3=待签约、4=签约待完成、5=正常使用中 6=开户失败
            openAccountStatusTxt: {
                0: '待绑卡认证',
                1: '待开户',
                2: '处理中',
                3: '已通过',
                4: '已通过',
                5: '已通过',
                6: '未通过',
            },
            openAccountStatusColor: {
                0: 'yellow', // '待绑卡认证'
                1: 'yellow', // '待开户',
                2: 'blue', // '开户处理中'
                3: 'green', // '待签约',
                4: 'green', // '签约待完成'
                5: 'green', // '正常使用中'
                6: 'red', // '开户失败',
            },
            list: {
                page: 1,
                size: window.$pageSize,
                total: 0,
                columns: [
                    {
                        title: '银行账号',
                        key: 'bankCardNumber',
                        align: 'center',
                        fixed: 'left',
                        minWidth: 160,
                    },
                    {
                        title: '开户银行',
                        key: 'bankName',
                        align: 'center',
                        minWidth: 160,
                    },
                    {
                        title: '开户支行',
                        key: 'bankDetailName',
                        align: 'center',
                        fixed: 'left',
                        minWidth: 160,
                    },
                    {
                        title: '开户省份',
                        key: 'bankProvince',
                        align: 'center',
                        minWidth: 160,
                        render: (h, p) => {
                            return h('div', this.$route.query.userType == 12 ? p.row.bankProvince : '-');
                        },
                    },
                    {
                        title: '开户城市',
                        key: 'bankCity',
                        align: 'center',
                        minWidth: 160,
                        render: (h, p) => {
                            return h('div', this.$route.query.userType == 12 ? p.row.bankCity : '-');
                        },
                    },
                    {
                        title: '状态',
                        key: 'cardStatus',
                        align: 'center',
                        width: 190,
                        render: (h, p) => {
                            // 状态 1=已绑卡 0=待绑卡认证
                            let badge = h('Badge', {
                                props: {
                                    color: {
                                        '': 'yellow',
                                        0: 'blue',
                                        1: 'green',
                                    }[p.row.cardStatus],
                                    text: {
                                        '': '-',
                                        0: '待绑卡认证',
                                        1: '已绑卡',
                                    }[p.row.cardStatus],
                                },
                            });
                            return h('div', [badge]);
                        },
                    },
                    {
                        title: '操作',
                        align: 'center',
                        width: 140,
                        fixed: 'right',
                        render: (h, params) => {
                            const btns = [];
                            // 状态 1=已绑卡 0=待绑卡认证
                            params.row.cardStatus === 0
                                ? btns.push(
                                      renderButton(h, { text: '绑卡认证', type: 'primary', size: 'small' }, () => {
                                          this.onBindCard(params.row);
                                      }),
                                  )
                                : btns.push('-');
                            return btns;
                        },
                    },
                ],
                data: [],
                loading: false,
            },
        };
    },
    props: {
        cardList: {
            type: Array,
        },
        detail: {
            type: Object,
        },
    },
    computed: {
        listData: function () {
            const list = this.list;
            list.data = this.cardList;
            return {
                ...list,
            };
        },
        canAddCard() {
            /**
             * 同时仅能存在一张“待绑卡认证”的银行卡，此时，无法添加新银行卡；
             * 点击按钮展示中金提供的错误提示
             * 所有银行卡都为“已绑卡”时，可添加银行卡
             * */
            const cardStatus = this.cardList.filter((item) => {
                // 状态 1=已绑卡 0=待绑卡认证
                return item.cardStatus == 1;
            });
            // 0=待绑卡认证、1=待开户、2=开户处理中、3=待签约、4=签约待完成、5=正常使用中 6=开户失败
            return cardStatus.length !== this.cardList.length || this.detail.accountStatus == 0;
        },
    },
    methods: {
        // 添加银行卡
        addBankCard() {
            jumpPage({
                path: '/sub-vue2/assets/dealerAccount/addBankCard',
                params: {
                    userType: this.$route.query.userType || '',
                    cpcnAccountId: this.$route.query.cpcnAccountId || '',
                    userId: this.detail.userId,
                },
            });
        },
        // 绑卡认证
        onBindCard(row) {
            jumpPage({
                path: '/sub-vue2/assets/dealerAccount/tiedCardApprove',
                params: {
                    userType: this.$route.query.userType || '',
                    cpcnAccountId: this.$route.query.cpcnAccountId || '',
                    cardId: row.cardId || '',
                    userId: this.detail.userId,
                    type: this.list.data.length === 1 ? '' : 'bank',
                },
            });
        },
    },
};
</script>
<style lang="less">
.dealer-account-detail {
    .dealer-account-img {
        width: 200px;
        height: 160px;
    }
}
</style>
