<template>
    <div class="store-edit">
        <Modal
            v-model="showModal"
            :title="type === 'ADD' ? '添加门店' : '变更门店'"
            width="600px"
            height="500px"
            ok-text="保存"
            footer-hide
            @on-ok="handleSaveStore"
            @on-cancel="handleCancel"
        >
            <Card :bordered="false" dis-hover class="ivu-mt" style="min-height: 450px">
                <Form :label-width="60" ref="form">
                    <Row :gutter="24">
                        <Col>
                            <FormItem label="门店：" prop="storeId">
                                {{ storeName }}
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
                <p class="store-tips">请点击选择门店~</p>

                <Input
                    style="margin: 10px auto"
                    @input="handleGetStore"
                    v-model="searchVal"
                    placeholder="请输入门店名称/联系人/联系方式进行搜索"
                    clearable
                    :maxlength="16"
                />

                <div class="store-list">
                    <Spin v-if="loading" fix></Spin>
                    <div
                        class="store-item"
                        v-for="(item, index) in storeList"
                        :key="index"
                        @click="checkStore(item, index)"
                        :class="{ active: item.checked }"
                    >
                        <p>门店名称：{{ item.storeName }}</p>
                        <p>联系人：{{ item.ownerName }}</p>
                        <p>联系方式：{{ item.ownerPhone }}</p>
                    </div>
                    <div class="no-data" v-if="!storeList || storeList.length <= 0">没有门店信息，请重新搜索~</div>
                </div>

                <div style="text-align: end; margin-top: 30px">
                    <Button @click="handleCancel" style="margin-right: 15px">取消</Button>
                    <Button type="primary" @click="handleSaveStore">保存</Button>
                </div>
            </Card>
        </Modal>
    </div>
</template>

<script>
import { getStoreList1, scanAddStore } from '../../../../api/scan';
import { debounce } from 'lodash';
let storeList = [];
export default {
    data() {
        return {
            loading: false,
            type: 'ADD',
            showModal: false,
            storeList: [],
            storeId: '',
            storeName: '请选择',
            searchVal: '',
        };
    },
    created() {
        getStoreList1().then(
            (res) => {
                this.loading = false;
                let list =
                    res.records.map((item) => ({
                        storeId: item.id,
                        storeName: item.storeName || '-',
                        ownerName: item.ownerName || '-',
                        ownerPhone: item.ownerPhone || '-',
                        checked: false,
                    })) || [];
                this.storeList = list;
                storeList = JSON.parse(JSON.stringify(list));
            },
            () => {
                this.loading = true;
                this.storeList = [];
                storeList = [];
            },
        );
    },
    methods: {
        checkStore(item, index) {
            this.storeId = item.storeId;
            this.storeName = item.storeName;
            let { storeList } = this;
            storeList.map((item, itemIndex) => {
                item.checked = index === itemIndex;
            });
            this.storeList = storeList;
        },
        open(type, id) {
            this.type = type;
            this.orderId = id;
            this.storeName = '请选择';
            this.searchVal = '';
            this.storeList = storeList.map((item) => {
                return {
                    ...item,
                    checked: false,
                };
            });
            this.showModal = true;
        },
        handleSaveStore() {
            if (!this.storeId) {
                this.$Message.warning({
                    content: '请选择门店',
                });
                return false;
            }
            this.$Message.loading({
                content: '保存中...',
                duration: 0,
            });
            this.loading = true;
            scanAddStore(this.orderId, this.storeId).then(
                () => {
                    this.loading = false;
                    this.$Message.destroy();
                    this.$Message.success(this.type === 'ADD' ? '添加成功！' : '变更成功！');
                    this.showModal = false;
                    this.$emit('getData');
                },
                () => {
                    this.$Message.destroy();
                    this.loading = false;
                },
            );
        },
        handleCancel() {
            this.showModal = false;
            this.storeId = '';
        },
        handleGetStore: debounce(function (keyword) {
            this.storeId = '';
            this.loading = true;
            getStoreList1({
                keyword,
            }).then(
                (res) => {
                    this.loading = false;
                    this.storeList =
                        res.records.map((item) => ({
                            storeId: item.id,
                            storeName: item.storeName || '-',
                            ownerName: item.ownerName || '-',
                            ownerPhone: item.ownerPhone || '-',
                            checked: false,
                        })) || [];
                },
                () => {
                    this.loading = true;
                    this.storeList = [];
                },
            );
        }, 300),
    },
};
</script>

<style scoped lang="less">
.store-tips {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
}
.store-list {
    position: relative;
    border: 1px solid #e6e6e6;
    width: 100%;
    height: 400px;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 5px;
    .store-item {
        padding: 15px 20px;
        line-height: 35px;
        border-bottom: 1px solid #e8e8e8;
        transition: ease all 300ms;
        &:hover {
            cursor: pointer;
        }
        &.active {
            background: #eee;
            color: #2d8cf0;
        }
    }
    .no-data {
        width: 100%;
        height: 80px;
        line-height: 80px;
        text-align: center;
    }
}
</style>
