<!--
 * @Author: chengleilei
 * @Date: 2021-12-29 15:10:04
 * @Description: 2022销售任务量详情
-->
<template>
    <div class="pd" v-loading="loading">
        <PageHeader title="签署门店销售任务量详情" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage">
                <Icon type="md-arrow-back" />
            </div>
        </PageHeader>
        <Card dis-hover :bordered="false" class="ivu-mt-16">
            <Form :label-width="155" label-position="right">
                <Card title="门店签署信息" :bordered="false" dis-hover class="ivu-mt">
                    <Row :gutter="24">
                        <Col :xl="8" :lg="8" :md="8" :sm="8" :xs="8">
                            <p class="ivu-mt-16">门店名称：{{ signInfo.storeName || '--' }}</p>
                        </Col>
                        <Col :xl="8" :lg="8" :md="8" :sm="8" :xs="8" v-if="signInfo.zhilunStoreId">
                            <p class="ivu-mt-16">绑定知轮门店ID：{{ signInfo.zhilunStoreId }}</p>
                        </Col>
                        <Col :xl="8" :lg="8" :md="8" :sm="8" :xs="8" v-if="signInfo.bindingTime">
                            <p class="ivu-mt-16">绑定时间：{{ signInfo.bindingTime }}</p>
                        </Col>
                    </Row>

                    <Row :gutter="24" v-for="(item, index) in infos" :key="index">
                        <Divider plain></Divider>
                        <Col :xl="12" :lg="12" :md="12" :sm="12" :xs="12">
                            <p class="ivu-mt-16">奖励档：{{ item.reward || '--' }}</p>
                            <p class="ivu-mt-16">合同有效期：{{ item.dateTime || '--' }}</p>
                        </Col>
                        <Col
                            :xl="12"
                            :lg="12"
                            :md="12"
                            :sm="12"
                            :xs="12"
                            class="ivu-row-flex ivu-row-flex-middle"
                            style="display: flex; height: 74px"
                        >
                            <a
                                href="javascript:;"
                                class="file-link"
                                :title="item.fileName || '暂无合同'"
                                @click="checkPdf(item)"
                            >
                                <Icon type="md-document" size="52" style="color: red" />
                                <span class="file-link-url">{{ item.fileName || '--' }}</span>
                                <span class="file-link-url ivu-ml-16" v-if="item.fileUrl">{{
                                    item.fileUrl ? '点击查看' : '--'
                                }}</span>
                            </a>
                        </Col>
                    </Row>
                </Card>
                <Card
                    :title="`年度任务完成量 ${detail.totalFinishNum || 0}/${
                        detail.totalTaskNum || 0
                    } ${zcAndZhiLunFinish(detail.zcTotalFinishNum, detail.zhilunTotalFinishNum)}`"
                    :bordered="false"
                    dis-hover
                    class="ivu-mt"
                >
                    <div class="flex-row">
                        <Progress
                            :percent="
                                Number(
                                    ((detail.totalFinishNum || 0) / (detail.totalTaskNum || 1)) * 100 > 100
                                        ? 100
                                        : (((detail.totalFinishNum || 0) / (detail.totalTaskNum || 1)) * 100).toFixed(
                                              2,
                                          ),
                                )
                            "
                            :stroke-width="15"
                            status="active"
                        />
                        <span>{{ detail.totalFinishNum || 0 }}</span>
                        <i>/{{ detail.totalTaskNum || 0 }}条</i>
                        <span v-if="signInfo.zhilunStoreId">
                            {{ zcAndZhiLunFinish(detail.zcTotalFinishNum, detail.zhilunTotalFinishNum) }}
                        </span>
                    </div>
                </Card>
                <Card
                    v-if="routeYear > 2023"
                    :title="`本季（第${detail.currentQuarter.quarterNum}季度）任务完成 ${
                        detail.currentQuarter.totalFinish || 0
                    }/${detail.currentQuarter.totalTask || 0} ${zcAndZhiLunFinish(
                        detail.currentQuarter.zcTotalFinish,
                        detail.currentQuarter.zhilunTotalFinish,
                    )}`"
                    :bordered="false"
                    dis-hover
                    class="ivu-mt"
                >
                    <div class="flex-row" v-show="detail.currentQuarter.totalTask">
                        <span class="left-title">季度</span>
                        <Progress
                            :percent="
                                Number(
                                    ((detail.currentQuarter.totalFinish || 0) /
                                        (detail.currentQuarter.totalTask || 1)) *
                                        100 >
                                        100
                                        ? 100
                                        : (
                                              ((detail.currentQuarter.totalFinish || 0) /
                                                  (detail.currentQuarter.totalTask || 1)) *
                                              100
                                          ).toFixed(2),
                                )
                            "
                            :stroke-width="15"
                            status="active"
                        />
                        <span>{{ detail.currentQuarter.totalFinish || 0 }}</span>
                        <i>/{{ detail.currentQuarter.totalTask || 0 }}条</i>
                        <span v-if="signInfo.zhilunStoreId">
                            {{
                                zcAndZhiLunFinish(
                                    detail.currentQuarter.zcTotalFinish,
                                    detail.currentQuarter.zhilunTotalFinish,
                                )
                            }}
                        </span>
                    </div>
                    <div class="flex-row ivu-mt" v-show="detail.currentQuarter.thirdMonth">
                        <span
                            class="left-title"
                            v-if="detail.currentQuarter.thirdMonth == detail.currentMonth.taskMonth"
                        >
                            本月({{ detail.currentQuarter.thirdMonth }}月)
                        </span>
                        <span class="left-title" v-else>{{ detail.currentQuarter.thirdMonth }}月</span>
                        <Progress
                            :percent="
                                Number(
                                    ((detail.currentQuarter.thirdFinish || 0) /
                                        (detail.currentQuarter.thirdTask || 1)) *
                                        100 >
                                        100
                                        ? 100
                                        : (
                                              ((detail.currentQuarter.thirdFinish || 0) /
                                                  (detail.currentQuarter.thirdTask || 1)) *
                                              100
                                          ).toFixed(2),
                                )
                            "
                            :stroke-width="15"
                            status="active"
                        />
                        <span>{{ detail.currentQuarter.thirdFinish || 0 }}</span>
                        <i>/{{ detail.currentQuarter.thirdTask || 0 }}条</i>
                        <span v-if="signInfo.zhilunStoreId">
                            {{
                                zcAndZhiLunFinish(
                                    detail.currentQuarter.zcThirdFinish,
                                    detail.currentQuarter.zhilunThirdFinish,
                                )
                            }}
                        </span>
                    </div>
                    <div class="flex-row ivu-mt" v-show="detail.currentQuarter.secondMonth">
                        <span
                            class="left-title"
                            v-if="detail.currentQuarter.secondMonth == detail.currentMonth.taskMonth"
                        >
                            本月({{ detail.currentQuarter.secondMonth }}月)
                        </span>
                        <span class="left-title" v-else>{{ detail.currentQuarter.secondMonth }}月</span>
                        <Progress
                            :percent="
                                Number(
                                    ((detail.currentQuarter.secondFinish || 0) /
                                        (detail.currentQuarter.secondTask || 1)) *
                                        100 >
                                        100
                                        ? 100
                                        : (
                                              ((detail.currentQuarter.secondFinish || 0) /
                                                  (detail.currentQuarter.secondTask || 1)) *
                                              100
                                          ).toFixed(2),
                                )
                            "
                            :stroke-width="15"
                            status="active"
                        />
                        <span>{{ detail.currentQuarter.secondFinish || 0 }}</span>
                        <i>/{{ detail.currentQuarter.secondTask || 0 }}条</i>
                        <span v-if="signInfo.zhilunStoreId">
                            {{
                                zcAndZhiLunFinish(
                                    detail.currentQuarter.zcSecondFinish,
                                    detail.currentQuarter.zhilunSecondFinish,
                                )
                            }}
                        </span>
                    </div>
                    <div class="flex-row ivu-mt" v-show="detail.currentQuarter.firstMonth">
                        <span
                            class="left-title"
                            v-if="detail.currentQuarter.firstMonth == detail.currentMonth.taskMonth"
                        >
                            本月({{ detail.currentQuarter.firstMonth }}月)
                        </span>
                        <span class="left-title" v-else>{{ detail.currentQuarter.firstMonth }}月</span>
                        <Progress
                            :percent="
                                Number(
                                    ((detail.currentQuarter.firstFinish || 0) /
                                        (detail.currentQuarter.firstTask || 1)) *
                                        100 >
                                        100
                                        ? 100
                                        : (
                                              ((detail.currentQuarter.firstFinish || 0) /
                                                  (detail.currentQuarter.firstTask || 1)) *
                                              100
                                          ).toFixed(2),
                                )
                            "
                            :stroke-width="15"
                            status="active"
                        />
                        <span>{{ detail.currentQuarter.firstFinish || 0 }}</span>
                        <i>/{{ detail.currentQuarter.firstTask || 0 }}条</i>
                        <span v-if="signInfo.zhilunStoreId">
                            {{
                                zcAndZhiLunFinish(
                                    detail.currentQuarter.zcFirstFinish,
                                    detail.currentQuarter.zhilunFirstFinish,
                                )
                            }}
                        </span>
                    </div>
                    <div v-if="!detail.currentQuarter.totalTask">- 暂无数据 -</div>
                </Card>
                <Card title="历史季度完成情况" :bordered="false" dis-hover class="ivu-mt">
                    <div class="ivu-mb" v-for="(item, index) in detail.quarterList" :key="index">
                        <h4>
                            {{
                                `第${item.quarterNum}季度 ${item.totalFinish}/${item.totalTask} ${zcAndZhiLunFinish(
                                    item.zcTotalFinish,
                                    item.zhilunTotalFinish,
                                )}`
                            }}
                        </h4>
                        <div class="flex-row ivu-mt">
                            <span class="left-title">季度</span>
                            <Progress
                                :percent="
                                    Number(
                                        ((item.totalFinish || 0) / (item.totalTask || 1)) * 100 > 100
                                            ? 100
                                            : (((item.totalFinish || 0) / (item.totalTask || 1)) * 100).toFixed(2),
                                    )
                                "
                                :stroke-width="15"
                                status="active"
                            />
                            <span>{{ item.totalFinish || 0 }}</span>
                            <i>/{{ item.totalTask || 0 }}条</i>
                            <span>{{ zcAndZhiLunFinish(item.zcTotalFinish, item.zhilunTotalFinish) }}</span>
                        </div>
                        <div class="flex-row ivu-mt" v-show="item.thirdMonth">
                            <span class="left-title">{{ item.thirdMonth }}月</span>
                            <Progress
                                :percent="
                                    Number(
                                        ((item.thirdFinish || 0) / (item.thirdTask || 1)) * 100 > 100
                                            ? 100
                                            : (((item.thirdFinish || 0) / (item.thirdTask || 1)) * 100).toFixed(2),
                                    )
                                "
                                :stroke-width="15"
                                status="active"
                            />
                            <span>{{ item.thirdFinish || 0 }}</span>
                            <i>/{{ item.thirdTask || 0 }}条</i>
                            <span>{{ zcAndZhiLunFinish(item.zcThirdFinish, item.zhilunThirdFinish) }}</span>
                        </div>
                        <div class="flex-row ivu-mt" v-show="item.secondMonth">
                            <span class="left-title">{{ item.secondMonth }}月</span>
                            <Progress
                                :percent="
                                    Number(
                                        ((item.secondFinish || 0) / (item.secondTask || 1)) * 100 > 100
                                            ? 100
                                            : (((item.secondFinish || 0) / (item.secondTask || 1)) * 100).toFixed(2),
                                    )
                                "
                                :stroke-width="15"
                                status="active"
                            />
                            <span>{{ item.secondFinish || 0 }}</span>
                            <i>/{{ item.secondTask || 0 }}条</i>
                            <span>{{ zcAndZhiLunFinish(item.zcSecondFinish, item.zhilunSecondFinish) }}</span>
                        </div>
                        <div class="flex-row ivu-mt" v-show="item.firstMonth">
                            <span class="left-title">{{ item.firstMonth }}月</span>
                            <Progress
                                :percent="
                                    Number(
                                        ((item.firstFinish || 0) / (item.firstTask || 1)) * 100 > 100
                                            ? 100
                                            : (((item.firstFinish || 0) / (item.firstTask || 1)) * 100).toFixed(2),
                                    )
                                "
                                :stroke-width="15"
                                status="active"
                            />
                            <span>{{ item.firstFinish || 0 }}</span>
                            <i>/{{ item.firstTask || 0 }}条</i>
                            <span>{{ zcAndZhiLunFinish(item.zcFirstFinish, item.zhilunFirstFinish) }}</span>
                        </div>
                    </div>
                    <div v-if="!detail.quarterList.length">- 暂无数据 -</div>
                </Card>
            </Form>
        </Card>
    </div>
</template>

<script>
import { esignTaskCurrentDetail } from '@/api/statistics';
import { esignStoreAllDetail, esignStoreFile } from '@/api/esign';
import { jumpPage, transPathToName } from '@/libs/util';

export default {
    data() {
        return {
            currentYear: new Date().getFullYear(),
            storeId: this.$route.query.storeId || '',
            mode: this.$route.query.mode,
            routeYear: this.$route.query.year,
            loading: false,
            detail: {
                currentMonth: {
                    finishNum: 0,
                    taskNum: 0,
                },
                currentQuarter: {},
                quarterList: [],
            },
            signInfo: {},
            infos: [],
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        zcAndZhiLunFinish(zcFinishNum, zlFinisshNum) {
            if (this.signInfo.zhilunStoreId && this.routeYear < 2024) {
                return `（中策${zcFinishNum || 0}，知轮${zlFinisshNum || 0}）`;
            } else {
                return '';
            }
        },
        checkPdf(row) {
            if (row.fileUrl) {
                const url = `${window.location.origin}/pdf/view?url=${row.fileUrl}&title=${row.fileName}`;
                window.open(url, '_blank');
            }
        },
        getData() {
            if (!this.storeId) return;
            this.loading = true;
            esignTaskCurrentDetail({
                storeId: this.storeId,
                startYear: this.$route.query.year,
            })
                .then((data) => {
                    this.detail = data || {
                        currentMonth: {
                            finishNum: 0,
                            taskNum: 0,
                        },
                    };
                    esignStoreAllDetail({
                        storeId: this.storeId,
                        year: this.$route.query.year,
                    }).then((res) => {
                        this.signInfo = res;
                        this.infos = res.infos;
                        if (res.infos && res.infos.length > 0) {
                            this.loading = true;
                            this.getAllPdf(res.infos).then(
                                () => {
                                    this.loading = false;
                                },
                                () => {
                                    this.loading = false;
                                },
                            );
                        } else {
                            this.loading = false;
                        }
                    });
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        getAllPdf(infos) {
            return new Promise((resolve, reject) => {
                let list = JSON.parse(JSON.stringify(infos));
                infos.map((item, index) => {
                    esignStoreFile({
                        flowId: item.flowId,
                    }).then(
                        (pdf) => {
                            if (pdf && pdf.docs.length > 0) {
                                this.$set(this.infos, index, {
                                    ...item,
                                    fileId: pdf.docs[0].fileId,
                                    fileName: pdf.docs[0].fileName,
                                    fileUrl: pdf.docs[0].fileUrl,
                                });
                            }
                            if (index === infos.length - 1) {
                                resolve(list);
                            }
                        },
                        () => {
                            reject(list);
                        },
                    );
                });
            });
        },
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/statistics/storeSign/list' });
        },
    },
};
</script>

<style lang="less" scoped>
.flex-row {
    display: flex;
    flex-flow: row nowrap;

    .left-title {
        display: inline-block;
        width: 70px;
        text-align: right;
        margin-right: 24px;
    }

    .ivu-progress {
        flex: 0 1 40%;
        margin-right: 24px;
        white-space: nowrap;
    }
}
</style>
