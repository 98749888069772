<!--
 * @Author: chengleilei
 * @Date: 2021-09-17 16:30:16
 * @Description: 集团招标详情
-->
<template>
    <div class="group-bidding-detail" v-loading="loading">
        <PageHeader title="集团客户招标查看" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage">
                <Icon type="md-arrow-back" />
            </div>
        </PageHeader>
        <Card dis-hover :bordered="false" class="ivu-mt-16">
            <Alert>{{ info.biddingStatusStr }}</Alert>
        </Card>
        <Card dis-hover :bordered="false" class="ivu-mt-16">
            <Form :label-width="180">
                <Row class="ivu-pr8">
                    <Col span="12">
                        <FormItem label="项目名称：">
                            {{ info.projectName || '-' }}
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem label="地区：">
                            {{ info.cityName || '-' }}
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem label="联系人：">
                            {{ info.contactPerson || '-' }}
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem label="联系方式：">
                            {{ info.contactWay || '-' }}
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem label="招标单位名称：">
                            {{ info.biddingName || '-' }}
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem label="单位类型：">
                            {{ info.companyTypeStr || '-' }}
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem label="主要车型：">
                            {{ info.mainCarTypeStr || '-' }}
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem label="轮胎年使用量：">
                            {{ info.tyreYearUseCount === null ? '-' : info.tyreYearUseCount }}
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem label="指派业务员：">
                            {{ info.salesman || '-' }}
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem label="业务员联系方式：">
                            {{ info.salesmanMobile || '-' }}
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem label="投标截止时间：">
                            {{ info.tenderEndDate || '-' }}
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem label="购买标书截止时间：">
                            {{ info.buyTenderEndDate || '-' }}
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem label="项目内容：">
                            <p
                                v-if="info.projectContentRichText"
                                class="value"
                                style="cursor: pointer"
                                @click="checkMore(info)"
                            >
                                <Ellipsis :text="info.projectContentRichText" :lines="5" :tooltip="false" />
                                <Icon type="ios-arrow-down" />
                            </p>
                            <p class="value">
                                <span
                                    class="source-img img-preview"
                                    :style="{ backgroundImage: 'url(' + img + ')' }"
                                    v-for="(img, idx) in info.projectContentImgUrlList"
                                    :key="idx"
                                >
                                    <img class="img" :src="img" preview-text="项目内容" :preview="'project'" />
                                </span>
                            </p>
                        </FormItem>
                    </Col>
                    <Divider />
                    <Col span="12">
                        <FormItem label="竞标经销商：">
                            {{ info.distributorName || '-' }}
                        </FormItem>
                    </Col>
                    <Divider />
                    <Col span="12">
                        <FormItem label="购买标书凭证：">
                            <span class="value" v-if="info.biddingVoucherList && info.biddingVoucherList.length">
                                <span
                                    class="source-img img-preview"
                                    :style="{ backgroundImage: 'url(' + img + ')' }"
                                    v-for="(img, idx) in info.biddingVoucherList"
                                    :key="idx"
                                >
                                    <img
                                        class="img"
                                        :src="img"
                                        preview-text="购买标书凭证"
                                        :preview="'biddingVoucher'"
                                    />
                                </span>
                            </span>
                            <span v-else>-</span>
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem label="招标文件：">
                            <span class="value" v-if="info.biddingDocumentList && info.biddingDocumentList.length">
                                <span
                                    class="source-img img-preview"
                                    :style="{ backgroundImage: 'url(' + img + ')' }"
                                    v-for="(img, idx) in info.biddingDocumentList"
                                    :key="idx"
                                >
                                    <img class="img" :src="img" preview-text="招标文件" :preview="'biddingDocument'" />
                                </span>
                            </span>
                            <span v-else>-</span>
                        </FormItem>
                    </Col>
                    <Divider />
                    <Col span="12">
                        <FormItem label="中标通知书：">
                            <span class="value" v-if="info.noticeList && info.noticeList.length">
                                <span
                                    class="source-img img-preview"
                                    :style="{ backgroundImage: 'url(' + img + ')' }"
                                    v-for="(img, idx) in info.noticeList"
                                    :key="idx"
                                >
                                    <img class="img" :src="img" preview-text="中标通知书" :preview="'notice'" />
                                </span>
                            </span>
                            <span v-else>-</span>
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem label="中标合同：">
                            <span class="value" v-if="info.contractList && info.contractList.length">
                                <span
                                    class="source-img img-preview"
                                    :style="{ backgroundImage: 'url(' + img + ')' }"
                                    v-for="(img, idx) in info.contractList"
                                    :key="idx"
                                >
                                    <img class="img" :src="img" preview-text="中标合同" :preview="'contract'" />
                                </span>
                            </span>
                            <span v-else>-</span>
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem label="中标单位名称：">
                            {{ info.bidWinnerName || '-' }}
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem label="中标品牌：">
                            {{ info.winBrand || '-' }}
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem label="中标金额：">
                            {{ info.winAmount ? `${info.winAmount}元` : '-' }}
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem label="中标合同时间：">
                            {{
                                info.winContractStartDate
                                    ? `${info.winContractStartDate}~${info.winContractEndDate}`
                                    : '-'
                            }}
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem label="备注：">
                            <span class="value">{{ info.remark || '-' }}</span>
                        </FormItem>
                    </Col>
                    <Divider />
                    <Col span="12">
                        <FormItem label="距下次招标：">
                            {{ info.distanceNextBiddingTime ? `还剩${info.distanceNextBiddingTime}天` : '-' }}
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        </Card>
    </div>
</template>

<script>
import { detailCustomerBidding } from '@/api/group';
import { jumpPage, transPathToName } from '@/libs/util';

export default {
    components: {},
    data() {
        return {
            biddingId: this.$route.query.biddingId || null,
            loading: false,
            info: {},
        };
    },
    created() {
        this.getData();
    },
    methods: {
        checkMore(info) {
            info.projectContentRichText &&
                this.$Modal.info({
                    title: '项目内容',
                    content: info.projectContentRichText,
                    width: '500px',
                    render: (h) => {
                        return h('div', {
                            style: {
                                height: '300px',
                                overflowY: 'auto',
                                lineHeight: '30px',
                                fontSize: '15px',
                                textAlign: 'justify',
                                padding: '10px 5px',
                            },
                            domProps: {
                                innerText: info.projectContentRichText,
                            },
                        });
                    },
                });
        },
        /**
         * @description 关闭当前标签返回上一页
         */
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/group/groupBidding/index' });
        },

        /**
         * @description 获取招标详情数据
         */
        getData() {
            if (!this.biddingId) return;
            this.loading = true;
            detailCustomerBidding(this.biddingId)
                .then((res) => {
                    this.$previewRefresh();
                    this.info = res || {};
                })
                .catch(() => {})
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="less" scoped>
.group-bidding-detail {
    .ivu-alert {
        text-align: center;
        margin-bottom: 0;
    }
    .ivu-divider {
        margin-top: 0;
    }
    .source-img {
        display: inline-block;
        width: 70px;
        height: 70px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 15px;
        cursor: pointer;
        position: relative;
        .img {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            opacity: 0;
        }
    }
}
</style>
