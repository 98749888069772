/**
 *@description: 巡店记录
 *@author: forguo
 *@date: 2020/11/16
 */
import request from '@/plugins/request';

/**
 *  门店巡店记录
 */
export function StoreCruise(data) {
    return request({
        url: `/storeTour/tourDetail`,
        method: 'get',
        params: data,
    });
}

/**
 *  巡店记录查询
 */
export function storeTourList(data) {
    return request({
        url: `/storeTour/storeTourList`,
        method: 'get',
        params: data,
    });
}

/**
 *  巡店记录导出
 */
export function storeTourListExport(data) {
    return request({
        url: `/storeTour/exportTitleUpload`,
        method: 'post',
        params: data,
    });
}

/**
 *  已巡
 */
export function hasTour(data) {
    return request({
        url: `/storeTour/hasTour`,
        method: 'get',
        params: data,
    });
}

/**
 *  未巡
 */
export function noTour(data) {
    return request({
        url: `/storeTour/noTour`,
        method: 'get',
        params: data,
    });
}
