<!-- eslint-disable vue/no-mutating-props -->
<!--
 * @Author: chengleilei
 * @Date: 2021-09-30 14:14:07
 * @Description: 法人信息组件
-->
<template>
    <Card title="法人信息" :bordered="false" dis-hover class="ivu-mt i-table-no-border">
        <Form hide-required-mark :model="legalForm" ref="legalForm" :rules="legalRules" :label-width="180">
            <FormItem label="法人姓名：" prop="legalPersonName">
                <Input v-model="legalForm.legalPersonName" placeholder="请输入法人姓名" disabled></Input>
            </FormItem>
            <FormItem label="法人手机号：" prop="legalPersonMobile" v-if="!isEnterprise">
                <Input
                    v-model="legalForm.legalPersonMobile"
                    maxlength="11"
                    placeholder="请输入法人手机号"
                    disabled
                ></Input>
            </FormItem>
            <FormItem label="法人身份证号：" prop="legalPersonNumber">
                <Input v-model="legalForm.legalPersonNumber" placeholder="请输入法人身份证号" disabled></Input>
            </FormItem>
            <FormItem label="证件有效期：">
                <DatePicker
                    style="width: 200px"
                    v-model="legalForm.legalCardStartTime"
                    type="date"
                    placeholder="请选择开始日期"
                ></DatePicker>
                <span class="txt">至</span>
                <DatePicker
                    style="width: 200px"
                    v-model="legalForm.legalCardEndTime"
                    :disabled="isLongLegal"
                    type="date"
                    placeholder="请选择结束日期"
                ></DatePicker>
                <Checkbox class="txt" v-model="isLongLegal">长期有效</Checkbox>
            </FormItem>
            <FormItem label="法人手机号：" prop="legalPersonMobile" v-if="isEnterprise">
                <Input v-model="legalForm.legalPersonMobile" maxlength="11" placeholder="请输入法人手机号"></Input>
            </FormItem>
            <FormItem label="法人邮箱：" prop="legalPersonEmail" v-if="isEnterprise">
                <Input v-model="legalForm.legalPersonEmail" maxlength="50" placeholder="请输入法人邮箱"></Input>
            </FormItem>
        </Form>
    </Card>
</template>
<script>
import { MOBILE_REG } from '@/libs/util';

export default {
    props: {
        // true企业 false个体户
        isEnterprise: {
            type: Boolean,
            default: true,
        },
        legalForm: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        const validatePhone = (rule, value, callback) => {
            if (!value) {
                callback(new Error(`请输入法人手机号`));
            } else if (!MOBILE_REG.test(value.trim())) {
                callback(new Error(`手机号格式不正确`));
            } else {
                callback();
            }
        };
        const emailCheck = (rule, value, callback) => {
            const reg =
                /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
            if (!value) {
                callback(new Error(`请输入法人邮箱`));
            } else if (!reg.test(value.trim())) {
                callback(new Error(`邮箱格式不正确`));
            } else {
                callback();
            }
        };
        return {
            isLongLegal: false, // 是否长期有效-法人证件
            // 法人信息校验规则
            legalRules: {
                legalPersonMobile: [{ validator: validatePhone, trigger: 'blur' }],
                legalPersonEmail: [{ validator: emailCheck, trigger: 'blur' }],
            },
        };
    },
    watch: {
        isLongLegal(to) {
            if (to) {
                // eslint-disable-next-line vue/no-mutating-props
                this.legalForm.legalCardEndTime = new Date('2099-12-31');
            }
        },
    },
    mounted() {},
};
</script>
<style lang="less" scoped>
.txt {
    margin: 0 8px;
}
</style>
