<template>
    <div class="type-chose">
        <PageHeader :title="title" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage">
                <Icon type="md-arrow-back" />
            </div>
        </PageHeader>
        <Card class="ivu-mt" :dis-hover="true" shadow style="margin-bottom: 40px">
            <Card bordered class="ivu-mb-16">
                <div class="ivu-row-flex type-chose-item" @click="choseType(12, 12)">
                    <img
                        src="@/assets/images/icon-business.png"
                        style="width: 40px; height: 40px; margin-right: 16px; margin-left: 5px"
                    />
                    <div :style="{ flex: 1 }">
                        <div class="ivu-row-flex ivu-row-flex-middle ivu-mb-16">
                            <h2 style="margin-right: 25px">企业</h2>
                            <span style="color: red">提现账户为企业公账</span>
                        </div>
                        <div>
                            <div>
                                <p><span>认证时需要准备以下材料：</span></p>
                                <p><span>1. 上传资质证明文件：工商营业执照、法人身份证、公账银行信息证明照片</span></p>
                                <p><span>2. 填写对公账户信息</span></p>
                                <p><span>3. 对公账户小额打款验证</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
            <Card bordered class="ivu-mb-16">
                <div class="ivu-row-flex type-chose-item" @click="choseType(11, 13)">
                    <img
                        src="@/assets/images/icon-person.png"
                        style="width: 40px; height: 40px; margin-right: 16px; margin-left: 5px"
                    />
                    <div style="flex: 1">
                        <div class="ivu-row-flex ivu-row-flex-middle ivu-mb-16">
                            <h2 style="margin-right: 25px">个体工商户</h2>
                            <span style="color: red">提现账户为法人个人银行卡</span>
                        </div>
                        <div>
                            <p><span>认证时需要准备以下材料：</span></p>
                            <p><span>1. 上传资质证明文件：工商营业执照、法人身份证的电子照片</span></p>
                            <p><span>2. 填写法人银行卡信息</span></p>
                            <p><span>3. 法人手机短信验证码验证</span></p>
                        </div>
                    </div>
                </div>
            </Card>
        </Card>
    </div>
</template>

<script>
import { jumpPage, transPathToName } from '@/libs/util';

export default {
    data() {
        return {
            title: '创建账户',
        };
    },
    methods: {
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({
                path: '/sub-vue2/assets/dealerAccount/list',
            });
        },
        choseType(bankAccountType, userType) {
            // bankAccountType 银行账户类型【11=个人账户 12=企业账户】
            // userType 影印件账户类型【12=企业账户 13 = 个体工商户】
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({
                path: '/sub-vue2/assets/dealerAccount/tiedCardForm',
                params: {
                    bankAccountType,
                    userType,
                },
                routeType: 'replaceState',
            });
        },
    },
};
</script>

<style lang="less" scoped>
.type-chose {
    .type-chose-item {
        position: relative;
        cursor: pointer;
        margin: 16px 0;
        display: flex;
        &:after {
            content: '';
            width: 15px;
            height: 15px;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translate3d(0, -50%, 0) rotate3d(0, 0, 1, 45deg);
            border-top: 3px solid #222;
            border-right: 3px solid #222;
        }
    }
}
</style>
