<template>
    <div v-loading="list.loading">
        <PageHeader title="扫码库存" hidden-breadcrumb>
            <Button
                slot="action"
                type="info"
                class="ivu-ml"
                icon="md-cloud-download"
                @click="onExport"
                :loading="isExporting"
                >{{ isExporting ? '正在导出...' : '导出' }}</Button
            >
        </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" @on-submit="onSubmit" ref="table-form" />
            <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list">
                <template v-slot:extra>
                    <Alert class="ivu-mt-16 ivu-text-right">
                        <span style="margin-left: 20px">历史库存：{{ hisTotal }}</span>
                        <span style="margin-left: 20px">扫码：{{ scanTotal }} </span>
                        <span style="margin-left: 20px">合计：{{ totalNumber }} </span>
                    </Alert>
                </template>
            </table-page>
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { ScanStockOptions, ScanStockList, ExportScanStock } from '@/api/statistics';

export default {
    name: 'statistics-stock-list',
    components: {
        tableForm,
        tablePage,
    },
    data() {
        return {
            isExporting: false,
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    { title: '商品名称', key: 'title', minWidth: 320, align: 'center' },
                    { title: '规格', key: 'tireSpec', minWidth: 170, align: 'center' },
                    { title: '品牌', key: 'brandName', minWidth: 200, align: 'center' },
                    { title: '花纹', key: 'tireFigure', minWidth: 120, align: 'center' },
                    { title: '尺寸', key: 'tireRim', minWidth: 120, align: 'center' },
                    { title: '入库', key: 'inNum', minWidth: 120, align: 'center' },
                    { title: '出库', key: 'outNum', minWidth: 120, align: 'center' },
                    { title: '退货', key: 'reNum', minWidth: 120, align: 'center' },
                    { title: '扫码库存', key: 'scanNum', minWidth: 120, align: 'center' },
                ],
                data: [],
            },
            formList: [
                {
                    title: '品牌',
                    type: 'select',
                    key: 'brandId',
                    placeholder: '请选择',
                    clearable: true,
                    options: [],
                },
                {
                    title: '花纹',
                    type: 'select',
                    key: 'tireFigure',
                    placeholder: '请选择',
                    clearable: true,
                    options: [],
                },
                {
                    title: '直径',
                    type: 'select',
                    key: 'tireRim',
                    placeholder: '请选择',
                    clearable: true,
                    options: [],
                },
                {
                    title: '商品名称',
                    type: 'input',
                    key: 'title',
                    clearable: true,
                    placeholder: '模糊搜索',
                },
            ],
            hisTotal: '',
            scanTotal: '',
            totalNumber: '',
        };
    },
    activated() {
        this.getOptions();
        this.getData();
    },
    methods: {
        getData(p) {
            this.list.loading = true;
            let params = {
                current: this.list.current,
                size: this.list.size,
                ...p,
            };
            ScanStockList(params)
                .then((res) => {
                    let records = res.records;
                    this.hisTotal = res.hisTotal || '-';
                    this.scanTotal = res.scanTotal || '-';
                    this.totalNumber = res.totalNumber || '-';
                    this.list.total = Number(res.total);
                    this.list.data = records || [];
                    this.list.loading = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.list.loading = false;
                });
        },
        getOptions(p) {
            const param = {
                current: this.list.current,
                size: 100000,
                ...p,
            };
            ScanStockOptions(param).then((res) => {
                this.formList[0].options = (res.brands || []).map((item) => {
                    return {
                        ...item,
                        name: item.brandName,
                        id: item.brandId,
                    };
                });
                this.formList[1].options = (res.figures || []).map((item) => {
                    return {
                        id: item,
                        name: item,
                    };
                });
                this.formList[2].options = (res.rims || []).map((item) => {
                    return {
                        id: item,
                        name: item,
                    };
                });
            });
        },
        onSubmit(param) {
            console.log(param);
            this.list.current = 1;
            this.getData(param);
        },
        onPageChange(val) {
            // console.log('on Page Change ==', val)

            this.list.current = val;
            let form = this.$refs['table-form'].getFormData();
            this.getData(form);
        },
        onPageSizeChange(val) {
            // console.log('on PageSize Change ==', val)

            this.list.current = 1;
            this.list.size = val;
            let form = this.$refs['table-form'].getFormData();
            this.getData(form);
        },
        onExport() {
            this.list.loading = true;
            this.isExporting = true;
            let form = this.$refs['table-form'].getFormData();
            let params = {
                current: this.list.current,
                size: this.list.size,
                ...form,
            };
            ExportScanStock(params)
                .then((res) => {
                    this.list.loading = false;
                    this.isExporting = false;
                    if (!res) {
                        this.$Message.warning('暂无数据');
                        return false;
                    }
                    let aLink = document.createElement('a');
                    aLink.href = res || '';
                    // console.log('href ===', res)
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    this.$Notice.success({
                        title: '导出成功',
                    });
                })
                .catch((err) => {
                    console.log(err);
                    this.$Notice.error({
                        title: '导出失败 ！',
                    });
                    this.list.loading = false;
                    this.isExporting = false;
                });
        },
    },
};
</script>
