/*
 * @Author: chengleilei
 * @Date: 2023-12-07 16:21:52
 * @Description: 
 */
const formList = [
    {
        title: '合同名称',
        type: 'input',
        key: 'contractName',
    },
    {
        title: '门店信息',
        type: 'input',
        key: 'storeName',
        placeholder: '请输入门店名称/联系人/联系方式',
    },
]

const commonCol = [
    {
        title: '门店名称',
        key: 'storeName',
        align: 'center',
        minWidth: 130,
        fixed: 'left',
    },
    {
        title: '联系人',
        key: 'ownerName',
        align: 'center',
        minWidth: 150,
    },
    {
        title: '联系方式',
        key: 'ownerPhone',
        align: 'center',
        minWidth: 160,
    },
    {
        title: '合同名称',
        key: 'contractName',
        align: 'center',
        minWidth: 160,
    },
    {
        title: '合同生效时间',
        key: 'contractStartTime',
        align: 'center',
        minWidth: 160,
    },
    {
        title: '档次',
        key: 'levelName',
        align: 'center',
        minWidth: 160,
    },
    {
        title: '主营品牌',
        key: 'brandMainName',
        align: 'center',
        minWidth: 160,
    },
]

const yearDataCol = [
    {
        title: '年度完成量/年度总任务量',
        key: 'yearNumStr',
        align: 'center',
        minWidth: 205,
        render: (h, { row }) => {
            if (!row.zhilunSign || row.startYear > 2023) return h('div', {}, `${row.yearNumStr || '--'}`);
            return h('div', {}, [
                h(
                    'Poptip',
                    {
                        props: {
                            trigger: 'hover',
                            content: `中策 ${row.zcYearFinishNum}，知轮 ${row.zhilunYearFinishNum}`,
                        },
                    },
                    [h('span', {}, `${row.yearNumStr || '--'}`)],
                ),
            ]);
        },
    },
    {
        title: '年度完成率',
        key: 'yearRate',
        align: 'center',
        minWidth: 160,
        render: (h, p) => {
            return h('div', {}, `${p.row.yearRate || '--'}`);
        },
    },
]

const zhilunSignDataCol = [
    {
        title: '知轮签约',
        key: 'zhilunSign',
        align: 'center',
        minWidth: 160,
        render: (h, p) => {
            return h('span', {}, p.row.zhilunSignStr);
        },
    },
]

const monthAndQuarterDataCol = [
    {
        title: '月度完成量/月度总任务量',
        key: 'monthNumStr',
        align: 'center',
        minWidth: 205,
        render: (h, { row }) => {
            if (!row.zhilunSign || row.startYear > 2023) return h('div', {}, `${row.monthNumStr || '--'}`);
            return h('div', {}, [
                h(
                    'Poptip',
                    {
                        props: {
                            trigger: 'hover',
                            content: `中策 ${row.zcMonthFinishNum}，知轮 ${row.zhilunMonthFinishNum}`,
                        },
                    },
                    [h('span', {}, `${row.monthNumStr || '--'}`)],
                ),
            ]);
        },
    },
    {
        title: '月度完成率',
        key: 'monthRate',
        align: 'center',
        minWidth: 160,
        render: (h, p) => {
            return h('div', {}, `${p.row.monthRate || '--'}`);
        },
    },
    {
        title: '季度完成量/季度总任务量',
        key: 'quarterFinishNumStr',
        align: 'center',
        minWidth: 205,
        render: (h, { row }) => {
            if (!row.zhilunSign || row.startYear > 2023) return h('div', {}, `${row.quarterFinishNumStr || '--'}`);
            return h('div', {}, [
                h(
                    'Poptip',
                    {
                        props: {
                            trigger: 'hover',
                            content: `中策 ${row.zcQuarterFinishNum}，知轮 ${row.zhilunQuarterFinishNum}`,
                        },
                    },
                    [h('span', {}, `${row.quarterFinishNumStr || '--'}`)],
                ),
            ]);
        },
    },
    {
        title: '季度完成率',
        key: 'quarterRate',
        align: 'center',
        minWidth: 160,
        render: (h, p) => {
            return h('div', {}, `${p.row.quarterRate || '--'}`);
        },
    },
]

export { formList, commonCol, zhilunSignDataCol, yearDataCol, monthAndQuarterDataCol }