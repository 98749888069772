<!--
 * @Author: chengleilei
 * @Date: 2021-10-14 15:31:50
 * @Description: 绑卡结果页
-->
<template>
    <div class="add-bank-result" v-loading="pageLoading">
        <PageHeader title="添加银行卡" hidden-breadcrumb> </PageHeader>
        <Card class="ivu-mt" :bordered="false" dis-hover>
            <div class="img-wrapper">
                <img src="@/assets/images/result-success.png" />
            </div>
            <Result title="绑卡成功">
                <div slot="desc">
                    您已成功绑定银行卡，<a>{{ countdown }}s</a>后即将为您跳转至账户详情页。
                </div>
                <div slot="actions">
                    <Button type="primary" @click="goPage">查看银行卡</Button>
                </div>
            </Result>
        </Card>
    </div>
</template>

<script>
import { jumpPage, transPathToName } from '@/libs/util';

export default {
    data() {
        return {
            pageLoading: false,
            userType: this.$route.query.userType || '', // 账户类型【 12=企业用户 13=个体工商用户】
            cpcnAccountId: this.$route.query.cpcnAccountId || '', // 中金账户id
            timer: null,
            countdown: 3, // 倒计时秒数
            codeMsg: '',
        };
    },

    created() {
        if (!this.timer) {
            this.timer = setInterval(() => {
                if (this.countdown > 0 && this.countdown <= 3) {
                    this.countdown--;
                    if (this.countdown !== 0) {
                        //
                    } else {
                        clearInterval(this.timer);
                        this.timer = null;
                        this.goPage();
                    }
                }
            }, 1000);
        }
    },

    destroyed() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    },

    methods: {
        /**
         * @description 跳转页面
         * @param {String} path 页面路由
         */
        goPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({
                path: '/sub-vue2/assets/dealerAccount/detail',
                params: {
                    userType: this.userType,
                    cpcnAccountId: this.cpcnAccountId,
                    type: 'card',
                },
            });
        },
    },
};
</script>

<style lang="less" scoped>
.add-bank-result {
    .ivu-mt {
        height: calc(~'100vh - 216px');
    }
    .img-wrapper {
        margin-top: 4%;
        text-align: center;
        img {
            width: 120px;
            height: 120px;
        }
    }
}
</style>
