/*
 * @Author: chengleilei
 * @Date: 2024-02-02 17:34:29
 * @Description: 路由汇总
 */
import { warehouseRoutes } from './modules/warehouse.js';
import { exportRoutes } from './modules/export.js';
import { scanRoutes } from './modules/scan.js';
import { customerRoutes } from './modules/customer.js';
import { creditRoutes } from './modules/credit.js';
import { decorationRoutes } from './modules/decoration.js';
import { groupRoutes } from './modules/group.js';
import { statisticsRoutes } from './modules/statistics.js';
import { salesRoutes } from './modules/sales.js';
import { assetsRoutes } from './modules/assets.js';
import { settingRoutes } from './modules/setting.js';

const subVue2Routes = [
    ...warehouseRoutes,
    ...exportRoutes,
    ...scanRoutes,
    ...customerRoutes,
    ...creditRoutes,
    ...decorationRoutes,
    ...groupRoutes,
    ...statisticsRoutes,
    ...salesRoutes,
    ...assetsRoutes,
    ...settingRoutes,
];

export { subVue2Routes };
