<template>
    <div v-loading="loading">
        <PageHeader title="门店详情" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage"><Icon type="md-arrow-back" /></div>
        </PageHeader>
        <Card :bordered="true" dis-hover class="ivu-mt">
            <Tabs type="card" class="bg-white" :animated="true">
                <TabPane label="门店信息" name="itemStore">
                    <List border size="large">
                        <ListItem>
                            <Col span="8">门店名称：</Col>
                            <Col>{{ storeInfo.storeName || '-' }}</Col>
                        </ListItem>
                        <ListItem>
                            <Col span="8">经销商：</Col>
                            <Col>{{ storeInfo.distributorName || '-' }}</Col>
                        </ListItem>
                        <ListItem>
                            <Col span="8">主营品牌：</Col>
                            <Col>{{ storeInfo.majorBrand || '-' }}</Col>
                        </ListItem>
                        <ListItem>
                            <Col span="8">副营品牌：</Col>
                            <Col>{{ storeInfo.secondaryBrand || '-' }}</Col>
                        </ListItem>
                        <!--                <ListItem>-->
                        <!--                    <Col span="8">门店类型：</Col>-->
                        <!--                    <Col>{{storeInfo.typeName || '-'}}</Col>-->
                        <!--                </ListItem>-->
                        <!--                <ListItem>-->
                        <!--                    <Col span="8">门店任务量：</Col>-->
                        <!--                    <Col>{{storeInfo.aboveTaskVolume || '-'}}</Col>-->
                        <!--                </ListItem>-->
                        <ListItem>
                            <Col span="8">门头编号：</Col>
                            <Col>{{ storeInfo.storeSn || '-' }}</Col>
                        </ListItem>
                        <ListItem>
                            <Col span="8">门店维护人：</Col>
                            <Col>{{ storeInfo.salesmanName || '-' }}</Col>
                            <Button style="margin-left: 10px" type="warning" @click="changeProtector">变更</Button>
                        </ListItem>
                        <ListItem>
                            <Col span="8">所在区域：</Col>
                            <Col>{{ storeInfo.area || '-' }}</Col>
                        </ListItem>
                        <ListItem>
                            <Col span="8">详细地址：</Col>
                            <Col>{{ storeInfo.place || '-' }}</Col>
                        </ListItem>
                        <ListItem>
                            <Col span="8">联系手机号码：</Col>
                            <Col>{{ storeInfo.ownerPhone || '-' }}</Col>
                        </ListItem>
                        <ListItem>
                            <Col span="8">服务项目：</Col>
                            <Col>{{ storeInfo.serveNames || '-' }}</Col>
                        </ListItem>
                        <ListItem v-if="storeInfo.zhilunStoreId">
                            <Col span="8"> 绑定知轮门店ID：</Col>
                            <Col>{{ storeInfo.zhilunStoreId || '-' }}</Col>
                        </ListItem>
                        <ListItem v-if="storeInfo.bindingTime">
                            <Col span="8">绑定时间：</Col>
                            <Col>{{ storeInfo.bindingTime || '-' }}</Col>
                        </ListItem>
                    </List>
                </TabPane>
                <TabPane label="返利账户" name="RebateRecord">
                    <RebateRecord ref="RebateRecord" :storeId="id" />
                </TabPane>
                <TabPane label="积分账户" name="ScoreRecord">
                    <ScoreRecord ref="ScoreRecord" :storeId="id" />
                </TabPane>
            </Tabs>
        </Card>

        <FooterToolbar>
            <Button @click="jumpPrevPage">返回</Button>
        </FooterToolbar>
        <ModalProtector ref="saleManModal" />
    </div>
</template>

<script>
import ModalProtector from './components/ModalProtector';
import RebateRecord from './components/RebateRecord';
import ScoreRecord from './components/ScoreRecord';
import { StoreDetail, SalesmanList, UpdateSalesMan } from '@/api/store.js';
import { jumpPage, transPathToName } from '@/libs/util';

export default {
    name: 'customer-manage-storeDetail',
    components: {
        ModalProtector,
        RebateRecord,
        ScoreRecord,
    },
    data() {
        return {
            id: this.$route.query.id || '',
            showModal: false,
            loading: false,
            storeInfo: {},
            salesManList: [],
        };
    },
    mounted() {
        this.getData();
        this.getSaleMan();
    },
    methods: {
        getSaleMan() {
            SalesmanList().then((res) => {
                this.salesManList = (res || []).map((item) => {
                    return {
                        value: item.id,
                        label: item.name,
                    };
                });
            });
        },
        getData() {
            StoreDetail(this.id).then((res) => {
                this.storeInfo = res;
                this.storeInfo.area = res.storeAddress.includes('区')
                    ? res.storeAddress.split('区')[0] + '区'
                    : res.storeAddress;
                this.storeInfo.place = res.storeAddress.includes('区')
                    ? res.storeAddress.split('区')[1]
                    : res.storeAddress;
                this.storeInfo.majorBrand = res.majorBrand
                    .map((item) => {
                        return item.brandName;
                    })
                    .join(', ');
                this.storeInfo.secondaryBrand = res.secondaryBrand
                    .map((item) => {
                        return item.brandName;
                    })
                    .join(', ');
            });
        },
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/customer/manage/list' });
        },
        changeProtector() {
            this.$refs['saleManModal'].open('ONE', this.storeInfo);
        },
        confirmProtector(data) {
            UpdateSalesMan(data).then(() => {
                this.closeModal();
                this.getData();
            });
        },
        closeModal() {
            this.showModal = false;
        },
    },
};
</script>

<style scoped></style>
