<!--
 * @Author: chengleilei
 * @Date: 2021-11-30 14:10:25
 * @Description: 授信账户
-->
<template>
    <div v-loading="list.loading" class="credit-account-list">
        <PageHeader title="客户授信账户管理" hidden-breadcrumb></PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" ref="table-form" @on-submit="onSubmit" @on-export="onExport">
                <Button class="ivu-ml-8" :loading="isExporting" :disabled="isExporting" slot="extraBtn" type="error"
                    >导出</Button
                >
            </table-form>
            <table-page :list="list" @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" />
        </Card>
        <PwdVerifyModal
            @refreshList="getData"
            :modal.sync="showVerifyModal"
            :modalType="modalType"
            :item="activeItem"
        />
        <PwdSetting ref="pwdSetting" :first="true" @success="settingDone"> </PwdSetting>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import PwdVerifyModal from './components/pwdVerifyModal';
import PwdSetting from '../components/PwdSetting';
import { renderButton, clearEmpty, jumpPage } from '@/libs/util';
import { SalesmanList } from '@/api/store';
import { creditList, creditExport, closeNotice, openNotice, creditAccountInfo } from '@/api/credit';

export default {
    components: {
        tableForm,
        tablePage,
        PwdVerifyModal,
        PwdSetting,
    },

    data() {
        return {
            isExporting: false,
            showVerifyModal: false, // 密码验证弹窗显隐
            modalType: 0, // 1调整额度 2收款 3关闭开启授信
            activeItem: {}, // 当前正在操作的行数据
            list: {
                page: 1,
                size: window.$pageSize,
                total: 0,
                columns: [
                    {
                        title: '门店名称',
                        key: 'storeName',
                        align: 'center',
                        minWidth: 140,
                        fixed: 'left',
                    },
                    {
                        title: '联系人',
                        key: 'ownerName',
                        align: 'center',
                        minWidth: 120,
                    },
                    {
                        title: '联系方式',
                        key: 'ownerPhone',
                        align: 'center',
                        minWidth: 130,
                    },
                    {
                        title: '门店维护人',
                        align: 'center',
                        minWidth: 110,
                        render: (h, p) => {
                            return [p.row.salesmanName || '-'];
                        },
                    },
                    {
                        title: '是否启用',
                        align: 'center',
                        minWidth: 100,
                        render: (h, p) => {
                            return h('Icon', {
                                props: {
                                    type: p.row.isEnabled ? 'md-checkmark' : 'md-close',
                                    color: p.row.isEnabled ? '#21c064' : '#e70707',
                                },
                            });
                        },
                    },
                    {
                        title: '授信账户',
                        align: 'center',
                        minWidth: 100,
                        render: (h, p) => {
                            return h(
                                'div',
                                {
                                    style: `color: #${p.row.creditAccountStatus ? '21c064' : 'e70707'}`,
                                },
                                p.row.creditAccountStatus ? '开通' : '关闭',
                            );
                        },
                    },
                    {
                        title: '授信额度（元）',
                        align: 'center',
                        minWidth: 140,
                        render: (h, p) => {
                            return h('div', { style: 'white-space: nowrap;' }, [
                                h('span', p.row.creditQuota),
                                h(
                                    'span',
                                    {
                                        style: {
                                            color: '#2d8cf0',
                                            cursor: 'pointer',
                                            marginLeft: '8px',
                                        },
                                        on: {
                                            click: () => {
                                                this.openPwdModal(1, p.row);
                                            },
                                        },
                                    },
                                    '调整',
                                ),
                            ]);
                        },
                    },
                    {
                        title: '余额（元）',
                        key: 'balance',
                        align: 'center',
                        minWidth: 120,
                    },
                    {
                        title: '待收金额（元）',
                        align: 'center',
                        minWidth: 140,
                        render: (h, p) => {
                            return h('div', { style: 'white-space: nowrap;' }, [
                                h('span', p.row.waitCollectionAmount < 0 ? 0 : p.row.waitCollectionAmount),
                                h(
                                    'span',
                                    {
                                        style: {
                                            color: '#2d8cf0',
                                            cursor: 'pointer',
                                            marginLeft: '8px',
                                        },
                                        on: {
                                            click: () => {
                                                this.openPwdModal(2, p.row);
                                            },
                                        },
                                    },
                                    '收款',
                                ),
                            ]);
                        },
                    },
                    {
                        title: '最近收款日期',
                        key: 'latelyCollectionTime',
                        align: 'center',
                        minWidth: 170,
                        render: (h, p) => {
                            return [p.row.latelyCollectionTime || '-'];
                        },
                    },
                    {
                        title: '最近收款金额（元）',
                        key: 'latelyCollectionAmount',
                        align: 'center',
                        minWidth: 170,
                        render: (h, p) => {
                            return [p.row.latelyCollectionAmount || '-'];
                        },
                    },
                    {
                        title: '操作',
                        align: 'center',
                        minWidth: 470,
                        fixed: 'right',
                        render: (h, p) => {
                            return [
                                renderButton(h, { text: '使用明细', type: 'info' }, () => {
                                    jumpPage({
                                        path: '/sub-vue2/credit/creditDetail/use',
                                        params: { storeId: p.row.storeId, storeName: p.row.storeName },
                                    });
                                }),
                                renderButton(
                                    h,
                                    {
                                        text: '收款明细',
                                        type: 'info',
                                    },
                                    () => {
                                        jumpPage({
                                            path: '/sub-vue2/credit/creditDetail/collection',
                                            params: { storeId: p.row.storeId, storeName: p.row.storeName },
                                        });
                                    },
                                ),
                                renderButton(
                                    h,
                                    {
                                        text: '额度变更明细',
                                        type: 'info',
                                    },
                                    () => {
                                        jumpPage({
                                            path: '/sub-vue2/credit/creditDetail/amount',
                                            params: { storeId: p.row.storeId, storeName: p.row.storeName },
                                        });
                                    },
                                ),
                                renderButton(
                                    h,
                                    {
                                        text: `${p.row.creditAccountStatus ? '关闭' : '开通'}授信`,
                                        type: p.row.creditAccountStatus ? 'error' : 'success',
                                    },
                                    () => {
                                        this.openPwdModal(3, p.row);
                                    },
                                ),
                                renderButton(
                                    h,
                                    {
                                        text: `${p.row.salesmanNoticeStatus ? '关闭' : '开启'}提醒`,
                                        type: p.row.salesmanNoticeStatus ? 'warning' : 'primary',
                                    },
                                    () => {
                                        this.updateNotice(p.row);
                                    },
                                ),
                            ];
                        },
                    },
                ],
                data: [],
                loading: false,
            },
            formList: [
                {
                    title: '门店名称',
                    type: 'input',
                    key: 'storeName',
                    placeholder: '请输入门店名称',
                },
                {
                    title: '联系人名称',
                    type: 'input',
                    key: 'ownerName',
                    placeholder: '请输入联系人名称',
                },
                {
                    title: '联系方式',
                    type: 'input',
                    key: 'ownerPhone',
                    placeholder: '请输入联系方式',
                },
                {
                    title: '门店维护人',
                    type: 'select',
                    key: 'salesmanId',
                    placeholder: '请选择',
                    clearable: true,
                    options: [],
                },
            ],
            hasPwd: false, // 是否设置过密码
        };
    },

    mounted() {
        this.getSaleMan();
        this.getCreditAccount();
        this.getData();
    },

    methods: {
        /**
         * @description 管理员授信账户信息
         */
        getCreditAccount() {
            creditAccountInfo().then((res) => {
                this.hasPwd = res.isSetUpAuthorizePwd;
            });
        },

        /**
         * @description 密码设置成功回调
         */
        settingDone() {
            this.hasPwd = true;
        },

        /**
         * @description 打开密码验证
         * @param {Numebr} modalType 1调整额度 2收款 3关闭开启授信
         * @param {Object} activeItem 行数据
         */
        openPwdModal(modalType, activeItem) {
            if (this.hasPwd) {
                // 密码验证弹窗打开
                this.modalType = modalType;
                this.activeItem = activeItem;
                this.showVerifyModal = true;
            } else {
                this.$refs['pwdSetting'].open();
            }
        },

        /**
         * @description 开启/关闭提醒
         */
        updateNotice(item) {
            const cmdObj = [
                {
                    txt: '开启',
                    cmd: openNotice,
                },
                {
                    txt: '关闭',
                    cmd: closeNotice,
                },
            ][Number(item.salesmanNoticeStatus)];
            this.$Modal.confirm({
                title: '提示',
                content: `是否确认${cmdObj.txt}门店维护人的账户变动短信提醒？`,
                onOk: () => {
                    cmdObj.cmd({ creditAccountId: item.id }).then(() => {
                        this.$Message.success('操作成功');
                        this.getData();
                    });
                },
            });
        },

        /**
         * @description 查询门店维护人列表
         */
        getSaleMan() {
            SalesmanList().then((res) => {
                this.formList[3].options = res || [];
            });
        },

        /**
         * @description 导出列表
         */
        onExport() {
            this.list.loading = true;
            this.isExporting = true;
            const params = this.getParams();
            creditExport(params)
                .then((res) => {
                    let aLink = document.createElement('a');
                    aLink.href = res || '';
                    // console.log('href ===', res)
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    this.$Notice.success({
                        title: '导出成功',
                    });
                    this.list.loading = false;
                    this.isExporting = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.$Notice.error({
                        title: '导出失败 ！',
                    });
                    this.list.loading = false;
                    this.isExporting = false;
                });
        },

        /**
         * @description 获取表格数据
         */
        getData() {
            const params = this.getParams();
            this.list.loading = true;
            creditList(params)
                .then((res) => {
                    this.list.data = [];
                    this.list.total = Number(res.total);
                    this.list.data = res.records || [];
                })
                .catch(() => {})
                .finally(() => {
                    this.list.loading = false;
                });
        },

        /**
         * @description 获取查询参数
         */
        getParams() {
            let p = this.$refs['table-form'].getFormData();
            p = clearEmpty(p, true);
            if ('win' in p) {
                p.win = Boolean(p.win);
            }

            const copyP = { ...p };
            const params = {
                ...copyP,
                current: this.list.page,
                size: this.list.size,
            };
            return params;
        },

        /**
         * @description 页码改变
         * @param {Number} val 页码
         */
        onPageChange(val) {
            this.list.page = val;
            this.getData();
        },

        /**
         * @description x条/页改变
         * @param {Number} val 条数
         */
        onPageSizeChange(val) {
            this.list.page = 1;
            this.list.size = val;
            this.getData();
        },

        /**
         * @description 查询
         */
        onSubmit() {
            this.list.page = 1;
            this.getData();
        },
    },
};
</script>

<style lang="less" scoped>
.credit-account-list {
}
</style>
