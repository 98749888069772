<template>
    <div>
        <PageHeader title="账号管理" hidden-breadcrumb>
            <Button type="primary" icon="md-add" @click="addAccount" slot="action">新增账号</Button>
        </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" @on-submit="onSubmit" ref="table-form" />
            <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list" />
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { SettingAccountList, SettingAccountEnable, SettingAccountReset } from '@/api/setting';
import { renderButton, clearEmpty, jumpPage } from '@/libs/util';

export default {
    name: 'setting-account-list',
    components: {
        tableForm,
        tablePage,
    },
    data() {
        return {
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    {
                        title: '用户姓名',
                        key: 'realName',
                        minWidth: 100,
                        align: 'center',
                    },
                    {
                        title: '登录账号',
                        key: 'userName',
                        minWidth: 100,
                        align: 'center',
                    },
                    {
                        title: '手机号',
                        key: 'mobile',
                        width: 150,
                        align: 'center',
                    },
                    {
                        title: '状态',
                        key: 'isEnabled',
                        align: 'center',
                        render: (h, p) => {
                            let badge = h('Badge', {
                                props: {
                                    status: p.row.isEnabled ? 'success' : 'error',
                                    text: p.row.isEnabled ? '已启用' : '已关闭',
                                },
                            });
                            return h('div', [badge]);
                        },
                        width: 130,
                    },
                    {
                        title: '账号类型',
                        key: 'isMaster',
                        render: (h, p) => {
                            return h('div', p.row.isMaster ? '主账号' : '子账号');
                        },
                        width: 130,
                        align: 'center',
                    },
                    {
                        title: '操作',
                        key: 'oprate',
                        minWidth: 320,
                        align: 'center',
                        render: (h, p) => {
                            if (p.row.isMaster) {
                                return [
                                    renderButton(h, { text: '详情', type: 'primary' }, () => {
                                        this.detail(p.row);
                                    }),
                                    renderButton(
                                        h,
                                        {
                                            text: '重置密码',
                                            type: 'success',
                                            confirm: true,
                                            confirmText: '确认重置当前密码？确认后将以短信形式发新密码到手机',
                                        },
                                        () => {
                                            this.resetPwd(p.row);
                                        },
                                    ),
                                ];
                            } else {
                                return [
                                    renderButton(h, { text: '详情', type: 'primary' }, () => {
                                        this.detail(p.row);
                                    }),
                                    renderButton(h, { text: '修改' }, () => {
                                        this.edit(p.row);
                                    }),
                                    renderButton(
                                        h,
                                        {
                                            text: '重置密码',
                                            type: 'success',
                                            confirm: true,
                                            confirmText: '确认重置当前密码？确认后将以短信形式发新密码到手机',
                                        },
                                        () => {
                                            this.resetPwd(p.row);
                                        },
                                    ),
                                    renderButton(
                                        h,
                                        {
                                            text: p.row.isEnabled ? '禁用' : '启用',
                                            confirmText: p.row.isEnabled ? '确认禁用该账户？' : '确认启用该账户？',
                                            type: 'warning',
                                            confirm: true,
                                        },
                                        () => {
                                            this.update(p.row);
                                        },
                                    ),
                                ];
                            }
                        },
                    },
                ],
                data: [],
            },
            formList: [
                {
                    title: '用户姓名',
                    type: 'input',
                    key: 'realName',
                    placeholder: '请输入用户姓名',
                },
            ],
        };
    },
    mounted() {
        this.getData();
        this.$bus.$on('refresh', this.getData);
    },
    beforeDestroy() {
        this.$bus.$off();
    },
    methods: {
        getData(p) {
            this.list.loading = true;
            p = clearEmpty(p);
            let params = {
                current: this.list.current,
                size: this.list.size,
                ...p,
            };
            return SettingAccountList(params)
                .then((res) => {
                    this.list.total = Number(res.total);
                    this.list.data = res.records || [];
                    this.isChangePage = false;
                    this.list.loading = false;
                })
                .catch(() => {
                    this.isChangePage = false;
                    this.list.loading = false;
                });
        },
        onSubmit(params) {
            this.list.current = 1;
            this.getData(params);
        },
        onPageChange(val) {
            if (this.isChangePage) return;
            this.list.current = val;
            let form = this.$refs['table-form'].getFormData();
            this.getData(form);
        },
        onPageSizeChange(val) {
            this.list.current = 1;
            this.isChangePage = true;
            this.list.size = val;
            let form = this.$refs['table-form'].getFormData();
            this.getData(form);
        },
        detail(row) {
            jumpPage({
                path: '/sub-vue2/setting/account/edit',
                params: {
                    id: row.id,
                    mode: 1,
                },
            });
        },
        addAccount() {
            jumpPage({
                path: '/sub-vue2/setting/account/edit',
                params: {
                    mode: 2,
                },
            });
        },
        edit(row) {
            jumpPage({
                path: '/sub-vue2/setting/account/edit',
                params: {
                    id: row.id,
                    mode: 2,
                },
            });
        },
        resetPwd(row) {
            this.list.loading = true;
            SettingAccountReset(row.id).then(
                () => {
                    this.list.loading = false;
                    this.$Notice.success({
                        title: '密码已重置',
                        desc: `将以短信形式发新密码到手机`,
                    });
                },
                () => {
                    this.list.loading = false;
                },
            );
        },
        update(row) {
            let id = row.id;
            let isEnabled = row.isEnabled;
            this.list.loading = true;
            SettingAccountEnable(isEnabled, id).then(
                () => {
                    this.list.loading = false;
                    this.$Notice.success({
                        title: (isEnabled ? '禁用' : '启用') + '成功',
                    });
                    let form = this.$refs['table-form'].getFormData();
                    this.getData(form);
                },
                () => {
                    this.list.loading = false;
                },
            );
        },
    },
};
</script>
