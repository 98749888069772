<!--
 * @Author: chengleilei
 * @Date: 2021-10-06 09:38:20
 * @Description: 企业\个体工商户 签约
-->
<template>
    <div class="sign-contract" v-loading="pageLoading">
        <PageHeader :title="userType == '12' ? '企业签约' : '个体工商户签约'" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage">
                <Icon type="md-arrow-back" />
            </div>
        </PageHeader>
        <Card class="ivu-mt" :bordered="false" dis-hover>
            <Alert show-icon class="com-alert">
                <p>
                    1、请确保您填写的手机号能<span class="red-font"
                        >及时查看验证码，于发送后10分钟内填写验证码，并打开签约链接</span
                    >，否则验证码和链接均会失效，需重新生成签约链接
                </p>
                <p>
                    2、<span class="red-font">链接仅可打开一次，关闭后失效</span
                    >，若需要再次进入签约页面，需重新生成签约链接
                </p>
                <p v-if="userType == '12'">
                    3、打开链接后签约过程中需进行<span class="red-font">小额打款认证</span
                    >，汇款账户名称为：中金支付有限公司，请提前联系财务负责人做好认证准备
                </p>
            </Alert>
            <Card
                :title="userType == '12' ? '请选择您的身份' : '请输入您收到的验证码'"
                :bordered="false"
                dis-hover
                class="ivu-mt i-table-no-border"
            >
                <Form
                    hide-required-mark
                    :model="['10', '30'].includes(protocolSignerType) ? legalForm : operatorForm"
                    ref="inputForm"
                    :rules="rules"
                    :label-width="150"
                >
                    <FormItem label="您的身份：" v-if="userType == '12'">
                        <RadioGroup v-model="protocolSignerName">
                            <Radio label="法定代表人"></Radio>
                            <Radio label="经办人"></Radio>
                        </RadioGroup>
                    </FormItem>
                    <div v-show="['10', '30'].includes(protocolSignerType)">
                        <FormItem label="法人姓名：">
                            <Input v-model="legalForm.agentName" placeholder="请输入法人姓名" disabled></Input>
                        </FormItem>
                        <FormItem label="法人身份证号：">
                            <Input v-model="legalForm.agentIdNumber" placeholder="请输入法人身份证号" disabled></Input>
                        </FormItem>
                        <FormItem label="法人手机号：">
                            <Input v-model="legalForm.agentPhoneNumber" placeholder="请输入法人手机号" disabled></Input>
                        </FormItem>
                    </div>
                    <div v-show="['20'].includes(protocolSignerType)">
                        <FormItem label="经办人姓名：" prop="agentName">
                            <Input
                                v-model="operatorForm.agentName"
                                maxlength="10"
                                placeholder="请输入经办人姓名"
                            ></Input>
                        </FormItem>
                        <FormItem label="经办人身份证号：" prop="agentIdNumber">
                            <Input
                                v-model="operatorForm.agentIdNumber"
                                maxlength="18"
                                placeholder="请输入经办人身份证号"
                            ></Input>
                        </FormItem>
                        <FormItem label="经办人手机号：" prop="agentPhoneNumber">
                            <Input
                                v-model="operatorForm.agentPhoneNumber"
                                maxlength="11"
                                placeholder="请输入经办人手机号"
                            ></Input>
                        </FormItem>
                    </div>
                    <FormItem label="短信验证码：">
                        <Input style="width: 200px" v-model="code" maxlength="6" placeholder="请输入短信验证码"></Input>
                        <Button class="ml-10" type="primary" :disabled="codeDisabled" @click="submitData">{{
                            codeMsg
                        }}</Button>
                    </FormItem>
                    <FormItem v-show="!signContractUrl">
                        <Button type="primary" :disabled="code.length < 6" @click="getUrl">生成签约链接</Button>
                    </FormItem>
                </Form>
            </Card>
            <Card
                title="签约链接"
                :bordered="false"
                dis-hover
                class="ivu-mt i-table-no-border"
                v-show="signContractUrl"
            >
                <p class="red-font">
                    <span>链接将在</span>
                    <CountDown :target="smsCodeExpireTime" :format="format" v-font="14" />
                    <span>后过期，链接仅可打开一次，关闭后失效。</span>
                </p>
                <p class="mb-10">
                    <a :href="signContractUrl" target="_blank">{{ signContractUrl }}</a>
                    <Button class="ml-10" size="small" type="primary" @click="handleCopy">复制链接</Button>
                </p>
                <p>1、请点击链接或复制链接到浏览器网址栏中签署协议</p>
                <p>
                    2、链接过期或失效后，若需再次进入签约页面，请重新获取验证码，将会生成新的链接，如在签约链接中发起过小额验证，验证金额仍然有效
                </p>
            </Card>
        </Card>
    </div>
</template>

<script>
import { clearEmpty, transPathToName, jumpPage } from '@/libs/util';
import { MOBILE_REG } from '@/libs/util';
import { accountDetail, getSignContractRecord, startSignContract, getSignContractUrl } from '@/api/dealerAccount';

export default {
    data() {
        const validatePhone = (rule, value, callback) => {
            if (!value) {
                callback(new Error(`请输入经办人手机号`));
            } else if (!MOBILE_REG.test(value.trim())) {
                callback(new Error(`手机号格式不正确`));
            } else {
                callback();
            }
        };
        return {
            pageLoading: false,
            userType: this.$route.query.userType || '12', // 账户类型【 12=企业用户 13=个体工商用户】
            cpcnAccountId: this.$route.query.cpcnAccountId || '', // 中金账户id
            userId: this.$route.query.userId || '', // 用户id
            protocolSignerName: '法定代表人', // 签署人类型
            code: '', // 验证码
            codeDisabled: false, // 是否禁用按钮
            countdown: 60, // 倒计时秒数
            codeMsg: '获取验证码', // 按钮上的文字
            timer: null, // 定时器
            // 法人信息
            legalForm: {
                agentName: '', // 法人姓名
                agentIdNumber: '', // 法人身份证号
                agentPhoneNumber: '', // 法人手机号
            },
            // 经办人信息
            operatorForm: {
                agentName: '', // 经办人姓名
                agentIdNumber: '', // 经办人身份证号
                agentPhoneNumber: '', // 经办人手机号
            },
            // 校验规则
            rules: {
                agentName: [
                    {
                        required: true,
                        message: '请输入经办人姓名',
                        trigger: 'blur',
                    },
                ],
                agentIdNumber: [
                    {
                        required: true,
                        message: '请输入经办人身份证号',
                        trigger: 'blur',
                    },
                ],
                agentPhoneNumber: [{ validator: validatePhone, trigger: 'blur' }],
            },
            signContractUrl: '', // 签约链接
            smsCodeExpireTime: new Date().getTime() + 60 * 10 * 1000, // 过期时间
        };
    },
    computed: {
        // 签署人类型【10：法人，20：经办人，30：个体工商户】
        protocolSignerType() {
            let type = '';
            if (this.userType == '12') {
                type = this.protocolSignerName === '法定代表人' ? '10' : '20';
            } else {
                type = '30';
            }
            return type;
        },
    },

    created() {
        this.pageLoading = true;
        this.getData();
    },

    // destroyed() {
    //     if (this.timer) {
    //         clearInterval(this.timer)
    //         this.timer = null
    //     }
    // },

    methods: {
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/assets/dealerAccount/list' });
        },

        /**
         * @description 倒计时自定义格式
         */
        format(date) {
            let s = parseInt(date / 1000);
            let m = 0;
            if (s > 60) {
                m = parseInt(s / 60);
                s = parseInt(s % 60);
                if (m >= 60) {
                    m = parseInt(m % 60);
                }
            }
            return `${m}分${s}秒`;
        },

        /**
         * @description 查看账户详情
         */
        getData() {
            getSignContractRecord(this.userId).then((res) => {
                if (res.signStatus == '20') {
                    this.pageLoading = false;
                    // 已签约跳转结果页
                    this.goPage();
                    return;
                }
                if (res.agentPhoneNumber) {
                    // 发起签约过
                    if (res.protocolSignerType == '20') {
                        this.protocolSignerName = '经办人';
                        this.operatorForm = {
                            agentName: res.agentName || '', // 法人姓名
                            agentIdNumber: res.agentIdNumber || '', // 法人身份证号
                            agentPhoneNumber: res.agentPhoneNumber || '', // 法人手机号
                        };
                    }
                    this.signContractUrl = res.signContractUrl || '';
                    this.smsCodeExpireTime = res.smsCodeExpireTime || '';
                }
                // 获取绑卡成功时默认信息
                this.getDefaultInfo();
            });
        },

        /**
         * @description 查看账户详情
         */
        getDefaultInfo() {
            const params = { cpcnAccountId: this.cpcnAccountId };
            accountDetail(params)
                .then((res) => {
                    this.legalForm = {
                        agentName: res.legalPersonName || '', // 法人姓名
                        agentIdNumber: res.legalPersonNumber || '', // 法人身份证号
                        agentPhoneNumber: res.legalPersonMobile || '', // 法人手机号
                    };
                })
                .finally(() => {
                    this.pageLoading = false;
                });
        },

        /**
         * @description 获取验证码
         */
        getCode() {
            if (!this.timer) {
                this.signContractUrl = '';
                this.timer = setInterval(() => {
                    if (this.countdown > 0 && this.countdown <= 60) {
                        this.countdown--;
                        if (this.countdown !== 0) {
                            this.codeDisabled = true;
                            this.codeMsg = `${this.countdown}s后重新获取`;
                        } else {
                            clearInterval(this.timer);
                            this.codeDisabled = false;
                            this.codeMsg = '获取验证码';
                            this.countdown = 60;
                            this.timer = null;
                        }
                    }
                }, 1000);
            }
        },

        /**
         * @description 提交表单数据
         */
        submitData() {
            this.$refs['inputForm'].validate((valid) => {
                if (!valid) {
                    this.$Modal.warning({
                        title: '提示',
                        content: '请完善表单内容',
                    });
                    return false;
                }
                let p = Object.assign(
                    {},
                    {
                        protocolSignerType: this.protocolSignerType,
                        userId: this.userId,
                    },
                    ['10', '30'].includes(this.protocolSignerType) ? this.legalForm : this.operatorForm,
                );
                p = clearEmpty(p);
                this.pageLoading = true;
                this.$Message.loading({
                    content: '提交中...',
                    duration: 0,
                });

                startSignContract(p)
                    .then((res) => {
                        if (res.signStatus == '10') {
                            // 未签约
                            this.getCode();
                        } else {
                            // 已签约
                            this.goPage();
                        }
                    })
                    .catch((err) => {
                        console.error('发起签约失败：', err);
                    })
                    .finally(() => {
                        this.$Message.destroy();
                        this.pageLoading = false;
                    });
            });
        },

        /**
         * @description 跳转页面
         * @param {String} path 页面路由
         */
        goPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/assets/dealerAccount/signContractResult' });
        },

        /**
         * @description 获取签约链接
         */
        getUrl() {
            getSignContractUrl(this.userId, this.code).then((res) => {
                this.signContractUrl = res.signContractUrl || '';
                this.smsCodeExpireTime = res.smsCodeExpireTime || '';
            });
        },

        /**
         * @description 复制链接到剪切板
         */
        handleCopy() {
            this.$Copy({
                text: this.signContractUrl,
            });
        },
    },
};
</script>

<style lang="less" scoped>
.sign-contract {
    .red-font {
        color: #ed4014;
    }
    .ml-10 {
        margin-left: 10px;
    }
    .mb-10 {
        margin-bottom: 10px;
    }
}
</style>
