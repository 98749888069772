<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div class="pnl upload-list">
        <!--vuedraggable 插件 可拖拽元素-->
        <draggable
            class="list-group"
            v-model="uploadList"
            v-bind="dragOptions"
            @start="isDragging = true"
            @end="onDragEnd"
        >
            <transition-group type="transition" name="flip-list">
                <span
                    :class="[{ bigSize: bigSize }, 'item', 'img-preview']"
                    v-for="(item, index) in uploadList"
                    :key="item.url"
                    title="点击预览"
                    :style="{ backgroundImage: 'url(' + item.url + ')' }"
                >
                    <img :class="[{ bigSize: bigSize }]" v-lazy="item.url" preview="0" :width="150" title="" alt="" />
                    <Icon
                        v-if="disabled"
                        class="btn-close"
                        type="md-close-circle"
                        @click.native="removeUploadItem(index)"
                    ></Icon>
                </span>
            </transition-group>
        </draggable>

        <Upload
            :class="[{ bigSize: bigSize }, 'item']"
            ref="upload"
            :before-upload="customerUpload"
            :on-success="onUploadSuccess"
            :on-format-error="onUploadFormatError"
            :on-exceeded-size="onUploadSizeError"
            :accept="accept"
            :format="format"
            :max-size="maxSize"
            :show-upload-list="true"
            type="drag"
            :style="style"
            :disabled="uploading"
            v-show="uploadList.length < maxlength"
        >
            <Spin size="small" fix v-if="uploading">上传中...</Spin>
            <slot v-if="$slots.content" name="content"></slot>
            <div v-else class="default-content">
                <Icon type="md-camera" size="40"></Icon>
            </div>
        </Upload>
    </div>
</template>

<script>
/** 图片上传组件 */
import draggable from 'vuedraggable';
import { upload } from '@/api/common';
export default {
    components: { draggable },
    props: {
        uploadList: {
            type: Array,
            default: () => {
                return [];
            },
        },
        maxlength: {
            type: Number,
            default: 5,
        },
        disabled: {
            type: Boolean,
            default: () => {
                return true;
            },
        },
        bigSize: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        maxSize: {
            type: Number,
            default: 4096,
        },
        format: {
            type: Array,
            default: () => {
                return ['jpg', 'jpeg', 'png'];
            },
        },
        accept: {
            type: String,
            default: '.gif,.png,.jpg,.jpeg,image/png,image/jpg,image/jpeg',
        },
    },
    data() {
        return {
            dragOptions: {
                animation: 10,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost',
            },
            uploading: false,
        };
    },

    methods: {
        onDragEnd() {
            this.isDragging = false;
            this.$emit('handleDragEnd', [...this.uploadList]);
        },
        customerUpload(file) {
            const size = Math.ceil(file.size / 1000);
            if (size > this.maxSize) {
                this.$Modal.warning({
                    title: '提示',
                    content: '图片过大，请上传「500KB」以内的图片',
                });
                return false;
            }
            let forms = new FormData();
            let name = file.name.split('.');
            forms.append('file', file, `${name[0]}.${new Date().getTime()}.${name[1]}`);
            this.uploading = true;
            upload(forms)
                .then((res) => {
                    this.uploading = false;
                    this.$Message.success('上传成功');
                    file.url = res;
                    // eslint-disable-next-line vue/no-mutating-props
                    this.uploadList.push(file);
                    this.$previewRefresh();
                })
                .catch((err) => {
                    this.uploading = false;
                    this.$Notice.error({
                        title: '上传失败',
                        desc: err.message,
                    });
                    this.$refs.upload.fileList.splice(this.$refs.upload.fileList.length - 1, 1);
                });
            return false;
        },

        removeUploadItem(i) {
            // console.log('删除 == ', i)
            // eslint-disable-next-line vue/no-mutating-props
            this.uploadList.splice(i, 1);
        },
        onUploadSuccess(res, file) {
            if (res.code === 200) {
                file.url = res.data;
                this.$Message.success('上传成功');
                // eslint-disable-next-line vue/no-mutating-props
                this.uploadList.push(file);
            } else {
                this.$Notice.error({
                    title: '上传失败',
                    desc: res.message,
                });
                this.$refs.upload.fileList.splice(this.$refs.upload.fileList.length - 1, 1);
            }
        },
        onUploadFormatError(file) {
            this.$Notice.warning({
                title: '文件格式不正确',
                desc: '文件 ' + file.name + ' 格式不正确，请上传 jpg 或 png 格式的图片。',
            });
        },
        onUploadSizeError(file) {
            this.$Notice.warning({
                title: '超出文件大小限制',
                desc: '文件 ' + file.name + ' 太大，不能超过 4M。',
            });
        },
    },
};
</script>

<style scoped lang="less">
@import '../../styles/variable';
// upload
.upload-list {
    padding-bottom: 10px;
    overflow: hidden;
    position: relative;
    min-width: 200px;
    min-height: 200px;
    .item {
        width: 204px;
        height: 299px;
        margin: @space @space 0 0;
        background-color: @white;
        text-align: center;
        position: relative;
        float: left;
        cursor: pointer;
        &.bigSize {
            width: 300px;
            height: 204px;
        }
        &.large {
            width: 200px;
            height: 200px;
            a {
                width: 100%;
                height: 100%;
            }
        }
        .btn-close {
            top: -@space / 2;
            right: -@space / 2;
            .radius();
            font-size: 30px;
            color: @red;
            background-color: @white;
            position: absolute;
            cursor: pointer;
            .flexCenter();
        }
        .btn-left {
            top: @space * 3.5;
            left: @space / 2;
            font-size: 30px;
            color: @blue;
            position: absolute;
            cursor: pointer;
        }
        .btn-right {
            top: @space * 3.5;
            right: @space / 2;
            font-size: 30px;
            color: @blue;
            position: absolute;
            cursor: pointer;
        }
        a {
            width: 100px;
            height: 100px;
            .flexCenter();
        }
        img {
            max-width: 100%;
            max-height: 100%;
        }
        .ivu-upload {
            height: 100%;
        }
        .ivu-upload-drag {
            border: 1px dashed #777;
            .radius(0);
            &:hover {
                border-color: @blue;
            }
            .ivu-icon {
                .center();
                font-size: 40px;
            }
        }
        .ivu-progress {
            float: left;
        }
    }
}

.bigSize {
    width: 300px;
    height: 204px;
}
.item img {
    border: 1px solid #ccc;
}
.ivu-upload-drag {
    width: 100%;
    height: 100%;
}
.upload-list /deep/ .item .ivu-upload-drag {
    width: 100%;
    height: 100%;
    background: rgba(250, 250, 250, 1);
}
.index-title {
    width: 100px;
    position: absolute;
    left: 10px;
    top: 108px;
    text-align: center;
    color: #2d8cf0;
    font-size: 16px;
    font-weight: bold;
}
.button {
    margin-top: 35px;
}
.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}
.ghost {
    opacity: 0.5;
    background: #191a23;
}
.list-group-item i {
    cursor: pointer;
}
</style>
