<template>
    <div v-loading="list.loading">
        <PageHeader title="条码/胎号查询" hidden-breadcrumb />
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" @on-submit="onSubmit" ref="table-form">
                <Button
                    class="ivu-ml-8"
                    :loading="isExporting"
                    :disabled="isExporting"
                    @click="templateDownload"
                    slot="extraBtn"
                    type="warning"
                    >模板下载</Button
                >
                <Upload
                    slot="extraBtn"
                    :before-upload="customerUpload"
                    style="display: inline-block"
                    :action="uploadApi"
                    :headers="uploadHeaders"
                    accept=".csv, .xlsx, .xls"
                    :on-success="onUploadSuccess"
                    :on-error="onUploadError"
                    :on-format-error="onUploadFormatError"
                    :show-upload-list="false"
                >
                    <Tooltip content="一次最多导入100个胎号" placement="top">
                        <Button class="ivu-ml-8" :loading="isUploading" :disabled="isUploading" type="success"
                            >批量导入</Button
                        >
                    </Tooltip>
                </Upload>
            </table-form>
            <Alert type="success">
                <p>
                    商品：{{ productCenterName }}，经销商：{{ distributorName || '-' }}，创建时间：{{
                        createDate || '-'
                    }}
                </p>
            </Alert>
            <table-page :hasPage="false" :list="list" :highlightRow="true" @highlightRowChange="highlightRowChange" />
        </Card>
        <!--【目测没用到】-->
        <!--<Dialog :showModal.sync="showModal" :barcodeInfo="barcodeInfo" />-->
    </div>
</template>
<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
//import Dialog from './components/dialog';
import util from '@/libs/util';
import axios from 'axios';
import { getScanCodeRecord, getScanCodeInfos, temlpateUrl } from '@/api/scan';
export default {
    name: 'scan-record',
    components: {
        tableForm,
        tablePage,
        //Dialog,
    },
    data() {
        return {
            uploadApi: '/api/distributor/scanCode/barCode/importTyreCodes',
            uploadHeaders: {
                Authorization: util.cookies.get('Base-token'),
                'supplier-domain': 'dt',
                'Content-Type': 'multipart/form-data',
            },
            isExporting: false,
            isUploading: false,
            createDate: '',
            productCenterName: '',
            distributorName: '',
            //showModal: false,
            barcodeInfo: {},
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    {
                        title: '轮胎名称',
                        key: 'productName',
                        align: 'center',
                        fixed: 'left',
                        render: (h, params) => {
                            let text = params.row.productName || '-';
                            let className = '';
                            if (params.row.abnormalCode) {
                                className = 'font-red';
                            }
                            return h(
                                'div',
                                {
                                    class: className,
                                },
                                text,
                            );
                        },
                    },
                    {
                        title: '业务号',
                        key: 'orderId',
                        align: 'center',
                        render: (h, params) => {
                            let text = params.row.orderId || '-';
                            let className = '';
                            if (params.row.abnormalCode) {
                                className = 'font-red';
                            }
                            return h(
                                'div',
                                {
                                    class: className,
                                },
                                text,
                            );
                        },
                    },
                    {
                        title: '经销商/门店',
                        key: 'distributorName',
                        align: 'center',
                        render: (h, params) => {
                            let text = params.row.distributorName || '-';
                            let className = '';
                            if (params.row.abnormalCode) {
                                className = 'font-red';
                            }
                            return h(
                                'div',
                                {
                                    class: className,
                                },
                                text,
                            );
                        },
                    },
                    {
                        title: '扫码时间',
                        key: 'scanDate',
                        align: 'center',
                        render: (h, params) => {
                            let text = params.row.scanDate || '-';
                            let className = '';
                            if (params.row.abnormalCode) {
                                className = 'font-red';
                            }
                            return h(
                                'div',
                                {
                                    class: className,
                                },
                                text,
                            );
                        },
                    },
                    {
                        title: '扫码类型',
                        key: 'scanTypeDesc',
                        align: 'center',
                        render: (h, params) => {
                            let text = params.row.scanTypeDesc || '-';
                            let className = '';
                            if (params.row.abnormalCode) {
                                className = 'font-red';
                            }
                            return h(
                                'div',
                                {
                                    class: className,
                                },
                                text,
                            );
                        },
                    },
                    {
                        title: '备注',
                        key: 'remark',
                        align: 'center',
                        render: (h, params) => {
                            let text = params.row.remark || '-';
                            let className = '';
                            if (params.row.abnormalCode) {
                                className = 'font-red';
                            }
                            return h(
                                'div',
                                {
                                    class: className,
                                },
                                text,
                            );
                        },
                    },
                ],
                data: [],
                noDataText: '暂无查询结果，请输入条码、胎号进行查询 ~',
            },
            formList: [
                { title: '条码', type: 'input', placeholder: '请输入条码', key: 'barcode' },
                { title: '胎号', type: 'input', placeholder: '请输入胎号', key: 'tyreCode' },
                { title: '标签码', type: 'input', placeholder: '请输入标签码', key: 'qrCode' },
            ],
            highlightRow: {
                longitude: 120.32944,
                latitude: 30.293894,
            },
            type_str: {
                0: '经销商',
                1: '门店',
            },
        };
    },
    methods: {
        getData(param) {
            let p = {
                current: this.list.current,
                size: this.list.size,
                ...param,
            };
            this.list.loading = true;
            getScanCodeRecord(p)
                .then((res) => {
                    this.list.loading = false;

                    this.list.data = (res || []).map((item) => {
                        return {
                            ...item,
                            // scanerType: this.type_str[item.scanerType]
                        };
                    });

                    this.list.loading = true;
                    getScanCodeInfos({
                        ...param,
                    })
                        .then((data) => {
                            this.list.loading = false;
                            this.createDate = data.createDate || '-';
                            this.distributorName = data.distributorName || '-';
                            this.productCenterName = data.productCenterName || '-';
                        })
                        .catch(() => {
                            this.createDate = '-';
                            this.distributorName = '-';
                            this.productCenterName = '-';
                            this.list.loading = false;
                        });
                })
                .catch(() => {
                    this.createDate = '-';
                    this.distributorName = '-';
                    this.productCenterName = '-';
                    this.list.loading = false;
                });
        },
        highlightRowChange(row) {
            if (row.latitude && row.longitude) {
                this.highlightRow.longitude = row.longitude;
                this.highlightRow.latitude = row.latitude;
            }
        },
        onSubmit(param) {
            this.list.current = 1;
            this.list.data = [];
            if (!param.barcode && !param.tyreCode && !param.qrCode) {
                this.$Message.warning('请先输入条码、胎号、标签码进行查询哦~');
                return false;
            }
            this.getData(param);
        },
        customerUpload(file) {
            this.file = file;
            let forms = new FormData();
            forms.append('file', file);
            let configs = {
                headers: {
                    Authorization: util.cookies.get('Base-token'),
                    'supplier-domain': 'dt',
                    'Content-Type': 'multipart/form-data',
                },
            };
            let url = `/api/distributor/scanCode/barCode/importTyreCodes`;
            let that = this;
            this.$Message.loading({
                content: '导入中...',
                duration: 0,
            });
            this.isUploading = true;
            that.$Loading.start();
            axios
                .post(url, forms, configs)
                .then((res) => {
                    this.$Message.destroy();
                    if (res.data.code === 200) {
                        that.isUploading = false;
                        that.$Loading.finish();
                        if (res.data.data) {
                            that.$Message.success({
                                content: '查询结果已下载， 请查看~',
                            });
                            let url = res.data.data;
                            that.downLoadByALink(url);
                        } else {
                            that.$Message.warning({
                                content: '暂无查询结果，请重试！',
                            });
                        }
                    } else {
                        this.$Message.destroy();
                        that.isUploading = false;
                        that.$Loading.error();
                        that.$Message.warning({
                            content: '导入失败，请重试！',
                        });
                    }
                })
                .catch(() => {
                    this.$Message.destroy();
                    that.isUploading = false;
                    that.$Loading.error();
                    that.$Message.error({
                        content: '网络连接错误',
                    });
                });

            return false;
        },
        onUploadSuccess(res, file) {
            console.log('========', res, file);
        },
        onUploadError(error, file, fileList) {
            console.log('上传失败：', error, file, fileList);
        },
        onUploadFormatError(file) {
            this.$Notice.warning({
                title: '文件格式不正确',
                desc: '文件 ' + file.name + ' 格式不正确，请上传 xlsx 或 xls 格式的图片。',
            });
        },
        downLoadByALink(url) {
            let aLink = document.createElement('a');
            aLink.href = url || '';
            document.body.appendChild(aLink);
            aLink.click();
            document.body.removeChild(aLink);
        },
        templateDownload() {
            this.$Message.loading({
                content: '下载中...',
                duration: 0,
            });
            this.isExporting = true;
            temlpateUrl().then(
                (res) => {
                    this.$Message.destroy();
                    this.$Message.success('下载成功');
                    this.isExporting = false;
                    this.downLoadByALink(res || '');
                },
                () => {
                    this.$Message.destroy();
                    this.$Message.warning({
                        title: '下载失败 ！',
                    });
                    this.isExporting = false;
                },
            );
        },
    },
};
</script>
