<template>
    <Select
        v-model="currValue"
        filterable
        remote
        ref="storeUser"
        :disabled="disabled"
        :loading="loading"
        clearable
        :label-in-value="isLabelInvalue"
        @on-change="onchange"
        @on-query-change="remote"
        @on-open-change="onOpen"
    >
        <Option v-for="item in userList" :value="item.id" :key="item.id">{{
            item.label
        }}</Option>
    </Select>
</template>
<script>
import { debounce } from "lodash";
import { distributorAccountList } from "@/api/shop";
export default {
    props: {
        value: {
            type: [String, Number],
            default: "",
        },
        isLabelInvalue: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            userList: [],
            loading: false,
            realName: "",
        };
    },
    computed: {
        currValue: {
            get() {
                return this.value;
            },
            set(v) {
                this.$emit("input", v || "");
            },
        },
    },
    methods: {
        onchange(val) {
            this.$emit("onchange", val);
        },
        onOpen(isOpen) {
            if (isOpen) this.getDetailData(this.realName);
        },
        remote(query = "") {
            this.realName = query;
            this.getDetailData(query);
        },
        getDetailData: debounce(function d(query = "") {
            this.loading = true;
            this.realName = query;
            let param = {};
            if (query) {
                param.realName = this.realName;
            }
            distributorAccountList(param).then((data) => {
                this.loading = false;
                this.userList = [];
                this.userList = (data.records || []).map((item) => {
                    return {
                        id: item.id,
                        label: item.realName,
                    };
                });
            });
        }, 400),
        selectDefault(val) {
            // 如需默认选中第一项，手动调用该方法
            this.realName = val;
            this.getDetailData(this.realName).then(() => {
                if (!this.realName) return;
                let item = this.userList[0] || {};
                this.$refs.storeUser.onOptionClick({
                    value: item.id,
                    label: item.label,
                });
                this.$emit("onchange", { value: item.id, label: item.label });
            });
        },
    },
};
</script>
