<template>
    <div>
        <PageHeader title="经销商扫码异常" hidden-breadcrumb>
            <Button
                slot="action"
                type="info"
                class="ivu-ml"
                icon="md-cloud-download"
                @click="onExport"
                :loading="isExporting"
                >{{ isExporting ? '正在导出...' : '导出' }}</Button
            >
        </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form :formList="formList" @on-submit="onSubmit" ref="table-form" />
            <table-page @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" :list="list" />
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { getDistributorScanList, getDistributorScanParam, DistributorScanExport } from '@/api/scan';
export default {
    name: 'scan-distributor',
    components: {
        tableForm,
        tablePage,
    },
    data() {
        return {
            isExporting: false,
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                isChangePage: false,
                columns: [
                    { title: '条码号', key: 'barcode', minWidth: 200, align: 'center' },
                    { title: '胎号', key: 'tyrecode', minWidth: 200, align: 'center' },
                    { title: '类型', key: 'scanCodeTypeMeaning', minWidth: 200, align: 'center' },
                    { title: '扫码时间', key: 'scanCodeDate', minWidth: 170, align: 'center' },
                    { title: '扫码人', key: 'scanCodeOperateName', minWidth: 150, align: 'center' },
                    { title: '异常', key: 'scanCodeResultTypeMeaning', minWidth: 320, align: 'center' },
                ],
                data: [],
            },
            formList: [
                { title: '类型', type: 'select', key: 'abnormalCode', placeholder: '请选择类型', options: [] },
                { title: '扫码人', type: 'distributorSelect', key: 'scanCodeOperateId' },
                { title: '时间', type: 'daterange', key: ['scanCodeBeginTime', 'scanCodeEndTime'] },
            ],
        };
    },
    activated() {
        this.getTypeOption();
        this.getData();
    },
    methods: {
        getTypeOption() {
            getDistributorScanParam().then((res) => {
                let types = [
                    {
                        id: '-9999',
                        name: '全部',
                    },
                ];
                res.map((item) => {
                    types.push({
                        id: item.key,
                        name: item.value,
                    });
                });
                let newObj = this.formList[0];
                newObj.options = types;
            });
        },
        getData(p = {}) {
            this.list.loading = true;
            const _p = { ...p };
            if (_p.abnormalCode === '-9999') {
                _p.abnormalCode = null;
            }
            const param = {
                current: this.list.current,
                size: this.list.size,
                ..._p,
            };
            getDistributorScanList(param)
                .then((res) => {
                    this.list.loading = false;
                    this.list.total = Number(res.total);
                    this.list.data = (res.records || []).map((item) => {
                        return {
                            id: item.id,
                            sn: item.sn,
                            barcode: item.barcode || '--',
                            tyrecode: item.tyrecode || '--',
                            scanCodeResultTypeMeaning: item.abnormalValue || '--',
                            scanCodeDate: item.createDate || '--',
                            scanCodeOperateName: item.scanCodeOperateName || '--',
                            scanCodeTypeMeaning: item.scanCodeType
                                ? {
                                      52: '出库',
                                      53: '退货',
                                      54: '出库',
                                      null: '-',
                                  }[item.scanCodeType]
                                : '--',
                        };
                    });
                })
                .catch(() => {
                    this.list.loading = false;
                });
        },
        onSubmit(param) {
            this.list.current = 1;
            this.list.data = [];
            this.getData(param);
        },
        onPageChange(val) {
            this.list.current = val;
            let form = this.$refs['table-form'].getFormData();
            this.getData(form);
        },
        onPageSizeChange(val) {
            this.list.current = 1;
            this.list.size = val;
            let form = this.$refs['table-form'].getFormData();
            this.getData(form);
        },
        onExport() {
            this.list.loading = true;
            this.isExporting = true;
            let form = this.$refs['table-form'].getFormData();
            let params = {
                current: this.list.current,
                size: this.list.size,
                ...form,
            };
            // console.log('导出 参数：', params)
            DistributorScanExport(params)
                .then((res) => {
                    let aLink = document.createElement('a');
                    aLink.href = res || '';
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    this.$Notice.success({
                        title: '导出成功',
                    });
                    this.list.loading = false;
                    this.isExporting = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.$Notice.error({
                        title: '导出失败 ！',
                    });
                    this.list.loading = false;
                    this.isExporting = false;
                });
        },
    },
};
</script>
